import CellWithSubRow from "../../../../common/components/DataTable/CellWithSubRow";
import BasePerformanceSection from "../presentation/BasePerformanceSection";
import {metricOrderArray} from "../constant";
import {useSelectorState} from "../../common/providers/selectorProvider";
import {useContext} from "react";
import {WindowSizeContext} from "../../../../common/context/WindowSizeContext";


export default function ToplinePerformance({ information, data, isLoading, isRowExpanded = false }) {
    const windowSize = useContext(WindowSizeContext);

    const [metricValue, setMetricValue] = useSelectorState('metric', 'sales');
    const MetricInformationFormat = ({ information }) => {
        return (
            <div style={{ padding: 10 }}>
                <div style={{ fontWeight: 'bold', fontSize: 18 }}> Metrics Formula</div>
                <hr style={{ width: '100%', textAlign: 'left', margin: 2 }} ></hr>
                {information.map((info, index) => (
                    <div key={index}>
                        <li><span style={{ fontWeight: 'bold' }}>{info.name}:</span> {info.value}</li>
                    </div>
                ))}
            </div>
        );
    };


    const cellFormat = ({ row, getValue }) => {
        const value = getValue();
        const boxStyle = { fontWeight: row?.original?.subRows && row?.depth === 0 ? 'bold' : 'regular', };
        return (
            <CellWithSubRow
                boxStyle={boxStyle} row={row} marginLeftMinimum={windowSize.isOnMobile ? 0 : 1} isMultiSelect={false}
                onRowClicked={()=> setMetricValue( row?.original?.metric)}
                isRadioSelected={metricValue === row?.original?.metric}
            >
                {value}
            </CellWithSubRow>
        );
    };

    return <BasePerformanceSection
        title={'Topline Performance'}
        information={<MetricInformationFormat information={information} />}
        selectorsOrFilters={null}
        categoryName={'Metric'}
        firstColumnCellFormat={cellFormat} // TODO
        tableId={'toplinePerformance'}
        data={data}
        isLoading={isLoading}
        orderArray={metricOrderArray}

        onSelectRow={(row) => {
            setMetricValue(row?.metric)
        }}
        rowSelectionDepth={1}
        enableRowSelection={true}
        isRowExpanded={isRowExpanded}
    >
    </BasePerformanceSection>
}