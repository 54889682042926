import React from "react";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import { MONTH } from "../../Constants/general";
import { useSelectorState, useSelectorValue } from "../../private/customDashboards/common/providers/selectorProvider";


// For Showing 
// to utilize this component, must have <SelectorProvider> encapsulating the component you will use this in
// previous ExtraInfo component (..\presentation\businessUnitView\CustomerLocation\ExtraInfo.jsx) did not utilize Selector Context
export default function ExtraInfo({ AsOfDate }) {
    // AsOfDate parameter accepts component
    const selectedRadio = useSelectorValue('latest_full_month') || 'latest_month'
    const year = useSelectorValue('year');
    const monthFromContext = useSelectorValue('month');
    const month = [monthFromContext]
    const [isFiltersOpen, setIsFiltersOpen] = useSelectorState('isFiltersOpen', false);

    const selectedYearMonthText = React.useMemo(() => {
        if (!month || !month.length || !monthFromContext) {
            return '';
        }
        
        const maxMonth = Math.max(...month)
        return `${MONTH.find(o => o.value === maxMonth)?.label} ${year}`
    }, [month, year, selectedRadio])

    let message;
    if (!monthFromContext) {
        message = 'Loading...'
    } else if (selectedRadio === 'latest_month') {
        message = 'Showing latest Data for'
    } else if (selectedRadio === 'full_month') {
        message = 'Showing full month Data for'
    }
    return <Grid container>
        {selectedYearMonthText && <Grid item xs={12} textAlign="right">
            <Typography ml={5} display="inline">{message} </Typography>
            <Button
                onClick={() => setIsFiltersOpen(true)}
                sx={{
                    fontSize: "1rem",
                    verticalAlign: "unset",
                    padding: 0,
                    // textDecoration: "underline",
                    textTransform: "capitalize",
                }}
            >
                {selectedYearMonthText}
                <Edit />
            </Button>
        </Grid>}
        <Grid item xs={12} textAlign="right">
            <Typography ml={5}>
                {AsOfDate}
            </Typography>
        </Grid>
    </Grid>
}