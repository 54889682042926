import {useMemo, useContext, useState, useEffect, useRef} from "react"
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import CellWithSubRow from "../../../../common/components/DataTable/CellWithSubRow";
import {Box} from "@mui/system";
import NumericValueDisplay from "../../common/presentation/numericValueDisplay";
import {IndicatorCellBox} from "../../Customers/Transactions/BUGroupShareOfWallet";
import BarIndicator from "../../common/presentation/barIndicator";
import {convertNumberToLocaleString, convertToValidNumber, sortArrayBasedOnGivenArray} from "../../../../common/utils";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import {useSelectorValue} from "../../common/providers/selectorProvider";
import {sortingFn} from "../../TmpTSSSales/StorePerformance/StorePerformanceTable/utils";
import {NOT_AVAILABLE, SELECTOR_ZONE_STORE_TOGGLE} from "../constant";
import CellTooltip from "./CellTooltip";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import NoAvailableDataDisplay from "../../SMACDashboard/NoAvailableDataDisplay";

const thickBorder = '2px solid #C3C3C3!important'
const customCellStyle = {
    width: {xs: '2rem'},
    maxWidth: {sm: '1.5rem'},
    textAlign: "left",
    verticalAlign: "middle",
}
const defaultCellFormat = ({ row, getValue }) => {
    const value = getValue();
    const boxStyle = { fontWeight: 'regular' };
    return (
        <CellWithSubRow boxStyle={boxStyle} row={row} marginLeftMinimum={1}>
            {value}
        </CellWithSubRow>
    );
};


const NOT_AVAILABLE_CELL = <Box sx={{ width: { sm: '5em' }, marginX: 'auto', my: .5 , textAlign: 'center', color: 'gray'}}>
    <span style={{ textWrap: 'nowrap'}}>
        {NOT_AVAILABLE}
    </span>
</Box>


export const rowSortNumericFn = (rowA, rowB, columnId) => {
    const valueA = rowA?.getValue(columnId);
    const valueB = rowB?.getValue(columnId);

    if (valueA === null || valueA === undefined || valueA === NOT_AVAILABLE || isNaN(valueA)) {
        return -1;
    }
    if (valueB === null || valueB === undefined || valueB === NOT_AVAILABLE || isNaN(valueB)) {
        return 1;
    }

    return valueA - valueB;
};


export default function BasePerformanceTable({ 
    categoryName, 
    firstColumnCellFormat = defaultCellFormat, 
    tableId = "",
    data = [],
    isLoading,
    orderArray,
    enableRowSelection,
    rowSelectionDepth,
    onSelectRow,
    isRowExpanded = false
}) {
    const windowSize = useContext(WindowSizeContext);
    const metric = useSelectorValue('metric') || 'sales';
    const pesoSignPrefixMetrics = ['sales', 'atv', 'space_productivity', 'total_manpower_productivity', 'selling_manpower_productivity', 'non_selling_manpower_productivity', 'personal_shopper_manpower_productivity']
    const showPesoSignPrefix = pesoSignPrefixMetrics.includes(metric);  
    const showPercentageSignPostFix = metric === 'catchment_rate' || metric === 'conversion_rate';  

    const pesoSignPrefix = showPesoSignPrefix ? '₱' : '';
    const percentageSignPostFix = showPercentageSignPostFix ? '%' : '';
    const isToplinePerformance = tableId === 'toplinePerformance';

    const handleToplinePrefix =(pk) => { 
        const pesoSignPrefixKeys = ['Sales', 'Avg Txn Value', 'Space Productivity', 'Total Manpower Productivity', 'Selling Productivity', 'Non-Selling Productivity', 'Personal Shopper Productivity']
        if(pesoSignPrefixKeys.includes(pk)){
            return '₱'

        } else {
            return ''
        }
    }

    const handleToplinePostfix =(pk) => { 
        if(pk === 'Catchment Rate' || pk === 'Conversion Rate'){
            return '%'

        } else {
            return ''
        }
    }

    const handleToplineVsLYPostfix = (pk) => {
        const nonPercentageVsLyMetricLabels = ['Avg Units Sold', 'Catchment Rate', 'Conversion Rate']
        const nonPercentageVsLyMetricKeys = ['units_sold_per_transaction', 'catchment_rate', 'conversion_rate']
        if((isToplinePerformance && nonPercentageVsLyMetricLabels.includes(pk)) || // for topline table and is part of specified metric rows
            (!isToplinePerformance && nonPercentageVsLyMetricKeys.includes(metric))) { // for non-topline tables and selected metric is non-percentage for vs LY
            return ''
        } 
        
         return '%'
    }


    const handleFractionDigits = (pk, metric, isPercentageValue, isForTooltip = false) => {

        const catchmentConversionMetricsLabels = ['Catchment Rate', 'Conversion Rate']
        const catchmentConversionMetricKeys = ['catchment_rate', 'conversion_rate']

        const isToplineMetric = pk === 'Avg Units Sold' || 
            (isPercentageValue && catchmentConversionMetricsLabels.includes(pk));
    
        const isNonToplineMetric = metric === 'units_sold_per_transaction' || 
            (isPercentageValue && catchmentConversionMetricKeys.includes(metric));
    
        if (isToplinePerformance && isToplineMetric) return 2; // if Topline Table Component AND the row is for a specific metric
        if (!isToplinePerformance && isNonToplineMetric) return 2; // if NOT Topline Table Component AND the rows are using the specific metric/s for this rule
        
        const isForCatchmentConversionRate =  (isToplinePerformance && catchmentConversionMetricsLabels.includes(pk)) || (!isToplinePerformance &&  catchmentConversionMetricKeys.includes(metric))
        if (isForTooltip && isForCatchmentConversionRate) return 1; // if Topline Table Component tooltips AND the row is for a Catchment or Conversion Rate

        return isForTooltip ? 0 : 1; // default decimal places in tooltip is 0, while in cells it's 1; ensures that only ATV, Catchment & Conversion Rate will have decimals in tooltip labels
    };
    
    const getTooltipTextArray = (row, accessorKey) => {
        const hasValue = (row?.getValue() !== 0 && row?.getValue() !== null)

        if(hasValue) {
            return row.row.original[accessorKey + '_tooltip']
        }

        return [];
    }

    const checkIfHasValue = (value) => {
        return value !== 0 && value !== null && value !== undefined;
    };

    const toggleZoneStore = useSelectorValue(SELECTOR_ZONE_STORE_TOGGLE);

    let hasPagination = false; 
    if (toggleZoneStore === 'store' && tableId === 'zoneToStorePerformance') {
        hasPagination = true;
    }

    const tableColumns = useMemo(
        () => {
            return [
                {
                    id: "pk",
                    header: categoryName,
                    accessorKey: "pk",
                    cell: firstColumnCellFormat,
                    customStyle: {
                        width: {xs: '5rem'},
                        maxWidth: {sm: '3rem'},
                        height: "2.5rem",
                        textAlign: "left",
                        verticalAlign: "middle",
                        borderRight: thickBorder
                    },
                    headerStyle: {
                        textAlign: "center",
                        borderRight: thickBorder,
                        borderTop: '2px solid #D0DCEC!important',
                    },
                    sortingFn
                },
                {
                    id: "mtd",
                    header: "MTD",
                    headerStyle: { borderRight: thickBorder },
                    columns: [
                        {
                            id: "mtd_actual",
                            header: "Actual",
                            accessorKey: "mtd_actual",
                            cell: (row) => {
                                const pk = row.row?.original.pk
                                const value = row?.getValue()
                                const accessorKey = row.column.id
                                const tooltips = getTooltipTextArray(row, accessorKey)
                                const fractionDigits = handleFractionDigits(pk, metric)
                                const tooltipFractionDigits = handleFractionDigits(pk, metric, false, true)

                                const prefix = isToplinePerformance ? handleToplinePrefix(pk) : pesoSignPrefix
                                const postfix = isToplinePerformance ? handleToplinePostfix(pk) : percentageSignPostFix
                                const tooltipTitle = `${prefix}${convertNumberToLocaleString(value, tooltipFractionDigits)}${postfix}`

                                if (value?.toString() === 'Loading...') {
                                    return <LoadingComponent sxClasses={{py:0}}/>
                                }
                                if (value?.toString() === NOT_AVAILABLE) {
                                    return NOT_AVAILABLE_CELL
                                }
                                return <Box sx={{ width: { sm: '5em' }, marginX: 'auto', my: .5 }}>
                                    <span style={{ textWrap: 'nowrap' }}>
                                    <CellTooltip title={tooltipTitle} informationList={tooltips}>
                                        <NumericValueDisplay value={value} prefix={prefix} postfix={postfix} isAbbreviated={true} fractionDigits={fractionDigits}/>
                                    </CellTooltip>
                                    </span>
                                </Box>
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            },
                            sortingFn: rowSortNumericFn,
                            enableSorting: !isToplinePerformance
                        },
                        {
                            id: "mtd_vs_plan",
                            header: "vs Plan",
                            accessorKey: "mtd_vs_plan",
                            cell: (row) => {
                                const value = row?.getValue()
                                const accessorKey = row.column.id
                                const tooltips = getTooltipTextArray(row, accessorKey)

                                if (value?.toString() === 'Loading...') {
                                    return <LoadingComponent sxClasses={{py:0}}/>
                                }
                                if (value?.toString() === NOT_AVAILABLE) {
                                    return NOT_AVAILABLE_CELL
                                }
                                const tooltipTitle = `${convertNumberToLocaleString(value, 1)}%`
                                return <Box sx={{ width: { sm: '5em' }, marginX: 'auto', my: .5 }}>
                                    <span style={{ textWrap: 'nowrap' }}>
                                        <CellTooltip title={tooltipTitle} informationList={tooltips}>
                                            <IndicatorCellBox>
                                                {

                                                    checkIfHasValue(value)
                                                        ? <BarIndicator value={convertToValidNumber(value)?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs plan" />
                                                        : '-'
                                                }
                                            </IndicatorCellBox>
                                        </CellTooltip>
                                    </span>
                                </Box>
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            },
                            sortingFn: rowSortNumericFn,
                            enableSorting: !isToplinePerformance
                        },
                        {
                            id: "mtd_vs_ly",
                            header: "vs Last Year",
                            accessorKey: "mtd_vs_ly",
                            cell: (row) => {
                                const pk = row.row?.original.pk
                                const value = row?.getValue()
                                const accessorKey = row.column.id
                                const tooltips = getTooltipTextArray(row, accessorKey)
                                const fractionDigits = handleFractionDigits(pk, metric, true)
                                const checkFracttionDigits = fractionDigits === 2
                                const colorFunction = checkFracttionDigits && 'difference' 

                                if (value?.toString() === 'Loading...') {
                                    return <LoadingComponent sxClasses={{py:0}}/>
                                }
                                if (value?.toString() === NOT_AVAILABLE) {
                                    return NOT_AVAILABLE_CELL
                                }
                                const postfix = handleToplineVsLYPostfix(pk)
                                const tooltipTitle = `${convertNumberToLocaleString(value, fractionDigits)}${postfix}`
                                return <Box sx={{ width: { sm: '5em' }, marginX: 'auto', my: .5 }}>
                                    <span style={{ textWrap: 'nowrap' }}>
                                        <CellTooltip title={tooltipTitle} informationList={tooltips}>
                                            <IndicatorCellBox>
                                                {
                                                    checkIfHasValue(value)
                                                        ? <BarIndicator value={convertToValidNumber(value)?.toFixed(fractionDigits)} isValueColorCoded={true} isOnTable={true} label="vs last year" colorFunctionFor={colorFunction}
                                                            postfix={checkFracttionDigits && ' ' }/>
                                                        : '-'
                                                }
                                            </IndicatorCellBox>
                                        </CellTooltip>
                                    </span>
                                </Box>
                            },
                            
                            customStyle: {
                                ...customCellStyle,
                                borderRight: thickBorder
                            },
                            headerStyle: {
                                borderRight: thickBorder,
                                backgroundColor: "#E7F1F8"
                            },
                            sortingFn: rowSortNumericFn,
                            enableSorting: !isToplinePerformance
                        }
                    ]
                },

                {
                    id: "ytd",
                    header: "YTD",
                    columns: [
                        {
                            id: "ytd_actual",
                            header: "Actual",
                            accessorKey: "ytd_actual",
                            cell: (row) => {
                                const pk = row.row?.original.pk
                                const value = row?.getValue()

                                const accessorKey = row.column.id
                                const tooltips = getTooltipTextArray(row, accessorKey)
                                const fractionDigits = handleFractionDigits(pk, metric)
                                const tooltipFractionDigits = handleFractionDigits(pk, metric, false, true)

                                const prefix = isToplinePerformance ? handleToplinePrefix(pk): pesoSignPrefix
                                const postfix = isToplinePerformance ? handleToplinePostfix(pk): percentageSignPostFix
                                const tooltipTitle = `${prefix}${convertNumberToLocaleString(value, tooltipFractionDigits)}${postfix}`

                                if (value?.toString() === 'Loading...') {
                                    return <LoadingComponent sxClasses={{py:0}}/>
                                }
                                if (value?.toString() === NOT_AVAILABLE) {
                                    return NOT_AVAILABLE_CELL
                                }
                                return <Box
                                 sx={{ width: { sm: '5em' }, marginX: 'auto', my: .5 }}>
                                    <span style={{ textWrap: 'nowrap' }}>
                                    <CellTooltip title={tooltipTitle} informationList={tooltips}>
                                        <NumericValueDisplay value={value} prefix={prefix} postfix={postfix} isAbbreviated={true} fractionDigits={fractionDigits}/>
                                    </CellTooltip>
                                    </span>
                                </Box>
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            },
                            sortingFn: rowSortNumericFn,
                            enableSorting: !isToplinePerformance
                        },
                        {
                            id: "ytd_vs_plan",
                            header: "vs Plan",
                            accessorKey: "ytd_vs_plan",
                            cell: (row) => {
                                const value = row?.getValue()
                                const accessorKey = row.column.id
                                const tooltips = getTooltipTextArray(row, accessorKey)

                                if (value?.toString() === 'Loading...') {
                                    return <LoadingComponent sxClasses={{py:0}}/>
                                }
                                if (value?.toString() === NOT_AVAILABLE) {
                                    return NOT_AVAILABLE_CELL
                                }
                                const tooltipTitle = `${convertNumberToLocaleString(value, 1)}%`
                                return <Box sx={{ width: { sm: '5em' }, marginX: 'auto', my: .5 }}>
                                    <span style={{ textWrap: 'nowrap' }}>
                                        <CellTooltip title={tooltipTitle} informationList={tooltips}>
                                            <IndicatorCellBox>
                                                {

                                                    checkIfHasValue(value)
                                                        ? <BarIndicator value={convertToValidNumber(value)?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs plan" />
                                                        : '-'
                                                }
                                            </IndicatorCellBox>
                                        </CellTooltip>
                                    </span>
                                </Box>
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            },
                            sortingFn: rowSortNumericFn,
                            enableSorting: !isToplinePerformance
                        },
                        {
                            id: "ytd_vs_ly",
                            header: "vs Last Year",
                            accessorKey: "ytd_vs_ly",
                            cell: (row) => {
                                const pk = row.row?.original.pk
                                const value = row?.getValue()
                                const accessorKey = row.column.id
                                const tooltips = getTooltipTextArray(row, accessorKey)
                                const fractionDigits = handleFractionDigits(pk, metric, true)
                                const checkFracttionDigits = fractionDigits === 2
                                const colorFunction = checkFracttionDigits && 'difference' 

                                if (value?.toString() === 'Loading...') {
                                    return <LoadingComponent sxClasses={{py:0}}/>
                                }
                                if (value?.toString() === NOT_AVAILABLE) {
                                    return NOT_AVAILABLE_CELL
                                }
                                const postfix = handleToplineVsLYPostfix(pk)
                                const tooltipTitle = `${convertNumberToLocaleString(value, fractionDigits)}${postfix}`
                                return <Box sx={{ width: { sm: '5em' }, marginX: 'auto', my: .5 }}>
                                    <span style={{ textWrap: 'nowrap' }}>
                                        <CellTooltip title={tooltipTitle} informationList={tooltips}>
                                            <IndicatorCellBox>
                                                {

                                                    checkIfHasValue(value)
                                                        ? <BarIndicator value={convertToValidNumber(value)?.toFixed(fractionDigits)} isValueColorCoded={true} isOnTable={true} label="vs last year" colorFunctionFor={colorFunction} 
                                                           postfix={checkFracttionDigits && ' ' }/>
                                                        : '-'
                                                }
                                            </IndicatorCellBox>
                                        </CellTooltip>
                                    </span>
                                </Box>
                            },
                            customStyle: customCellStyle,
                            headerStyle: {
                                backgroundColor: "#E7F1F8"
                            },
                            sortingFn: rowSortNumericFn,
                            enableSorting: !isToplinePerformance
                        }
                    ]
                }
            ]
        }, [metric, data, toggleZoneStore, categoryName]
    )

    if(isLoading){
        return <LoadingComponent/>
    }

    return <TableComponentV2
        columns={tableColumns}
        data={sortArrayBasedOnGivenArray(data, orderArray, 'pk') || []}
        tableId={tableId}
        isCompact
        customTableHeaderStyle={{ backgroundColor: "#caddeb", textAlign: "center", height: "3em" }}
        customTableContainerStyle={{ 
            borderRadius: 0, 
            boxShadow: "none",
            maxHeight: !windowSize?.isOnMobile ? '70vh' : null 
        }}
        customStickyHeaderStyle={{
            position: !windowSize?.isOnMobile ? 'sticky' : 'relative',
            top: 0,
            zIndex:100
        }}
        hasPagination={hasPagination}
        propPageSize={10}
        enableRowSelection={enableRowSelection}
        rowSelectionDepth={rowSelectionDepth}
        onSelectRow={onSelectRow}
        isOpexAccounts={isRowExpanded}
        autoResetAll={toggleZoneStore === 'store' ? false : true} 
    />
}