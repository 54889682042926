import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Back from "../../../common/components/BackNavigateComponent";
import MostActiveModule from "./MostActiveModule";
import DashboardTrackingHistory from "./DashboardTrackingHistory";
import MostActiveUsers from "./MostActiveUsers";
import SelectorProvider from "../../customDashboards/common/providers/selectorProvider";
import DatePickerSelector from "./selectors/DatePickerSelector";
import UserSelector from "./selectors/UserSelector";
import ModuleSelector from "./selectors/ModuleSelector";

export default function DashboardUsageMonitoring() {
    return <SelectorProvider>
        <Grid container> 
            <Grid sx={{ mt: 3 }} display={'flex'}>
                <Back  />
                <Typography variant="h5" style={{ marginLeft: '16px' }}>Dashboard Usage Monitoring</Typography>
            </Grid>
            <Grid item xs={12} sm={12} pt={2}>                
                <Box flexGrow={1} display='flex' xs={12} sx={{backgroundColor: "#f6f6f8", borderRadius: ".5rem"}} p={2}>
                    <Grid container spacing={2}>
                        <Grid item sm={3} xs={12}>
                            <DatePickerSelector />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <UserSelector />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <ModuleSelector />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <MostActiveModule/>
            </Grid>

            <Grid item xs={12} sm={12}>
                <MostActiveUsers/>
            </Grid>
        </Grid>
    </SelectorProvider>
}