import React from 'react';
import { useSelectorState, useSelectorValue } from '../../common/providers/selectorProvider';
import DropdownCheckboxV3 from '../../../../common/components/DropdownCheckboxV3';
import { getSelectedOption } from '../../../../common/utils';
import { useBUDeptGroupings } from '../hooks/values';


export default function BusinessUnitSelector() {
    const { buData } = useBUDeptGroupings()
    const [selectedBusinessUnits, setSelectedBusinessUnits] = useSelectorState('bu_desc', []) || [];

    const metric = useSelectorValue('metric') || 'sales';
    const canUseSelector = metric !== 'catchment_rate' && metric !== 'conversion_rate';

    const selectedBUGrps = useSelectorValue('bu_grp');

    const filteredBu = selectedBUGrps?.length
    ? buData.filter(({ bu_grp }) => selectedBUGrps.includes(bu_grp))
    : buData;

    const buOptions = (filteredBu || [])
        .map((row) => {
            return {
                value: row?.bu_desc,
                label: row?.bu_desc,
            }
        })
        .filter((option) => option.value !== "" && option.label !== "")
        .sort((a, b) => a.label?.localeCompare(b.label));
    
    function handleSelectBU(data) {
        let result = data.map(a => a.value);
        setSelectedBusinessUnits(result)
        return result
    }

    const displayedValue = canUseSelector ?
        getSelectedOption(selectedBusinessUnits, buOptions) :
        []

    if (!buOptions || buOptions.length <= 1) {  // if we only have one option, don't show the selector
        return null
    }

    return (
        <DropdownCheckboxV3
            id="business_unit_filter"
            options={buOptions}
            label="Business Unit"
            limitTags={2}
            value={displayedValue || []}
            onChange={(event, newValue) => {
                handleSelectBU(getSelectedOption(newValue, buOptions))
            }}
            disabled={!canUseSelector}
        />
    )
}