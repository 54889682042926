import React from 'react';
import { useSelectorState } from '../../common/providers/selectorProvider';
import DropdownCheckboxV3 from '../../../../common/components/DropdownCheckboxV3';
import { getSelectedOption } from '../../../../common/utils';
import { useStoreNames } from '../hooks/values';


export default function StoreSelector() {
    const { data: storeData, isLoading } = useStoreNames();
    const [selectedStores, setSelectedStores] = useSelectorState('store', []) || [];

    const optionListStore = (storeData || [])
        .map((storeName) => {
            return {
                value: storeName,
                label: storeName
            }
        })
        .flat()
        .filter((option) => option.value !== "" && option.label !== "")
        .sort((a, b) => a.label?.localeCompare(b.label));

    
    function handleSelectStore(data) {
        let result = data.map(a => a.value);
        setSelectedStores(result)
        return result
    }

    return (
        <DropdownCheckboxV3
            id='zone_store_filter'
            label='Store'
            options={optionListStore}
            limitTags={2}
            value={getSelectedOption(selectedStores, optionListStore) || []}
            onChange={(event, newValue) => {
                handleSelectStore(getSelectedOption(newValue, optionListStore))
            }}
            isLoading={isLoading}
        />
    )
}