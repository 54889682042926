/* eslint-disable react-hooks/exhaustive-deps */
// utility to check if a column is used in params
import { JOIN_PAIR_DETAILS, MANPOWER_SOURCE_METRICS, OPERATIONS_BASIC_METRICS, OPERATIONS_SOURCE_METRICS } from "./constants";
import ApiClient from "../../../../common/API";
import { useQuery } from "react-query";
import { USE_QUERY_DEFAULT_OPTIONS } from "../../../../Constants/settings";
import { NOT_AVAILABLE } from "../constant";
import { convertNumberToLocaleString } from "../../../../common/utils";
import { useSelectorValue } from "../../common/providers/selectorProvider";
import React from "react";
import { NumericFormat } from "react-number-format";
import { useIsComponentUsable } from "../../common/providers/blacklistedComponentsProvider/hooks";
import {
    MANPOWER_METRIC_TOTAL_MANPOWER_PRODUCTIVITY,
    MANPOWER_METRIC_SELLING_MANPOWER_PRODUCTIVITY,
    MANPOWER_METRIC_NON_SELLING_MANPOWER_PRODUCTIVITY,
    MANPOWER_METRIC_PERSONAL_SHOPPER_MANPOWER_PRODUCTIVITY,
    OPERATIONS_METRIC_CATCHMENT_RATE,
    OPERATIONS_METRIC_CONVERSION_RATE,
    OPERATIONS_METRIC_SPACE_PRODUCTIVITY,
    SALES_METRIC_ATV, SALES_METRIC_ATV_PLAN,
    SALES_METRIC_SALES,
    SALES_METRIC_TRANSACTIONS, SALES_METRIC_TRANSACTIONS_PLAN,
    SALES_METRIC_UNITS_SOLD
} from "../dashboardDefinition";
import { HorizontalTooltipDivider } from "../../../../common/components/HorizontalTooltipDivider";
import moment from "moment";

export const hasColumn = (params, column) => {
    if (params.columns && params.columns.includes(column)) {
        return true;
    }
    if (params.aggregates && params.aggregates.includes(column)) {
        return true;
    }
    if (params.group_by && params.group_by.includes(column)) {
        return true;
    }

    return Object.keys(params).some(key => {
        if (key.startsWith(column) && !!params[key]) {
            return true;
        }
    })
}

export async function tableJoinApiCall(tableNames, joinPairs, params) {
    const { columns, aggregates, group_by, offset, limit, ...restParams } = params;
    const reformattedParams = {
        columns,
        aggregates,
        group_by,
        offset,
        limit
    }

    // convert strings to arrays
    if (columns && typeof columns === 'string') {
        reformattedParams.columns = columns.split(',').map(s => s.trim())
    }
    if (aggregates && typeof aggregates === 'string') {
        reformattedParams.aggregates = aggregates.split(',').map(s => s.trim())
    }
    if (group_by && typeof group_by === 'string') {
        reformattedParams.group_by = group_by.split(',').map(s => s.trim())
    }

    // fill in columns if not defined but has a group by
    if (
        reformattedParams.group_by && reformattedParams.group_by.length > 0
        && (!reformattedParams.columns || reformattedParams.length === 0)
    ) {
        reformattedParams.columns = reformattedParams.group_by
    }

    // fill in group by if we have aggregates and columns
    if (
        (!reformattedParams.group_by || reformattedParams.group_by.length === 0) &&
        (reformattedParams.aggregates && reformattedParams.aggregates.length > 0) &&
        (reformattedParams.columns && reformattedParams.columns.length > 0)
    ) {
        reformattedParams.group_by = reformattedParams.columns
    }

    // remove empty params
    Object.keys(restParams).forEach(key => {
        if (!restParams[key] || (Array.isArray(restParams[key]) && restParams[key].length === 0)) {
            delete restParams[key]
        }
    })

    // convert array params into strings
    Object.keys(restParams).forEach(key => {
        if (Array.isArray(restParams[key])) {
            const valueOrValues = restParams[key]
            if (valueOrValues.length === 1) {
                restParams[key] = valueOrValues[0]
            } else {
                restParams[`${key}__in`] = restParams[key].sort().join(',')
                delete restParams[key]
            }
        }
    })

    const joinConditions = joinPairs?.map(pair => JOIN_PAIR_DETAILS[pair.join('+')])

    const postData = {
        ...reformattedParams,
        join_conditions: joinConditions,
        filters: restParams
    }

    return ApiClient().post(`/data/table/join_query/`, postData)
}


// FIXME: WARNING! this is a WiP
export async function tableV2ApiCall(tableName, params) {
    const { columns, aggregates, group_by, offset, limit, filters_1, view, ...restParams } = params;
    const reformattedParams = {
        columns,
        aggregates,
        group_by,
        offset,
        limit,
        filters_1,
        view
    }


    // convert strings to arrays
    if (columns && typeof columns === 'string') {
        reformattedParams.columns = columns.split(',').map(s => s.trim())
    }
    if (aggregates && typeof aggregates === 'string') {
        reformattedParams.aggregates = aggregates.split(',').map(s => s.trim())
    }
    if (group_by && typeof group_by === 'string') {
        reformattedParams.group_by = group_by.split(',').map(s => s.trim())
    }

    // fill in columns if not defined but has a group by
    if (
        reformattedParams.group_by && reformattedParams.group_by.length > 0
        && (!reformattedParams.columns || reformattedParams.length === 0)
    ) {
        reformattedParams.columns = reformattedParams.group_by
    }

    // fill in group by if we have aggregates and columns
    if (
        (!reformattedParams.group_by || reformattedParams.group_by.length === 0) &&
        (reformattedParams.aggregates && reformattedParams.aggregates.length > 0) &&
        (reformattedParams.columns && reformattedParams.columns.length > 0)
    ) {
        reformattedParams.group_by = reformattedParams.columns
    }

    // remove empty params
    Object.keys(restParams).forEach(key => {
        if (!restParams[key] || (Array.isArray(restParams[key]) && restParams[key].length === 0)) {
            delete restParams[key]
        }
    })

    // convert array params into strings
    Object.keys(restParams).forEach(key => {
        if (Array.isArray(restParams[key])) {
            const valueOrValues = restParams[key]
            if (valueOrValues.length === 1) {
                restParams[key] = valueOrValues[0]
            } else {
                restParams[`${key}__in`] = restParams[key].sort().join(',')
                delete restParams[key]
            }
        }
    })


    const postData = {
        ...reformattedParams,
        use_raw_view: true,
        filters: restParams
    }

    console.log("postData", postData)

    return ApiClient().post(`/data/table/${tableName}/queryv2/`, postData)
}

export const useTableJoinQuery = ({ tableNames, joinPairs, params, useQueryOptions = {} }) => {
    return useQuery({
        queryKey: [tableNames, joinPairs, params],
        queryFn: () => {
            return tableJoinApiCall(tableNames, joinPairs, params).then(response => {
                const data = response.data.data
                if (params.limit === data.length) {
                    console.warn('Data limit reached, consider increasing the limit. Current limit:', params.limit)
                }
                return data
            })
        },

        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions
    })
}


export const useTableV2Query = ({ tableNames, params, useQueryOptions = {} }) => {
    return useQuery({
        queryKey: [tableNames, params],
        queryFn: () => {
            return tableV2ApiCall(tableNames[0], params).then(response => {
                const data = response.data.data
                if (params.limit === data.length) {
                    console.warn('Data limit reached, consider increasing the limit. Current limit:', params.limit)
                }
                return data
            })
        },

        ...USE_QUERY_DEFAULT_OPTIONS,
        ...useQueryOptions
    })
}


const getMetricDisplay = (row, metric, prefix, hiddenMetrics = []) => {
    const hideSales = hiddenMetrics.includes('sales');  
    const hideTransactions = hiddenMetrics.includes('transactions');
    const hideTransactionPlan = hiddenMetrics.includes('transaction_plan');
    const hideATVPlan = hiddenMetrics.includes('atv_plan');

    // optimize this
    if (metric === 'sales') {
        return {
            [`${prefix}_actual`]: row['cySales'],
            [`${prefix}_actual_tooltip`]: row['cySales'] === 0 && row['cySales'] === null ? [] : [<></>], // just so the tooltip label appears
            [`${prefix}_vs_plan`]: row['vsSalesPlan'],
            [`${prefix}_vs_plan_tooltip`]: row['vsSalesPlan'] === 0 && row['vsSalesPlan'] === null ? [] : [
                <><b>Sales Plan:</b> {convertNumberToLocaleString(row['salesPlan'])}</>,
            ],
            [`${prefix}_vs_ly`]: row['vsLYSales'],
            [`${prefix}_vs_ly_tooltip`]: row['vsLYSales'] === 0 && row['vsLYSales'] === null ? [] : [
                <><b>LY Sales:</b> {convertNumberToLocaleString(row['lySales'])}</>,
            ]
        }
    } else if (metric === 'transactions') {
        const tmp = {
            [`${prefix}_actual`]: row['cyTransactions'],
            [`${prefix}_actual_tooltip`]: row['cyTransactions'] === 0 && row['cyTransactions'] === null ? [] : [<></>], // just so the tooltip label appears
            [`${prefix}_vs_ly`]: row['vsLYTransactions'],
            [`${prefix}_vs_ly_tooltip`]: row['vsLYTransactions'] === 0 && row['vsLYTransactions'] === null ? [] : [
                <><b>LY Transactions:</b> {convertNumberToLocaleString(row['lyTransactions'])}</>,
            ]
        }
        const planValues = hideTransactionPlan ? {
            [`${prefix}_vs_plan`]: NOT_AVAILABLE,
        } : {
            [`${prefix}_vs_plan`]: row['vsTransactionPlan'],
            [`${prefix}_vs_plan_tooltip`]: row['vsTransactionPlan'] === 0 && row['vsTransactionPlan'] === null ? [] : [
                <><b>Transaction Plan:</b> {convertNumberToLocaleString(row['transactionPlan'])}</>,
            ],
        }
        return {
            ...tmp,
            ...planValues
        }
    } else if (metric === 'atv') {
        const tmp = {
            [`${prefix}_actual`]: row['cyATV'],
            [`${prefix}_actual_tooltip`]: row['cyATV'] === 0 && row['cyATV'] === null ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Sales / Transactions</>,
                <><b>Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['cySales'])}</>,
                <><b>Transactions:</b> {hideTransactions ? "hidden" : convertNumberToLocaleString(row['cyTransactions'])}</>,
            ],
            [`${prefix}_vs_ly`]: row['vsLYATV'],
            [`${prefix}_vs_ly_tooltip`]: row['vsLYATV'] === 0 && row['vsLYATV'] === null ? [] : [
                <><b>LY Avg Txn Value: </b> {convertNumberToLocaleString(row['lyATV'])}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['lySales'])}</>,
                <><b>LY Transactions:</b> {hideTransactions ? "hidden" : convertNumberToLocaleString(row['lyTransactions'])}</>,
            ],
        }
        const plan = hideATVPlan ? {
            [`${prefix}_vs_plan`]: NOT_AVAILABLE,
        } : {

            [`${prefix}_vs_plan`]: row['vsATVPlan'],
            [`${prefix}_vs_plan_tooltip`]: row['vsATVPlan'] === 0 && row['vsATVPlan'] === null ? [] : [
                <><b>Avg Txn Value Plan:</b> {convertNumberToLocaleString(row['atvPlan'])}</>,
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Sales Plan / Transaction Plan</>,
                <><b>Sales Plan:</b> {convertNumberToLocaleString(row['salesPlan'])}</>,
                <><b>Transaction Plan:</b> {convertNumberToLocaleString(row['transactionPlan'])}</>,
            ],

        }
        return {
            ...tmp,
            ...plan
        }
    } else if (metric === 'units_sold_per_transaction') {
        return {
            [`${prefix}_actual`]: row['cyUnitsSoldPerTransaction'],
            [`${prefix}_actual_tooltip`]: row['cyUnitsSoldPerTransaction'] === 0 && row['cyUnitsSoldPerTransaction'] === null ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Units Sold / Transactions</>,
                <><b>Units Sold:</b> {hideTransactions ? "hidden" : convertNumberToLocaleString(row['cyTransactions'])}</>,
                <><b>Transactions:</b> {convertNumberToLocaleString(row['cyUnitsSold'])}</>,
            ],
            [`${prefix}_vs_plan`]: NOT_AVAILABLE,
            [`${prefix}_vs_ly`]: row['vsLYUnitsSoldPerTransaction'],
            [`${prefix}_vs_ly_tooltip`]: row['vsLYUnitsSoldPerTransaction'] === 0 && row['vsLYUnitsSoldPerTransaction'] === null ? [] : [
                <><b>LY Avg Units Sold:</b> {convertNumberToLocaleString(row['lyUnitsSoldPerTransaction'], 2)}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Units Sold:</b> {convertNumberToLocaleString(row['lyUnitsSold'])}</>,
                <><b>LY Transactions:</b> {hideTransactions ? "hidden" : convertNumberToLocaleString(row['lyTransactions'])}</>,
            ],
        }
    } else if (metric === 'catchment_rate') {
        return {
            [`${prefix}_actual`]: row['cyCatchmentRate'],
            [`${prefix}_actual_tooltip`]: row['cyCatchmentRate'] === 0 && row['cyCatchmentRate'] === null ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Foot Traffic / Mall Foot Traffic</>,
                <><b>Foot Traffic:</b> {convertNumberToLocaleString(row['cyDSFootTraffic'])}</>,
                <><b>Mall Foot Traffic:</b> {convertNumberToLocaleString(row['cyMallFootTraffic'])}</>,
            ],
            [`${prefix}_vs_plan`]: NOT_AVAILABLE,
            [`${prefix}_vs_ly`]: row['vsLYCatchmentRate'],
            [`${prefix}_vs_ly_tooltip`]: row['vsLYCatchmentRate'] === 0 && row['vsLYCatchmentRate'] === null ? [] : [
                <><b>LY Catchment Rate:</b> {convertNumberToLocaleString(row['lyCatchmentRate'], 2)}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Foot Traffic:</b> {convertNumberToLocaleString(row['lyDSFootTraffic'])}</>,
                <><b>LY Mall Foot Traffic:</b> {convertNumberToLocaleString(row['lyMallFootTraffic'])}</>,
            ]
        }
    } else if (metric === 'conversion_rate') {
        return {
            [`${prefix}_actual`]: row['cyConversionRate'],
            [`${prefix}_actual_tooltip`]: row['cyConversionRate'] === 0 && row['cyConversionRate'] === null ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Transactions / Foot Traffic</>,
                <><b>Transactions:</b> {convertNumberToLocaleString(row['cyTransactions'])}</>,
                <><b>Foot Traffic:</b> {convertNumberToLocaleString(row['cyDSFootTraffic'])}</>,
            ],
            [`${prefix}_vs_plan`]: NOT_AVAILABLE,
            [`${prefix}_vs_ly`]: row['vsLYConversionRate'],
            [`${prefix}_vs_ly_tooltip`]: row['vsLYConversionRate'] === 0 && row['vsLYConversionRate'] === null ? [] : [
                <><b>LY Conversion Rate:</b> {convertNumberToLocaleString(row['lyConversionRate'], 2)}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Transactions:</b> {convertNumberToLocaleString(row['lyTransactions'])}</>,
                <><b>LY Foot Traffic:</b> {convertNumberToLocaleString(row['lyDSFootTraffic'])}</>,
            ]
        }
    } else if (metric === 'space_productivity') {
        return {
            [`${prefix}_actual`]: row['cySpaceProductivity'],
            [`${prefix}_actual_tooltip`]: row['cySpaceProductivity'] === 0 && row['cySpaceProductivity'] === null ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Gross Sales / Months Coverage / Net Selling Area</>,
                <><b>Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['cySales'])}</>,
                <><b>Months:</b> {convertNumberToLocaleString(row['cySalesMonthsCoverage'])}</>,
                <><b>Net Selling Area:</b> {convertNumberToLocaleString(row['cyNSA'])}</>,
            ],
            [`${prefix}_vs_plan`]: row['vsSpaceProductivityPlan'],
            [`${prefix}_vs_plan_tooltip`]: row['vsSpaceProductivityPlan'] === 0 && row['vsSpaceProductivityPlan'] === null ? [] : [
                <><b>Space Productivity Plan:</b> {convertNumberToLocaleString(row['spaceProductivityPlan'])}</>,
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Sales Plan / Months Coverage / Net Selling Area</>,
                <><b>Sales Plan:</b> {convertNumberToLocaleString(row['salesPlan'])}</>,
                <><b>Months:</b> {convertNumberToLocaleString(row['cySalesMonthsCoverage'])}</>,
                <><b>Net Selling Area:</b> {convertNumberToLocaleString(row['cyNSA'])}</>,
            ],
            [`${prefix}_vs_ly`]: row['vsLYSpaceProductivity'],
            [`${prefix}_vs_ly_tooltip`]: row['vsLYSpaceProductivity'] === 0 && row['vsLYSpaceProductivity'] === null ? [] : [
                <><b>LY Space Productivity:</b> {convertNumberToLocaleString(row['lySpaceProductivity'])}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['lySales'])}</>,
                <><b>LY Months:</b> {convertNumberToLocaleString(row['lySalesMonthsCoverage'])}</>,
                <><b>LY Net Selling Area:</b> {convertNumberToLocaleString(row['lyNSA'])}</>,
            ]
        }
    } else if (metric === 'total_manpower_productivity') {
        return {    
            [`${prefix}_actual`]: row['cyTotalManpowerProductivity'],
            [`${prefix}_actual_tooltip`]: row['cyTotalManpowerProductivity'] === 0 && row['cyTotalManpowerProductivity'] === null ? [] :  [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Gross Sales / Total Headcount</>,
                <><b>Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['cySales'])}</>,
                <><b>Total Headcount:</b> {convertNumberToLocaleString(row['cyTotalHeadcount'])}</>,
            ],
          
            [`${prefix}_vs_plan`]: NOT_AVAILABLE,
          
            [`${prefix}_vs_ly`]: row['vsLYTotalManpowerProductivity'],
            [`${prefix}_vs_ly_tooltip`]: row['vsLYTotalManpowerProductivity'] === 0 && row['vsLYTotalManpowerProductivity'] === null ? [] : [
                <><b>LY Total Manpower Productivity:</b> {convertNumberToLocaleString(row['vsLYTotalManpowerProductivity'])}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['lySales'])}</>,
                <><b>LY Total Headcount:</b> {convertNumberToLocaleString(row['lyTotalHeadcount'])}</>,
            ]
        }
    }
    else if (metric === 'selling_manpower_productivity') {
        return {    
            [`${prefix}_actual`]: row['cySellingProductivity'],
            [`${prefix}_actual_tooltip`]: row['cySellingProductivity'] === 0 && row['cySellingProductivity'] === null ? [] :  [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Gross Sales / Selling Headcount</>,
                <><b>Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['cySales'])}</>,
                <><b>Total Headcount:</b> {convertNumberToLocaleString(row['cySellingHeadcount'])}</>,
            ],
          
            [`${prefix}_vs_plan`]: NOT_AVAILABLE,
          
            [`${prefix}_vs_ly`]: row['vsLYSellingProductivity'],
            [`${prefix}_vs_ly_tooltip`]: row['vsLYSellingProductivity'] === 0 && row['vsLYSellingProductivity'] === null ? [] : [
                <><b>LY Selling Productivity:</b> {convertNumberToLocaleString(row['vsLYSellingProductivity'])}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['lySales'])}</>,
                <><b>LY Selling Headcount:</b> {convertNumberToLocaleString(row['lySellingHeadcount'])}</>,
            ]
        }
    }
    else if (metric === 'non_selling_manpower_productivity') {
        return {    
            [`${prefix}_actual`]: row['cyNonSellingProductivity'],
            [`${prefix}_actual_tooltip`]: row['cyNonSellingProductivity'] === 0 && row['cyNonSellingProductivity'] === null ? [] :  [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Gross Sales / Non-Selling Headcount</>,
                <><b>Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['cySales'])}</>,
                <><b>Total Headcount:</b> {convertNumberToLocaleString(row['cyNonSellingHeadcount'])}</>,
            ],
          
            [`${prefix}_vs_plan`]: NOT_AVAILABLE,
          
            [`${prefix}_vs_ly`]: row['vsLYNonSellingProductivity'],
            [`${prefix}_vs_ly_tooltip`]: row['vsLYNonSellingProductivity'] === 0 && row['vsLYNonSellingProductivity'] === null ? [] : [
                <><b>LY Non-Selling Productivity:</b> {convertNumberToLocaleString(row['lyNonSellingProductivity'])}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['lySales'])}</>,
                <><b>LY Non-Selling Headcount:</b> {convertNumberToLocaleString(row['lyNonSellingHeadcount'])}</>,
            ]
        }
    }
    else if (metric === 'personal_shopper_manpower_productivity') {
        return {    
            [`${prefix}_actual`]: row['cyPersonalShopperProductivity'],
            [`${prefix}_actual_tooltip`]: row['cyPersonalShopperProductivity'] === 0 && row['cyPersonalShopperProductivity'] === null ? [] :  [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Personal Shopper Sales / Personal Shopper Headcount</>,
                <><b>Personal Shopper Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['cyPsSales'])}</>,
                <><b>Personal Shopper Headcount:</b> {convertNumberToLocaleString(row['cyPersonalShopperHeadcount'])}</>,
            ],
          
            [`${prefix}_vs_plan`]: NOT_AVAILABLE,
          
            [`${prefix}_vs_ly`]: row['vsPersonalShopperProductivity'],
            [`${prefix}_vs_ly_tooltip`]: row['vsPersonalShopperProductivity'] === 0 && row['vsPersonalShopperProductivity'] === null ? [] : [
                <><b>LY Personal Shopper Productivity:</b> {convertNumberToLocaleString(row['vsPersonalShopperProductivity'])}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Personal Shopper Sales:</b> {hideSales ? "hidden" : convertNumberToLocaleString(row['lyPsSales'])}</>,
                <><b>LY Personal Shopper Headcount:</b> {convertNumberToLocaleString(row['lyPersonalShopperHeadcount'])}</>,
            ]
        }           
    }
}


export const rowsToDisplay = (mtdDisplayData, ytdDisplayData, pkKey, metric, hiddenMetrics = []) => {
    const groupedData = {};
    const noValues = [0, null, undefined]
    mtdDisplayData?.filter(row => !noValues.includes(row?.cySales)).forEach(row => {
        const keyValue = row[pkKey];
        if (!groupedData[keyValue]) {
            groupedData[keyValue] = {
                pk: row[pkKey],
                pkKey: pkKey
            }
        }
        groupedData[keyValue] = {
            ...groupedData[keyValue],
            ...getMetricDisplay(row, metric, 'mtd', hiddenMetrics)
        }
    })

    ytdDisplayData?.filter(row => !noValues.includes(row?.cySales)).forEach(row => {
        const keyValue = row[pkKey];
        if (!groupedData[keyValue]) {
            groupedData[keyValue] = {
                pk: row[pkKey],
                pkKey: pkKey
            }
        }
        groupedData[keyValue] = {
            ...groupedData[keyValue],
            ...getMetricDisplay(row, metric, 'ytd', hiddenMetrics)
        }
    });


    return Object.values(groupedData);
}

export const processBUDeptDict = (data) => {
    const bu_grp_to_bu = {};
    const bu_to_dept = {};
    const dept_list = [];

    data.forEach(entry => {
        const buGrp = entry.bu_grp;
        const bu = entry.bu_desc;
        const dept = entry.dept_desc_standardized;

        // Populate bu_grp_to_bu
        if (!bu_grp_to_bu[buGrp]) {
            bu_grp_to_bu[buGrp] = [];
        }
        if (!bu_grp_to_bu[buGrp].includes(bu)) {
            bu_grp_to_bu[buGrp].push(bu);
        }

        // Populate bu_to_dept
        if (!bu_to_dept[bu]) {
            bu_to_dept[bu] = [];
        }
        if (!bu_to_dept[bu].includes(dept)) {
            bu_to_dept[bu].push(dept);
        }

        dept_list.push(dept)
    });

    return { bu_grp_to_bu, bu_to_dept, dept_list };
};



export const NumericFormatField = React.forwardRef(function NumericFormatField(
    props,
    ref,
) {
    const { onChange, value, ...other } = props;

    const formattedValue = value ? value.toString() : ''; // Ensure value is a string

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            value={formattedValue}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});

export const useMetricVisibility = () => {
    return {
        ['sales']: useIsComponentUsable(SALES_METRIC_SALES),
        ['transactions']: useIsComponentUsable(SALES_METRIC_TRANSACTIONS),
        ['transaction_plan']: useIsComponentUsable(SALES_METRIC_TRANSACTIONS_PLAN),
        ['atv']: useIsComponentUsable(SALES_METRIC_ATV),
        ['atv_plan']: useIsComponentUsable(SALES_METRIC_ATV_PLAN),
        ['units_sold_per_transaction']: useIsComponentUsable(SALES_METRIC_UNITS_SOLD),
        ['catchment_rate']: useIsComponentUsable(OPERATIONS_METRIC_CATCHMENT_RATE),
        ['conversion_rate']: useIsComponentUsable(OPERATIONS_METRIC_CONVERSION_RATE),
        ['space_productivity']: useIsComponentUsable(OPERATIONS_METRIC_SPACE_PRODUCTIVITY),
        ['total_manpower_productivity']: useIsComponentUsable(MANPOWER_METRIC_TOTAL_MANPOWER_PRODUCTIVITY),
        ['selling_manpower_productivity']: useIsComponentUsable(MANPOWER_METRIC_SELLING_MANPOWER_PRODUCTIVITY),
        ['non_selling_manpower_productivity']: useIsComponentUsable(MANPOWER_METRIC_NON_SELLING_MANPOWER_PRODUCTIVITY),
        ['personal_shopper_manpower_productivity']: useIsComponentUsable(MANPOWER_METRIC_PERSONAL_SHOPPER_MANPOWER_PRODUCTIVITY),
    }
}

export const useIsMetricVisible = (metric) => {
    return useMetricVisibility()[metric]
}


export const defaultDataFilter = (row) => {
    // filter out rows will all 0 and null values
    const result = OPERATIONS_SOURCE_METRICS.some(metric => {
        return row[metric] !== null && row[metric] !== 0 && row[metric] !== "0" && row[metric] !== undefined;
    })

    if (!result) {
        console.debug("Filtered out row", row)
    }
    return result
}


export const operationsDataFilter = (row) => {
    const result = OPERATIONS_BASIC_METRICS.some(metric => {
        return row[metric] !== null && row[metric] !== 0 && row[metric] !== "0" && row[metric] !== undefined;
    })

    if (!result) {
        console.debug("Filtered out row", row)
    }
    return result
}

export const manpowerDataFilter = (row) => {
    const result = MANPOWER_SOURCE_METRICS.some(metric => {
        return row[metric] !== null && row[metric] !== 0 && row[metric] !== "0" && row[metric] !== undefined;
    })

    if (!result) {
        console.debug("Filtered out row", row)
    }
    return result
}

// Function to determine the correct month based on if user simply wants the latest data (could be incomplete), or the data of the latest full month
export const getMonthBasedOnRadio = (dateRange, isLatest) => {
    if (!dateRange?.to) return null;

    const toDate = moment(dateRange.to);
    const latestMonth = toDate.month() + 1; // moment month is 0-based, so +1
    const fullMonth = toDate.month();

    if (!isLatest) { // usually selectedRadio === 'full_month'
        // Check if `toDate` is the last day of the month
        if (toDate.date() === toDate.daysInMonth()) {
            return latestMonth;
        } else {
            return fullMonth // Go to the previous month
        }
    }

    return latestMonth; // default is latest month
};

export function getFullMonthsDateRange(dateRange) {
    if (!dateRange?.to) return dateRange; // Return as-is if invalid input

    moment(dateRange.to);

    // If toDate is NOT the last day of the month, move it to the previous month's last day
    if (moment(dateRange.to).date() < moment(dateRange.to).daysInMonth()) {
        const toDate = moment(dateRange.to).subtract(1, 'month').endOf('month');

        return {
            ...dateRange,
            to: moment(toDate.format("YYYY-MM-DD")), // Convert back to string in the same format
        };
    }

    return dateRange
}


export function cleanParams(params) {
    if (!params) return {};

    const cleanedParams = { ...params };

    // Remove empty arrays
    Object.keys(cleanedParams).forEach(key => {
        if (Array.isArray(cleanedParams[key]) && cleanedParams[key].length === 0) {
            delete cleanedParams[key];
        } else if (cleanedParams[key] === undefined) {
            delete cleanedParams[key];
        } else if (cleanedParams[key] === null) {
            delete cleanedParams[key];
        } else if (typeof cleanedParams[key] === 'string' && cleanedParams[key].trim() === '') {
            delete cleanedParams[key];
        }
    });

    return cleanedParams;
}

export const useNestedData = (pkKeys, displayFlatLevel=0, params, useQueryOptions, useMtdData, useYtdData, defaultMetric) => {

    const hiddenMetrics = [];
    const isSalesMetricVisible = useIsMetricVisible('sales');
    const isTransactionsMetricVisible = useIsMetricVisible('transactions');

    if (!isSalesMetricVisible) {
        hiddenMetrics.push('sales');
    }

    if (!isTransactionsMetricVisible) {
        hiddenMetrics.push('transactions');
    }

    const metricSelected = useSelectorValue('metric') || defaultMetric;

    const [pkKey1, pkKey2, pkKey3] = pkKeys;

    // Generate params for specific depth
    const generateDepthParams = (pkKey) => ({
        columns: [...pkKey],
        limit: 1000,
        ...(params || {}),
    });
    
    const useQueryEnabled = useQueryOptions?.enabled !== false;

    // Helper function to reduce duplication of code
    const useDepthData = (depthParams, pkKey, parentDataCheck, depth) => {
        const {data: mtdData, isLoading: mtdLoading} = useMtdData(depthParams, {
            ...(useQueryOptions || {}),
            enabled: (parentDataCheck || displayFlatLevel === depth) && !!pkKey && useQueryEnabled, // only enable if we have parent data
        });
    
        const {data: ytdData, isLoading: ytdLoading} = useYtdData(depthParams, {
            ...(useQueryOptions || {}),
            enabled: (parentDataCheck || displayFlatLevel === depth) && !!pkKey && useQueryEnabled, // only enable if we have parent data
        });
    
        return { mtdData, mtdLoading, ytdData, ytdLoading };
    }

    // Helper function for getting data w/subrows
    const generateDataWithSubrows = (parentRows, childMtdData, childYtdData, parentKeys, childKey) => {
        return parentRows.map(parentRow => ({
            ...parentRow,
            subRows: rowsToDisplay(
                childMtdData.filter(row => row[parentKeys] === parentRow.pk),
                childYtdData.filter(row => row[parentKeys] === parentRow.pk),
                childKey,
                metricSelected,
                hiddenMetrics
            )
        }));
    };
    
    // Depth 1 Query
    const depth1Params = generateDepthParams([pkKey1]);
    const { 
        mtdData: mtdDepth1Data, 
        mtdLoading: mtdDepth1Loading, 
        ytdData: ytdDepth1Data, 
        ytdLoading: ytdDepth1Loading 
    } = useDepthData(depth1Params, pkKey1, true, 1); // parentDataCheck manually set to true to enable first depth query
    
    // Depth 2 Query
    const hasDepth1Data = !!mtdDepth1Data || !!ytdDepth1Data;
    const depth2Params = generateDepthParams([pkKey2, pkKey1]);
    const { 
        mtdData: mtdDepth2Data, 
        mtdLoading: mtdDepth2Loading, 
        ytdData: ytdDepth2Data, 
        ytdLoading: ytdDepth2Loading 
    } = useDepthData(depth2Params, pkKey2, hasDepth1Data, 2);
    
    // Depth 3 Query
    const hasDepth2Data = !!mtdDepth2Data || !!ytdDepth2Data;
    const depth3Params = generateDepthParams([pkKey3, pkKey2, pkKey1]);
    const { 
        mtdData: mtdDepth3Data, 
        mtdLoading: mtdDepth3Loading, 
        ytdData: ytdDepth3Data, 
        ytdLoading: ytdDepth3Loading 
    } = useDepthData(depth3Params, pkKey3, hasDepth2Data, 3);

    // Depth 1 display data
    const depth1Data = React.useMemo(() => {
        if (!metricSelected || !hasDepth1Data) {
            return []
        }

        return rowsToDisplay(mtdDepth1Data, ytdDepth1Data, pkKey1, metricSelected, hiddenMetrics)

    }, [hasDepth1Data, mtdDepth1Data, ytdDepth1Data, metricSelected, pkKey1, hiddenMetrics])

    // Depth 2 display data
    const depth2Data = React.useMemo(() => {
        if (displayFlatLevel === 1) {
            
            return depth1Data;
        } else if (displayFlatLevel === 2) {
            if (!mtdDepth2Data || !ytdDepth2Data || !metricSelected) {
                return []
            }
            return rowsToDisplay(mtdDepth2Data, ytdDepth2Data, pkKey2, metricSelected, hiddenMetrics)
        }

        if (!mtdDepth2Data || !ytdDepth2Data || !metricSelected || !depth1Data || !depth1Data.length) {
            return depth1Data;
        }

        return generateDataWithSubrows(depth1Data, mtdDepth2Data, ytdDepth2Data, pkKey1, pkKey2);
    }, [depth1Data, metricSelected, mtdDepth2Data, ytdDepth2Data, mtdDepth2Loading, mtdDepth2Loading, pkKey1, pkKey2, displayFlatLevel, hiddenMetrics,])

    // Depth 3 display data
    const depth3Data = React.useMemo(() => {
        if (displayFlatLevel === 1 || displayFlatLevel === 2) {
            return depth2Data;
        } else if (displayFlatLevel === 3) {
            if (!mtdDepth3Data || !ytdDepth3Data || !metricSelected) {
                return []
            }
            return rowsToDisplay(mtdDepth3Data, ytdDepth3Data, pkKey3, metricSelected, hiddenMetrics)
        }

        if (displayFlatLevel === 1) {
            return depth1Data;
        } else if (displayFlatLevel === 2) {
            return rowsToDisplay(mtdDepth2Data, ytdDepth2Data, pkKey2, metricSelected, hiddenMetrics)
        }

        if (!mtdDepth3Data || !ytdDepth3Data || !metricSelected || !depth2Data || !depth2Data.length) {
            return depth2Data;
        }

        return depth2Data.map(depth1Row => {
            return {
                ...depth1Row,
                subRows: generateDataWithSubrows(depth1Row.subRows || [], mtdDepth3Data, ytdDepth3Data, pkKey2, pkKey3)
            }
        })

    }, [depth2Data, metricSelected, mtdDepth3Data, ytdDepth3Data, mtdDepth3Loading, ytdDepth3Data, pkKey1, pkKey2, pkKey3, hiddenMetrics])

    
    return {
        data: depth3Data,
        isLoading: (mtdDepth1Loading || ytdDepth1Loading) || 
        (displayFlatLevel === 2 && (mtdDepth2Loading || ytdDepth2Loading)) || 
        (displayFlatLevel === 3 && (mtdDepth3Loading || ytdDepth3Loading)) ,
    }
}

