import React from 'react'
import VerticalBarGraph from './verticalBarGraph'
import { Grid } from '@mui/material'
import { useSMACMembersByMaritalStatus } from '../../hooks'
import LoadingComponent from '../../../../../common/components/LoadingComponent'
import { sortArrayBasedOnGivenArray } from '../../../../../common/utils'

export default function MaritalStatus() {
    const {data, isLoading} = useSMACMembersByMaritalStatus()

    const filteredData = data?.filter(row => row.dimension !== 'Overall')

    const sortingOrders = ['Single', 'Married', 'Widowed', 'Separated', 'Others','No Data'];

    const sortedData = sortArrayBasedOnGivenArray(filteredData, sortingOrders, 'dimension_value')

    const maritalStatusLabel = sortedData?.filter(item => item.dimension_value !== 'Others')?.map(item => item.dimension_value)
    const maritalStatusData = sortedData?.filter(item => item.dimension_value !== 'Others')?.map(item => item.total_members)

    if(isLoading){
        return <LoadingComponent />
    }

    return <Grid> <VerticalBarGraph title={'Marital Status'} xAxisLabel={maritalStatusLabel} seriesData={maritalStatusData}/> </Grid>
} 