import {
    defaultDataFilter,
    hasColumn,
    operationsDataFilter,
    rowsToDisplay,
    useIsMetricVisible,
    useTableJoinQuery,
    useNestedData
} from "./utils";
import {useTableQuery} from "../../../../common/API/hooks";
import {
    COLUMNS_TO_IGNORE_ON_MATCH,
    CY_LOADING_OUTPUT,
    LY_LOADING_OUTPUT,
    LY_OPERATIONS_LOADING_OUTPUT, OPERATIONS_BASIC_METRICS, OPERATIONS_PLAN_METRICS,
    OPERATIONS_SOURCE_METRICS,
    TABLE_AGG_STORE_BU,
    TABLE_DIM_BRANCH,
    TABLE_DIM_SUBDEPT
} from "./constants";
import React, {useMemo} from "react";
import {useMaxFilterDate, useMTDSalesData, useYTDSalesData} from "./sales";
import {useSelectorValue} from "../../common/providers/selectorProvider";
import moment from "moment/moment";
import {NOT_AVAILABLE, SELECTOR_ZONE_STORE_TOGGLE} from "../constant";
import {convertNumberToLocaleString} from "../../../../common/utils";
import {useDashboardMetaContext} from "../../common/providers/DashboardMetaProvider";
import { Divider } from "@mui/material";
import { HorizontalTooltipDivider } from "../../../../common/components/HorizontalTooltipDivider";
import {useOrgEnvFilterFromSourceSelector} from "./values";


export const useAggStoreBUJoinQuery = (params, useQueryOptions = {}, hasSubDepartment = false, hasStore = false) => {
    const tableNames = [TABLE_AGG_STORE_BU];
    const joinPairs = [];

    if (hasSubDepartment) {
        tableNames.push(TABLE_DIM_SUBDEPT);
        joinPairs.push([TABLE_AGG_STORE_BU, TABLE_DIM_SUBDEPT]);
    }
    if (hasStore) {
        tableNames.push(TABLE_DIM_BRANCH);
        joinPairs.push([TABLE_AGG_STORE_BU, TABLE_DIM_BRANCH]);
    }

    return useTableJoinQuery({
        tableNames: tableNames,
        joinPairs: joinPairs,
        params,
        useQueryOptions: {
            ...useQueryOptions,
            // enabled: false
        }
    });
}

export const useAggStoreBUQuery = (params, useQueryOptions = {}) => {
    return useTableQuery({
        tableName: TABLE_AGG_STORE_BU,
        params: {
            ...(params || {}),
        },
        useQueryOptions: {
            ...useQueryOptions
        }
    })
}


export const useBaseOperationsData = (params, useQueryOptions={}, dataFilter=defaultDataFilter, forceHasSubDept=false, aggregates=OPERATIONS_SOURCE_METRICS) => {
    const operationsFiltersFromDefinition = useDashboardMetaContext('operations_filters') || {}
    const orgEnvFilters = useOrgEnvFilterFromSourceSelector()

    const appliedParams = {
        ...(params || {}),
        ...operationsFiltersFromDefinition,  // this is bad, we are overwriting the params provided by the user
        // but this is the quickest way to make sure that the filters are applied,
        ...orgEnvFilters
    }

    const hasSubDept = forceHasSubDept ||
        hasColumn(appliedParams, 'subdept_desc_standardized') ||
        hasColumn(appliedParams, 'dept_desc_standardized') ||
        hasColumn(appliedParams, 'bu_desc') ||
        hasColumn(appliedParams, 'bu_grp');
    // const hasStore = hasColumn(params, 'branch_name') || hasColumn(params, 'region');

    // // forced join to store and subdept for all queries; this is in case user have a data restriction to be applied on join
    // const hasSubDept = true;  // forced subdept joining for operations is causing incorrect data.
    const hasStore = true;

    const hasJoin = hasSubDept || hasStore;
    const hasDateFilters = hasColumn(appliedParams, 'date_key_formatted') || hasColumn(params, 'date_key_formatted');

    const withJoinQuery = useAggStoreBUJoinQuery(
        {
            ...appliedParams,
            aggregates: aggregates,
        },
        {
            ...useQueryOptions,
            enabled: hasJoin && hasDateFilters && (useQueryOptions?.enabled !== false)
        },
        hasSubDept,
        hasStore
    );
    const plainQuery = useAggStoreBUQuery(
        {
            ...appliedParams,
            aggregates: aggregates,
        },
        {
            ...useQueryOptions,
            enabled: !hasJoin  && hasDateFilters && (useQueryOptions?.enabled !== false)
        }
    );

    const {data, ...rest} = hasJoin ? withJoinQuery : plainQuery;

    return {
        data: (dataFilter instanceof Function) ? data?.filter(dataFilter) : data,
        ...rest
    }
}


export const useBURelatedOperationsData = (params, useQueryOptions={}, dataFilter=defaultDataFilter) => {
    return useBaseOperationsData(params, useQueryOptions, dataFilter, true, [
        'total_actual_area_sum', ...OPERATIONS_PLAN_METRICS
    ]);
}

export const useNonBURelatedOperationsData = (params, useQueryOptions={}, dataFilter=defaultDataFilter) => {
    return useBaseOperationsData(params, useQueryOptions, dataFilter, false, [
        ...OPERATIONS_BASIC_METRICS
    ]);
}

export const useOperationsData = (params, useQueryOptions={}, dataFilter=defaultDataFilter) => {
    const {data: baseOperationsData, ...restBaseOperationsResult} = useNonBURelatedOperationsData(params, useQueryOptions, dataFilter);
    const {data: buOperationsData, ...restBUOperationsData} = useBURelatedOperationsData(params, useQueryOptions, dataFilter);


    const mergedData = useMemo(() => {
        if (!baseOperationsData || !buOperationsData) {
            return baseOperationsData
        }

        const primaryOperationsData = baseOperationsData?.length > buOperationsData?.length ? baseOperationsData : buOperationsData;

        return primaryOperationsData.map(primaryRow => {
            const matchingBURow = buOperationsData.find(buRow => {
                return Object.keys(buRow).every(key => {
                    if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) return true;
                    return buRow[key] === primaryRow[key];
                });
            });

            const matchingBaseRow = baseOperationsData.find(baseRow => {
                return Object.keys(baseRow).every(key => {
                    if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) return true;
                    return baseRow[key] === primaryRow[key];
                });
            });


            if (matchingBURow || matchingBaseRow) {
                return {
                    ...(matchingBaseRow || {}),
                    ...(matchingBURow || {})
                }
            }
            return primaryRow;
        });
    }, [baseOperationsData, buOperationsData]);

    return {
        data: mergedData,
        ...restBaseOperationsResult,
        ...restBUOperationsData
    }
}

export const useMTDOperationsData = (isLastYear, params, useQueryOptions={}, dataFilter=operationsDataFilter) => {
    const year = useSelectorValue('year');
    const month = useSelectorValue('month');

    const filterMaxDate = useMaxFilterDate(isLastYear, true);

    const filterMinDate = useMemo(() => {
        if (!year || !month) {
            return null;
        }
        if (isLastYear) {
            return moment(`${year}-${month}-01`, "YYYY-MM-DD").subtract(1, 'year').format('YYYY-MM-DD');
        }
        return moment(`${year}-${month}-01`, "YYYY-MM-DD").startOf('month').format('YYYY-MM-DD');
    }, [year, month, isLastYear]);

    const newFilters = {
        date_key_formatted__gte: filterMinDate,
        date_key_formatted__lte: filterMaxDate,
        ...(params || {})
    }


    return useOperationsData(newFilters, useQueryOptions, dataFilter);
}

// Purpose of function is to overwrite ytdOperationsData's total_actual_area_sum value with the ones in mtdOperationsData
const useOverwriteYTDOperationsData = (ytdOperationsData, mtdOperationsData) => {    
    const mergedData = useMemo(() => {
        // Validate that both datasets are available and contain data
        if (Array.isArray(ytdOperationsData?.data) && ytdOperationsData.data.length > 0 &&
            Array.isArray(mtdOperationsData?.data) && mtdOperationsData.data.length > 0) {
            const updatedYtdData = ytdOperationsData.data.map(ytdRow => {

                const matchingMtdRow = mtdOperationsData.data?.find(mtdRow => {
                    return Object.keys(mtdRow).every(key => {
                        if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) return true;
                        return mtdRow[key] === ytdRow[key];
                    });
                });

                if (matchingMtdRow) {
                    return {
                        ...ytdRow,
                        total_actual_area_sum: matchingMtdRow.total_actual_area_sum,
                    }
                }
                // Return the original YTD row if no corresponding MTD row is found
                return ytdRow
            });

            // Return a new object preserving the original structure of ytdOperationsData
            return {
                ...ytdOperationsData,
                data: updatedYtdData,
            };
        }

        // If datasets are not available or columns are invalid, return the original ytdOperationsData
        return ytdOperationsData;
    }, [ytdOperationsData, mtdOperationsData]);

    return mergedData;
};

export const useYTDOperationsData =  (isLastYear, params, useQueryOptions={}, dataFilter=operationsDataFilter) => {
    const year = useSelectorValue('year');

    const filterMaxDate = useMaxFilterDate(isLastYear, true);
    const filterMinDate = useMemo(() => {
        if (!year) {
            return null;
        }
        if (isLastYear) {
            return moment(`${year}-01-01`, "YYYY-MM-DD").subtract(1, 'year').format('YYYY-MM-DD');
        }
        return moment(`${year}-01-01`, "YYYY-MM-DD").format('YYYY-MM-DD');
    }, [year, isLastYear]);

    const newFilters = {
        date_key_formatted__gte: filterMinDate,
        date_key_formatted__lte: filterMaxDate,
        ...(params || {})
    }

    const ytdOperationsData = useOperationsData(newFilters, useQueryOptions, dataFilter);
    const mtdOperationsData = useMTDOperationsData(isLastYear, params, useQueryOptions, dataFilter);

    const mergedOperationsData = useOverwriteYTDOperationsData(ytdOperationsData, mtdOperationsData);

    return mergedOperationsData
}



const computeRowDisplay = (
    cyRow, lyRow, isCYLoading, isLYLoading,
    cySalesRow, lySalesRow, isCYSalesLoading, isLYSalesLoading
) => {
    const commonMatchedKeys = Object.keys(cyRow || lyRow).filter(key => {
        return !COLUMNS_TO_IGNORE_ON_MATCH.includes(key);
    })

    const commonDisplayData = commonMatchedKeys.reduce((acc, key) => {
        acc[key] = cyRow[key];
        return acc;
    }, {});

    if (isCYLoading) {
        return {
            commonDisplayData,
            ...CY_LOADING_OUTPUT,
            ...LY_LOADING_OUTPUT
        }
    }

    const cyNSA = cyRow?.total_actual_area_sum  // net selling area
    const lyNSA = lyRow?.total_actual_area_sum

    const cyMallFootTraffic = cyRow?.mall_foot_traffic_sum
    const lyMallFootTraffic = lyRow?.mall_foot_traffic_sum

    const cyDSFootTraffic = cyRow?.foot_traffic_sum
    const lyDSFootTraffic = lyRow?.foot_traffic_sum

    const cyTransactions = cySalesRow?.transaction_key_count_distinct
    const lyTransactions = lySalesRow?.transaction_key_count_distinct

    const cySales = cySalesRow?.gross_sales_sum
    const cySalesMonthsCoverage = moment(cySalesRow?.transaction_date_max).diff(moment(cySalesRow?.transaction_date_min), 'months') + 1

    const lySales = lySalesRow?.gross_sales_sum
    const lySalesMonthsCoverage = moment(lySalesRow?.transaction_date_max).diff(moment(lySalesRow?.transaction_date_min), 'months') + 1

    const planSales = cyRow?.sales_plan_sum;

    // computations


    const cyCatchmentRate = cyMallFootTraffic ?
        cyDSFootTraffic / cyMallFootTraffic * 100:
        null
    const lyCatchmentRate = (!!lyDSFootTraffic && !!lyMallFootTraffic) ?
        lyDSFootTraffic / lyMallFootTraffic * 100:
        null

    const vsLYCatchmentRate = (cyCatchmentRate === null && lyCatchmentRate === null) ?
        null :
        (
            (!!cyCatchmentRate && !!lyCatchmentRate && (cyCatchmentRate !== null && lyCatchmentRate !== null)) ?
                cyCatchmentRate - lyCatchmentRate 
                : null
        )

    const cyConversionRate = cyTransactions / cyDSFootTraffic * 100
    const lyConversionRate =  lyTransactions / lyDSFootTraffic * 100
    const vsLYConversionRate = (cyConversionRate && lyConversionRate) ?
        cyConversionRate - lyConversionRate
        : null

    const cySpaceProductivity = (cySales / cySalesMonthsCoverage) / cyNSA
    const lySpaceProductivity = (lySales / lySalesMonthsCoverage) / lyNSA

    const vsLYSpaceProductivity = (cySpaceProductivity && lySpaceProductivity) ?
        cySpaceProductivity / lySpaceProductivity * 100
        : null

    const planSpaceProductivity = (planSales / cySalesMonthsCoverage) / cyNSA

    const vsSpaceProductivityPlan = (cySpaceProductivity && planSpaceProductivity) ?
        cySpaceProductivity / planSpaceProductivity * 100
        : null

    // FIXME: check if we want these columns as standard column names
    const cyDisplayData = {
        cyConversionRate: isCYSalesLoading ? "Loading..." : cyConversionRate,
        cyCatchmentRate,
        cyDSFootTraffic,
        cyMallFootTraffic,
        cyTransactions,
        cySales,
        cySalesMonthsCoverage,
        cySpaceProductivity: isCYSalesLoading ? "Loading..." : cySpaceProductivity,
        vsSpaceProductivityPlan: vsSpaceProductivityPlan,
        spaceProductivityPlan: planSpaceProductivity,
        salesPlan: planSales,
        cyNSA,
    };
    const lyDisplayData = {
        lyConversionRate: isLYSalesLoading ? "Loading..." : lyConversionRate,
        lyCatchmentRate: lyCatchmentRate,
        lyDSFootTraffic,
        lyMallFootTraffic,
        lyTransactions,
        lySales,
        lySalesMonthsCoverage,

        vsLYCatchmentRate: (isCYSalesLoading || isLYSalesLoading || isLYLoading) ? "Loading..." : vsLYCatchmentRate,
        vsLYConversionRate,
        vsLYSpaceProductivity: (isCYSalesLoading || isLYSalesLoading || isLYLoading) ? "Loading..." : vsLYSpaceProductivity,

        lyNSA,
    }

    if (isLYLoading) {
        return {
            ...commonDisplayData,
            ...cyDisplayData,
            ...LY_OPERATIONS_LOADING_OUTPUT
        }
    }

    return {
        ...commonDisplayData,
        ...cyDisplayData,
        ...lyDisplayData
    }

}

const computeRowsDisplay = (cyOperationsResult, lyOperationsResult, cySalesResult, lySalesResult) => {
    if (cyOperationsResult.isLoading && lyOperationsResult.isLoading) {
        return null;
    }

    // find pairs of rows to compare
    if (!cyOperationsResult.data) {
        return null;
    }



    const displayData = cyOperationsResult.data.map((cyRow) => {
        const matchingLYRow = lyOperationsResult.data?.find((lyRow) => {
            return Object.keys(cyRow).every(key => {
                if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) {
                    return true;
                }
                return cyRow[key] === lyRow[key];
            });
        });

        const matchingSalesCYRow = cySalesResult.data?.find((cySalesRow) => {
            return Object.keys(cyRow).every(key => {
                if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) {
                    return true;
                }
                return cyRow[key] === cySalesRow[key];
            });
        });
        const matchingSalesLYRow = lySalesResult.data?.find((lySalesRow) => {
            return Object.keys(cyRow).every(key => {
                if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) {
                    return true;
                }
                return cyRow[key] === lySalesRow[key];
            });
        });

        return computeRowDisplay(
            cyRow, matchingLYRow, cyOperationsResult.isLoading, lyOperationsResult.isLoading,
            matchingSalesCYRow, matchingSalesLYRow, cySalesResult.isLoading, lySalesResult.isLoading
        );
    })

    return displayData;
}


export const useMTDCompleteDisplayData = (params, useQueryOptions={}) => {
    const cyOperationsResult = useMTDOperationsData(false, params, useQueryOptions);
    const lyOperationsResult = useMTDOperationsData(true, params, {
        ...useQueryOptions,
        enabled: !!cyOperationsResult.data && (useQueryOptions?.enabled !== false)
    });

    // we also need number of transactions
    const cySalesResult = useMTDSalesData(false, params, useQueryOptions);
    const lySalesResult = useMTDSalesData(true, params, {
        ...useQueryOptions,
        enabled: !!cySalesResult.data && (useQueryOptions?.enabled !== false)
    });

    const displayData = React.useMemo(() => {
        return computeRowsDisplay(cyOperationsResult, lyOperationsResult, cySalesResult, lySalesResult);
    }, [cyOperationsResult, lyOperationsResult])

    return {
        data: displayData,
        isLoading: cyOperationsResult.isLoading,
        isPartialLoading: cyOperationsResult.isLoading || lyOperationsResult.isLoading ||
            cySalesResult.isLoading || lySalesResult.isLoading,
    }
}

export const useYTDCompleteDisplayData = (params, useQueryOptions={}) => {
    const cyOperationsResult = useYTDOperationsData(false, params, useQueryOptions);
    const lyOperationsResult = useYTDOperationsData(true, params, {
        ...useQueryOptions,
        enabled: !!cyOperationsResult.data && (useQueryOptions?.enabled !== false)
    });

    // we also need number of transactions
    const cySalesResult = useYTDSalesData(false, params, useQueryOptions);
    const lySalesResult = useYTDSalesData(true, params, {
        ...useQueryOptions,
        enabled: !cySalesResult.data && (useQueryOptions?.enabled !== false)
    });

    const displayData = React.useMemo(() => {
        return computeRowsDisplay(cyOperationsResult, lyOperationsResult, cySalesResult, lySalesResult);
    }, [cyOperationsResult, lyOperationsResult])

    return {
        data: displayData,
        isLoading: cyOperationsResult.isLoading,
        isPartialLoading: cyOperationsResult.isLoading || lyOperationsResult.isLoading ||
            cySalesResult.isLoading || lySalesResult.isLoading,
    }
}


export const useTopLineData = () => {
    const params = useOperationsUnifiedParams();

    const isSalesMetricVisible = useIsMetricVisible('sales');
    const isTransactionsMetricVisible = useIsMetricVisible('transactions');

    const isConversionRateMetricVisible = useIsMetricVisible('conversion_rate');
    const isCatchmentRateMetricVisible = useIsMetricVisible('catchment_rate');
    const isSpaceProductivityMetricVisible = useIsMetricVisible('space_productivity');

    const {data: mtdDisplayData, isLoading: mtdIsLoading} = useMTDCompleteDisplayData(params);
    const {data: ytdDisplayData, isLoading: ytdIsLoading} = useYTDCompleteDisplayData(params);

    // reformat data to match the format of the data in the SalesOperations topline
    const hasDeptFilter = params.hasOwnProperty('dept_desc_standardized') || params.hasOwnProperty('bu_desc') || params.hasOwnProperty('bu_grp');

    const catchmentRateRow = hasDeptFilter ? {
            pk: 'Catchment Rate',
            metric: 'catchment_rate',
            mtd_actual: NOT_AVAILABLE,
            mtd_vs_plan: NOT_AVAILABLE,
            mtd_vs_ly: NOT_AVAILABLE,
            ytd_actual: NOT_AVAILABLE,
            ytd_vs_plan:  NOT_AVAILABLE,
            ytd_vs_ly: NOT_AVAILABLE,
        } : {
            pk: 'Catchment Rate',
            metric: 'catchment_rate',
            mtd_actual: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.cyCatchmentRate,
            mtd_actual_tooltip: mtdIsLoading ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Foot Traffic / Mall Foot Traffic</>,
                <><b>Foot Traffic:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyDSFootTraffic)}</>,
                <><b>Mall Foot Traffic:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyMallFootTraffic)}</>,
            ],
            mtd_vs_plan: NOT_AVAILABLE,
            mtd_vs_ly: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsLYCatchmentRate,
            mtd_vs_ly_tooltip: mtdIsLoading ? [] : [
                <><b>LY Catchment Rate:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyCatchmentRate, 1)}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Foot Traffic:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyDSFootTraffic)}</>,
                <><b>LY Mall Foot Traffic:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyMallFootTraffic)}</>,
            ],
            ytd_actual: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.cyCatchmentRate,
            ytd_actual_tooltip: ytdIsLoading ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Foot Traffic / Mall Foot Traffic</>,
                <><b>Foot Traffic:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyDSFootTraffic)}</>,
                <><b>Mall Foot Traffic:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyMallFootTraffic)}</>,
            ],
            ytd_vs_plan:  NOT_AVAILABLE,
            ytd_vs_ly: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsLYCatchmentRate,
            ytd_vs_ly_tooltip: ytdIsLoading ? [] : [
                <><b>LY Catchment Rate:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyCatchmentRate, 1)}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Foot Traffic:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyDSFootTraffic)}</>,
                <><b>LY Mall Foot Traffic:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyMallFootTraffic)}</>,
            ],

        };

    const conversionRateRow = hasDeptFilter ? {
        pk: 'Conversion Rate',
        metric: 'conversion_rate',
        mtd_actual: NOT_AVAILABLE,
        mtd_vs_plan: NOT_AVAILABLE,
        mtd_vs_ly: NOT_AVAILABLE,
        ytd_actual: NOT_AVAILABLE,
        ytd_vs_plan:  NOT_AVAILABLE,
        ytd_vs_ly: NOT_AVAILABLE,
    } : {
        pk: 'Conversion Rate',
        metric: 'conversion_rate',
        mtd_actual: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.cyConversionRate,
        mtd_actual_tooltip: mtdIsLoading ? [] : [
            <HorizontalTooltipDivider />,
            <><b>Formula:</b> Transactions / Foot Traffic</>,
            <><b>Transactions:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyTransactions)}</>,
            <><b>Foot Traffic:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyDSFootTraffic)}</>,
        ],
        mtd_vs_plan: NOT_AVAILABLE,
        mtd_vs_ly: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsLYConversionRate,
        mtd_vs_ly_tooltip: mtdIsLoading ? [] : [
            <><b>LY Conversion Rate:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyConversionRate, 1)}</>,
            <HorizontalTooltipDivider />,
            <><b>LY Transactions:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyTransactions)}</>,
            <><b>LY Foot Traffic:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyDSFootTraffic)}</>,
        ],
        ytd_actual: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.cyConversionRate,
        ytd_actual_tooltip: ytdIsLoading ? [] : [
            <HorizontalTooltipDivider />,
            <><b>Formula:</b> Transactions / Foot Traffic</>,
            <><b>Transactions:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyTransactions)}</>,
            <><b>Foot Traffic:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyDSFootTraffic)}</>,
        ],
        ytd_vs_plan: NOT_AVAILABLE,
        ytd_vs_ly: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsLYConversionRate,
        ytd_vs_ly_tooltip: ytdIsLoading ? [] : [
            <><b>LY Conversion Rate:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyConversionRate, 1)}</>,
            <HorizontalTooltipDivider />,
            <><b>LY Transactions:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyTransactions)}</>,
            <><b>LY Foot Traffic:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyDSFootTraffic)}</>,
        ],
    }

    const spaceProductivityRow = {
        pk: 'Space Productivity',
        metric: 'space_productivity',
        mtd_actual: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.cySpaceProductivity,
        mtd_actual_tooltip: mtdIsLoading ?
            [] :
            [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Gross Sales / Months Coverage / Net Selling Area</>,
                <><b>Sales:</b> {isSalesMetricVisible ? convertNumberToLocaleString(mtdDisplayData?.[0]?.cySales) : "hidden"}</>,
                <><b>Months:</b> {mtdDisplayData?.[0]?.cySalesMonthsCoverage}</>,
                <><b>Net Selling Area:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyNSA)}</>,
            ],
        mtd_vs_plan: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsSpaceProductivityPlan,
        mtd_vs_plan_tooltip: mtdIsLoading ?
            [] :
            [
                <><b>Space Productivity Plan:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.spaceProductivityPlan)}</>,
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Sales Plan / Months Coverage / Net Selling Area</>,
                <><b>Sales Plan:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.salesPlan)}</>,
                <><b>Months:</b> {mtdDisplayData?.[0]?.cySalesMonthsCoverage}</>,
                <><b>Net Selling Area:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyNSA)}</>,
            ],
        mtd_vs_ly: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsLYSpaceProductivity,
        mtd_vs_ly_tooltip: mtdIsLoading ?
            [] :
            [
                <><b>LY Space Productivity:</b> {isSalesMetricVisible ? convertNumberToLocaleString(mtdDisplayData?.[0]?.lySales) : "hidden"}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Sales:</b> {isSalesMetricVisible ? convertNumberToLocaleString(mtdDisplayData?.[0]?.lySales) : "hidden"}</>,
                <><b>LY Months:</b> {mtdDisplayData?.[0]?.lySalesMonthsCoverage}</>,
                <><b>LY Net Selling Area:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyNSA)}</>,
            ],
        ytd_actual: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.cySpaceProductivity,
        ytd_actual_tooltip: ytdIsLoading ?
            [] :
            [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Gross Sales / Months Coverage / Net Selling Area</>,
                <><b>Sales:</b> {isSalesMetricVisible ? convertNumberToLocaleString(ytdDisplayData?.[0]?.cySales) : "hidden"}</>,
                <><b>Months:</b> {ytdDisplayData?.[0]?.cySalesMonthsCoverage}</>,
                <><b>Net Selling Area:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyNSA)}</>,
            ],
        ytd_vs_plan: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsSpaceProductivityPlan,
        ytd_vs_plan_tooltip: ytdIsLoading ?
            [] : [
                <><b>Space Productivity Plan:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.spaceProductivityPlan)}</>,
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Sales Plan / Months Coverage / Net Selling Area</>,
                <><b>Sales Plan:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.salesPlan)}</>,
                <><b>Months:</b> {ytdDisplayData?.[0]?.cySalesMonthsCoverage}</>,
                <><b>Net Selling Area:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyNSA)}</>,
            ],
        ytd_vs_ly: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsLYSpaceProductivity,
        ytd_vs_ly_tooltip: ytdIsLoading ?
            [] : [
                <><b>LY Space Productivity:</b> {isSalesMetricVisible ? convertNumberToLocaleString(ytdDisplayData?.[0]?.lySales) : "hidden"}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Sales:</b> {isSalesMetricVisible ? convertNumberToLocaleString(ytdDisplayData?.[0]?.lySales) : "hidden"}</>,
                <><b>LY Months:</b> {ytdDisplayData?.[0]?.lySalesMonthsCoverage}</>,
                <><b>LY Net Selling Area:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyNSA)}</>,
            ],
    };

    const data = [];
    if (isCatchmentRateMetricVisible) {
        data.push(catchmentRateRow);
    }
    if (isConversionRateMetricVisible) {
        data.push(conversionRateRow);
    }
    if (isSpaceProductivityMetricVisible) {
        data.push(spaceProductivityRow);
    }

    return {
        data,
        isLoading: false,  // since we already know the rows, we can set this to false
        isPartialLoading: mtdIsLoading || ytdIsLoading
    }
}

export const useOperationsUnifiedParams = () => {
    const selectedBuGroups = useSelectorValue('bu_grp');
    const selectedBUs = useSelectorValue('bu_desc');
    const selectedDepts = useSelectorValue('dept_desc_standardized');

    const metric = useSelectorValue('metric') || 'catchment_rate';

    const store = useSelectorValue('store');

    const branchNameColumn = useDashboardMetaContext('branch_name_column');


    const canApplyBUAndDeptFilter = metric === "space_productivity" || metric === "total_manpower_productivity";

    const params = {};

    if (canApplyBUAndDeptFilter) {
        if (selectedBuGroups && selectedBuGroups.length > 0) {
            params.bu_grp = selectedBuGroups;
        }
        if (selectedBUs && selectedBUs.length > 0) {
            params.bu_desc = selectedBUs;
        }
        if (selectedDepts && selectedDepts.length > 0) {
            params.dept_desc_standardized = selectedDepts;
        }
    }

    if (store && store.length > 0) {
        params[branchNameColumn] = store;
    }

    return params
}

export const useZoneToStorePerformance = () => {
    const zoneStoreToggle = useSelectorValue(SELECTOR_ZONE_STORE_TOGGLE);
    const branchNameColumn = useDashboardMetaContext('branch_name_column');

    const params = useOperationsUnifiedParams();

    const useQueryOptions = {};

    return useNestedData(
            ['region', 'branch_name'],
            zoneStoreToggle === 'zone' ? 0 : 2,
            params,
            useQueryOptions,
            useMTDCompleteDisplayData,
            useYTDCompleteDisplayData,
            'catchment_rate'
        );
}

export const useBusinessUnitToDepartmentPerformance = () => {
    const metric = useSelectorValue('metric') || 'catchment_rate';

    const isApplicableMetric = metric === "space_productivity";
    const params = useOperationsUnifiedParams();

    const appliedParams = {
        ...params,
        bu_grp__is_not_null: true,
        bu_desc__is_not_null: true,
        dept_desc_standardized__is_not_null: true,
    }

    return useNestedData(['bu_grp', 'bu_desc', 'dept_desc_standardized'], 0, appliedParams, {
            enabled: isApplicableMetric
        },
        useMTDCompleteDisplayData,
        useYTDCompleteDisplayData,
        'catchment_rate'
    );
}