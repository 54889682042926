import {registerDashboard} from "../../../../common/utils";
import SalesOperationsDashboard from "../index";
import {raAceHardwareDashboardDefinition} from "../dashboardDefinition";


registerDashboard(raAceHardwareDashboardDefinition);  // This is a function that registers the dashboard definition to the dashboard list
// this will allow the dashboard to be managed by permission management.


export default function AceHardware({ title = "Sales Dashboard", defaultTab = 'sales' }) {
    return <SalesOperationsDashboard title={title} defaultTab={defaultTab} dashboardDefinition={raAceHardwareDashboardDefinition}/>
}