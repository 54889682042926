import React from "react";
import BasePerformanceSection from "../presentation/BasePerformanceSection";
import MetricSelector from "../Selectors/MetricSelector";
import {buOrderArray} from "../constant";
import {createParentMapping, createParentMappingV2, useBUDeptGroupings} from "../hooks/values";
import {useSelectorState} from "../../common/providers/selectorProvider";
import {Box, IconButton} from "@mui/material";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';


export default function BUToDepartmentPerformance({
    data, 
    isLoading, 
    selectedTab, 
    information,
}) {
    const { data: buDataGroupings,
        buGrpToBUDict, // mapping of each BU Group and their BUs
        buToDeptDict, // mapping of each BU and their Departments
    } = useBUDeptGroupings();

    const dataParentMap = React.useMemo(() => {
        return createParentMappingV2(buDataGroupings)
    }, [buDataGroupings])

    const [selectedBUGrps, setSelectedBUGrps] = useSelectorState('bu_grp', []);
    const [selectedBUs, setSelectedBUs] = useSelectorState('bu_desc', []);
    const [selectedDepts, setSelectedDepts] = useSelectorState('dept_desc_standardized', []);

    const findDirectParent = (row) => {
        const rowValue = row['pk'];
        const rowPkKey = row['pkKey'];

        if (rowPkKey === 'bu_grp') {
            return null
        }

        const parents = dataParentMap[`${rowPkKey}|${rowValue}`] || [];
        if (parents.length === 2) {
            return data.find(row => row['pk'] === parents[0].pk).subRows.find(row => row['pk'] === parents[1].pk);
        } else if (parents.length === 1) {
            return data.find(row => row['pk'] === parents[0].pk);
        }
        return null
    }

    function OnSelectRow(row) {
        const rowValue = row['pk'];
        const rowPkKey = row['pkKey'];

        if (rowPkKey === 'bu_grp') {
            const buGroupBUValues = buGrpToBUDict[rowValue]
            const buGroupDepartmentValues = buGrpToBUDict[rowValue].reduce((allBUDepts, businessUnit) => {
                return [
                    ...allBUDepts,
                    ...buToDeptDict[businessUnit]
                ]
            }, [])

            if (selectedBUGrps?.includes(rowValue)) {  // if already selected, deselect
                setSelectedBUGrps(selectedBUGrps.filter(val => val !== rowValue));
                // de-select all departments under this BU Group > BU
                setSelectedDepts(selectedDepts.filter(val => !buGroupDepartmentValues.includes(val)));
                setSelectedBUs(selectedBUs.filter(val => !buGroupBUValues.includes(val)));
            } else {
                setSelectedBUGrps([...(selectedBUGrps || []), rowValue]);
                // select all departments under this BU Group > BU
                setSelectedDepts([...selectedDepts, ...buGroupDepartmentValues]);
                setSelectedBUs([...selectedBUs, ...buGroupBUValues]);
            }
        } else if (rowPkKey === 'bu_desc') {
            const buDepartmentValues = buToDeptDict[rowValue];
            const buGroupRow = findDirectParent(row);

            if (getIsRowSelected(row)) { // currentlySelected
                if (getIsRowSelected(buGroupRow)) { // parent is selected
                    // deselect parent
                    setSelectedBUGrps(selectedBUGrps.filter(val => val !== buGroupRow['pk']));
                }
                setSelectedBUs(selectedBUs.filter(val => val !== rowValue));
                setSelectedDepts(selectedDepts.filter(val => !buDepartmentValues.includes(val)));
            } else {
                setSelectedBUs([...(selectedBUs || []), rowValue]);
                setSelectedDepts([...selectedDepts, ...buDepartmentValues]);

                // check if all siblings are selected
                const allSiblingsSelected = buGroupRow.subRows.filter(
                    subRow => subRow['pk'] !== rowValue
                ).every(subRow => getIsRowSelected(subRow));
                if (allSiblingsSelected) {
                    setSelectedBUGrps([...selectedBUGrps, buGroupRow['pk']]);
                }
            }
        } else if (rowPkKey === 'dept_desc_standardized') {
            const buRow = findDirectParent(row);
            const buRowValue = buRow['pk']
            const buGroupRow = findDirectParent(buRow);
            const buGroupRowValue = buGroupRow['pk']


            if (getIsRowSelected(row)) { // currentlySelected
                if (getIsRowSelected(buRow)) { // parent is selected
                    // deselect parent
                    setSelectedBUs(selectedBUs.filter(val => val !== buRow['pk']));
                }
                if (getIsRowSelected(buGroupRow)) { // grand parent is selected
                    // deselect grand parent
                    setSelectedBUGrps(selectedBUGrps.filter(val => val !== buGroupRow['pk']));
                }
                setSelectedDepts(selectedDepts.filter(val => val !== rowValue));
            } else {
                setSelectedDepts([...(selectedDepts || []), rowValue]);

                // check if all siblings are selected
                // const allSiblingsSelected = buRow.subRows.filter(subRow => {
                //     return subRow['pk'] !== rowValue
                // }).every(subRow => getIsRowSelected(subRow));
                const allSiblingsSelected = buToDeptDict[buRowValue].filter(dept => {
                    return dept !== rowValue
                }).every(dept => getIsRowSelected({ pk: dept, pkKey: 'dept_desc_standardized' }));

                if (allSiblingsSelected) {
                    setSelectedBUs([...selectedBUs, buRowValue]);
                }

                // const allBUsSelected = allSiblingsSelected && buGroupRow.subRows.filter(subRow => {
                //     return subRow['pk'] !== buRow['pk']
                // }).every(subRow => getIsRowSelected(subRow));
                const allBUsSelected = allSiblingsSelected && buGrpToBUDict[buGroupRowValue].filter(businessUnit => {
                    return businessUnit !== buRowValue
                }).every(businessUnit => getIsRowSelected({ pk: businessUnit, pkKey: 'bu_desc' }));

                if (allBUsSelected) {
                    setSelectedBUGrps([...selectedBUGrps, buGroupRowValue]);
                }
            }
        }
    }

    const getIsRowSelected = (rowData) => {
        if (!rowData) return false;

        const rowPKKey = rowData['pkKey'];
        const rowValue = rowData['pk'];

        if (rowPKKey === 'bu_desc') {
            return selectedBUs?.includes(rowValue);
        } else if (rowPKKey === 'bu_grp') {
            return selectedBUGrps?.includes(rowValue);
        } else if (rowPKKey === 'dept_desc_standardized') {
            return selectedDepts?.includes(rowValue);
        }
        return false
    }

    const getIsSelectedByAncestry = (rowData) => {
        if (!rowData) return false;

        const rowPKKey = rowData['pkKey'];
        const rowValue = rowData['pk'];

        const parents = dataParentMap[`${rowPKKey}|${rowValue}`] || [];



        const tmp = parents.some(parent => {
            return getIsRowSelected(parent)
        })

        return tmp
    }

    const cellFormat = ({ row, getValue }) => {
        const value = getValue();
        const hasSubRows = row.original.subRows && row.original.subRows.length > 0;
        const isRowSelected = getIsRowSelected(row?.original);

        return (
            <Box sx={{ marginLeft: `${(row.depth * 1) + 1}rem`, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                {/* <VisibilityIcon sx={{ marginRight: '.5rem' }} fontSize='small'
                    color={isRowSelected ? 'primary' : 'disabled'}
                /> */}

                <Box fontWeight={row?.depth === 0 || hasSubRows ? 'bold' : 'normal'}>
                    {value}
                </Box>
                {hasSubRows && (
                    <IconButton size='small' onClick={(event) => {
                        event.stopPropagation(); // Stop the click event from propagating
                        row.getToggleExpandedHandler()();
                    }}
                        sx={{ cursor: "pointer" }}
                    >
                        {row.getIsExpanded() ? <ArrowDropDownRoundedIcon fontSize='medium' /> :
                            <ArrowRightRoundedIcon fontSize='medium' color='#e0e0e0' />}
                    </IconButton>
                )}
            </Box>
        );
    };

    const categoryName = React.useMemo(() => {
        if (isLoading) {
            return 'Loading...'
        }

        if (data?.length > 0) {
            const pkKey = data[0].pkKey
            if (pkKey === 'bu_desc_standardized' || pkKey === 'bu_desc') {
                return 'Business Unit / Department'
            } else if (pkKey === 'bu_grp') {
                return 'Business Unit Group / Business Unit / Department'
            } else if (pkKey === 'dept_desc_standardized') {
                return 'Department'
            }
        }

        return 'Business Unit / Department'
    }, [data, isLoading])

    const title = React.useMemo(() => {
         if (isLoading) {
            return 'Business Unit to Department Performance'
        }

        if (data?.length > 0) {
            const pkKey = data[0].pkKey
            if (pkKey === 'bu_desc_standardized' || pkKey === 'bu_desc') {
                return 'Business Unit Group to Department Performance'
            } else if (pkKey === 'bu_grp') {
                return 'Business Unit to Department Performance'
            } else if (pkKey === 'dept_desc_standardized') {
                return 'Department Performance'
            }
        }

        return 'Business Unit to Department Performance'
    }, [])

    return <BasePerformanceSection
        title={title}
        selectorsOrFilters={[ <MetricSelector  selectedTab={selectedTab}/> ]}
        information={information}
        categoryName={categoryName}
        firstColumnCellFormat={cellFormat} // TODO
        tableId={'buDeptPerformance'}
        data={data}
        isLoading={isLoading}
        // enableRowSelection={true}
        // rowSelectionDepth={2}
        // onSelectRow={OnSelectRow}
        orderArray={buOrderArray}
    >
    </BasePerformanceSection>
}