import React from "react";
import { Box, Button, Grid } from "@mui/material";

export default function ChartWithShowAll({children, length, showAll, setShowAll}) {
    return <>
        {
            length > 10 &&
            <Grid display="flex" justifyContent="end" alignItems="center" pt={2} px={2}>
                <Box>
                    <Button variant="contained" size="small" sx={{ textTransform:'none'}} onClick={()=>setShowAll(!showAll)}>{ showAll ? 'Show top 10' : 'Show all'}</Button>
                </Box>
            </Grid>
        }
        {children}
    </>
}