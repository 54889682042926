import { Box, Card, Divider, Grid, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function ActionModal({ open, close, action, title, content, button, customWidthMD }) {


    return (
        <Modal open={open}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

            <Grid xs={10} sm={7} md={customWidthMD || 3}>
                <Card >
                    <Grid container justifyContent={'space-between'}>
                        <Typography variant="h6" sx={{ ml: 3, mt: 2 }}>{action} <span style={{ color: '#0A4FB4', fontWeight: 'bold' }}>{title}</span></Typography>

                        <IconButton onClick={close} sx={{ mr: 1, mt: 1 }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Divider sx={{ my: 1, borderBottom: 1, }} />
                    {content}
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                        {button}
                    </Box>
                </Card>
            </Grid>
        </Modal >
    )
}