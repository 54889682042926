
import BUToDepartmentPerformance from "./index";
import { useBusinessUnitToDepartmentPerformance } from "../hooks/manpower";


export default function ManpowerBUToDepartmentPerformance({selectedTab}) {
    const information = <div style={{ width: '25rem', padding: 5 }}>
        <div><span style={{ fontWeight: 'bold' }}>Data is not applicable to the following metrics:</span></div>
        <div style={{ margin: 5 }}>
            <ul>
                <li> <span>Selling Productivity</span> </li>
                <li> <span>Non-Selling Productivity</span> </li>
                <li> <span>Personal Shopper Productivity</span> </li>
            </ul>
        </div>
    </div>
    
    const { data: sortedData, isLoading } = useBusinessUnitToDepartmentPerformance();

    return <BUToDepartmentPerformance data={sortedData} isLoading={isLoading} selectedTab={selectedTab} information={information}/>
}
