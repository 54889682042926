import React from 'react';
import { useSelectorState, useSelectorValue } from '../../common/providers/selectorProvider';
import DropdownCheckboxV3 from '../../../../common/components/DropdownCheckboxV3';
import { getSelectedOption } from '../../../../common/utils';
import { useBUDeptGroupings } from '../hooks/values';


export default function BUGroupSelector() {
    const { buGroupData } = useBUDeptGroupings()
    const [selectedBUGrps, setSelectedBUGrps] = useSelectorState('bu_grp', []) || [];

    const metric = useSelectorValue('metric') || 'sales';
    const canUseSelector = metric !== 'catchment_rate' && metric !== 'conversion_rate';

    const buGroupOptions = (buGroupData || [])
        .map((row) => {
            return {
                value: row?.bu_grp,
                label: row?.bu_grp,
            }
        })
        .filter((option) => option.value !== "" && option.label !== "")
        .sort((a, b) => a.label?.localeCompare(b.label));

    
    function handleSelectBUGroup(data) {
        let result = data.map(a => a.value);
        setSelectedBUGrps(result)
        return result
    }

    const displayedValue = canUseSelector ?
        getSelectedOption(selectedBUGrps, buGroupOptions) :
        []

    if (!buGroupOptions || buGroupOptions.length <= 1) {  // if we only have one option, don't show the selector
        return null
    }

    return (
        <DropdownCheckboxV3
            id="bu_grp_filter"
            options={buGroupOptions}
            label="Business Unit Group"
            limitTags={2}
            value={displayedValue || []}
            onChange={(event, newValue) => {
                handleSelectBUGroup(getSelectedOption(newValue, buGroupOptions))
            }}
            disabled={!canUseSelector}
        />
    )
}