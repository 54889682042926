import { useQueryClient } from 'react-query';
import ApiClient from '../../common/API';

export function useSave() {
    const queryClient = useQueryClient();

    const saveData = (resourceType, data) => {
        let endpoint = '';
        let queryKeys = [];

        if (resourceType === 'user') {
            endpoint = 'user/';
            queryKeys = ['user', 'group'];
        } else if (resourceType === 'group') {
            endpoint = 'group/';
            queryKeys = ['group', 'user'];
        } else if (resourceType === 'module') {
            endpoint = 'module/';
            queryKeys = ['module'];
        } else if (resourceType === 'ip-set') {
            endpoint = 'ip-set/';
            queryKeys = ['ip-set'];
        } else if (resourceType === 'ip-rule') {
            endpoint = 'ip-rule/';
            queryKeys = ['ip-rule'];
        }

        return ApiClient().post(endpoint, data)
            .then(() => {
                queryKeys.forEach(key => queryClient.invalidateQueries([key]));
            });
    };

    return { saveData };
}