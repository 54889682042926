import CellWithSubRow from "../../../../common/components/DataTable/CellWithSubRow";
import ZonetoStoreToggleSelector from "../presentation/ZonetoStoreToggleSelector";
import BasePerformanceSection from "../presentation/BasePerformanceSection";
import {SELECTOR_ZONE_STORE_TOGGLE, zoneOrderArray} from "../constant";
import { useSelectorState, useSelectorValue } from "../../common/providers/selectorProvider";
import { useContext, useMemo } from "react";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import MetricColumnSelector from "../Selectors/MetricColumnSelector";
import { convertToValidNumber } from "../../../../common/utils";
import ConditionSelector from "../Selectors/ConditionSelector";
import MetricInputValueSelector from "../Selectors/MetricInputSelector";
import {useIsComponentUsable} from "../../common/providers/blacklistedComponentsProvider/hooks";
import {GENERAL_ZONE_STORE_TOGGLE} from "../dashboardDefinition";

export default function ZoneToStorePerformance({ data, isLoading }) {
    const [storeFilters, setStoreFilters] = useSelectorState('store', []);
    const [metricFilter, setMetricFilters] = useSelectorState('metric_column', []);
    const [conditionFilter, setConditionFilters] = useSelectorState('condition', []);
    const [metricValueFilter, setMetricValueFilters] = useSelectorState('metric_value', []);

    const isZoneStoreToggleUsable = useIsComponentUsable(GENERAL_ZONE_STORE_TOGGLE);

    const category = useSelectorValue(SELECTOR_ZONE_STORE_TOGGLE);
    const categoryName = category === 'zone' ? 'Zone' : 'Store';

    const filteredData = useMemo(() => {
        if (category === 'store') {
            return storeFilters?.length > 0
                ? data.filter(obj => storeFilters.includes(obj?.pk))
                : data;
        } else {
            return storeFilters?.length > 0
                ? data
                    .filter(obj =>
                        obj?.subRows?.some(subRow => storeFilters.includes(subRow?.pk))
                    )
                    .map(obj => ({
                        ...obj,
                        subRows: obj.subRows.filter(subRow => storeFilters.includes(subRow?.pk)) // Filter subRows
                    }))
                : data;
        }
    }, [storeFilters, data, category]);


    const calculateFilteredDataSubtotal = (data) => {

        const newSubtotal = {
            pk: 'SUBTOTAL',
            mtd_actual: null,
            mtd_vs_plan: null,
            mtd_vs_ly: null,
            ytd_actual: null,
            ytd_vs_plan: null,
            ytd_vs_ly: null,
            _isFooter: true
        };

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const newValues = data[key];
                let sum = 0;

                for (let i = 0; i < newValues.length; i++) {
                    if (typeof newValues[i] === 'string' || isNaN(parseFloat(newValues[i])) || !isFinite(parseFloat(newValues[i]))) {
                        newValues[i] = 0;
                    }
                    sum += parseFloat(newValues[i]);
                }
                if ((String(key).endsWith('plan') || String(key).endsWith('ly'))) {
                    newSubtotal[key] = convertToValidNumber(sum / (newValues.length || 1));
                }
                else {
                    newSubtotal[key] = sum;
                }
            }
        }
        return newSubtotal;
    };
    let hasFooterSubtotal = filteredData.length <= 5;

    const dataToDisplay = useMemo(() => {
        let filteredDataToDisplay = filteredData;

        if (!!metricFilter && !!conditionFilter && metricValueFilter !== "") {
            if (conditionFilter === 'greater_than_equal') {
                filteredDataToDisplay = filteredDataToDisplay?.filter(data => {
                    return data?.[metricFilter] >= parseFloat(metricValueFilter);
                });
            } else if (conditionFilter === 'less_than_equal') {
                filteredDataToDisplay = filteredDataToDisplay?.filter(data => {
                    return data[metricFilter] <= parseFloat(metricValueFilter);
                });
            }
        }
        hasFooterSubtotal = filteredDataToDisplay.length !== 0 && filteredDataToDisplay.length <= 5;
        if (hasFooterSubtotal) {
            const newRowValues = {
                mtd_actual: [],
                mtd_vs_plan: [],
                mtd_vs_ly: [],
                ytd_actual: [],
                ytd_vs_plan: [],
                ytd_vs_ly: []
            };
            filteredDataToDisplay.forEach(data => {
                newRowValues.mtd_actual.push(parseFloat(data.mtd_actual));
                newRowValues.mtd_vs_plan.push(parseFloat(data.mtd_vs_plan));
                newRowValues.mtd_vs_ly.push(parseFloat(data.mtd_vs_ly));
                newRowValues.ytd_actual.push(parseFloat(data.ytd_actual));
                newRowValues.ytd_vs_plan.push(parseFloat(data.ytd_vs_plan));
                newRowValues.ytd_vs_ly.push(parseFloat(data.ytd_vs_ly));
            });
            return [...filteredDataToDisplay, calculateFilteredDataSubtotal(newRowValues)];
        }
        return filteredDataToDisplay;

    }, [filteredData, metricFilter, conditionFilter, metricValueFilter, hasFooterSubtotal]);

    const cellFormat = ({ row, getValue }) => {
        const value = getValue();

        return (
            <CellWithSubRow row={row} marginLeftMinimum={1}>
                {value}
            </CellWithSubRow>
        );
    };

    const selectorsOrFilters = [
        <MetricColumnSelector />,
        <ConditionSelector />,
        <MetricInputValueSelector />,
    ]
    if (isZoneStoreToggleUsable) {
        selectorsOrFilters.push(<ZonetoStoreToggleSelector />)
    }

    const title = isZoneStoreToggleUsable ? 'Zone to Store Performance' : (
        category === 'store' ? 'Store Performance' : 'Zone Performance'
    );

    return <BasePerformanceSection
        title={title}
        selectorsOrFilters={selectorsOrFilters}
        categoryName={categoryName}
        firstColumnCellFormat={cellFormat}
        tableId={'zoneToStorePerformance'}
        data={dataToDisplay}
        isLoading={isLoading}
        orderArray={zoneOrderArray}
        customGridItemStyle={{ xs: 12, sm: 6, md: 2.5, marginRight: 1 }}
    >
    </BasePerformanceSection>
}