import React, { useMemo } from "react";
import { useSelectorValue } from "../../common/providers/selectorProvider";
import { COLUMNS_TO_IGNORE_ON_MATCH, CY_LOADING_OUTPUT, LOADING_STRING, LY_LOADING_OUTPUT, LY_MANPOWER_LOADING_OUTPUT, MANPOWER_SOURCE_METRICS } from "./constants";
import { useBaseOperationsData, useOperationsUnifiedParams } from "./operations";
import { useMaxFilterDate, useMTDSalesData, useYTDSalesData } from "./sales";
import { manpowerDataFilter, useIsMetricVisible, rowsToDisplay, useNestedData } from "./utils";
import moment from "moment";
import { HorizontalTooltipDivider } from "../../../../common/components/HorizontalTooltipDivider";
import { convertNumberToLocaleString } from "../../../../common/utils";
import { NOT_AVAILABLE, SELECTOR_ZONE_STORE_TOGGLE} from "../constant";

export const useMTDManpowerData = (isLastYear, params, useQueryOptions={}, dataFilter=manpowerDataFilter) => {
    const year = useSelectorValue('year');
    const month = useSelectorValue('month');

    const filterMaxDate = useMaxFilterDate(isLastYear, false); // align date with Sales Tab rather than Ops tab

    const filterMinDate = useMemo(() => {
        if (!year || !month) {
            return null;
        }
        if (isLastYear) {
            return moment(`${year}-${month}-01`, "YYYY-MM-DD").subtract(1, 'year').format('YYYY-MM-DD');
        }
        return moment(`${year}-${month}-01`, "YYYY-MM-DD").startOf('month').format('YYYY-MM-DD');
    }, [year, month, isLastYear]);

    const newFilters = {
        date_key_formatted__gte: filterMinDate,
        date_key_formatted__lte: filterMaxDate,
        ...(params || {})
    }

    return useBaseOperationsData(newFilters, useQueryOptions, dataFilter, true, [
        ...MANPOWER_SOURCE_METRICS
    ]);
}

export const useYTDManpowerData =  (isLastYear, params, useQueryOptions={}, dataFilter=manpowerDataFilter) => {
    const year = useSelectorValue('year');

    const filterMaxDate = useMaxFilterDate(isLastYear, false); // align date with Sales Tab rather than Ops tab
    const filterMinDate = useMemo(() => {
        if (!year) {
            return null;
        }
        if (isLastYear) {
            return moment(`${year}-01-01`, "YYYY-MM-DD").subtract(1, 'year').format('YYYY-MM-DD');
        }
        return moment(`${year}-01-01`, "YYYY-MM-DD").format('YYYY-MM-DD');
    }, [year, isLastYear]);

    const newFilters = {
        date_key_formatted__gte: filterMinDate,
        date_key_formatted__lte: filterMaxDate,
        ...(params || {})
    }

    return useBaseOperationsData(newFilters, useQueryOptions, dataFilter, true, [
        ...MANPOWER_SOURCE_METRICS
    ]);
}

const computeRowDisplay = (
    cyRow, lyRow, isCYLoading, isLYLoading,
    cySalesRow, lySalesRow, isCYSalesLoading, isLYSalesLoading,
    cyPsSalesRow, lyPsSalesRow, isCyPsSalesLoading, isLyPsSalesLoading
) => {
    const commonMatchedKeys = Object.keys(cyRow || lyRow).filter(key => {
        return !COLUMNS_TO_IGNORE_ON_MATCH.includes(key);
    })

    const commonDisplayData = commonMatchedKeys.reduce((acc, key) => {
        acc[key] = cyRow[key];
        return acc;
    }, {});

    if (isCYLoading) {
        return {
            commonDisplayData,
            ...CY_LOADING_OUTPUT,
            ...LY_LOADING_OUTPUT
        }
    }

    const cyDays = cyRow?.date_key_formatted_count_distinct
    const lyDays = lyRow?.date_key_formatted_count_distinct
    
    // raw sum throughout days counted
    const cySellingHeadcountSum = cyRow?.selling_headcount_sum
    const lySellingHeadcountSum = lyRow?.selling_headcount_sum

    const cyNonSellingHeadcountSum = cyRow?.non_selling_headcount_sum
    const lyNonSellingHeadcountSum = lyRow?.non_selling_headcount_sum

    const cyPersonalShopperHeadcountSum = cyRow?.ps_headcount_sum
    const lyPersonalShopperHeadcountSum = lyRow?.ps_headcount_sum

    // actual approximated headcounts
    const cySellingHeadcount = cyDays ? (cySellingHeadcountSum / cyDays) : null
    const lySellingHeadcount = lyDays ? (lySellingHeadcountSum / lyDays) : null

    const cyNonSellingHeadcount = cyDays ? (cyNonSellingHeadcountSum / cyDays) : null
    const lyNonSellingHeadcount = lyDays ? (lyNonSellingHeadcountSum / lyDays) : null

    const cyPersonalShopperHeadcount = cyDays ? (cyPersonalShopperHeadcountSum / cyDays) : null
    const lyPersonalShopperHeadcount = lyDays ? (lyPersonalShopperHeadcountSum / lyDays) : null

    const cyTotalHeadcount = cySellingHeadcount + cyNonSellingHeadcount + cyPersonalShopperHeadcount
    const lyTotalHeadcount = lySellingHeadcount + lyNonSellingHeadcount + lyPersonalShopperHeadcount

    // sales
    const cySales = cySalesRow?.gross_sales_sum
    const cyPsSales = cyPsSalesRow?.gross_sales_sum
    const cySalesMonthsCoverage = moment(cySalesRow?.transaction_date_max).diff(moment(cySalesRow?.transaction_date_min), 'months') + 1

    const lySales = lySalesRow?.gross_sales_sum
    const lyPsSales = lyPsSalesRow?.gross_sales_sum
    const lySalesMonthsCoverage = moment(lySalesRow?.transaction_date_max).diff(moment(lySalesRow?.transaction_date_min), 'months') + 1

    const planSales = cyRow?.sales_plan_sum;

    // computations
    //  actual current & last year values
    const cySellingProductivity = cySellingHeadcount ? (cySales / cySellingHeadcount) : null
    const lySellingProductivity = lySellingHeadcount ? (lySales / lySellingHeadcount) : null
    const vsLYSellingProductivity = (cySellingProductivity && lySellingProductivity) ?
        cySellingProductivity / lySellingProductivity * 100
        : null

    const cyNonSellingProductivity = cyNonSellingHeadcount ? (cySales / cyNonSellingHeadcount) : null
    const lyNonSellingProductivity = lyNonSellingHeadcount ? (lySales / lyNonSellingHeadcount) : null
    const vsLYNonSellingProductivity = (cyNonSellingProductivity && lyNonSellingProductivity) ?
        cyNonSellingProductivity / lyNonSellingProductivity * 100
        : null

    const cyPersonalShopperProductivity = cyPersonalShopperHeadcount ? (cyPsSales / cyPersonalShopperHeadcount) : null
    const lyPersonalShopperProductivity = lyPersonalShopperHeadcount ? (lyPsSales / lyPersonalShopperHeadcount) : null
    const vsLYPersonalShopperProductivity = (cyPersonalShopperProductivity && lyPersonalShopperProductivity) ?
        cyPersonalShopperProductivity / lyPersonalShopperProductivity * 100
        : null

    const cyTotalManpowerProductivity = cyTotalHeadcount ? (cySales / cyTotalHeadcount) : null
    const lyTotalManpowerProductivity = lyTotalHeadcount ? (lySales / lyTotalHeadcount) : null
    const vsLYTotalManpowerProductivity = (cyTotalManpowerProductivity && lyTotalManpowerProductivity) ?
        cyTotalManpowerProductivity / lyTotalManpowerProductivity * 100
        : null

    // FIXME: check if we want these columns as standard column names
    const cyDisplayData = {
        cyTotalManpowerProductivity: isCYSalesLoading ? LOADING_STRING : cyTotalManpowerProductivity,
        cySellingProductivity: isCYSalesLoading ? LOADING_STRING : cySellingProductivity,
        cyNonSellingProductivity: isCYSalesLoading ? LOADING_STRING : cyNonSellingProductivity,
        cyPersonalShopperProductivity: isCYSalesLoading ? LOADING_STRING : cyPersonalShopperProductivity,
        cySales,
        cyPsSales,
        cyDays,
        cySellingHeadcountSum,
        cyNonSellingHeadcountSum,
        cyPersonalShopperHeadcountSum,
        cySellingHeadcount,
        cyNonSellingHeadcount,
        cyPersonalShopperHeadcount,
        cyTotalHeadcount,
        // cySalesMonthsCoverage,
    };
    const lyDisplayData = {
        lyTotalManpowerProductivity: isLYSalesLoading ? LOADING_STRING : lyTotalManpowerProductivity,
        lySellingProductivity: isLYSalesLoading ? LOADING_STRING : lySellingProductivity,
        lyNonSellingProductivity: isLYSalesLoading ? LOADING_STRING : lyNonSellingProductivity,
        lyPersonalShopperProductivity: isLYSalesLoading ? LOADING_STRING : lyPersonalShopperProductivity,
        lySales,
        lyPsSales,
        lyDays,
        lySellingHeadcountSum,
        lyNonSellingHeadcountSum,
        lyPersonalShopperHeadcountSum,
        lySellingHeadcount,
        lyNonSellingHeadcount,
        lyPersonalShopperHeadcount,
        lyTotalHeadcount,
        // lySalesMonthsCoverage,
        vsLYTotalManpowerProductivity: (isCYSalesLoading || isLYSalesLoading || isLYLoading) ? LOADING_STRING : vsLYTotalManpowerProductivity,
        vsLYSellingProductivity: (isCYSalesLoading || isLYSalesLoading || isLYLoading) ? LOADING_STRING : vsLYSellingProductivity,
        vsLYNonSellingProductivity: (isCYSalesLoading || isLYSalesLoading || isLYLoading) ? LOADING_STRING : vsLYNonSellingProductivity,
        vsLYPersonalShopperProductivity: (isCYSalesLoading || isLYSalesLoading || isLYLoading) ? LOADING_STRING : vsLYPersonalShopperProductivity,

    }

    if (isLYLoading) {
        return {
            ...commonDisplayData,
            ...cyDisplayData,
            ...LY_MANPOWER_LOADING_OUTPUT
        }
    }

    return {
        ...commonDisplayData,
        ...cyDisplayData,
        ...lyDisplayData
    }

}

const computeRowsDisplay = (cyManpowerResult, lyManpowerResult, cySalesResult, lySalesResult, cyPSSalesResult, lyPSSalesResult) => {
    if (cyManpowerResult.isLoading && lyManpowerResult.isLoading) {
        return null;
    }

    // find pairs of rows to compare
    if (!cyManpowerResult.data) {
        return null;
    }

    const displayData = cyManpowerResult.data?.map((cyRow) => {
        const matchingLYRow = lyManpowerResult.data?.find((lyRow) => {
            return Object.keys(cyRow).every(key => {
                if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) {
                    return true;
                }
                return cyRow[key] === lyRow[key];
            });
        });

        const matchingSalesCYRow = cySalesResult.data?.find((cySalesRow) => {
            return Object.keys(cyRow).every(key => {
                if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) {
                    return true;
                }
                return cyRow[key] === cySalesRow[key];
            });
        });


        const matchingSalesLYRow = lySalesResult.data?.find((lySalesRow) => {
            return Object.keys(cyRow).every(key => {
                if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) {
                    return true;
                }
                return cyRow[key] === lySalesRow[key];
            });
        });

        const matchingPSSalesCYRow = cyPSSalesResult?.data?.find((cySalesRow) => {
            return Object.keys(cyRow).every(key => {
                if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) {
                    return true;
                }
                return cyRow[key] === cySalesRow[key];
            });
        });

        const matchingPSSalesLYRow = lyPSSalesResult?.data?.find((lySalesRow) => {
            return Object.keys(cyRow).every(key => {
                if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) {
                    return true;
                }
                return cyRow[key] === lySalesRow[key];
            });
        });

        return computeRowDisplay(
            cyRow, matchingLYRow, cyManpowerResult.isLoading, lyManpowerResult.isLoading,
            matchingSalesCYRow, matchingSalesLYRow, cySalesResult.isLoading, lySalesResult.isLoading,
            matchingPSSalesCYRow, matchingPSSalesLYRow, cyPSSalesResult?.isLoading, lyPSSalesResult?.isLoading
        );
    })

    return displayData;
}

export const useMTDCompleteDisplayData = (params, useQueryOptions={}) => {
    const cyManpowerResult = useMTDManpowerData(false, params, useQueryOptions);
    const lyManpowerResult = useMTDManpowerData(true, params, {
        ...useQueryOptions,
        enabled: !!cyManpowerResult.data && (useQueryOptions?.enabled !== false)
    });

    const psParams = {
        ...params,
        channel_desc: 'Personal Shopper'
    }

    const cySalesResult = useMTDSalesData(false, params, useQueryOptions);
    const lySalesResult = useMTDSalesData(true, params, {
        ...useQueryOptions,
        enabled: !!cySalesResult.data && (useQueryOptions?.enabled !== false)
    });

    const cyPSSalesResult = useMTDSalesData(false, psParams, useQueryOptions);
    const lyPSSalesResult = useMTDSalesData(true, psParams, {
        ...useQueryOptions,
        enabled: !!cyPSSalesResult.data && (useQueryOptions?.enabled !== false)
    });


    const displayData = React.useMemo(() => {
        return computeRowsDisplay(cyManpowerResult, lyManpowerResult, cySalesResult, lySalesResult, cyPSSalesResult,lyPSSalesResult);
    }, [cyManpowerResult, lyManpowerResult,cySalesResult,lySalesResult,cyPSSalesResult,lyPSSalesResult])

    return {
        data: displayData,
        isLoading: cyManpowerResult.isLoading,
        isPartialLoading: cyManpowerResult.isLoading || lyManpowerResult.isLoading ||
            cySalesResult.isLoading || lySalesResult.isLoading || cyPSSalesResult.isLoading ||
            lyPSSalesResult.isLoading
    }
}

export const useYTDCompleteDisplayData = (params, useQueryOptions={}) => {
    const cyManpowerResult = useYTDManpowerData(false, params, useQueryOptions);
    const lyManpowerResult = useYTDManpowerData(true, params, {
        ...useQueryOptions,
        enabled: !!cyManpowerResult.data && (useQueryOptions?.enabled !== false)
    });

    const psParams = {
        ...params,
        channel_desc: 'Personal Shopper'
    }

    const cySalesResult = useYTDSalesData(false, params, useQueryOptions);
    const lySalesResult = useYTDSalesData(true, params, {
        ...useQueryOptions,
        enabled: !cySalesResult.data && (useQueryOptions?.enabled !== false)
    });

    const cyPSSalesResult = useYTDSalesData(false, psParams, useQueryOptions);
    const lyPSSalesResult = useYTDSalesData(true, psParams, {
        ...useQueryOptions,
        enabled: !!cyPSSalesResult.data && (useQueryOptions?.enabled !== false)
    });

    const displayData = React.useMemo(() => {
        return computeRowsDisplay(cyManpowerResult, lyManpowerResult, cySalesResult, lySalesResult,cyPSSalesResult,lyPSSalesResult);
    }, [cyManpowerResult, cyPSSalesResult, cySalesResult, lyManpowerResult, lyPSSalesResult, lySalesResult])

    return {
        data: displayData,
        isLoading: cyManpowerResult.isLoading,
        isPartialLoading: cyManpowerResult.isLoading || lyManpowerResult.isLoading ||
            cySalesResult.isLoading || lySalesResult.isLoading || cyPSSalesResult.isLoading || 
            lyPSSalesResult.isLoading
    }
}

export const useTopLineData = () => {
    const params = useOperationsUnifiedParams();

    const isSalesMetricVisible = useIsMetricVisible('sales');

    const isTotalManpowerProductivityMetricVisible = useIsMetricVisible('total_manpower_productivity');
    const isSellingProductivityMetricVisible = useIsMetricVisible('selling_manpower_productivity');
    const isNonSellingProductivityMetricVisible = useIsMetricVisible('non_selling_manpower_productivity');
    const isPersonalShopperProductivityMetricVisible = useIsMetricVisible('personal_shopper_manpower_productivity');

    const {data: mtdDisplayData, isLoading: mtdIsLoading} = useMTDCompleteDisplayData(params);
    const {data: ytdDisplayData, isLoading: ytdIsLoading} = useYTDCompleteDisplayData(params);

    // reformat data to match the format of the data in the SalesOperations topline
    const hasDeptFilter = params.hasOwnProperty('dept_desc_standardized') || params.hasOwnProperty('bu_desc') || params.hasOwnProperty('bu_grp');

    const totalManpowerProductivityRow = {
        pk: 'Total Manpower Productivity',
        metric: 'total_manpower_productivity',
        mtd_actual: mtdIsLoading ? LOADING_STRING : mtdDisplayData?.[0]?.cyTotalManpowerProductivity,
        mtd_actual_tooltip: mtdIsLoading || !isSalesMetricVisible ? [] : [
            <HorizontalTooltipDivider />,
            <><b>Formula:</b> Gross Sales / Total Headcount</>,
            <><b>Sales:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cySales)}</>,
            <><b>Total Headcount:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyTotalHeadcount)}</>,
        ],
        mtd_vs_plan: NOT_AVAILABLE,
        mtd_vs_ly: mtdIsLoading ? LOADING_STRING : mtdDisplayData?.[0]?.vsLYTotalManpowerProductivity,
        mtd_vs_ly_tooltip: mtdIsLoading || !isSalesMetricVisible ? [] : [
            <><b>LY Total Manpower Productivity:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyTotalManpowerProductivity, 1)}</>,
            <HorizontalTooltipDivider />,
            <><b>LY Sales:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lySales)}</>,
            <><b>LY Total Headcount:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyTotalHeadcount)}</>,
        ],
        ytd_actual: ytdIsLoading ? LOADING_STRING : ytdDisplayData?.[0]?.cyTotalManpowerProductivity,
        ytd_actual_tooltip: ytdIsLoading || !isSalesMetricVisible ? [] : [
            <HorizontalTooltipDivider />,
            <><b>Formula:</b> Gross Sales / Total Headcount</>,
            <><b>Sales:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cySales)}</>,
            <><b>Total Headcount:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyTotalHeadcount)}</>,
        ],
        ytd_vs_plan:  NOT_AVAILABLE,
        ytd_vs_ly: ytdIsLoading ? LOADING_STRING : ytdDisplayData?.[0]?.vsLYTotalManpowerProductivity,
        ytd_vs_ly_tooltip: ytdIsLoading || !isSalesMetricVisible ? [] : [
            <><b>LY Total Manpower Productivity:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyTotalManpowerProductivity, 1)}</>,
            <HorizontalTooltipDivider />,
            <><b>LY Sales:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lySales)}</>,
            <><b>LY Total Headcount:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyTotalHeadcount)}</>,
        ],
        subRows: []
    };

    const sellingProductivityRow = hasDeptFilter ? {
        pk: 'Selling Productivity',
        metric: 'selling_manpower_productivity',
        mtd_actual: NOT_AVAILABLE,
        mtd_vs_plan: NOT_AVAILABLE,
        mtd_vs_ly: NOT_AVAILABLE,
        ytd_actual: NOT_AVAILABLE,
        ytd_vs_plan:  NOT_AVAILABLE,
        ytd_vs_ly: NOT_AVAILABLE,
    } : {
        pk: 'Selling Productivity',
        metric: 'selling_manpower_productivity',
        mtd_actual: mtdIsLoading ? LOADING_STRING : mtdDisplayData?.[0]?.cySellingProductivity,
        mtd_actual_tooltip: mtdIsLoading || !isSalesMetricVisible ? [] : [
            <HorizontalTooltipDivider />,
            <><b>Formula:</b> Gross Sales / Selling Headcount</>,
            <><b>Sales:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cySales)}</>,
            <><b>Selling Headcount Raw:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cySellingHeadcountSum)}</>,
            <><b>Days:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyDays)}</>,
            <><b>Selling Headcount:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cySellingHeadcount)}</>,
        ],
        mtd_vs_plan: NOT_AVAILABLE,
        mtd_vs_ly: mtdIsLoading || !isSalesMetricVisible ? LOADING_STRING : mtdDisplayData?.[0]?.vsLYSellingProductivity,
        mtd_vs_ly_tooltip: mtdIsLoading ? [] : [
            <><b>LY Selling Productivity:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lySellingProductivity, 1)}</>,
            <HorizontalTooltipDivider />,
            <><b>LY Sales:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lySales)}</>,
            <><b>LY Selling Headcount Raw:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lySellingHeadcountSum)}</>,
            <><b>LY Days:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyDays)}</>,
            <><b>LY Selling Headcount:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lySellingHeadcount)}</>,
        ],
        ytd_actual: ytdIsLoading ? LOADING_STRING : ytdDisplayData?.[0]?.cySellingProductivity,
        ytd_actual_tooltip: ytdIsLoading || !isSalesMetricVisible ? [] : [
            <HorizontalTooltipDivider />,
            <><b>Formula:</b> Gross Sales / Selling Headcount</>,
            <><b>Sales:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cySales)}</>,
            <><b>Selling Headcount Raw:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cySellingHeadcountSum)}</>,
            <><b>Days:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyDays)}</>,
            <><b>Selling Headcount:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cySellingHeadcount)}</>,
        ],
        ytd_vs_plan: NOT_AVAILABLE,
        ytd_vs_ly: ytdIsLoading ? LOADING_STRING : ytdDisplayData?.[0]?.vsLYSellingProductivity,
        ytd_vs_ly_tooltip: ytdIsLoading || !isSalesMetricVisible ? [] : [
            <><b>LY Selling Productivity:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lySellingProductivity, 1)}</>,
            <HorizontalTooltipDivider />,
            <><b>LY Sales:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lySales)}</>,
            <><b>LY Selling Headcount Raw:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lySellingHeadcountSum)}</>,
            <><b>LY Days:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyDays)}</>,
            <><b>LY Selling Headcount:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lySellingHeadcount)}</>,
        ],
    }

    const nonSellingProductivityRow = hasDeptFilter ? {
        pk: 'Non-Selling Productivity',
        metric: 'non_selling_manpower_productivity',
        mtd_actual: NOT_AVAILABLE,
        mtd_vs_plan: NOT_AVAILABLE,
        mtd_vs_ly: NOT_AVAILABLE,
        ytd_actual: NOT_AVAILABLE,
        ytd_vs_plan:  NOT_AVAILABLE,
        ytd_vs_ly: NOT_AVAILABLE,
    } : {
        pk: 'Non-Selling Productivity',
        metric: 'non_selling_manpower_productivity',
        mtd_actual: mtdIsLoading ? LOADING_STRING : mtdDisplayData?.[0]?.cyNonSellingProductivity,
        mtd_actual_tooltip: mtdIsLoading || !isSalesMetricVisible ? [] : [
            <HorizontalTooltipDivider />,
            <><b>Formula:</b> Gross Sales / Non-Selling Headcount</>,
            <><b>Sales:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cySales)}</>,
            <><b>Non-Selling Headcount Raw:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyNonSellingHeadcountSum)}</>,
            <><b>Days:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyDays)}</>,
            <><b>Non-Selling Headcount:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyNonSellingHeadcount)}</>,
        ],
        mtd_vs_plan: NOT_AVAILABLE,
        mtd_vs_ly: mtdIsLoading ? LOADING_STRING : mtdDisplayData?.[0]?.vsLYNonSellingProductivity,
        mtd_vs_ly_tooltip: mtdIsLoading || !isSalesMetricVisible ? [] : [
            <><b>LY Non-Selling Productivity:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lYNonSellingProductivity, 1)}</>,
            <HorizontalTooltipDivider />,
            <><b>LY Sales:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lySales)}</>,
            <><b>LY Non-Selling Headcount Raw:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyNonSellingHeadcountSum)}</>,
            <><b>LY Days:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyDays)}</>,
            <><b>LY Non-Selling Headcount:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyNonSellingHeadcount)}</>,
        ],
        ytd_actual: ytdIsLoading ? LOADING_STRING : ytdDisplayData?.[0]?.cyNonSellingProductivity,
        ytd_actual_tooltip: ytdIsLoading || !isSalesMetricVisible ? [] : [
            <HorizontalTooltipDivider />,
            <><b>Formula:</b> Gross Sales / Non-Selling Headcount</>,
            <><b>Sales:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cySales)}</>,
            <><b>Non-Selling Headcount Raw:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cySellingHeadcountSum)}</>,
            <><b>Days:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyDays)}</>,
            <><b>Non-Selling Headcount:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyNonSellingHeadcount)}</>,
        ],
        ytd_vs_plan: NOT_AVAILABLE,
        ytd_vs_ly: ytdIsLoading ? LOADING_STRING : ytdDisplayData?.[0]?.vsLYNonSellingProductivity,
        ytd_vs_ly_tooltip: ytdIsLoading || !isSalesMetricVisible ? [] : [
            <><b>LY Non-Selling Productivity:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyNonSellingProductivity, 1)}</>,
            <HorizontalTooltipDivider />,
            <><b>LY Sales:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lySales)}</>,
            <><b>LY Non-Selling Headcount Raw:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyNonSellingHeadcountSum)}</>,
            <><b>LY Days:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyDays)}</>,
            <><b>LY Non-Selling Headcount:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyNonSellingHeadcount)}</>,
        ],
    }

    const psProductivityRow = hasDeptFilter ? {
        pk: 'Personal Shopper Productivity',
        metric: 'personal_shopper_manpower_productivity',
        mtd_actual: NOT_AVAILABLE,
        mtd_vs_plan: NOT_AVAILABLE,
        mtd_vs_ly: NOT_AVAILABLE,
        ytd_actual: NOT_AVAILABLE,
        ytd_vs_plan:  NOT_AVAILABLE,
        ytd_vs_ly: NOT_AVAILABLE,
    } : {
        pk: 'Personal Shopper Productivity',
        metric: 'personal_shopper_manpower_productivity',
        mtd_actual: mtdIsLoading ? LOADING_STRING : mtdDisplayData?.[0]?.cyPersonalShopperProductivity,
        mtd_actual_tooltip: mtdIsLoading || !isSalesMetricVisible ? [] : [
            <HorizontalTooltipDivider />,
            <><b>Formula:</b> Personal Shopper Sales / Personal Shopper Headcount</>,
            <><b>Personal Shopper Sales:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyPsSales)}</>, 
            <><b>Personal Shopper Headcount:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyPersonalShopperHeadcount)}</>,
        ],
        mtd_vs_plan: NOT_AVAILABLE,
        mtd_vs_ly: mtdIsLoading ? LOADING_STRING : mtdDisplayData?.[0]?.vsLYPersonalShopperProductivity,
        mtd_vs_ly_tooltip: mtdIsLoading || !isSalesMetricVisible ? [] : [
            <><b>LY Personal Shopper Productivity:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyPersonalShopperProductivity, 1)}</>,
            <HorizontalTooltipDivider />,
            <><b>LY Personal Shopper Sales:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyPsSales)}</>,
            <><b>LY Personal Shopper Headcount:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyPersonalShopperHeadcount)}</>,
        ],
        ytd_actual: ytdIsLoading ? LOADING_STRING : ytdDisplayData?.[0]?.cyPersonalShopperProductivity,
        ytd_actual_tooltip: ytdIsLoading || !isSalesMetricVisible ? [] : [
            <HorizontalTooltipDivider />,
            <><b>Formula:</b> Personal Shopper Sales / Personal Shopper Headcount</>,
            <><b>Personal Shopper Sales:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyPsSales)}</>,
            <><b>Personal Shopper Headcount:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyPersonalShopperHeadcount)}</>,
        ],
        ytd_vs_plan: NOT_AVAILABLE,
        ytd_vs_ly: ytdIsLoading ? LOADING_STRING : ytdDisplayData?.[0]?.vsLYPersonalShopperProductivity,
        ytd_vs_ly_tooltip: ytdIsLoading || !isSalesMetricVisible ? [] : [
            <><b>LY Personal Shopper Productivity:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyPersonalShopperProductivity, 1)}</>,
            <HorizontalTooltipDivider />,
            <><b>LY Personal Shopper Sales:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyPsSales)}</>,
            <><b>LY Personal Shopper Headcount:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyPersonalShopperHeadcount)}</>,
        ],
    }

    const data = [];
    
    if (isSellingProductivityMetricVisible) {
        totalManpowerProductivityRow.subRows.push(sellingProductivityRow);
    }
    if (isNonSellingProductivityMetricVisible) {
        totalManpowerProductivityRow.subRows.push(nonSellingProductivityRow);
    }
    if (isPersonalShopperProductivityMetricVisible) {
        totalManpowerProductivityRow.subRows.push(psProductivityRow);
    }
    if (isTotalManpowerProductivityMetricVisible) {
        data.push(totalManpowerProductivityRow);
    }

    return {
        data,
        isLoading: false,  // since we already know the rows, we can set this to false
        isPartialLoading: mtdIsLoading || ytdIsLoading
    }
}

export const useZoneToStorePerformance = () => {
    const zoneStoreToggle = useSelectorValue(SELECTOR_ZONE_STORE_TOGGLE);

    const params = useOperationsUnifiedParams();

    const useQueryOptions = {};

    return useNestedData(
        ['region', 'branch_name'],
        zoneStoreToggle === 'zone' ? 0 : 2,
        params,
        useQueryOptions,
        useMTDCompleteDisplayData,
        useYTDCompleteDisplayData,
        'total_manpower_productivity'
    );
}

export const useBusinessUnitToDepartmentPerformance = () => {
    const metricSelected = useSelectorValue('metric') || 'total_manpower_productivity';

    const isApplicableMetric = metricSelected === 'total_manpower_productivity';
    const params = useOperationsUnifiedParams();

    const appliedParams = {
        ...params,
        bu_grp__is_not_null: true,
        bu_desc__is_not_null: true,
        dept_desc_standardized__is_not_null: true,
    }

    return useNestedData(['bu_grp', 'bu_desc', 'dept_desc_standardized'], 0, params, {
        enabled: isApplicableMetric
    },
    useMTDCompleteDisplayData,
    useYTDCompleteDisplayData,
    'total_manpower_productivity'
    );
}

