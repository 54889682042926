import React, {useMemo} from 'react';
import {useSelectorValue} from '../../common/providers/selectorProvider';
import ContextBasedDropdownSelector from '../../common/presentation/selectors/ContextBasedDropdownSelector';
import {useMetricVisibility} from "../hooks/utils";


const OPERATION_METRIC_OPTIONS = [
    {value: 'catchment_rate', label: 'Catchment Rate'},
    {value: 'conversion_rate', label: 'Conversion Rate'},
    {value: 'space_productivity', label: 'Space Productivity'}
]

const SALES_METRIC_OPTIONS = [
    {value: 'sales', label: 'Sales'},
    {value: 'transactions', label: 'Transactions'},
    {value: 'atv', label: 'Avg Txn Value'},
    {value: 'units_sold_per_transaction', label: 'Avg Units Sold'}
]

const MANPOWER_METRIC_OPTIONS = [
    { value: 'total_manpower_productivity', label: 'Total Manpower Productivity' },
    { value: 'selling_manpower_productivity', label: 'Selling Productivity' },
    { value: 'non_selling_manpower_productivity', label: 'Non-Selling Productivity' },
    { value: 'personal_shopper_manpower_productivity', label: 'Personal Shopper Productivity' }
]

const METRIC_OPTIONS_BY_TAB = {
    'operations': OPERATION_METRIC_OPTIONS,
    'sales': SALES_METRIC_OPTIONS,
    'manpower': MANPOWER_METRIC_OPTIONS,
}


export default function MetricSelector({selectedTab}) {
    const metricVisibility = useMetricVisibility()

    const options = useMemo(() => {
        return METRIC_OPTIONS_BY_TAB[selectedTab].filter(option => metricVisibility[option.value])
    }, [selectedTab, metricVisibility])

    const defaultVal = useSelectorValue('metric') || options[0].value;

    return <ContextBasedDropdownSelector
        options={options}
        label="Metric"
        contextKey="metric"
        defaultValue={defaultVal}
        startAdornment={'Metric:'}
    />
}