import { useMemo, useState } from "react";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditIPSet from "./EditIPSet";
import DeleteIPSet from "./DeleteIPSet";

export default function IPSetTable({ data, isLoading, filters, handleRadioChange, setActionApply }) {

    function MenuAction({ row, setActionApply }) {
        const [editModalOpen, setEditModalOpen] = useState(false)
        const [deleteModalOpen, setDeleteModalOpen] = useState(false)

        return <>
            <Tooltip title="Edit">
                <IconButton>
                    <EditRoundedIcon onClick={() => setEditModalOpen(true)} />
                </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
                <IconButton>
                    <DeleteRoundedIcon
                        onClick={() => setDeleteModalOpen(true)}
                        sx={{ color: "red" }}
                    />
                </IconButton>
            </Tooltip>

            <EditIPSet row={row} open={editModalOpen} setOpen={setEditModalOpen} setActionApply={setActionApply} />
            <DeleteIPSet row={row} open={deleteModalOpen} setOpen={setDeleteModalOpen} setActionApply={setActionApply} />
        </>
    }



    const IP_SET_COLUMNS = useMemo(() => {
        return [
            {
                id: 'ip_set_name',
                header: 'IP Set Name',
                accessorKey: 'ip_set_name',
                cell: ({ row }) => {
                    const ipSetId = row?.original?.id;
                    const ipSetName = row?.original?.ip_set_name;

                    const isSelected = filters?.id === ipSetId;
                    return (
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mx: 1 }}>
                            <IconButton onClick={() => handleRadioChange(ipSetId, ipSetName)}>
                                {isSelected ? (
                                    <RadioButtonCheckedIcon sx={{ color: "#1976D2" }} />
                                ) : (
                                    <RadioButtonUncheckedIcon sx={{ color: "#1976D2" }} />
                                )}
                            </IconButton>
                            <Typography>{ipSetName}</Typography>
                        </Box>
                    );
                },
                customStyle: { width: "19rem", textAlign: "left", verticalAlign: "middle" }
            },
            {
                id: 'ip_addresses',
                header: 'IP Addresses',
                accessorKey: 'ip_addresses',
                cell: ({ row, getValue }) => {
                    const value = getValue();
                    return (
                        <Box sx={{ alignItems: "center", gap: 1, mx: 1 }}>
                            <Typography>
                                <Box sx={{ marginLeft: '1em', my: 1, display: 'flex' }}>
                                    {value?.map((ip, index) => (
                                        <li style={{ marginRight: 10 }} key={index}>{ip}</li>
                                    ))}
                                </Box>
                            </Typography>
                        </Box>
                    );
                },
                customStyle: { width: "19rem" }
            },
            {
                id: 'menu_actions',
                header: 'Action Menu',
                cell: (row) => (
                    <MenuAction row={row} setActionApply={setActionApply} />
                ),
                customStyle: { width: "5rem", textAlign: "center", }

            }
        ]
    }, [data, filters]
    )

    if (isLoading) {
        return <LoadingComponent />
    }

    return <TableComponentV2
        columns={IP_SET_COLUMNS}
        data={data}
        tableId={'IPSet'}
        isCompact
        isInventoryTable={true}
        customTableContainerStyle={{
            borderRadius: "0",
            boxShadow: 'none',
        }}

    />
}