// TODO: fix this and move MonthSelector and YearSelector to a common folder
import React from 'react';
import { useSelectorState, useSelectorValue } from '../../common/providers/selectorProvider';
import moment from 'moment';
import FilterMonth from "../../../../common/components/FilterMonth";
import { getFullMonthsDateRange, getMonthBasedOnRadio } from '../../SalesOperations/hooks/utils';


export default function MonthSelector({ isLoadingDateRange, dateRange, isDisabled, hasOptionLatestOrFull = false }) {
    const selectedTab = useSelectorValue('selected_tab') || 'sales';
    const selectedRadio = useSelectorValue('latest_full_month') || 'latest_month'
    const year = useSelectorValue('year');
    const [month, setMonth] = useSelectorState('month');
    const isLatest = selectedRadio === 'latest_month'
    const displayedDateRange = hasOptionLatestOrFull && selectedRadio === 'full_month' ? getFullMonthsDateRange(dateRange) : dateRange

    //defaults
    React.useEffect(() => {
        // double check this. FilterMonth already detects and sets a value. Might be redundant, or we can create a more generic FilterMonth component
        if (!month) {
            if (year === moment(dateRange?.to).year()) {
                setMonth(moment(dateRange?.to).month() + 1)
            } else {
                setMonth(12)
            }
        }
        if (isLatest) {
            setMonth(getMonthBasedOnRadio(dateRange, true))
        }
    }, [year, month, selectedTab, selectedRadio, dateRange]);


    return (
        <FilterMonth
            onDropdownChange={(value) => setMonth(value[0])}
            filters={{ month: [month], year }}
            dateRangeData={displayedDateRange}
            isLoading={isLoadingDateRange || !dateRange || !year || !month}
            isSuccess={!!dateRange && !isLoadingDateRange}
            isDisabled={isDisabled}
        />
    )
}