import SelectorProvider from "../common/providers/selectorProvider";
import TmpTSSSales from "../TmpTSSSales";
import React from "react";
import { useLocation } from "react-router-dom";

export default function ByBUGroup({ higherMBU, title, path, ...props }) {
    const allowedMetrics = [
        'actual_sales',
        'actual_transactions',
        'actual_basket_size',
        'actual_space_nsa',
        'actual_smac_sales',
        'actual_units_sold',
        'actual_units_sold_per_txn'
    ];
    const allowedMetricsForChannel = [
        'actual_sales',
        'actual_smac_sales',
        'actual_transactions',
        'actual_basket_size',
    ];
    const toplineMetricItems = [
        'actual_sales',
        'actual_transactions',
        'basket_size',
        'actual_smac_sales',
        'actual_units_sold',
        'actual_units_sold_per_txn',
        'actual_space_nsa'
    ]

    // get viewby value from URL; default viewby is `channel`
    const loc = useLocation()
    const queryParams = new URLSearchParams(loc.search);
    const viewBy = queryParams.get('viewby') || 'channel';
    const dashboardDefinition = {
        name: title,
        entry_points: [
            path,
        ],
        owners: [
            "raphaelchristopher.a.sepnio@smretail.com"
        ],
        path: path
    }
    return <SelectorProvider defaults={{
            "viewBy": viewBy,
            "metric": "actual_sales",
            "period": "mtd"
        }}>
            <TmpTSSSales
                additionalParams={{ sbu_bu_group: higherMBU }} title={title}
                componentProps={{
                    topLine: {
                        componentProps: {
                            metricFilter: {
                                optionsFilter: (option, viewBy) => {
                                    if (viewBy === 'channel') {
                                        return allowedMetricsForChannel.includes(option.value)
                                    }
                                    return allowedMetrics.includes(option.value)
                                }
                            }
                        }
                    },
                    topLineDataProvider: {
                        topLineMetricItems: toplineMetricItems,
                        enableMetricPlan: toplineMetricItems,
                        enableVSPlan: toplineMetricItems,
                        enabledVSPlan: toplineMetricItems,
                        enabledVSLastYear: toplineMetricItems,
                    },
                    storePerformance: {
                        enableBranchLink: true,
                    },
                    salesPerformancePerGroup: {
                        enabled: true,
                        enableZoneLink: true
                    },
                }}
                moduleDefinition={dashboardDefinition}
                {...props}
            />
        </SelectorProvider>
}