import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContents from "./CardContents";
import {useCheckHasAnyAccess} from "./hooks";


export default function CardGroup({title, contents}) {

    const contentPaths = contents.filter(dashboardItem => !dashboardItem.disabled).map(dashboardItem => dashboardItem.path)
    const hasAccessToAnyItem = useCheckHasAnyAccess(contentPaths)

    if (!hasAccessToAnyItem) {
        return null;
    }


    return (
        <Grid item xs={12} md={3}>
            <Typography variant='h5' ml={1} mb={1} sx={{fontWeight: '500'}}>
                {title}
            </Typography>

            <Card variant="outlined">
                <Grid container spacing={2} p={2} direction={'row'}>
                    {
                        contents.map(dashboardItem => {
                            return <CardContents {...dashboardItem} key={dashboardItem.name}/>
                        })
                    }
                </Grid>
            </Card>
        </Grid>
    )
}