import React, { useMemo } from 'react'
import Section from "../../../../common/components/Dashboard/Section";
import { useDashboardTrackingMostActiveModule } from '../../../../common/API/hooks';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import { Typography } from '@mui/material';
import MostActiveModuleTable from './MostActiveModuleChart';
import { useSelectorValue } from '../../../customDashboards/common/providers/selectorProvider';
import { formatDateToObjectFromAndTo } from '../utils';

export default function MostActiveModule() {
    
    const date = useSelectorValue('date')
    const user = useSelectorValue('user')
    const module = useSelectorValue('module')
    const dateRange = formatDateToObjectFromAndTo(date)    

    const filters = useMemo(() => ({
        ...dateRange,
        username: user === 'All' ? null : user, //to restrieve all users if All is selected
        module_name: module === 'All' ? null : module //to restrieve all modules if All is selected
    }), [dateRange,user, module]);
    
    const { data, isLoading, error } = useDashboardTrackingMostActiveModule(filters);
    const renderContent = () => {
        if (isLoading) {
            return <LoadingComponent />
        }
        if (error) {
            return <Typography variant="h5">Error loading data</Typography> 
        }

        return <MostActiveModuleTable data = { data }/>
    }
    return <Section
        title={"Most Active Dashboard"}
        isCollapsible={true}
    >
        { renderContent() }
        
    </Section>
}