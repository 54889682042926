import { Grid } from "@mui/material";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { topLineDisplayNumber } from "../../../../../common/utils";
import DoughnutGraph from "../../../CustomerAnalytics/SMACCardStatus/DoughnutGraph";
import { useContext } from "react";
import { WindowSizeContext } from "../../../../../common/context/WindowSizeContext";
import {CHART_FONT_SIZE} from "../../constants";

export default function ToplineDoughnutGraph({ data, label }) {
    const windowSize = useContext(WindowSizeContext);
    const doughnutData = data ? Object.entries(data) : [];
    const newDoughnutData = doughnutData?.map(([name, value]) => ({
        value,
        name,
        itemStyle: { color: name === label ? '#4E88DD' : '#AFAFAF' }
    }));

    const doughnutOption = {
        animation: false,
        tooltip: { show: false },
        grid: {
            top: '20%',
            containLabel: true
        },
        series: [
            // Inner label display
            {
                type: 'pie',
                emphasis: { disabled: true },
                radius: [25, 80],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                data: newDoughnutData,
                label: {
                    show: true,
                    position: 'inside',
                    color: '#ffffff',
                    formatter: params => `{value|${params.percent}}%`,
                    rich: { value: { color: '#ffffff', fontSize: CHART_FONT_SIZE } },
                    z: 1000
                },
                labelLine: { show: true, lineStyle: { width: 0 } }
            },
            // Outer label display
            {
                type: 'pie',
                emphasis: { disabled: true },
                radius: [25, 80],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                data: newDoughnutData,
                label: {
                    show: true,
                    position: 'outside',
                    color: '#000000',
                    formatter: params => {
                        const formattedValue = topLineDisplayNumber(params.value);
                        const name = params.name;
                        const wrappedName = name.split(' ').join('\n');

                        return windowSize.isOnMobile ? `{name|${wrappedName}}:\n{value|${formattedValue}}`
                            : `{name|${wrappedName}}: {value|${formattedValue}}`;
                    },
                    rich: {
                        name: { color: '#000000', fontSize: CHART_FONT_SIZE },
                        value: { color: '#000000', fontSize: CHART_FONT_SIZE }
                    },
                    z: 1
                },
                labelLine: { show: true, lineStyle: { color: '#000000', width: 2 } }
            }
        ]
    }

    return <DoughnutGraph option={doughnutOption} echartContainerStyle={{ minHeight: "25rem"  }} />
}