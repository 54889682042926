import React, { useContext } from 'react';
import { getSelectedOption } from '../../../../common/utils';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { useSelectorState } from '../../common/providers/selectorProvider';

export default function ConditionSelector() {
    const [conditionFilter, setConditionFilters] = useSelectorState('condition', []);
    const windowSize = useContext(WindowSizeContext);

    const options = [
        { value: 'greater_than_equal', label: '>=' },
        { value: 'less_than_equal', label: '<=' }
    ]

    return (
        <>
            <ConditionalSelectComponent
                id="condition_filter"
                labelId="condition_filter-label"
                options={options}
                label="Condition"
                value={conditionFilter ? (windowSize.isOnMobile ? conditionFilter : getSelectedOption(conditionFilter, options)) : '' && null}
                onSelectChange={(event, newValue) => {
                    windowSize.isOnMobile ? setConditionFilters(event.target.value, 'condition')
                        : setConditionFilters(newValue?.value, 'condition')
                }}
                endAdornment={true}
                startAdornment={false}
                clearSelected={() => setConditionFilters('', 'condition')}
                disableClearable={false}
            />
        </>

    )
}