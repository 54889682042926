import React from "react";
import {useOrganizationOptions} from "../hooks";
import {useSelectorContext, useSelectorValue} from "../../common/providers/selectorProvider";
import DropdownRadioComponent from "../../../../common/components/DropdownRadioComponent";

export default function OrganizationSelectorV2() {
    const { data: organizationOptions, isLoading } = useOrganizationOptions();

    const organizationValue = useSelectorValue('organization') || 'SM Store';
    const companyValue = useSelectorValue('company') || 'All';
    const card_group = useSelectorValue('card_group') || 'SMAC'

    const {patchValues} = useSelectorContext();

    const flatOptions = organizationOptions.reduce((acc, option) => {
        acc.push(option);
        if (option.children) {
            acc.push(...option.children);
        }
        return acc;
    }, [])

    let selectedOption = flatOptions.find(option =>
        option.data.organization === organizationValue && option.data.company === companyValue
    );

    if (organizationValue === "SM Markets" && card_group === "SLP") {
        selectedOption = flatOptions.find(option => option.data.organization === "SM Store" && option.data.company === 'All' ) || selectedOption;
        patchValues({ organization: "SM Store", company: 'All' });
    }

    return (
        <DropdownRadioComponent
            id={"organization_selector"}
            labelId={"organization_selector-label"}
            selectedKey={selectedOption?.key}
            isLoading={isLoading}
            options={organizationOptions}
            label={"Organization"}
            contextKey={"organization"}
            defaultValue={organizationValue}
            onSelectChange={(event, clickedOption) => {
                patchValues(clickedOption.data)
            }}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: '40vh',
                        overflowY: "auto",
                    },
                },
            }}
        />
    )
}