import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {ListItemIcon, ListItemText, MenuItem, Typography} from "@mui/material";
import React from "react";

export function DropdownRadioOption({option, onSelectChange, selectedKey, iconSx = {}, disabled, isParent = false }) {
    const {key, label, unclickable} = option;

    const radioDisplay = selectedKey === key 
        ? <RadioButtonCheckedIcon fontSize="small" color="primary"/> 
        : <RadioButtonUncheckedIcon fontSize="small" color="primary"/>

    const menuItemStyle = { 
        marginLeft: unclickable ? "2.3em" : undefined,
        ...(unclickable && {
            pointerEvents: "none", 
            backgroundColor: "transparent !important", 
            "&:hover": {
                backgroundColor: "transparent !important" 
            }
        })
    }
        
    return <MenuItem 
            value={key} 
            onClick={(e) => {
                if (unclickable) {
                    e.preventDefault();
                } else {
                    onSelectChange(e, option);
                }
            }}
            disableRipple={unclickable}
            disableTouchRipple={unclickable}
            disabled={disabled}
            sx={menuItemStyle}
           >
            {!unclickable && <ListItemIcon sx={iconSx}> {radioDisplay} </ListItemIcon> }
            <ListItemText disableTypography 
                primary={
                    <Typography sx={{ color: "black", fontWeight: isParent ? "bold" : "normal" }}>
                        {label}
                    </Typography>
                } 
            />
    </MenuItem>
}