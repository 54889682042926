import { TAB_MANPOWER, TAB_OPERATIONS, TAB_SALES } from "./dashboardDefinition";

export const VIEW_BY_SALES = 'sales';
export const VIEW_BY_OPERATIONS = 'operations';
export const VIEW_BY_MANPOWER = 'manpower';


export const VIEW_BY = [
    { label: 'Sales', value: VIEW_BY_SALES, id: TAB_SALES },
    { label: 'Operations', value: VIEW_BY_OPERATIONS, id: TAB_OPERATIONS },
    { label: 'Manpower', value: VIEW_BY_MANPOWER, id: TAB_MANPOWER },
]

export const metricOrderArray = ['Sales', 'Transactions', 'Avg Txn Value', 'Avg Units Sold', 'Catchment Rate', 'Conversion Rate', 'Space Productivity']
export const buOrderArray = ['Fashion', 'Home', 'Kids', 'Beauty', 'Others']
export const zoneOrderArray = ['NCR 1', 'NCR 2', 'NCR 3', 'North Luzon 1', 'North Luzon 2', 'North Luzon 3', 'South Luzon 1', 'South Luzon 2', 'Visayas 1', 'Visayas 2', 'Mindanao', 'Online']


export const NOT_AVAILABLE = 'N/A';


export const METRIC_FILTER_OPTIONS = [
    { value: 'sales', label: 'Sales' },
    { value: 'transactions', label: 'Transactions' },
    { value: 'atv', label: 'Avg Txn Value' },
    { value: 'units_sold_per_transaction', label: 'Avg Units Sold' },
    { value: 'catchment_rate', label: 'Catchment Rate' },
    { value: 'conversion_rate', label: 'Conversion Rate' },
    { value: 'space_productivity', label: 'Space Productivity' },
    { value: 'total_manpower_productivity', label: 'Total Manpower Productivity' },
    { value: 'selling_manpower_productivity', label: 'Selling Productivity' },
    { value: 'non_selling_manpower_productivity', label: 'Non-Selling Productivity' },
    { value: 'personal_shopper_manpower_productivity', label: 'Personal Shopper Productivity' }
]


// SELECTOR KEYS
export const SELECTOR_ZONE_STORE_TOGGLE = 'zone_store_toggle';


export const FILTERS_DEFINITIONS = {
    store: {
        key: 'store',
        label: 'Store',
        column: 'store',
        crossFilterSources: [],
        multiSelect: true
    },
    "bu_grp": { // BU Group
        "key": "bu_grp",
        "label": "Business Unit Group",
        "column": "bu_grp",
        "crossFilterSources": [],
        "multiSelect": true
    },
    "bu_desc": { // Business Unit
        "key": "bu_desc",
        "label": "Business Unit",
        "column": "bu_desc",
        "crossFilterSources": [],
        "multiSelect": true
    },
    "dept_desc_standardized": { // Department
        "key": "dept_desc_standardized",
        "label": "Department",
        "column": "dept_desc_standardized",
        "crossFilterSources": [],
        "multiSelect": true
    },
}
