import React from 'react'
import VerticalBarGraph from './verticalBarGraph'
import { Grid, } from '@mui/material'
import { useSMACMembersByGeneration } from '../../hooks'
import LoadingComponent from '../../../../../common/components/LoadingComponent'
import { sortArrayBasedOnGivenArray } from '../../../../../common/utils'

export default function GenerationGraph() {
    const {data, isLoading} = useSMACMembersByGeneration()
    
    const filteredData = data?.filter(row => row.dimension !== 'Overall')

    const sortingOrders = ['Gen Z', 'Millennials', 'Gen X', 'Boomers', 'No Data']

    const sortedData = sortArrayBasedOnGivenArray(filteredData, sortingOrders, 'dimension_value')

    const generationLabel = sortedData?.filter(item => item.dimension_value !== 'Gen Alpha')?.map(item => item.dimension_value)
    const generationData = sortedData?.filter(item => item.dimension_value !== 'Gen Alpha')?.map(item => item.total_members)

    if (isLoading) {
        return <LoadingComponent />
    }

    return <Grid> <VerticalBarGraph title={'Generation'} xAxisLabel={generationLabel} seriesData={generationData}/> </Grid>
}