import moment from "moment";

//function to normalize the view to counts to a range of 30-70
export const normalizeValueForColor = (value, data) => {

    const min = Math.min(...data.map(item => item.view_count));
    const max = Math.max(...data.map(item => item.view_count));

    return 70 - ((value - min) * (70 - 30)) / (max - min);
}

export const showData = (showAll, data) => {
    if(showAll) {
        return data;
    }
    return data.slice(0,10);
}

export const formatDateToObjectFromAndTo = (date) => {
    if (!date) return null

    return {
        from: moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
        to: moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
    }
}