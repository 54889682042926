import React, {useMemo} from 'react';
import {useSelectorValue} from "../../common/providers/selectorProvider";
import {useMaxRecordDateMoment, useOrgEnvFilterFromSourceSelector} from "./values";
import moment from "moment";
import {useTableQuery} from "../../../../common/API/hooks";
import {
    cleanParams,
    defaultDataFilter,
    hasColumn,
    rowsToDisplay,
    useIsMetricVisible,
    useMetricVisibility,
    useTableJoinQuery, useTableV2Query,
    useNestedData
} from "./utils";
import {
    COLUMNS_TO_IGNORE_ON_MATCH,
    CY_LOADING_OUTPUT,
    LY_LOADING_OUTPUT,
    SALES_SOURCE_METRICS,
    TABLE_DIM_BRANCH,
    TABLE_DIM_SUBDEPT,
    TABLE_DIM_CHANNEL,
    TABLE_FCT_TSS
} from "./constants";
import {useMTDOperationsData, useYTDOperationsData} from "./operations";
import {NOT_AVAILABLE, SELECTOR_ZONE_STORE_TOGGLE} from "../constant";
import {convertNumberToLocaleString} from "../../../../common/utils";
import {useDashboardMetaContext} from "../../common/providers/DashboardMetaProvider";
import { HorizontalTooltipDivider } from '../../../../common/components/HorizontalTooltipDivider';


export const useFactTSSJoinQuery = (params, useQueryOptions = {}, hasSubDepartment = false, hasStore = false, hasChannel = false) => {
    const salesTableSource = useDashboardMetaContext().table_source_by_tag?.['sales'] || TABLE_FCT_TSS;
    const tableNames = [salesTableSource];
    const joinPairs = [];

    if (hasSubDepartment) {
        tableNames.push(TABLE_DIM_SUBDEPT);
        joinPairs.push([salesTableSource, TABLE_DIM_SUBDEPT]);
    }
    if (hasStore) {
        tableNames.push(TABLE_DIM_BRANCH);
        joinPairs.push([salesTableSource, TABLE_DIM_BRANCH]);
    }
    if(hasChannel){
        tableNames.push(TABLE_DIM_CHANNEL)
        joinPairs.push([salesTableSource, TABLE_DIM_CHANNEL]);
    }

    return useTableJoinQuery({
        tableNames: tableNames,
        joinPairs: joinPairs,
        params,
        useQueryOptions: {
            ...useQueryOptions,
            // enabled: false
        }
    });
}


// FIXME: Warning! This is a WIP
export const useTableViewQuery = (params, useQueryOptions = {}) => {
    const metaContext = useDashboardMetaContext()
    const partitionDateColumn = useDashboardMetaContext('partition_date_column') || 'transaction_date';
    const hasSalesTableView = useDashboardMetaContext('sales_table_view');

    const salesTableSource = useDashboardMetaContext().table_source_by_tag?.['sales'] || TABLE_FCT_TSS;
    const tableNames = [salesTableSource, TABLE_DIM_SUBDEPT, TABLE_DIM_BRANCH];

    const filters_1 = Object.keys(params).reduce((acc, key) => {
        if (key.startsWith(partitionDateColumn)) {
            acc[key] = params[key];
        }
        return acc;
    }, {})
    const newParams = Object.keys(params).reduce((acc, key) => {
        if (!key.startsWith(partitionDateColumn)) {
            acc[key] = params[key];
        }
        return acc;
    }, {})

    return useTableV2Query({
        tableNames: tableNames,
        params: {
            ...newParams,
            filters_1,
            view: hasSalesTableView
        },
        useQueryOptions: {
            ...useQueryOptions,
            // enabled: false
        }
    });
}


export const useFactTSSQuery = (params, useQueryOptions) => {
    const salesTableSource = useDashboardMetaContext().table_source_by_tag?.['sales'] || TABLE_FCT_TSS;

    return useTableQuery({
        tableName: salesTableSource,
        params: {
            ...(params || {}),
        },
        useQueryOptions: {
            ...useQueryOptions,
            // enabled: false
        }
    });
}


export const useBaseSalesData = (params, useQueryOptions = {}) => {
    // const hasSubDept = hasColumn(params, 'subdept_desc_standardized') ||
    //     hasColumn(params, 'dept_desc_standardized') ||
    //     hasColumn(params, 'bu_desc') ||
    //     hasColumn(params, 'bu_grp');
    // const hasStore = hasColumn(params, 'branch_name') || hasColumn(params, 'region');
    const hasSalesTableView = useDashboardMetaContext('sales_table_view');

    // forced join to store and subdept for all queries; this is in case user have a data restriction to be applied on join
    const hasStore = true;
    const hasSubDept = true;
    const hasChannel = !!params?.channel_desc

    const hasJoin = hasSubDept || hasStore || hasChannel;
    const partitionDateColumn = useDashboardMetaContext('partition_date_column') || 'transaction_date';
    const salesFiltersFromDefinition = useDashboardMetaContext('sales_filters') || {}

    const hasDateFilters = hasColumn(params, partitionDateColumn) || hasColumn(params, 'header_tran_date');

    const cleanedParams = cleanParams(params);

    const orgEnvFilters = useOrgEnvFilterFromSourceSelector();

    const withJoinQuery = useFactTSSJoinQuery(
        {
            ...salesFiltersFromDefinition,
            ...cleanedParams,
            ...orgEnvFilters,
            aggregates: SALES_SOURCE_METRICS,
        },
        {
            ...useQueryOptions,
            enabled: hasDateFilters && hasJoin && (useQueryOptions?.enabled !== false) && !hasSalesTableView
        },
        hasSubDept,
        hasStore,
        hasChannel
    );
    const plainQuery = useFactTSSQuery(
        {
            ...salesFiltersFromDefinition,
            ...cleanedParams,
            ...orgEnvFilters,
            aggregates: SALES_SOURCE_METRICS,
            view: hasSalesTableView
        },
        {
            ...useQueryOptions,
            enabled: hasDateFilters && !hasJoin && (useQueryOptions?.enabled !== false) && !hasSalesTableView
        }
    );

    const viewQuery = useTableViewQuery(
        {
            ...salesFiltersFromDefinition,
            ...cleanedParams,
            ...orgEnvFilters,
            aggregates: SALES_SOURCE_METRICS,
        },
        {
            ...useQueryOptions,
            enabled: false && hasDateFilters && (useQueryOptions?.enabled !== false) && !!hasSalesTableView
        }
    );

    if (!!hasSalesTableView) {
        return viewQuery;
    }

    return hasJoin ? withJoinQuery : plainQuery;
}

export const useMaxFilterDate = (lastYear, ignoreMaxRecordDateMoment = false) => {
    const {data: maxRecordDateMoment} = useMaxRecordDateMoment();
    const year = useSelectorValue('year');
    const month = useSelectorValue('month');

    return useMemo(() => {
        if (!year || !month || (!maxRecordDateMoment && !ignoreMaxRecordDateMoment)) {
            return null;
        }

        if (!ignoreMaxRecordDateMoment && maxRecordDateMoment.year() === year && maxRecordDateMoment.month() + 1 === month) {
            // if the max record date is the last day of the month, we can use it as is
            if (lastYear) {
                return maxRecordDateMoment.subtract(1, 'year').format('YYYY-MM-DD');
            }
            return maxRecordDateMoment.format('YYYY-MM-DD');
        } else {
            if (lastYear) {
                return moment(`${year}-${month}-01`, "YYYY-MM-DD").subtract(1, 'year').endOf('month').format('YYYY-MM-DD');
            }
            return moment(`${year}-${month}-01`, "YYYY-MM-DD").endOf('month').format('YYYY-MM-DD');
        }
    }, [maxRecordDateMoment, year, month, lastYear]);
}

export const useMTDSalesData = (lastYear = false, filters = null, useQueryOptions = {}) => {
    const year = useSelectorValue('year');
    const month = useSelectorValue('month');

    const partitionDateColumn = useDashboardMetaContext('partition_date_column') || 'transaction_date';

    const filterMaxDate = useMaxFilterDate(lastYear);

    const filterMinDate = useMemo(() => {
        if (!year || !month) {
            return null;
        }
        if (lastYear) {
            return moment(`${year}-${month}-01`, "YYYY-MM-DD").subtract(1, 'year').format('YYYY-MM-DD');
        }
        return moment(`${year}-${month}-01`, "YYYY-MM-DD").startOf('month').format('YYYY-MM-DD');
    }, [year, month, lastYear]);

    const newFilters = {
        [`${partitionDateColumn}__gte`]: filterMinDate,
        [`${partitionDateColumn}__lte`]: filterMaxDate,
        ...(filters || {})
    }


    return useBaseSalesData(newFilters, useQueryOptions);
}

export const useYTDSalesData = (lastYear = false, filters = null, useQueryOptions = {}) => {
    // assuming that we can retrieve all metrics from the same API call
    const partitionDateColumn = useDashboardMetaContext('partition_date_column') || 'transaction_date';
    const year = useSelectorValue('year');

    const filterMaxDate = useMaxFilterDate(lastYear);
    const filterMinDate = useMemo(() => {
        if (!year) {
            return null;
        }
        if (lastYear) {
            return moment(`${year}-01-01`, "YYYY-MM-DD").subtract(1, 'year').format('YYYY-MM-DD');
        }
        return moment(`${year}-01-01`, "YYYY-MM-DD").format('YYYY-MM-DD');
    }, [year, lastYear]);

    const newFilters = {
        [`${partitionDateColumn}__gte`]: filterMinDate,
        [`${partitionDateColumn}__lte`]: filterMaxDate,
        ...(filters || {})
    }
    return useBaseSalesData(newFilters, useQueryOptions);
}


const computeRowDisplay = (cyRow, lyRow, isCYLoading, isLYLoading, planRow, isPlanLoading) => {
    const commonMatchedKeys = Object.keys(cyRow || lyRow).filter(key => {
        return !COLUMNS_TO_IGNORE_ON_MATCH.includes(key);
    })

    const commonDisplayData = commonMatchedKeys.reduce((acc, key) => {
        acc[key] = cyRow[key];
        return acc;
    }, {});

    if (isCYLoading) {
        return {
            commonDisplayData,
            ...CY_LOADING_OUTPUT,
            ...LY_LOADING_OUTPUT
        }
    }

    // do compute here
    const cySales = cyRow?.gross_sales_sum;
    const lySales = lyRow?.gross_sales_sum;
    const planSales = planRow?.sales_plan_sum;


    const vsLYSales = lySales ? (cySales / lySales) * 100 : 0;
    const vsPlanSales = planSales ? (cySales / planSales) * 100 : 0;

    const cyTransactions = cyRow?.transaction_key_count_distinct;
    const lyTransactions = lyRow?.transaction_key_count_distinct;
    const planTransactions = planRow?.transaction_plan_sum;

    const vsLYTransactions = lyTransactions ? (cyTransactions / lyTransactions) * 100 : 0;
    const vsPlanTransactions = planTransactions ? (cyTransactions / planTransactions) * 100 : 0;

    const cyATV = cySales / cyTransactions;
    const lyATV = (lySales && lyTransactions) ? lySales / lyTransactions : null;
    const planATV = planSales / planTransactions;

    const vsLYATV = lyATV ? (cyATV / lyATV) * 100 : 0;
    const vsPlanATV = planATV ? (cyATV / planATV) * 100 : 0;

    const cyUnitsSold = cyRow?.sales_qty_sum;
    const lyUnitsSold = lyRow?.sales_qty_sum;

    const cyUnitsSoldPerTransaction = cyUnitsSold / cyTransactions;
    const lyUnitsSoldPerTransaction = lyUnitsSold / lyTransactions;

    const vsLYUnitsSoldPerTransaction = lyUnitsSoldPerTransaction ? (cyUnitsSoldPerTransaction - lyUnitsSoldPerTransaction) : 0;


    // FIXME: check if we want these columns as standard column names
    const cyDisplayData = {
        cySales,
        cyTransactions,
        cyATV,
        cyUnitsSold,
        cyUnitsSoldPerTransaction
    };
    const lyDisplayData = {
        lySales: lySales || 0,
        vsLYSales,
        lyTransactions: lyTransactions || 0,
        vsLYTransactions,
        lyATV: lyATV || 0,
        vsLYATV,
        lyUnitsSoldPerTransaction: lyUnitsSoldPerTransaction || 0,
        vsLYUnitsSoldPerTransaction,
        lyUnitsSold: lyUnitsSold || 0
    }

    if (isLYLoading) {
        return {
            ...commonDisplayData,
            ...cyDisplayData,
            ...LY_LOADING_OUTPUT
        }
    }

    return {
        ...commonDisplayData,
        ...cyDisplayData,
        ...lyDisplayData,
        vsSalesPlan: isPlanLoading ? "Loading..." : vsPlanSales,
        salesPlan: isPlanLoading ? "Loading..." : planSales,
        vsTransactionPlan: isPlanLoading ? "Loading..." : vsPlanTransactions,
        transactionPlan: isPlanLoading ? "Loading..." : planTransactions,
        vsATVPlan: isPlanLoading ? "Loading..." : vsPlanATV,
        atvPlan: isPlanLoading ? "Loading..." : planATV,
    }
}

const computeRowsDisplay = (cyResult, lyResult, planResult) => {
    // find pairs of rows to compare
    if (!cyResult.data) {
        return null;
    }

    const displayData = cyResult.data.map((cyRow) => {
        const matchingLYRow = lyResult.data?.find((lyRow) => {
            return Object.keys(cyRow).every(key => {
                if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) {
                    return true;
                }
                return cyRow[key] === lyRow[key];
            });
        });

        const matchingPlan = planResult?.data?.find((planRow) => {
            return Object.keys(cyRow).every(key => {
                if (COLUMNS_TO_IGNORE_ON_MATCH.includes(key)) {
                    return true;
                }
                return cyRow[key] === planRow[key];
            });
        });

        return computeRowDisplay(
            cyRow, matchingLYRow, cyResult.isLoading, (lyResult.isLoading || cyResult.isLoading),
            matchingPlan, (planResult?.isLoading || cyResult.isLoading)
        );
    })

    return displayData;
}


export const useMTDCompleteDisplayData = (params, useQueryOptions = {}) => {
    const mtdCYSalesResult = useMTDSalesData(false, params, useQueryOptions);
    const hasMTDData = !!mtdCYSalesResult.data;

    const mtdLYSalesResult = useMTDSalesData(true, params, {
        ...useQueryOptions,
        enabled: hasMTDData && (useQueryOptions?.enabled !== false)
    });
    const planResult = useMTDOperationsData(
        false,
        params,
        {
            ...useQueryOptions,
            enabled: hasMTDData && (useQueryOptions?.enabled !== false)
        },
        defaultDataFilter
    )

    const displayData = React.useMemo(() => {
        if (!mtdCYSalesResult.data && !mtdLYSalesResult.data) {
            return null;
        }
        if (mtdCYSalesResult.isLoading && mtdLYSalesResult.isLoading) {
            return null;
        }

        return computeRowsDisplay(mtdCYSalesResult, mtdLYSalesResult, planResult);
    }, [mtdCYSalesResult, mtdLYSalesResult, planResult])

    return {
        data: displayData,
        isLoading: mtdCYSalesResult.isLoading,
        isPartialLoading: mtdCYSalesResult.isLoading || mtdLYSalesResult.isLoading || planResult.isLoading,
    }
}


export const useYTDCompleteDisplayData = (params, useQueryOptions) => {
    const ytdCYSalesResult = useYTDSalesData(false, params, useQueryOptions);
    const hasYTDData = !!ytdCYSalesResult.data;

    const ytdLYSalesResult = useYTDSalesData(true, params, {
        ...useQueryOptions,
        enabled: hasYTDData && (useQueryOptions?.enabled !== false)
    });
    const planResult = useYTDOperationsData(
        false,
        params, {
            ...useQueryOptions,
            enabled: hasYTDData && (useQueryOptions?.enabled !== false)
        },
        defaultDataFilter
    )

    const displayData = React.useMemo(() => {
        if (!ytdCYSalesResult.data && !ytdLYSalesResult.data) {
            return null;
        }
        if (ytdCYSalesResult.isLoading && ytdLYSalesResult.isLoading) {
            return null;
        }

        return computeRowsDisplay(ytdCYSalesResult, ytdLYSalesResult, planResult);
    }, [ytdCYSalesResult, ytdLYSalesResult, planResult])

    return {
        data: displayData,
        isLoading: ytdCYSalesResult.isLoading,
        isPartialLoading: ytdCYSalesResult.isLoading || ytdLYSalesResult.isLoading || planResult.isLoading
    }
}


export const useTopLine = () => {
    const params = useUnifiedSalesParams();

    const {data: mtdDisplayData, isLoading: mtdIsLoading} = useMTDCompleteDisplayData(params);
    const {data: ytdDisplayData, isLoading: ytdIsLoading} = useYTDCompleteDisplayData(params);

    const isSalesMetricVisible = useIsMetricVisible('sales');
    const isTransactionMetricVisible = useIsMetricVisible('transactions');
    const isATVMetricVisible = useIsMetricVisible('atv');
    const isUnitsSoldMetricVisible = useIsMetricVisible('units_sold_per_transaction');

    const isTransactionPlanVisible = useIsMetricVisible('transaction_plan');
    const isATVPlanVisible = useIsMetricVisible('atv_plan');

    const data = [];
    if (isSalesMetricVisible) {
        data.push({
            pk: 'Sales',
            metric: 'sales',
            mtd_actual: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.cySales,
            mtd_actual_tooltip: mtdIsLoading ? [] : [<></>],
            mtd_vs_plan: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsSalesPlan,
            mtd_vs_plan_tooltip: mtdIsLoading ? [] : [
                <><b>Sales Plan:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.salesPlan)}</>,
            ],
            mtd_vs_ly: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsLYSales,
            mtd_vs_ly_tooltip: mtdIsLoading ? [] : [
                <><b>LY Sales:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lySales)}</>,
            ],
            ytd_actual: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.cySales,
            ytd_actual_tooltip: ytdIsLoading ? [] : [<></>],
            ytd_vs_plan: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsSalesPlan,
            ytd_vs_plan_tooltip: ytdIsLoading ? [] : [
                <><b>Sales Plan:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.salesPlan)}</>,
            ],
            ytd_vs_ly: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsLYSales,
            ytd_vs_ly_tooltip: ytdIsLoading ? [] : [
                <><b>LY Sales:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lySales)}</>,
            ],

        })
    }

    if (isTransactionMetricVisible) {
        const transactionRow = {
            pk: 'Transactions',
            metric: 'transactions',
            mtd_actual: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.cyTransactions,
            mtd_actual_tooltip: mtdIsLoading ? [] : [<></>],
            mtd_vs_plan: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsTransactionPlan,
            mtd_vs_plan_tooltip: mtdIsLoading ? [] : [
                <><b>Transaction Plan:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.transactionPlan)}</>,
            ],
            mtd_vs_ly: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsLYTransactions,
            mtd_vs_ly_tooltip: mtdIsLoading ? [] : [
                <><b>LY Transactions:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyTransactions)}</>,
            ],
            ytd_actual: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.cyTransactions,
            ytd_actual_tooltip: ytdIsLoading ? [] : [<></>],
            ytd_vs_plan: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsTransactionPlan,
            ytd_vs_plan_tooltip: ytdIsLoading ? [] : [
                <><b>Transaction Plan:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.transactionPlan)}</>,
            ],
            ytd_vs_ly: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsLYTransactions,
            ytd_vs_ly_tooltip: ytdIsLoading ? [] : [
                <><b>LY Transactions:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyTransactions)}</>,
            ],
        }
        if (!isTransactionPlanVisible) {
            transactionRow.mtd_vs_plan = NOT_AVAILABLE;
            transactionRow.mtd_vs_plan_tooltip = [];
            transactionRow.ytd_vs_plan = NOT_AVAILABLE;
            transactionRow.ytd_vs_plan_tooltip = [];
        }
        data.push(transactionRow)
    }

    if (isATVMetricVisible) {
        const atvRow = {
            pk: 'Avg Txn Value',
            metric: 'atv',
            mtd_actual: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.cyATV,
            mtd_actual_tooltip: mtdIsLoading ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Sales / Transactions</>,
                <><b>Sales:</b> {isSalesMetricVisible ? convertNumberToLocaleString(mtdDisplayData?.[0]?.cySales) : "hidden"}</>,
                <><b>Transactions:</b> {isTransactionMetricVisible ? convertNumberToLocaleString(mtdDisplayData?.[0]?.cyTransactions) : "hidden"}</>,
            ],
            mtd_vs_plan: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsATVPlan,
            mtd_vs_plan_tooltip: mtdIsLoading ? [] : [
                <><b>Avg Txn Value Plan:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.atvPlan)}</>,
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Sales Plan / Transaction Plan</>,
                <><b>Sales Plan:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.salesPlan)}</>,
                <><b>Transaction Plan:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.transactionPlan)}</>,
            ],
            mtd_vs_ly: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsLYATV,
            mtd_vs_ly_tooltip: mtdIsLoading ? [] : [
                <><b>LY Avg Txn Value: </b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyATV)}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Sales:</b> {isSalesMetricVisible ? convertNumberToLocaleString(mtdDisplayData?.[0]?.lySales) : "hidden"}</>,
                <><b>LY Transactions:</b> {isTransactionMetricVisible ? convertNumberToLocaleString(mtdDisplayData?.[0]?.lyTransactions) : "hidden"}</>,
            ],
            ytd_actual: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.cyATV,
            ytd_actual_tooltip: ytdIsLoading ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Sales / Transactions</>,
                <><b>Sales:</b> {isSalesMetricVisible ? convertNumberToLocaleString(ytdDisplayData?.[0]?.cySales) : "hidden"}</>,
                <><b>Transactions:</b> {isTransactionMetricVisible ? convertNumberToLocaleString(ytdDisplayData?.[0]?.cyTransactions) : "hidden"}</>,
            ],
            ytd_vs_plan: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsATVPlan,
            ytd_vs_plan_tooltip: ytdIsLoading ? [] : [
                <><b>Avg Txn Value Plan:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.atvPlan)}</>,
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Sales Plan / Transaction Plan</>,
                <><b>Sales Plan:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.salesPlan)}</>,
                <><b>Transaction Plan:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.transactionPlan)}</>,
            ],
            ytd_vs_ly: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsLYATV,
            ytd_vs_ly_tooltip: ytdIsLoading ? [] : [
                <><b>LY Avg Txn Value: </b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyATV)}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Sales:</b> {isSalesMetricVisible ? convertNumberToLocaleString(ytdDisplayData?.[0]?.lySales) : "hidden"}</>,
                <><b>LY Transactions:</b> {isTransactionMetricVisible ? convertNumberToLocaleString(ytdDisplayData?.[0]?.lyTransactions) : "hidden"}</>,
            ],
        }
        if (!isATVPlanVisible) {
            atvRow.mtd_vs_plan = NOT_AVAILABLE;
            atvRow.mtd_vs_plan_tooltip = [];
            atvRow.ytd_vs_plan = NOT_AVAILABLE;
            atvRow.ytd_vs_plan_tooltip = [];
        }
        data.push(atvRow)
    }

    if (isUnitsSoldMetricVisible) {
        data.push({
            pk: 'Avg Units Sold',
            metric: 'units_sold_per_transaction',
            mtd_actual: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.cyUnitsSoldPerTransaction,
            mtd_actual_tooltip: mtdIsLoading ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Units Sold / Transactions</>,
                <><b>Units Sold:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.cyUnitsSold)}</>,
                <><b>Transactions:</b> {isTransactionMetricVisible ? convertNumberToLocaleString(mtdDisplayData?.[0]?.cyTransactions) : "hidden"}</>,
            ],
            mtd_vs_plan: NOT_AVAILABLE,
            mtd_vs_ly: mtdIsLoading ? "Loading..." : mtdDisplayData?.[0]?.vsLYUnitsSoldPerTransaction,
            mtd_vs_ly_tooltip: mtdIsLoading ? [] : [
                <><b>LY Avg Units Sold:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyUnitsSoldPerTransaction, 2)}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Units Sold:</b> {convertNumberToLocaleString(mtdDisplayData?.[0]?.lyUnitsSold)}</>,
                <><b>LY Transactions:</b> {isTransactionMetricVisible ? convertNumberToLocaleString(mtdDisplayData?.[0]?.lyTransactions) : "hidden"}</>,
            ],
            ytd_actual: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.cyUnitsSoldPerTransaction,
            ytd_actual_tooltip: ytdIsLoading ? [] : [
                <HorizontalTooltipDivider />,
                <><b>Formula:</b> Units Sold / Transactions</>,
                <><b>Units Sold:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.cyUnitsSold)}</>,
                <><b>Transactions:</b> {isTransactionMetricVisible ? convertNumberToLocaleString(ytdDisplayData?.[0]?.cyTransactions) : "hidden"}</>,
            ],
            ytd_vs_plan: NOT_AVAILABLE,
            ytd_vs_ly: ytdIsLoading ? "Loading..." : ytdDisplayData?.[0]?.vsLYUnitsSoldPerTransaction,
            ytd_vs_ly_tooltip: ytdIsLoading ? [] : [
                <><b>LY Avg Units Sold:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyUnitsSoldPerTransaction, 2)}</>,
                <HorizontalTooltipDivider />,
                <><b>LY Units Sold:</b> {convertNumberToLocaleString(ytdDisplayData?.[0]?.lyUnitsSold)}</>,
                <><b>LY Transactions:</b> {isTransactionMetricVisible ? convertNumberToLocaleString(ytdDisplayData?.[0]?.lyTransactions) : "hidden"}</>,
            ],
        })
    }

    return {
        data,
        isLoading: false,  // since we already know the rows, we can set this to false
        isPartialLoading: mtdIsLoading || ytdIsLoading
    }
}

export const useBusinessUnitToDepartmentPerformance = () => {
    const params = useUnifiedSalesParams();
    const autoFlatten = useDashboardMetaContext('bu_to_department_auto_flatten')

    return useNestedData(['bu_grp', 'bu_desc', 'dept_desc_standardized'], 0, params, {},
            useMTDCompleteDisplayData,
            useYTDCompleteDisplayData,
            'sales'
        );
}

export const useZoneToStorePerformance = () => {
    const zoneStoreToggle = useSelectorValue(SELECTOR_ZONE_STORE_TOGGLE);
    const branchNameColumn = useDashboardMetaContext('branch_name_column');

    const params = useUnifiedSalesParams();

    return useNestedData(
        ['region', 'branch_name'],
        zoneStoreToggle === 'zone' ? 0 : 2,
        params,
        {},
        useMTDCompleteDisplayData,
        useYTDCompleteDisplayData,
        'sales'
    );
}


export const useUnifiedSalesParams = () => {
    const selectedBuGroups = useSelectorValue('bu_grp');
    const selectedBUs = useSelectorValue('bu_desc');
    const selectedDepts = useSelectorValue('dept_desc_standardized');
    const store = useSelectorValue('store') || [];

    const branchNameColumn = useDashboardMetaContext('branch_name_column');

    const params = {
        bu_grp: selectedBuGroups,
        bu_desc: selectedBUs,
        dept_desc_standardized: selectedDepts
    };
    if (store.length > 0) {
        params[branchNameColumn] = store;
    }

    return params;
}
