import React from 'react';
import {useSelectorState} from "../../common/providers/selectorProvider";
import {Checkbox, FormControlLabel, FormGroup, Grid} from "@mui/material";


export default function RASourceSelector() {
    const [raSourceStandAlone, setRaSourceStandAlone] = useSelectorState('ra_source_stand_alone', true);
    const [raSourceDSP, setRaSourceDSP] = useSelectorState('ra_source_dsp', true);
    const [raSourceMarkets, setRaSourceMarkets] = useSelectorState('ra_source_markets', true);

    return (
        <FormGroup sx={{width: '100%'}}>
            <Grid container columns={{xs: 3}}>
                <Grid item xs={3}>
                    Data Sources
                </Grid>

                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox checked={raSourceStandAlone === undefined ? true : raSourceStandAlone}/>}
                        label={"Stand Alone"} onChange={() => setRaSourceStandAlone(!raSourceStandAlone)}/>
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox checked={raSourceDSP === undefined ? true : raSourceDSP}/>}
                        label={"SM Store"} onChange={() => setRaSourceDSP(!raSourceDSP)}/>
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox checked={raSourceMarkets === undefined ? true : raSourceMarkets}/>}
                        label={"Others"} onChange={() => setRaSourceMarkets(!raSourceMarkets)}/>
                </Grid>
            </Grid>
        </FormGroup>
    );
}