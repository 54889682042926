import { Box, Grid, Typography } from "@mui/material";
import Section from "../../../common/components/Dashboard/Section";
import Filters from "../common/presentation/filters";
import GroupSelector from "../common/presentation/selectors/groupSelector";
import { useSelectorState, useSelectorValue } from "../common/providers/selectorProvider";
import { VIEW_BY, FILTERS_DEFINITIONS } from "./constant";
import MonthSelector from "../SMACDashboard/Selectors/MonthSelector";
import YearSelector from "./presentation/YearSelector";
import StoreSelector from "./presentation/StoreSelector";
import BUGroupSelector from "./presentation/BUGroupSelector";
import BusinessUnitSelector from "./presentation/BusinessUnitSelector";
import DepartmentSelector from "./presentation/DepartmentSelector";
import { rollupDataBy } from "../../../common/utils";
import { useBUDeptGroupings, useMaxRecordDateMoment, usePeriodValues, useStoreNames } from "./hooks/values";
import { useEffect, useState } from "react";
import {RA_DATA_SOURCES_FILTER, TAB_MANPOWER, TAB_OPERATIONS, TAB_SALES, TAB_SELECTOR} from "./dashboardDefinition";
import { useIsComponentUsable } from "../common/providers/blacklistedComponentsProvider/hooks";
import FilterTextDisplay from "../common/presentation/filters/filterTextDisplay";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PeriodRadioButton from "./presentation/PeriodRadioButton";
import RASourceSelector from "./Selectors/RASourceSelector";


export default function DashboardLevelFilters({ defaultTab }) {
    const [isFiltersOpen, setIsFiltersOpen] = useSelectorState('isFiltersOpen', false);
    const [tabContentBy, setTabContentBy] = useSelectorState('selected_tab', defaultTab);
    const metricSelected = useSelectorValue('metric') || 'sales';
    const [selectedRadio, setSelectedRadio] = useSelectorState('latest_full_month', 'latest_month'); // 'latest' or 'full'

    const isRADataSourceFilterVisible = useIsComponentUsable(RA_DATA_SOURCES_FILTER)

    const { isLoading, isSuccess, asDateRange: dateRangeData, data: rawData } = usePeriodValues();

    // const { data: storeData, isLoading: isLoadingStoreData } = useStoreNames();
    const { data: asOfDateData, isLoading: isLoadingAsOfDateData } = useMaxRecordDateMoment();

    const isTabSelectorUsable = useIsComponentUsable(TAB_SELECTOR);

    const tabVisibility = {
        [TAB_SALES]: useIsComponentUsable(TAB_SALES),
        [TAB_OPERATIONS]: useIsComponentUsable(TAB_OPERATIONS),
        [TAB_MANPOWER]: useIsComponentUsable(TAB_MANPOWER),
    }

    const visibleTabs = VIEW_BY.filter(tab => tabVisibility[tab.id])


    useEffect(() => {
        if (!visibleTabs || visibleTabs.length === 0) {
            setTabContentBy(null);
        }
        if (tabContentBy == null || visibleTabs.findIndex(tab => tab.value === tabContentBy) === -1) {
            setTabContentBy(visibleTabs[0]?.value);
        }
    }, [tabContentBy, visibleTabs]);

    const selectedStores = useSelectorValue('store');
    const selectedBUGrps = useSelectorValue('bu_grp');
    const selectedBUs = useSelectorValue('bu_desc');
    const selectedDepts = useSelectorValue('dept_desc_standardized');

    const filters = {
        store: selectedStores,
        bu_grp: selectedBUGrps,
        bu_desc: selectedBUs,
        dept_desc_standardized: selectedDepts
    };

    const noBUFilterMetrics = ['catchment_rate', 'conversion_rate'];
    const hasNoBUFilterMetric = noBUFilterMetrics.includes(metricSelected);

    const { bu_grp, bu_desc, dept_desc_standardized, ...rest } = filters;

    const displayedFilters = hasNoBUFilterMetric ? rest : filters;

    const filterIdsUsed = displayedFilters ? Object.keys(displayedFilters).filter(key => displayedFilters[key] !== null && displayedFilters[key]?.length > 0) : []
    const isFiltersUsed = filterIdsUsed && filterIdsUsed.length > 0
    const isDisabledPeriodFilters = selectedRadio === 'latest_month'

    return <>
        <Filters isOpen={isFiltersOpen} setIsOpen={setIsFiltersOpen} >
            <>
                {
                    isRADataSourceFilterVisible && <Grid item xs={12} sm={12} display={'flex'} alignItems={'center'} justifyContent={"flex-start"} gap={1}>
                        <RASourceSelector/>
                    </Grid>
                }
                <Grid item xs={12} sm={12} display={'flex'} alignItems={'center'} justifyContent={"flex-start"} gap={1}>
                    <PeriodRadioButton
                        optionKey="latest_month"
                        label={`Show latest Data (${asOfDateData?.format('MMMM DD, YYYY')})`}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Box component='section' sx={{ border: 1, borderRadius: 1, borderColor: 'grey.500' }}>
                        <Grid item xs={12} sm={12} display={'flex'} alignItems={'center'} justifyContent={"flex-start"} gap={1}>
                            <PeriodRadioButton
                                optionKey="full_month"
                                label="Show full month data for:"
                            />
                        </Grid>
                        <Grid item container xs={12} sm={12} columnSpacing={1} rowSpacing={1} marginY={.3} paddingX={.5}>
                            <Grid item xs={12} sm>
                                <MonthSelector
                                    isLoadingDateRange={isLoading}
                                    dateRange={dateRangeData}
                                    isDisabled={isDisabledPeriodFilters}
                                    hasOptionLatestOrFull={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm>
                                <YearSelector
                                    isLoadingDateRange={isLoading}
                                    dateRange={dateRangeData}
                                    isDisabled={isDisabledPeriodFilters}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <StoreSelector />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <BUGroupSelector />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <BusinessUnitSelector />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <DepartmentSelector />
                </Grid>
            </>
        </Filters>

        <FilterTextDisplay
            filters={displayedFilters} filterDefinitions={FILTERS_DEFINITIONS} filterIdsUsed={filterIdsUsed}
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
        />

        {isTabSelectorUsable &&
            <Section
                title={<GroupSelector
                    options={visibleTabs}
                    onSelectGroupChange={(value) => setTabContentBy(value)}
                    selected={tabContentBy}
                />}
                isCollapsible={false}
                customStyle={{ backgroundColor: "#f6f6f8", borderRadius: isFiltersUsed ? ".5rem .5rem 0 0" : ".5rem", paddingBottom: 1 }}
                collapseCustomStyle={{ marginBottom: 0 }} // so that when sticky, it doesn't have the extra grey space below
            >
                <Grid
                    item id={'viewby-extra-info'} sm={12} xs={12} display={'flex'} alignItems={'center'} justifyContent={"flex-start"}
                    sx={{
                        backgroundColor: "#caddeb",
                        borderBottomRightRadius: ".25rem",
                        borderBottomLeftRadius: ".25rem",
                    }}>
                    {/*
                    This is used as a portal target for any items added below the view by section.
                    Usually this is used to display filter texts. Filters are scoped to the underlying/child dashboard.
                    */}
                </Grid>
            </Section>
        }
    </>
}