
export const dashboardName = 'SM Markets OPEX'
export const DASHBOARD_PATH = 'markets-dashboard/opex'


export const dashboardDefinition = {
    name: dashboardName,
    entry_points: [
        'markets-dashboard/opex',
    ],
    owners: [
        "raphaelchristopher.a.sepnio@smretail.com"
    ],
    path: DASHBOARD_PATH
}