import SMLogo from "../img/sm_logo.png";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import {GroupsRounded} from "@mui/icons-material";
import BUGroupChainFashion from "../img/dashboardIcons/bu-group-chain-fashion.svg";
import BUGroupChainHome from "../img/dashboardIcons/bu-group-chain-home.svg";
import BUGroupChainBeauty from "../img/dashboardIcons/bu-group-chain-beauty.svg";
import BUGroupChainKids from "../img/dashboardIcons/bu-group-chain-kids.svg";
import SMACPerformanceIcon from "../img/dashboardIcons/smac-performance-icon.svg";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import FashionOverview from "../img/dashboardIcons/fashionBUIcons/fashion-overview.svg";
import React from "react";
import FashionShoes from "../img/dashboardIcons/fashionBUIcons/fashion-shoes.svg";
import FashionLadies from "../img/dashboardIcons/fashionBUIcons/fashion-ladies-fashion.svg";
import FashionMens from "../img/dashboardIcons/fashionBUIcons/fashion-mens-fashion.svg";
import FashionChildrens from "../img/dashboardIcons/fashionBUIcons/fashion-childrens-fashion.svg";
import FashionAccessoriesManagement from "../img/dashboardIcons/fashionBUIcons/fashion-accessories-management.svg";
import FashionSmartBuy from "../img/dashboardIcons/fashionBUIcons/fashion-smart-buy.svg";
import PersonalShopperIcon from "../img/personal-shopper.svg";
import TheSmStoreAnalytics from "../private/customDashboards/StoresAnalytics/Instances/TheSmStoreAnalytics";
import SuperMarketAnalytics from "../private/customDashboards/StoresAnalytics/Instances/SuperMarketAnalytics";
import HyperMarketAnalytics from "../private/customDashboards/StoresAnalytics/Instances/HyperMarketAnalytics";
import SaveMoreAnalytics from "../private/customDashboards/StoresAnalytics/Instances/SaveMoreAnalytics";
import Sales from "../private/customDashboards/Sales";
import Opex from "../private/customDashboards/Opex";
import Inventory from "../private/customDashboards/Inventory";
import SMStorePerformance from "../private/customDashboards/SMStorePerformance";
import BusinessUnitView from "../private/customDashboards/common/presentation/businessUnitView";
import TmpAceSales from "../private/customDashboards/TmpAceSales";
import Merchandise from "../private/customDashboards/merchandise";
import Customers from "../private/customDashboards/Customers";
import SMStoreBUGroupPerformance from "../private/customDashboards/SMStorePerformance/byBUGroup";
import MarketBasket from "../private/customDashboards/MarketBasket";
import PersonalShopper from "../private/customDashboards/SMStorePerformance/PersonalShopper";
import SMACPerformance from "../private/customDashboards/SMACPerformance";
import ZeroSales from "../private/customDashboards/ZeroSales";
import ZeroSalesIcon from "../img/dashboardIcons/SMMarkets/zero-sales-icon.svg";
import OpexIcon from "../img/dashboardIcons/SMMarkets/opex-icon.svg";
import SMACDashboard from "../private/customDashboards/SMACDashboard";
import SMACDashboardIcon from "../img/dashboardIcons/new-smac-dashboard-icon.svg";
import DashboardUsageInstance from "../private/customDashboards/SMACDashboard/DashboardUsageInstance";
import DashboardAcquisitionInstance from "../private/customDashboards/SMACDashboard/DashboardAcquisitionInstance";
import DashboardRegistrationInstance from "../private/customDashboards/SMACDashboard/DashboardRegistrationInstance";
import DashboardAcquisitionRegistrationInstance
    from "../private/customDashboards/SMACDashboard/DashboardAcquisitionRegistrationInstance";
import SalesOperationsDashboardIcon from "../img/dashboardIcons/sales-operations-icon.svg";
import SMStoreSalesAndOperations from "../private/customDashboards/SalesOperations/SMStoreSalesAndOperations";
import {retailAffiliateSalesSection} from "./customDashboardSets/retailAffiliateSales";

export const FASHION_BU_ICONS = {
    'Shoes': FashionShoes,
    "Ladies' Fashion": FashionLadies,
    "Men's Fashion": FashionMens,
    "Children's Fashion": FashionChildrens,
    "Accessories Management": FashionAccessoriesManagement,
    'Smart Buy': FashionSmartBuy
}

export const DASHBOARD_ICON_DEFAULT_STYLE = {color: '#1976DB', fontSize: 45, alignSelf: 'center'}

export const DASHBOARD_GROUPS = [
    {
        title: 'SM Store',
        contents: [
            {
                name: 'Operations',
                path: '/sbu/sales',
                icon: <AnalyticsOutlinedIcon sx={DASHBOARD_ICON_DEFAULT_STYLE}/>,
                component: <SMStorePerformance/>
            },
            {
                name: 'Merchandise',
                path: '/sbu/merchandise',
                icon: <LocalMallIcon sx={{...DASHBOARD_ICON_DEFAULT_STYLE, color: 'grey'}}/>,
                isComingSoon: true,
                component: <Merchandise/>
            },
            {
                name: 'Customers',
                path: '/sbu/customers',
                icon: <GroupsRounded sx={DASHBOARD_ICON_DEFAULT_STYLE}/>,
                component: <Customers/>
            },
            {
                name: 'Personal Shopper',
                path: '/sbu/personal-shopper',
                image: PersonalShopperIcon,
                component: <PersonalShopper/>
            },
            {
                name: 'Sales and Operations',
                path: '/sm-store/sales-and-operations',
                image: SalesOperationsDashboardIcon,
                isNewDashboard: true,
                component: <SMStoreSalesAndOperations/>
            },
        ]
    },
    {
        ...retailAffiliateSalesSection
    },
    {
        title: 'Fashion',
        contents: [
            {
                name: 'Overview',
                path: '/mbu/fashion/sales?viewby=business_unit',
                pathForRoute: 'mbu/fashion/sales',
                image: FashionOverview,
                component: <SMStoreBUGroupPerformance title={"SM Store Fashion Performance"} higherMBU="Fashion" path='/mbu/fashion/sales?viewby=business_unit'/>
            },
            ...(Object.entries(FASHION_BU_ICONS).map(([bu, IconComponent]) => {
                return {
                    name: bu,
                    pathForRoute: 'mbu/bu-view/:business_unit',
                    path: `/mbu/bu-view/${encodeURIComponent(bu)}`,
                    image: IconComponent,
                    shrinkIcon: '2.5em',
                    component: <BusinessUnitView title={bu} path={`/mbu/bu-view/${encodeURIComponent(bu)}`}/>
                }
            }))
        ]
    },
    {
        title: 'SM Markets',
        contents: [
            {
                name: 'Sales',
                path: '/markets-dashboard/sales',
                icon: <AnalyticsOutlinedIcon sx={DASHBOARD_ICON_DEFAULT_STYLE}/>,
                component: <Sales/>
            },
            {
                name: 'Zero Sales',
                path: '/markets-dashboard/zero-sales',
                image: ZeroSalesIcon,
                component: <ZeroSales/>
            },
            {
                name: 'OPEX',
                path: '/markets-dashboard/opex',
                image: OpexIcon,
                component: <Opex/>
            },
            {
                name: 'Inventory',
                path: '/markets-dashboard/inventory',
                icon: <InventoryOutlinedIcon sx={DASHBOARD_ICON_DEFAULT_STYLE}/>,
                component: <Inventory/>
            },
            // {name: 'Merchandise', icon: <LocalMallIcon sx={{color: 'grey', fontSize: 45, alignSelf: 'center'}} />, isComingSoon: true},
            // {name: 'Top/Bottom Store', path: '/markets-dashboard/top-bottom-store', icon: <StoreMallDirectoryRoundedIcon sx={iconSx} />, component:  <TopBottomStore />},
            // {name: '% to Sales ', path: '/markets-dashboard/percentage-sales', icon: <PercentRoundedIcon sx={iconSx} />, component:  <PercentageSales />}
        ]
    },
    {
        title: 'Business Unit Group',
        contents: [
            {
                name: 'Fashion',
                path: '/mbu/fashion/sales',
                image: BUGroupChainFashion,
                disabled: true,
                component: <SMStoreBUGroupPerformance title={"SM Store Fashion Performance"} higherMBU="Fashion" path='/mbu/fashion/sales'/>
            },  // disabled since we have aa full group dedicatd to fashion
            {
                name: 'Home',
                path: '/mbu/home/sales',
                image: BUGroupChainHome,
                component: <SMStoreBUGroupPerformance title={"SM Store Home Performance"} higherMBU="Home" path='/mbu/home/sales'/>
            },
            {
                name: 'Beauty',
                path: '/mbu/beauty/sales',
                image: BUGroupChainBeauty,
                component: <SMStoreBUGroupPerformance title={"SM Store Beauty Performance"} higherMBU="Beauty" path='/mbu/beauty/sales'/>
            },
            {
                name: 'Kids',
                path: '/mbu/kids/sales',
                image: BUGroupChainKids,
                component: <SMStoreBUGroupPerformance title={"SM Store Kids Performance"} higherMBU="Kids" path='/mbu/kids/sales'/>
            }
        ]
    },    
    {
        title: 'DAC',
        contents: [
            {
                name: 'SMAC Performance',
                path: '/retail-dashboard/smac-performance',
                image: SMACPerformanceIcon,
                shrinkIcon: '2.5em',
                component: <SMACPerformance/>
            },
            {
                name: 'SMAC Dashboard',
                path: '/retail-dashboard/smac-dashboard',
                image: SMACDashboardIcon,
                component: <SMACDashboard/>
            },
            {
                name: 'SMAC Dashboard',
                path: '/dac/smac-dashboard-ar',
                image: SMACDashboardIcon,
                component: <DashboardAcquisitionRegistrationInstance/>
            },
            {
                name: 'SMAC Acquisition',
                path: '/dac/smac-acquisition',
                image: SMACDashboardIcon,
                component: <DashboardAcquisitionInstance/>
            },
            {
                name: 'SMAC Registration',
                path: '/dac/smac-registration',
                image: SMACDashboardIcon,
                component: <DashboardRegistrationInstance/>
            },
            {
                name: 'SMAC Usage',
                path: '/dac/smac-usage',
                image: SMACDashboardIcon,
                component: <DashboardUsageInstance/>
            }
        ]
    },
    {
        title: 'Retail Affiliates',
        contents: [
            {
                name: 'Ace Hardware',
                path: '/ace/sales',
                icon: <AnalyticsOutlinedIcon sx={DASHBOARD_ICON_DEFAULT_STYLE}/>,
                component: <TmpAceSales/>
            }
        ]
    },
    {
        title: 'Market Basket',
        contents: [
            {
                name: 'SM Store',
                image: SMLogo,
                path: '/market-basket-analysis/tss',
                component: <MarketBasket title="SM Store" companyKey="1"/>
            },
            {
                name: 'HYPERMARKET',
                image: SMLogo,
                path: '/market-basket-analysis/hypermarket',
                component: <MarketBasket title="Hypermarket" companyKey="3"/>
            },
            {
                name: 'SAVEMORE',
                image: SMLogo,
                path: '/market-basket-analysis/savemore',
                component: <MarketBasket title="Supermarket" companyKey="4"/>
            },
            {
                name: 'SUPERMARKET',
                image: SMLogo,
                path: '/market-basket-analysis/supermarket',
                component: <MarketBasket title="Supermarket" companyKey="2"/>
            }
        ]
    },
    {
        title: 'Analytics',
        contents: [
            {name: 'SM STORE', image: SMLogo, path: '/location-analytics/tss', component: <TheSmStoreAnalytics/>},
            {
                name: 'HYPERMARKET',
                image: SMLogo,
                path: '/location-analytics/hypermarket',
                component: <HyperMarketAnalytics/>
            },
            {name: 'SAVEMORE', image: SMLogo, path: '/location-analytics/savemore', component: <SaveMoreAnalytics/>},
            {
                name: 'SUPERMARKET',
                image: SMLogo,
                path: '/location-analytics/supermarket',
                component: <SuperMarketAnalytics/>
            }
        ]
    }
]