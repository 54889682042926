import { useContext, useState, useMemo, useEffect } from "react";
import GroupSelector from "../common/presentation/selectors/groupSelector";
import { Grid, Box, Collapse } from "@mui/material";
import CollapsibleComponent from "../../../common/components/CollapsibleComponent";
import TabContent from "./TabContent";
import ToplinePerformanceCharts from "./ToplinePerformanceCharts";
import {VIEW_BY, VIEW_BY_ACQUISITION, VIEW_BY_REGISTRATION, VIEW_BY_USAGE} from "./constants";
import BranchSelector from "./Selectors/BranchSelector";
import {useSelectorState} from "../common/providers/selectorProvider";
import { WindowSizeContext } from "../../../common/context/WindowSizeContext";
import { useBlacklistedDashboardComponents } from "../common/providers/blacklistedComponentsProvider/hooks";
import RegistrationSelector from "./Selectors/RegistrationSelector";
import OrganizationSelectorV2 from "./Selectors/OrganizationSelectorV2";

const TAB_ORDER = [VIEW_BY_ACQUISITION, VIEW_BY_REGISTRATION, VIEW_BY_USAGE];

export default function TabSection({fixedTabValue, tabBlackList }) {
    const { isOnMobile } = useContext(WindowSizeContext);
    const [isExpanded, setIsExpanded] = useState(true);

    const blacklistedComponents = useBlacklistedDashboardComponents();

    const defaultTab = useMemo(() => {
        // Check if fixedTabValue is provided and not blacklisted; otherwise, find the next available option
        if (fixedTabValue && !blacklistedComponents.includes(fixedTabValue)) {
            return fixedTabValue;
        }

        const nextAvailableOption = TAB_ORDER.find(tab => !blacklistedComponents.includes(tab));
        if (nextAvailableOption) {
            return nextAvailableOption;
        } else {
            return null
        }

    }, [fixedTabValue, blacklistedComponents]);

    const [tabContentBy, setTabContentBy] = useSelectorState('mainTab', defaultTab );

    useEffect(() => {
        if (tabContentBy == null) { 
            setTabContentBy(defaultTab);
        }
    }, [ blacklistedComponents ]);

    const isUsageTab = tabContentBy === VIEW_BY_USAGE;
    const isMobileAndUsageTab = isOnMobile && isUsageTab;

    const availableTabs = useMemo(() => {
        let restrictions;
        
        if (tabBlackList.length > 0) {
            restrictions = tabBlackList;
        } else if (blacklistedComponents) {
            restrictions = blacklistedComponents;
        } else {
            restrictions = [];
        }
    
        return VIEW_BY.filter(tab => !restrictions.includes(tab.value));
    }, [tabBlackList, blacklistedComponents]);


    return <Grid item xs={12} sx={{ backgroundColor: '#e0e0e0' }}>
        {
            !fixedTabValue && <Box flexGrow={1} xs={12} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem", position: {sm: 'sticky', xs: 'static'}, top: '10rem', zIndex: 5 }}>
                <Grid container alignItems="center" columnSpacing={1} sx={{ paddingY: {xs: 1, md: 0.5}, paddingLeft: {xs: 2, md: 3} }}>

                    {/* Group Selector */}
                    <Grid item xs={11} sm={ isUsageTab ? 5 : 11 } sx={{ paddingBottom: {xs: isUsageTab && 2, md: 0} }}>
                        <GroupSelector
                            options={availableTabs}
                            onSelectGroupChange={(value) => setTabContentBy(value)}
                            selected={tabContentBy}
                        />
                    </Grid>

                    {/* Collapsible Component for Mobile Usage Tab */
                        isMobileAndUsageTab && <>
                            <Grid item xs={1} sm sx={{ paddingBottom: 2, paddingX: 0.5 }}>
                                <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded} />
                            </Grid>
                        </>
                    }

                    {/* Organization and Branch Selectors for Usage Tab */
                        isUsageTab && <>
                            <Grid item xs={12} sm={2} sx={{ paddingX: {xs: 2, md: 0}, paddingBottom: {xs: 1, md: 0} }}>
                                <OrganizationSelectorV2 /> 
                            </Grid>

                            <Grid item xs={12} sm={2} sx={{ paddingX: {xs: 2, md: 0} , paddingBottom: {xs: 1, md: 0} }}>
                                <BranchSelector />
                            </Grid>

                            <Grid item xs={12} sm={2} sx={{ paddingX: {xs: 2, md: 0}}}>
                                <RegistrationSelector />
                            </Grid>
                        </>
                    }

                    {/* Collapsible Component when not on Mobile AND Usage Tab */
                        !(isMobileAndUsageTab) && <>
                            <Grid item xs={1} sm justifyContent="flex-end" sx={{ paddingX: 0.5 }}>
                                <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        }
        
        <Collapse in={isExpanded}>
            <Grid container>                
                <ToplinePerformanceCharts selectedTab={tabContentBy} blacklistedComponents={blacklistedComponents}/>
            </Grid>
        </Collapse>

        <TabContent selectedTab={tabContentBy} blacklistedComponents={blacklistedComponents}/>
    </Grid>
    
}