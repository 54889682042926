import {GlobalStyles, Grid, Typography} from "@mui/material";
import SelectorProvider from "../common/providers/selectorProvider";
import Back from "../../../common/components/BackNavigateComponent";
import {PERIOD_YEARLY} from "../../../Constants/general";
import {WindowSizeContext} from "../../../common/context/WindowSizeContext";
import {useContext} from "react";
import TabSection from "./TabSection";
import ExtraInfo from "../../../common/components/ExtraInfo";
import AsOfDate from "./AsOfDate";
import DashboardMetaProvider from "../common/providers/DashboardMetaProvider";
import DashboardLevelFilters from "./DashboardLevelFilters";
import {SELECTOR_ZONE_STORE_TOGGLE} from "./constant";
import {dashboardDefinition} from "./dashboardDefinition";
import {registerDashboard} from "../../../common/utils";
import useScrollTrigger from "@mui/material/useScrollTrigger";


export default function SalesOperationsDashboard({ title = "Sales & Operations", defaultTab = 'sales', dashboardDefinition }) {
    const windowSize = useContext(WindowSizeContext)

    const trigger = useScrollTrigger();

    //backgroundColor:trigger ? '#f6f6f8' : 'transaparent',position:'sticky',top: { sm: '5rem', xs: !trigger ? '5rem' : 3}, zIndex: 200,

    return <DashboardMetaProvider dashboardDefinition={dashboardDefinition}>
        <SelectorProvider defaults={{
            'period': PERIOD_YEARLY,
            [SELECTOR_ZONE_STORE_TOGGLE]: 'zone',
            ...(dashboardDefinition?.selectorDefaults || {})
        }}>
            <GlobalStyles
                styles={{
                    body: { backgroundColor: '#e0e0e0' },
                }} />

            <Grid container>
                <Grid container sx={{ display: !windowSize.isOnMobile && "flex", justifyContent: "space-between", alignItems: "center", mt: { sm: 0, xs: 1}, position: 'sticky', top: { sm: '5rem', xs: !trigger ? '4.5rem' : 0}, zIndex: 200, backgroundColor: '#e0e0e0', transition: "top 0.4s ease-in-out", }}>
                    <Grid item xs={12} sx={{ display: !windowSize.isOnMobile && "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                        <Grid item xs display="flex" sx={{ alignItems: "center" }}>
                            <Back />
                            <Typography variant="h5" ml={3}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <ExtraInfo AsOfDate={<AsOfDate textOnly={true} />} />
                        </Grid>
                    </Grid>
                    <DashboardLevelFilters defaultTab={defaultTab} />
                </Grid>
                <TabSection />
            </Grid>
        </SelectorProvider>
    </DashboardMetaProvider>
}