import React from "react";
import Typography from "@mui/material/Typography";
import {createPortal} from "react-dom";
import {Filter1, FilterList} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";


export default function FilterTextDisplay({filters, filterDefinitions, filterIdsUsed, maxDisplayPerFilter=3, ...props}) {
    const filterSummaryTextDisplayComponents = React.useMemo(() => {
        if (filterIdsUsed.length === 0) {
            return []
        }

        return filterIdsUsed.map(filterId => {
            const label = filterDefinitions[filterId].label;
            const key = filterDefinitions[filterId].key;

            const filterValueOrValues = filters[key];
            if (!filterValueOrValues) {
                return null;
            }
            const labelComponent = <Typography display={"inline-flex"} mr={0.5} variant={"subtitle2"}>{label}:</Typography>
            // ensure filterValueOrValues is an array
            const filterValues = Array.isArray(filterValueOrValues) ? filterValueOrValues : [filterValueOrValues];
            if (filterValues.length === 0) {
                return null;
            }

            const displayValues = filterValues.map(value => {
                const definition = filterDefinitions[filterId];
                return definition.labelFormatter ? definition.labelFormatter(value) : value
            })

            if (displayValues?.length > maxDisplayPerFilter) {
                return <>
                    {labelComponent}
                    <Tooltip title={displayValues?.join(", ")}> {/* tooltip to show all values */}
                        <Typography component="span">
                            {displayValues?.slice(0, maxDisplayPerFilter).map((value, index) => (
                                <Typography
                                    display={"inline-flex"}
                                    key={value}
                                    component="span"
                                    sx={{ marginRight: index < maxDisplayPerFilter - 1 ? "0.25rem" : 0 }}
                                >
                                    {value}{index < maxDisplayPerFilter - 1 ? ", " : ""}
                                </Typography>
                            ))}
                            , ...
                        </Typography>
                    </Tooltip>
                </>
            }
            return <>
                {labelComponent}
                {displayValues?.map((value, index) => (
                    <Typography display={"inline-flex"} key={value} component="span" sx={{ marginRight: index < displayValues?.length - 1 ? "0.25rem" : 0 }}>
                        {value}{index < displayValues?.length - 1 ? ", " : ""}
                    </Typography>
                ))}
            </>

        }).filter(o => !!o).reduce((acc, curr, index) => {
            if (index === 0) {
                return [curr]
            }
            return [...acc, <Typography display={"inline-flex"} mx={1}>|</Typography>, curr]
        }, []);
    }, [filters, filterIdsUsed])

    if (!filterSummaryTextDisplayComponents || filterSummaryTextDisplayComponents.length === 0) {
        return null;
    }

    if (document.getElementById('viewby-extra-info')) {
        // if we find a portal target, render the filter summary text in the portal
        return (
            createPortal(
                <Typography paddingX={2.5} paddingY={0.5} {...props} >
                    <Typography  display={"inline-flex"} variant={"subtitle2"} sx={{color: '#555'}}>FILTERS APPLIED</Typography>{" "}
                    {filterSummaryTextDisplayComponents}
                    <EditIcon sx={{cursor: 'pointer', marginLeft: "0.5rem"}} color={'primary'}/>
                </Typography>,
                document.getElementById('viewby-extra-info'))
        )
    }

    return (<Typography
            sx={{
                position: 'fixed',
                bottom: "1rem",
                right: "2rem",
                textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white',
                zIndex: 1000,
                "&:hover": {
                    backgroundColor: "white",
                }
            }}
            px={2}
            variant="caption"
            {...props}
        >
            {filterSummaryTextDisplayComponents}
        </Typography>)

}