
import BUToDepartmentPerformance from "./index";
import {useBusinessUnitToDepartmentPerformance} from "../hooks/operations";


export default function OperationsBUToDepartmentPerformance({selectedTab, information}) {
    const { data: sortedData, isLoading } = useBusinessUnitToDepartmentPerformance();

    return <BUToDepartmentPerformance data={sortedData} isLoading={isLoading} selectedTab={selectedTab} information={information}/>
}
