import BUToDepartmentPerformance from "./index";
import {useBusinessUnitToDepartmentPerformance} from "../hooks/sales";
import { useSelectorValue, useSelectorState } from "../../common/providers/selectorProvider";
import { useState, useEffect } from 'react';
import { isRowSelected } from "@tanstack/react-table";


export default function SalesBUToDepartmentPerformance({selectedTab, information}) {
    const { data: sortedData, isLoading } = useBusinessUnitToDepartmentPerformance();
    
    return (
        <BUToDepartmentPerformance
            data={sortedData}
            isLoading={isLoading}
            selectedTab={selectedTab}
            information={information}
        />
    )
}
