import React from 'react'
import ContextBasedDropdownSelector from '../../../customDashboards/common/presentation/selectors/ContextBasedDropdownSelector';

export default function ModuleSelector() {
    const dashboardRegistry = window.dashboardRegistry;
    const dashboardRegistryKeys = Object.keys(dashboardRegistry);
    
    const options = [
        { value: "All", label: "All" },
        ...(dashboardRegistryKeys?.map(key => ({
            value: dashboardRegistry[key].path,
            label: dashboardRegistry[key].name
        })) || [])
    ];
    
    return <ContextBasedDropdownSelector
        autoSelectFirstOption={false}
        options={options}
        disableClearable={false}
        label={"Module List"}
        contextKey={"module"}
        defaultValue={"All"}
    />
}