import { Grid } from "@mui/material";
import { useSelectorState } from "../common/providers/selectorProvider";
import TabContent from "./TabContent";
import React from "react";

export default function TabSection({ defaultTab }) {
    const [tabContentBy, setTabContentBy] = useSelectorState('selected_tab', defaultTab);

    return <Grid xs={12} marginTop={2}> 
        <TabContent selectedTab={tabContentBy} /> 
    </Grid>
}