import React from 'react';
import { useSelectorContext, useSelectorValue } from '../../common/providers/selectorProvider';
import FilterYear from '../../../../common/components/FilterYear';
import moment from 'moment';


export default function YearSelector({isLoadingDateRange, dateRange, isDisabled}) {
    const yearFromContext = useSelectorValue('year');
    const year = yearFromContext
    const {setValue: setSelectorValue} = useSelectorContext()
    const setYear = (value) => setSelectorValue('year', value)
    const selectedRadio = useSelectorValue('latest_full_month') || 'latest_month'
    const isLatest = selectedRadio === 'latest_month'

    //defaults
    React.useEffect(() => {
        if (!yearFromContext || isLatest) {
            setYear(moment(dateRange?.to).year())
        }
    }, [yearFromContext, dateRange, selectedRadio, isLatest]);

    return (
        <FilterYear
            onDropdownChange={(value) => setYear(value)}
            filters={{year}}
            dateRangeData={dateRange}
            isLoading={isLoadingDateRange}
            isDisabled={isDisabled}
        />
    )
}