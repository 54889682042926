import React, { useEffect, useState } from "react";
import { WindowSizeContext } from "../../../context/WindowSizeContext";
import { Grid, Typography } from "@mui/material";
import CollapsibleComponent from "../../CollapsibleComponent";
import InformationToolTip from "../../InformationToolTip";
import { useHasScrolledDownDetector } from "../../../utils/hooks";


export default function ResponsiveSectionHeader({ title, selectorsOrFilters, isExpanded, setIsExpanded, isCollapsible = true, information, customGridItemStyle }) {
    const windowSize = React.useContext(WindowSizeContext)
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const scrolling = useHasScrolledDownDetector()

    useEffect(() => {
        if (scrolling && tooltipOpen) {
            setTooltipOpen(false);
        }
    }, [scrolling, tooltipOpen]);
    const defaultGridItemStyle = { xs: 12, sm: 6, md: 4 }

    const displaySelectorOrFilters = React.useMemo(() => {
        if (!selectorsOrFilters) return null;
        if (Array.isArray(selectorsOrFilters)) {
            return (
                <Grid item flexGrow={4}>
                    <Grid container justifyContent={"flex-end"}>
                        {
                            selectorsOrFilters.map((selector, index) => {
                                const gridItemStyle = { ...defaultGridItemStyle, ...customGridItemStyle };
                                return <Grid item key={index} {...gridItemStyle} >
                                    {selector}
                                </Grid>
                            })
                        }
                    </Grid>
                </Grid >
            )
        }
        return selectorsOrFilters
    }, [selectorsOrFilters])

    return (
        <Grid container columns={12} alignItems="center" p={.5} spacing={1}>
            {
                windowSize?.isOnMobile ? <>
                    <Grid item xs={12} display="flex" justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="h6" ml={1} style={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}>
                            {title} {information && <InformationToolTip onChange={setTooltipOpen} open={tooltipOpen} information={information} />}
                        </Typography>
                        {isCollapsible && <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded} />}
                    </Grid>
                    {displaySelectorOrFilters}
                </> : <>
                    <Grid item flexGrow={1}>
                        <Typography variant='h6' ml={2} style={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}>
                            {title} {information && <InformationToolTip onChange={setTooltipOpen} open={tooltipOpen} information={information} />}
                        </Typography>
                    </Grid>
                    {displaySelectorOrFilters}

                    {
                        isCollapsible &&
                        <Grid item justifyContent="flex-end">
                            <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded} />
                        </Grid>
                    }
                </>
            }
        </Grid>
    )
}