import React from "react";
import AceHardwareIcon from "../../img/dashboardIcons/RetailAffiliates/ace_icon.svg";
import AceHardware from "../../private/customDashboards/SalesOperations/RetailAffiliateSalesDashboards/AceHardware";
import SportsCentralIcon from "../../img/dashboardIcons/RetailAffiliates/sports_central_icon.svg";
import SportsCentral from "../../private/customDashboards/SalesOperations/RetailAffiliateSalesDashboards/SportsCentral";
import KulturaIcon from "../../img/dashboardIcons/RetailAffiliates/kultura_icon.svg";
import Kultura from "../../private/customDashboards/SalesOperations/RetailAffiliateSalesDashboards/Kultura";
import SurplusIcon from "../../img/dashboardIcons/RetailAffiliates/surplus_icon.svg";
import Surplus from "../../private/customDashboards/SalesOperations/RetailAffiliateSalesDashboards/Surplus";
import SACIIcon from "../../img/dashboardIcons/RetailAffiliates/sm_appliance_icon.svg";
import SACI from "../../private/customDashboards/SalesOperations/RetailAffiliateSalesDashboards/SACI";
import ToyKingdom from "../../private/customDashboards/SalesOperations/RetailAffiliateSalesDashboards/ToyKingdom";


import ToyKingdomIcon from "../../img/dashboardIcons/RetailAffiliates/toy_kingdom_icon.svg";
import BabyCompanyIcon from "../../img/dashboardIcons/RetailAffiliates/baby_company_icon.svg";
import PetExpressIcon from "../../img/dashboardIcons/RetailAffiliates/pet_express_icon.svg";
import SuppliesStationIcon from "../../img/dashboardIcons/RetailAffiliates/supplies_station_icon.svg";
import BabyCompany from "../../private/customDashboards/SalesOperations/RetailAffiliateSalesDashboards/BabyCompany";
import PetExpress from "../../private/customDashboards/SalesOperations/RetailAffiliateSalesDashboards/PetExpress";
import SuppliesStation
    from "../../private/customDashboards/SalesOperations/RetailAffiliateSalesDashboards/SuppliesStation";


export const retailAffiliateSalesSection = {
    title: "Retail Affiliates Sales",
    contents: [
        // {
        //     name: 'Sales',
        //     path: '/ra/overall-sales',
        //     image: SalesOperationsDashboardIcon,
        //     isNewDashboard: true,
        //     component: <RetailAffiliateSales/>
        // },
        {
            name: 'Ace Hardware',
            path: '/ra/ace-hardware-sales',
            image: AceHardwareIcon,
            isNewDashboard: true,
            component: <AceHardware/>
        },
        {
            name: 'Sports Central',
            path: '/ra/sports-central-sales',
            image: SportsCentralIcon,
            isNewDashboard: true,
            component: <SportsCentral/>
        },
        {
            name: 'Toy Kingdom',
            path: '/ra/toy-kingdom-sales',
            image: ToyKingdomIcon,
            isNewDashboard: true,
            component: <ToyKingdom/>
        },
        {
            name: 'Baby Company',
            path: '/ra/baby-company-sales',
            image: BabyCompanyIcon,
            isNewDashboard: true,
            component: <BabyCompany/>
        },
        {
            name: 'Pet Express',
            path: '/ra/pet-express-sales',
            image: PetExpressIcon,
            isNewDashboard: true,
            component: <PetExpress/>
        },
        {
            name: 'Supplies Station',
            path: '/ra/supplies-station-sales',
            image: SuppliesStationIcon,
            isNewDashboard: true,
            component: <SuppliesStation/>
        },
        {
            name: 'Kultura',
            path: '/ra/kultura-sales',
            image: KulturaIcon,
            isNewDashboard: true,
            component: <Kultura/>
        },
        {
            name: 'Surplus',
            path: '/ra/surplus-sales',
            image: SurplusIcon,
            isNewDashboard: true,
            component: <Surplus/>
        },
        {
            name: 'SACI',
            path: '/ra/saci-sales',
            image: SACIIcon,
            isNewDashboard: true,
            component: <SACI/>
        },
    ]
}