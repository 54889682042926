import {dashboardDefinition} from "./dashboardDefinition";
import {registerDashboard} from "../../../common/utils";
import SalesOperationsDashboard from "./index";


registerDashboard(dashboardDefinition);  // This is a function that registers the dashboard definition to the dashboard list
// this will allow the dashboard to be managed by permissin management.


export default function SMStoreSalesAndOperations({ title = "Sales & Operations", defaultTab = 'sales' }) {
    return <SalesOperationsDashboard title={title} defaultTab={defaultTab} dashboardDefinition={dashboardDefinition}/>
}