import { useZoneToStorePerformance } from "../hooks/manpower";
import ZoneToStorePerformance from "./index";

export default function ManpowerZoneToStorePerformance() {
    const { data: sortedData, isLoading } = useZoneToStorePerformance();

    return <ZoneToStorePerformance data={sortedData} isLoading={isLoading} />
}


