
export const dashboardName = 'SM Store Customers'
export const DASHBOARD_PATH = 'sbu/customers'


export const dashboardDefinition = {
    name: dashboardName,
    entry_points: [
        'sbu/customers',
    ],
    owners: [
        "raphaelchristopher.a.sepnio@smretail.com"
    ],
    path: DASHBOARD_PATH
}