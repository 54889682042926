import * as React from 'react';
import { Typography, Box, styled, LinearProgress, linearProgressClasses } from '@mui/material';
import { getDefaultPercentageColor, getInvertedPercentageColor } from '../../../TmpTSSSales/util';
import { getDifferencePercentageColor } from '../../util';
import { getDecimalPointColor, getDecimalPointZeroColor } from '../../../Inventory/utils';
import ShareOfWalletIndicator from './ShareOfWalletIndicator';
import ProgresBarIndicator from './ProgressBarIndicator';
import CustomerPurchaseIndicator from './CustomerPurchaseBehaviorIndicator';
import { decimalDisplayNumber, topLineDisplayNumber } from '../../../../../common/utils';

export default function BarIndicator({ value, isValueColorCoded, isInverted, colorFunctionFor, undefinedDisplay = '-', componentType, bgColor, prefix, postfix}) {

    if (value === 'N/A') {
        return value;
    }

    if (!value || !isFinite(value) || isNaN(value) || value === 0  || value === '0.00') {
        return undefinedDisplay;
    }
    
    //special case to not to show 0.0% for sales/opex
    if(value === '0.0' && (colorFunctionFor === 'sales' || colorFunctionFor === 'opex')) {
        value = 0
    }

    let colorFunction = getDefaultPercentageColor;
    if (colorFunctionFor === 'sales' || colorFunctionFor === 'opex') {
        colorFunction = getInvertedPercentageColor;
    }
    if(colorFunctionFor === 'difference') {
        colorFunction = getDifferencePercentageColor;
    } 
    if (colorFunctionFor === 'inventory_rtv') {
        colorFunction = getDecimalPointColor
    }
    if (colorFunctionFor === 'inventory_ds') {
        colorFunction = getDecimalPointZeroColor
    }

    const backgroundColor = bgColor ? bgColor : (isValueColorCoded ? colorFunction(value, isInverted) : '#4472c4');
    const textColor = bgColor ? 'black' : 'white';
    switch (componentType) {
        case 'sow':
            return <ShareOfWalletIndicator value={value} />;
        case 'progress_bar':
            return <ProgresBarIndicator value={value} />;
        case 'cpb':
            return <CustomerPurchaseIndicator value={value} bgColor={bgColor} prefix={prefix} postfix={postfix}/>
        default:
            return (
                <Box
                    sx={{
                        width: 'inherit',
                        height: 'inherit',
                        borderRadius: 1,
                        color: textColor,
                        bgcolor: backgroundColor,
                        '&:hover': {
                            bgcolor: backgroundColor,
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant='body2' fontSize='11px'>
                        {value}{postfix ? postfix : '%'}
                    </Typography>
                </Box>
            );
    }
}