import ManpowerToplinePerformance from "../ToplinePerformance/ManpowerToplinePerformance";
import ManpowerZoneToStorePerformance from "../ZonetoStorePerformance/ManpowerZoneToStorePerformance";
import ManpowerBUToDepartmentPerformance from "../BUtoDepartmentPerformance/ManpowerBUToDepartmentPerformance";
import {useIsComponentUsable} from "../../common/providers/blacklistedComponentsProvider/hooks";
import {
    GENERAL_BU_DEPT_PERFORMANCE,
    GENERAL_STORE_PERFORMANCE,
    GENERAL_TOPLINE,
    MANPOWER_BU_DEPT_PERFORMANCE,
    MANPOWER_STORE_PERFORMANCE,
    MANPOWER_TOPLINE
} from "../dashboardDefinition";
// import ManpowerBUToDepartmentPerformance from "../BUtoDepartmentPerformance/ManpowerBUToDepartmentPerformance";


export default function ManpowerContent({selectedTab}) {
    const isGeneralToplineUsable = useIsComponentUsable(GENERAL_TOPLINE);
    const isManpowerToplineUsable = useIsComponentUsable(MANPOWER_TOPLINE);

    const isToplineUsable = isGeneralToplineUsable && isManpowerToplineUsable;

    const isGeneralBUDeptPerformanceUsable = useIsComponentUsable(GENERAL_BU_DEPT_PERFORMANCE);
    const isManpowerBUDeptPerformanceUsable = useIsComponentUsable(MANPOWER_BU_DEPT_PERFORMANCE);

    const isBUDeptPerformanceUsable = isGeneralBUDeptPerformanceUsable && isManpowerBUDeptPerformanceUsable;

    const isGeneralStorePerformanceUsable = useIsComponentUsable(GENERAL_STORE_PERFORMANCE);
    const isManpowerStorePerformanceUsable = useIsComponentUsable(MANPOWER_STORE_PERFORMANCE);

    const isStorePerformanceUsable = isGeneralStorePerformanceUsable && isManpowerStorePerformanceUsable;

    return <>
        {
            isToplineUsable && <ManpowerToplinePerformance />
        }
        {
            isBUDeptPerformanceUsable && <ManpowerBUToDepartmentPerformance selectedTab={selectedTab} />
        }
        {
            isStorePerformanceUsable && <ManpowerZoneToStorePerformance />
        }
    </>
}