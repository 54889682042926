import {useTopLine} from "../hooks/sales";
import ToplinePerformance from "./index";

export default function SalesToplinePerformance() {
      const information = [
        { name: 'Sales', value: 'Gross Sales' },
        { name: 'Sales Plan', value: 'Based on BU/Aptos Sales Plan' },
        { name: 'Transaction', value: 'Transaction Count' },
        { name: 'Transaction Plan', value: 'Sales Plan / Avg Txn Value Plan' },
        { name: 'Avg Txn Value', value: 'Sales / Transactions' },
        { name: 'Avg Txn Value Plan', value: '1900 for Premier stores, 1300 for others' },
        { name: 'Avg Units Sold', value: 'Units Sold / Transaction Count' },
        // { name: 'Avg Units Sold Plan', value: 'N/A' },
    ]


    const { data: sortedData, isLoading } = useTopLine();

    return <ToplinePerformance information={information} data={sortedData} isLoading={isLoading} />
}