export const SALES_SOURCE_METRICS = [
    'gross_sales_sum', 'transaction_key_count_distinct', 'sales_qty_sum',
    'transaction_date_max', 'transaction_date_min',  // added to compute coverage months
]

export const OPERATIONS_BASIC_METRICS = [
    'total_actual_area_sum', 'mall_foot_traffic_sum', 'foot_traffic_sum',
]
export const OPERATIONS_PLAN_METRICS = [
    'transaction_plan_sum', 'sales_plan_sum',
]

export const OPERATIONS_SOURCE_METRICS = [
    ...OPERATIONS_BASIC_METRICS, ...OPERATIONS_PLAN_METRICS
]

export const MANPOWER_SOURCE_METRICS = [
    'selling_headcount_sum', 'non_selling_headcount_sum', 'ps_headcount_sum', 'date_key_formatted_count_distinct'
]

export const COLUMNS_TO_IGNORE_ON_MATCH = [
    ...SALES_SOURCE_METRICS,
    ...OPERATIONS_SOURCE_METRICS,
    ...MANPOWER_SOURCE_METRICS
];  // FIXME: this is just a placeholder

// BU to dept not applicable metrics
export const NOT_APPLICABLE_METRICS = [
    'catchment_rate',
    'conversion_rate',
    'selling_manpower_productivity',
    'non_selling_manpower_productivity',
    'personal_shopper_manpower_productivity'
]; 

// TABLE NAMES
export const TABLE_FCT_TSS = 'fct_sm_store'
export const TABLE_FCT_RA = 'fct_sm_ra';
export const VIEW_FCT_RA_TSS = 'vw_union_ra_store'
export const TABLE_DIM_SUBDEPT = 'dim_subdept';
export const TABLE_DIM_BRANCH = 'dim_branch';
export const TABLE_DIM_CHANNEL = 'dim_channel';
export const TABLE_AGG_STORE_BU = 'agg_store_bu_operations';


export const JOIN_PAIR_DETAILS = {
    [`${TABLE_FCT_TSS}+${TABLE_DIM_SUBDEPT}`]: {
        tables: [TABLE_FCT_TSS, TABLE_DIM_SUBDEPT],
        t1_col: 'subdept_key',
        t2_col: 'subdept_key',
        join_type: 'inner'
    },
    [`${TABLE_FCT_TSS}+${TABLE_DIM_BRANCH}`]: {
        tables: [TABLE_FCT_TSS, TABLE_DIM_BRANCH],
        t1_col: 'branch_key',
        t2_col: 'branch_key',
    },
    [`${TABLE_FCT_TSS}+${TABLE_DIM_CHANNEL}`]: {
        tables: [TABLE_FCT_TSS, TABLE_DIM_CHANNEL],
        t1_col: 'channel_key',
        t2_col: 'channel_key',
    },
    [`${TABLE_AGG_STORE_BU}+${TABLE_DIM_SUBDEPT}`]: {
        tables: [TABLE_AGG_STORE_BU, TABLE_DIM_SUBDEPT],
        t1_col: 'subdept_key',
        t2_col: 'subdept_key',
        join_type: 'left'
    },
    [`${TABLE_AGG_STORE_BU}+${TABLE_DIM_BRANCH}`]: {
        tables: [TABLE_AGG_STORE_BU, TABLE_DIM_BRANCH],
        t1_col: 'branch_key',
        t2_col: 'branch_key',
    },
    [`${TABLE_FCT_RA}+${TABLE_DIM_BRANCH}`]: {
        tables: [TABLE_FCT_RA, TABLE_DIM_BRANCH],
        t1_col: 'branch_key',
        t2_col: 'branch_key',
    },
    [`${TABLE_FCT_RA}+${TABLE_DIM_SUBDEPT}`]: {
        tables: [TABLE_FCT_RA, TABLE_DIM_SUBDEPT],
        t1_col: 'subdept_key',
        t2_col: 'subdept_key',
        join_type: 'inner'
    },
    [`${VIEW_FCT_RA_TSS}+${TABLE_DIM_BRANCH}`]: {
        tables: [VIEW_FCT_RA_TSS, TABLE_DIM_BRANCH],
        t1_col: 'branch_key',
        t2_col: 'branch_key',
    },
    [`${VIEW_FCT_RA_TSS}+${TABLE_DIM_SUBDEPT}`]: {
        tables: [VIEW_FCT_RA_TSS, TABLE_DIM_SUBDEPT],
        t1_col: 'subdept_key',
        t2_col: 'subdept_key',
        join_type: 'inner'
    }
}

export const LOADING_STRING = 'Loading...'

export const CY_LOADING_OUTPUT = {
    cySales: LOADING_STRING,
    cyTransactions: LOADING_STRING,
    vsSalesPlan: LOADING_STRING,
    vsTransactionsPlan: LOADING_STRING,
    vsATVPlan: LOADING_STRING,
    cyATV: LOADING_STRING,
    cyUnitsSoldPerTransaction: LOADING_STRING
}
export const LY_LOADING_OUTPUT = {
    vsLYSales: LOADING_STRING,
    vsLYTransactions: LOADING_STRING,
    vsLYATV: LOADING_STRING,
    vsLYUnitsSoldPerTransaction: LOADING_STRING
}


export const LY_OPERATIONS_LOADING_OUTPUT = {
    vsLYCatchmentRate: LOADING_STRING,
    vsLYConversionRate: LOADING_STRING,
    vsLYSpaceProductivity: LOADING_STRING
}

export const LY_MANPOWER_LOADING_OUTPUT = {
    vsLYTotalManpowerProductivity: LOADING_STRING,
    vsLYSellingProductivity: LOADING_STRING,
    vsLYNonSellingProductivity: LOADING_STRING,
    vsLYPersonalShopperProductivity: LOADING_STRING
}