import React from 'react';
import {FormControl, InputLabel, Menu, Select} from '@mui/material';
import {DropdownRadioOption} from "./DropdownRadioOption";
import { useSelectorValue } from '../../private/customDashboards/common/providers/selectorProvider';


export default function DropdownRadioComponent({
    id,
    labelId,
    label,
    selectedKey,
    options,
    onSelectChange,
    disabled=false,
    isLoading=false,
    MenuProps={}
}) {

    const flatOptions = options.reduce((acc, option) => {
        acc.push(option);
        if (option.children) {
            acc.push(...option.children);
        }
        return acc;
    }, [])

    const card_group = useSelectorValue('card_group')

    const checkItem = (option) => {
        return ['SM Markets', 'SSM', 'SMCO', 'SVI'].includes(option?.key) && card_group === 'SLP'
    }

    return <>
        <FormControl variant="outlined" size="small" sx={{ width: "100%" }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                disabled={disabled || isLoading}
                labelId={labelId}
                id={id}
                label={label}
                value={selectedKey?.key || selectedKey || ''}
                onChange={onSelectChange}
                sx={{ backgroundColor: "white" }}
                renderValue={(value) => {
                    return flatOptions.find(option => option.key === value)?.label || value;
                }}
                MenuProps={MenuProps}
            >
                {options?.map((option) => (
                    <>
                        <DropdownRadioOption option={option} onSelectChange={onSelectChange} selectedKey={selectedKey} disabled={checkItem(option)} isParent={true} />
                        {option.children && option.children.map((child) => {
                            return <DropdownRadioOption option={child} onSelectChange={onSelectChange} selectedKey={selectedKey} iconSx={{
                                marginLeft: "3em",
                            }} disabled={checkItem(option)} />
                        })}
                    </>
                ))}
            </Select>
        </FormControl>
    </>
}