import ToplinePerformance from "./index";
import {useTopLineData} from "../hooks/manpower";

export default function ManpowerToplinePerformance() {
    const information = [
        { name: 'Total Manpower Productivity', value: 'Gross Sales / Total Headcount' },
        { name: 'Selling Productivity', value: 'Gross Sales / Selling Headcount' },
        { name: 'Non-Selling Productivity', value: 'Gross Sales / Non-Selling Headcount' },
        { name: 'Personal Shopper Productivity', value: 'Personal Shopper Sales / Personal Shopper Headcount' },
    ]

    const { data: sortedData, isLoading } = useTopLineData();

    return <ToplinePerformance information={information} data={sortedData} isLoading={false} isRowExpanded={true} />
}