import {useSelectorValue} from "../common/providers/selectorProvider";
import React from "react";
import {PERIOD_MONTHLY, PERIOD_QUARTERLY, PERIOD_YEARLY} from "../../../Constants/general";
import {useCustomerSegments, useTableQueryWithDateFilters, useTableQueryWithQuarterFilters} from "../CustomerAnalytics/hooks";
import moment from "moment/moment";
import {customAgeBinOrder} from "../common/presentation/businessUnitView/constants";
import {computeLY} from "../../../common/utils";
import { SEGMENT_TYPE_RFM } from "../CustomerAnalytics/constants";
import {useTableQuery} from "../../../common/API/hooks";
import {SEGMENT_BU_OVERALL} from "./Usage/CustomerSegments/BusinessUnitSelector";
import {VIEW_BY_USAGE} from "./constants";
import {useDashboardTableSource} from "../common/providers/DashboardMetaProvider";
import { organizationOrder } from "./constants";

export const TABLE_SMAC_MEMBERS_MONTHLY = "l4_smac_members_monthly";
export const TABLE_SMAC_MEMBERS_YEARLY = "l4_smac_members_yearly";

export const TABLE_CITY_PENETRATION_MONTHLY = "l4_smac_member_penetration_monthly";
export const TABLE_CITY_PENETRATION_YEARLY = "l4_smac_member_penetration_yearly";

export const TABLE_SMAC_ACTIVITY_MONTHLY = "l4_smac_activity_status_monthly";
export const TABLE_SMAC_ACTIVITY_YEARLY = "l4_smac_activity_status_yearly";

export const TABLE_DAY_TIME_SALES_MONTHLY = "l4_smac_sales_day_time_monthly";
export const TABLE_DAY_TIME_SALES_YEARLY = "l4_smac_sales_day_time_yearly";

export const TABLE_BU_ACTIVITY_MONTHLY = "l4_smac_bu_activity_monthly";
export const TABLE_BU_ACTIVITY_YEARLY = "l4_smac_bu_activity_yearly";

export const TABLE_CUSTOMER_RECENCY_MONTHLY = "l4_smac_recency_monthly";
export const TABLE_CUSTOMER_RECENCY_YEARLY = "l4_smac_recency_yearly";

export const TABLE_SKU_PERFORMANCE_MONTHLY = "l4_smac_sales_sku_perf_monthly";
export const TABLE_SKU_PERFORMANCE_YEARLY = "l4_smac_sales_sku_perf_yearly";

export const TABLE_HOMEBRANCH_MONTHLY = "l4_smac_homebranch_monthly";
export const TABLE_HOMEBRANCH_YEARLY = "l4_smac_homebranch_yearly";

export const TABLE_SMAC_CUSTOMER_SEGMENTS_MONTHLY = "l4_smac_customer_segments_monthly";
export const TABLE_SMAC_CUSTOMER_SEGMENTS_YEARLY = "l4_smac_customer_segments_yearly";

export const TABLE_BASKET_SIZE_MONTHLY = "l4_smac_basket_size_monthly";
export const TABLE_BASKET_SIZE_YEARLY = "l4_smac_basket_size_yearly";

/*******************************************************************
 BASE TABLE HOOKS
 Processing needed for all tables
 *******************************************************************/
export function useTableQueryWithYearMonthFilters(
    {
        tableName,
        params,
        useQueryOptions = {},
        applyDateFilters = true,
        lastYear = false,
        maxTransactionDate = null
    }
) {
    // this hook forces year,month filters even with yearly tables
    // to use this hook, the table structure must follow the following format
    // year,month, ...
    const period = useSelectorValue('period');
    const periodicity = useSelectorValue('periodicity');
    const year = useSelectorValue('year');
    const newParams = {
        ...params
    }
    const newQueryOptions = {
        ...useQueryOptions
    }
    if (applyDateFilters) {
        if (!year || (!periodicity && period !== PERIOD_YEARLY)) {
            newQueryOptions.enabled = false;
            console.warn("Year or Periodicity not set, disabling query")
        }

        if (lastYear) {
            newParams.year = year - 1;
        } else {
            newParams.year = year;
        }
        if (period === PERIOD_QUARTERLY) {
            newParams.quarter = periodicity;
        } else if (period === PERIOD_MONTHLY) {
            newParams.month = periodicity?.toString().padStart(2, '0');
        } else if (period === PERIOD_YEARLY) {
            if (!maxTransactionDate) {
                console.warn("Max Transaction Date not set, disabling query")
                newQueryOptions.enabled = false;
            } else {
                if (year === maxTransactionDate.getFullYear()) {
                newParams.month = (maxTransactionDate.getMonth() + 1).toString().padStart(2, '0');
                } else {
                    newParams.month = "12"
                }
            }
        }
    }
    return useTableQuery({ tableName, params: newParams, useQueryOptions: newQueryOptions })
}


/*******************************************************************
 BASIC TABLE HOOKS
 per table hooks without any fancy processing
 *******************************************************************/
export function useSMACMembersTableQuery({params, useQueryOptions, applyDateFilters = true, lastYear = false}) {
    const {asDateRange, isLoading: isLoadingDateRange} = usePeriodValues();
    const BASE_SOURCE = useDashboardTableSource();
    const period = useSelectorValue('period');
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_SMAC_MEMBERS_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_SMAC_MEMBERS_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithYearMonthFilters({
        tableName,
        params,
        useQueryOptions,
        applyDateFilters,
        lastYear,
        maxTransactionDate: asDateRange?.to
    })
}

export function useSMACMembersTableQueryWithoutDateFilters({params, useQueryOptions}) {
    // this hook is created to avoid recursive call stack of with datefilters in yearly period
    const period = useSelectorValue('period');
    const BASE_SOURCE = useDashboardTableSource();
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_SMAC_MEMBERS_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_SMAC_MEMBERS_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithYearMonthFilters({
        tableName,
        params,
        useQueryOptions,
        applyDateFilters: false,
        lastYear: false
    })
}


export function useSMACActivityTableQueryWithoutDateFilters({params, useQueryOptions}) {
    // this hook is created to avoid recursive call stack of with datefilters in yearly period
    const period = useSelectorValue('period');
    const BASE_SOURCE = useDashboardTableSource();
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_SMAC_ACTIVITY_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_SMAC_ACTIVITY_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithYearMonthFilters({
        tableName,
        params,
        useQueryOptions,
        applyDateFilters: false,
        lastYear: false
    })
}


export function useCityPenetrationTableQuery({params, useQueryOptions, applyDateFilters = true, lastYear = false}) {
    const period = useSelectorValue('period');
    const card_group = useSelectorValue('card_group') || 'SMAC';
    const BASE_SOURCE = useDashboardTableSource();
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_CITY_PENETRATION_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_CITY_PENETRATION_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithDateFilters({
        tableName,
        params: {
            ...params,
            limit: 2000,  // this table has more rows
            card_group: card_group
        },
        useQueryOptions,
        applyDateFilters,
        lastYear
    })
}

export function useSMACActivityStatusTableQuery({params, useQueryOptions, applyDateFilters = true, lastYear = false}) {
    const {asDateRange, isLoading: isLoadingDateRange} = usePeriodValues();
    const BASE_SOURCE = useDashboardTableSource();
    const period = useSelectorValue('period');
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_SMAC_ACTIVITY_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_SMAC_ACTIVITY_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithYearMonthFilters({
        tableName,
        params: {
            ...params,
            limit: 40000,  // this table has more rows (3 card types + 1 overall) * 2 (active and inactive) * 450 (branches) = 3600, 3600 not enough will adjut to 10000 due to card_status update and to cover sm store branches(actual data retrieve is around 7700)
        },
        useQueryOptions,
        applyDateFilters,
        lastYear,
        maxTransactionDate: asDateRange?.to
    })
}

export function useDayTimeSalesTableQuery({params, useQueryOptions}) {
    const period = useSelectorValue('period');
    const BASE_SOURCE = useDashboardTableSource();
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_DAY_TIME_SALES_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_DAY_TIME_SALES_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])


    return useTableQueryWithDateFilters({
        tableName,
        params,
        useQueryOptions
    })
}


export function useBUActivityTableQuery({params, useQueryOptions}) {
    const period = useSelectorValue('period');
    const BASE_SOURCE = useDashboardTableSource();
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_BU_ACTIVITY_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_BU_ACTIVITY_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithDateFilters({
        tableName,
        params,
        useQueryOptions
    })
}

export function useCustomerRecencyTableQuery({params, useQueryOptions}) {
    const period = useSelectorValue('period');
    const BASE_SOURCE = useDashboardTableSource();
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_CUSTOMER_RECENCY_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_CUSTOMER_RECENCY_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithQuarterFilters({
        tableName,
        params,
        useQueryOptions
    })
}

export function useSKUPerformanceTableQuery({params, useQueryOptions}) {
    const period = useSelectorValue('period');
    const BASE_SOURCE = useDashboardTableSource();
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_SKU_PERFORMANCE_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_SKU_PERFORMANCE_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithDateFilters({
        tableName,
        params,
        useQueryOptions
    })
}


export function useHomeBranchTableQuery({params, useQueryOptions}) {
    const period = useSelectorValue('period');
    const BASE_SOURCE = useDashboardTableSource();
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_HOMEBRANCH_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_HOMEBRANCH_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithQuarterFilters({
        tableName,
        params,
        useQueryOptions
    })
}


export function useSMACCustomerSegmentsTableQuery({params, useQueryOptions, lastYear=false}) {
    const period = useSelectorValue('period');
    const BASE_SOURCE = useDashboardTableSource();
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_SMAC_CUSTOMER_SEGMENTS_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_SMAC_CUSTOMER_SEGMENTS_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithDateFilters({
        tableName,
        params,
        useQueryOptions,
        lastYear
    })
}

export function useSMACBasketSizeTableQuery({params, useQueryOptions}) {
    const period = useSelectorValue('period');
    const BASE_SOURCE = useDashboardTableSource();
    const tableName = React.useMemo(() => {
        if (period === PERIOD_MONTHLY) {
            return BASE_SOURCE+TABLE_BASKET_SIZE_MONTHLY;
        } else if (period === PERIOD_YEARLY) {
            return BASE_SOURCE+TABLE_BASKET_SIZE_YEARLY;
        }
        throw new Error(`Invalid period ${period}`);
    }, [period])

    return useTableQueryWithDateFilters({
        tableName,
        params,
        useQueryOptions
    })
}

/*******************************************************************
 LOOKUP, META HOOKS
 *******************************************************************/


// utility function for usePeriodValues
const asDatesFn = (data, isLoading) => {
    if (isLoading || !data) {
        return [];
    }
    return data.map((row) => {
        if (row.quarter) {
            return moment(`${row.year} Q${row.quarter}`, "YYYY Q").toDate()
        } else if (row.month) {
            return moment(`${row.year}-${row.month}`, "YYYY-MM").toDate()
        }
        return null;  // Add return statement for non-matching conditions
    }).sort((a, b) => a - b);  // Add sort function to sort dates
}

const asDateRangeFn = (asDates) => {
    if (!asDates || asDates.length === 0) {
        return null;
    }
    return {
        from: asDates[0],
        to: asDates[asDates.length - 1]
    }
}

export function usePeriodValues() {
    const mainTab = useSelectorValue('mainTab');

    const hookResponseForSMACMembers = useSMACMembersTableQueryWithoutDateFilters({
        params: {group_by: ['year', 'quarter', 'month']},
        useQueryOptions: {
            enabled: mainTab !== VIEW_BY_USAGE
        }
    });
    const hookResponseForActivity = useSMACActivityTableQueryWithoutDateFilters({
        params: {group_by: ['year', 'quarter', 'month']},
        useQueryOptions: {
            enabled: mainTab === VIEW_BY_USAGE
        }
    });

    const {data, isLoading, ...rest} = mainTab === VIEW_BY_USAGE ? hookResponseForActivity : hookResponseForSMACMembers;


    const asDates = React.useMemo(() => {
        return asDatesFn(data, isLoading)
    }, [data, isLoading]);

    const asDateRange = React.useMemo(() => {
        return asDateRangeFn(asDates)
    }, [asDates])


    return {
        data,  // raw data
        asDates,  // as javascript dates
        asDateRange,  // date range from the first to the last date
        isLoading,
        ...rest
    }
}

export function useLatestLoadDateMoment() {
    const mainTab = useSelectorValue('mainTab');

    const hookResponseForSMACMembers = useSMACMembersTableQueryWithoutDateFilters({
        params: {
            aggregates: ['load_date_max']
        },
        useQueryOptions: {
            enabled: mainTab !== VIEW_BY_USAGE
        }
    });
    const hookResponseForActivity = useSMACActivityTableQueryWithoutDateFilters({
        params: {
            aggregates: ['load_date_max']
        },
        useQueryOptions: {
            enabled: mainTab === VIEW_BY_USAGE
        }
    });

    const {data, isLoading, ...rest} = mainTab === VIEW_BY_USAGE ? hookResponseForActivity : hookResponseForSMACMembers;

    const latestLoadDate = data?.[0]?.load_date_max ? moment(data[0]?.load_date_max) : null;

    return {
        data: latestLoadDate,
        isLoading,
        rawData: data,
        ...rest
    }
}


/*******************************************************************
 FETCH HOOKS
 Minor processing of data
 *******************************************************************/

export function useSMACMemberCounts({lastYear = false}) {
    const card_group = useSelectorValue('card_group');
    const params = { 
        card_group:  card_group,
    }
    return useSMACMembersTableQuery({
        params,
        lastYear
    })
}


export function useSMACActivityOptionsData() {
    // returns a query that fetches all activity status options used for overall filters
    return useSMACActivityStatusTableQuery({
        params: {group_by: ['org', 'company', 'store']},  // params
        applyDateFilters: false
    })
}


export function useOrganizationOptions() {
    const { data, ...rest } = useSMACActivityOptionsData();

    const organizationOptions = React.useMemo(() => {
        if (!data) {
            return [{ 
                label: "SM Store", 
                key: "SM Store", 
                data: {
                    organization: 'SM Store',
                    company: 'All'
                }
             }];
        }

        const organizationSet = new Set(data.map(row => row.org));
        const organizations = Array.from(organizationSet);

        const smMarketsCompanies = Array.from(
            new Set(
                data
                    .filter(row => row.org === "SM Markets" && row.company !== 'All')
                    .map(row => row.company)
            )
        ).map(company => ({
            key: company,
            label: company,
            data: {organization: 'SM Markets', company: company }
        }));

        const retailAffiliates = Array.from(
            new Set(
                data
                    .filter(row => row.org === "Retail Affiliates" && row.company !== 'All')
                    .map(row => row.company).sort((a, b) => a.localeCompare(b))
            )
        ).map(company => ({
            key: company,
            label: company,
            data: {organization: 'Retail Affiliates', company: company }
        }));


        const options = organizations.map(org => ({
            label: org,
            key: org,
            data: { organization: org, company: 'All' },
            ...(org === "SM Markets" && { children: smMarketsCompanies }),
            ...(org === "Retail Affiliates" && { children: retailAffiliates, unclickable: true })
        }));

         return options.sort((a, b) => {
            return organizationOrder.indexOf(a.label) - organizationOrder.indexOf(b.label);
        });
    }, [data]);

    return {
        data: organizationOptions,
        ...rest,
    };
}

export function useCompanyOptions(){
    const {data, ...rest} = useSMACActivityOptionsData();
    const companyOptions = React.useMemo(() => {
        if (!data) {
            return [{label: "All", value: "All"}];
        }

        const companySet = new Set(data.map(row => row.company));

        const options = Array.from(companySet).map(company => {
            return {
                label: company,
                value: company
            }
        })

        const customOrder = ["All", "SSM", "SVI", "SMCO"];

        return options.sort((a, b) => {
            const indexA = customOrder.indexOf(a.label);
            const indexB = customOrder.indexOf(b.label);

            if (indexA === -1 && indexB === -1) {
                return a.label.localeCompare(b.label);
            }
            if (indexA === -1) return 1;
            if (indexB === -1) return -1; 
            
            return indexA - indexB;
        });

    }, [data])

    return {
        data: companyOptions,
        ...rest
    }

}

export const LABEL_ALL_BRANCH = "All Branches"

export function useBranchOptions() {
    const organization = useSelectorValue('organization') || "SM Store";
    const company = useSelectorValue('company') || 'All'

    const {data, ...rest} = useSMACActivityOptionsData()

    const organizationFilteredData = React.useMemo(() => {
        if (!data) {
            return [];
        }

        return data.filter(row => row.org === organization).filter(row => {
            if (organization === 'SM Markets' || organization === 'Retail Affiliates') {
                return row.company === company
            }
            return true;
        })
    }, [data, organization, company]);


    const branchOptions = React.useMemo(() => {
        if (!organizationFilteredData || organizationFilteredData.length === 0) {
            return [{label: LABEL_ALL_BRANCH, value: "All Branch"}];
        }

        const branchSet = new Set(organizationFilteredData.map(row => row.store));
        return Array.from(branchSet).map(branch => {
            return {
                label: branch === "All Branch" ? LABEL_ALL_BRANCH : branch,
                value: branch
            }
        }).sort((a, b) => {
            if (a.label === LABEL_ALL_BRANCH) {
                return -1;
            } else if (b.label === LABEL_ALL_BRANCH) {
                return 1;
            }
            return a.label.localeCompare(b.label)
        })
    }, [organizationFilteredData]);

    return {
        data: branchOptions,
        ...rest
    }
}


export function useSubDeptDictionary() {
    const organization = useSelectorValue('organization') || "";

    const params = {
        org: organization,
        limit: 1000,
        columns: [
            'bu_desc',
            'dept_desc',
            'subdept_desc',
        ],
        distinct: 1,
    }


    const {data, ...rest} = useSKUPerformanceTableQuery({params})

    const nestedDict = React.useMemo(() => {
        if (!data) {
            return {};
        }

        const filteredData = data.filter(row => row.subdept_desc !== 'Overall')

        return filteredData.reduce((acc, row) => {
            if (!acc[row.bu_desc]) {
                acc[row.bu_desc] = {}
            }
            if (!acc[row.bu_desc][row.dept_desc]) {
                acc[row.bu_desc][row.dept_desc] = []
            }
            acc[row.bu_desc][row.dept_desc].push(row.subdept_desc)
            return acc
        }, {})
    }, [data])

    return {
        data: nestedDict,
        ...rest
    }
}


export function useSMACCustomerSegmentsData({ lastYear, useQueryOptions = {} }) {
    const organization = useSelectorValue('organization') || 'SM Store';
    const branch = useSelectorValue('branch') || 'All';
    const registration = useSelectorValue('registration') || 'All';
    const company = useSelectorValue('company') || 'All'
    const card_group = useSelectorValue('card_group') || 'SMAC'

    const params = {
        org: organization,
        store: branch,
        card_status:registration,
        limit: 200,  // demographics tab = 6 generations; card type = 3 types; total segments 9 * 400 branches = 3600
        card_group: card_group
    }

    if(organization === 'SM Markets' || organization === 'Retail Affiliates'){
        params.company = company
    }

    return useSMACCustomerSegmentsTableQuery({
        params,
        lastYear,
        useQueryOptions: {
            ...useQueryOptions,
            enabled: !!organization && !!branch && (useQueryOptions.enabled || useQueryOptions.enabled === undefined)
        }
    })
}

export const LABEL_ALL_BU_GROUP = "All Business Unit Groups"
        
export function useSegmentsBusinessUnitOptions() {
    const segmentType = useSelectorValue('segment_type');
    const organization = useSelectorValue('organization')

    const params = {
        columns: ['bu_grp'],
        distinct: 1
    }
     const {data, ...rest} = useSMACCustomerSegmentsTableQuery({
        params,
        lastYear: false
    })

    const buOptionsFromSMACCustomerSegments = React.useMemo(() => {
        if (!data || data.length === 0) {
            return [{label: SEGMENT_BU_OVERALL, value: SEGMENT_BU_OVERALL}];
        }

        const buSet = new Set(data.map(row => row.bu_grp));
        return Array.from(buSet).filter(Boolean).map(bu => {
            return {
                label: bu === "Overall" ? LABEL_ALL_BU_GROUP : bu,
                value: bu
            }
        }).sort((a, b) => {
            if (a.label === LABEL_ALL_BU_GROUP) {
                return -1;
            } else if (b.label === LABEL_ALL_BU_GROUP) {
                return 1;
            }
            return a.label.localeCompare(b.label)
        })
    }, [data]);

    if (segmentType === SEGMENT_TYPE_RFM || organization === "SM Markets") {  // FIXME: if RFM will have business unit group filters, remove this and query it from RFM
        return {
            data: [],
            isLoading: false
        }

    }

    return {
        data: buOptionsFromSMACCustomerSegments,
        ...rest
    }
}


/*******************************************************************
 HIGHER LEVEL HOOKS
 Hooks using multiple tables, fancy processing or reformatting
 *******************************************************************/

const DIMENSION_SMAC_MEMBER_CARD_TYPE = 'Card Type';
const DIMENSION_SMAC_MEMBER_INACTIVE = 'Inactive';
const DIMENSION_SMAC_MEMBER_CONTACTABILITY = 'Contactability';
const DIMENSION_GEOLOCATED_MEMBERS = 'Geo-Located Members';
const DIMENSION_SMAC_MEMBER_AGE = 'Age';
const DIMENSION_SMAC_MEMBER_EMAIL = 'Email';
const DIMENSION_SMAC_MEMBER_MOBILE = 'Mobile';
const DIMENSION_SMAC_MEMBER_CONSENT = 'Consent';
const DIMENSION_SMAC_MEMBER_OVERALL = 'Overall';
const DIMENSION_SMAC_MEMBER_CITY_ADDRESS = 'City Address';
const DIMENSION_SMAC_MEMBER_MARITAL_STATUS = 'Marital Status';
const DIMENSION_SMAC_MEMBER_CAMPAIGN_SUBSCRIPTION = 'Campaign Subscription';
const DIMENSION_SMAC_MEMBER_GENDER = "Gender";
const DIMENSION_SMAC_MEMBER_GENERATION = "Generation";
const DIMENSION_SMAC_MEMBER_ACTIVE = 'Active';
const DIMENSION_SMAC_MEMBER_ACTIVITY_STATUS = 'Activity Status';

const CARD_STATUS_EXPIRED = 'Expired';

export const CARD_STATUS_REGISTERED = 'Registered';
export const CARD_STATUS_UNREGISTERED = 'Unregistered';

const CARD_STATUS_NEW = 'New';
const CARD_STATUS_EXISTING = 'Existing';
const CARD_STATUS_RENEWAL = 'Renewal';
const CARD_STATUSES_ACTIVE = [
    CARD_STATUS_NEW,
    CARD_STATUS_EXISTING,
    CARD_STATUS_RENEWAL
]

const ACQUISITION_CARD_STATUSES = [
    CARD_STATUS_NEW,
    CARD_STATUS_EXISTING,
    CARD_STATUS_RENEWAL,
]
const REGISTRATION_CARD_STATUSES = [
    CARD_STATUS_REGISTERED,
    CARD_STATUS_UNREGISTERED,
]

export function useSMACMembersByDimension({dimension, lastYear = false}) {
    const {data, ...rest} = useSMACMemberCounts({lastYear});
    const requestedDimensionData = React.useMemo(() => {
        if (!data) {
            return null;
        }
        return data.filter(row => row.dimension === dimension || row.dimension === 'Overall' );
    }, [data])

    const asDict = React.useMemo(() => {
        if (!requestedDimensionData) {
            return {};
        }

        return requestedDimensionData.reduce((acc, row) => {
            acc[row.dimension_value] = row.total_members;
            return acc;
        }, {})
    }, [requestedDimensionData])

    return {
        data: requestedDimensionData,
        asDict,
        ...rest
    }
}


export function useToplineSMACMemberCount({lastYear = false}) {
    const {data, ...rest} = useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_OVERALL, lastYear})
    // for usage tab
    const {asUsagePieData, ...restUsageData} = useToplineSMACMemberCountForUsage({lastYear})

    const selectedTab = useSelectorValue('mainTab')

    const totalMembers = React.useMemo(() => {
        if (!data || data.length === 0) {
            return 0
        }

        return data.filter(row => CARD_STATUSES_ACTIVE.includes(row.card_status)).reduce((acc, row) => {
            return acc + row.total_members
        }, 0)
    }, [data]);


    const membersByCardStatusDict = (data, cardStatusList) => {
        return cardStatusList.reduce((acc, cardStatus) => {
            acc[cardStatus] = data.find(row => row.card_status === cardStatus)?.total_members || 0;
            return acc;
        }, {})
    }

    const asAcquisitionBarData = React.useMemo(() => {
        if (!data || data.length === 0) {
            return {}
        }
        return {
            ...membersByCardStatusDict(data, ACQUISITION_CARD_STATUSES),
            Expired: data.find(row => row.card_status === CARD_STATUS_EXPIRED)?.total_members || 0,
        }
    }, [data])

    const asRegistrationPieData = React.useMemo(() => {
        if (!data || data.length === 0) {
            return {}
        }
        return membersByCardStatusDict(data, REGISTRATION_CARD_STATUSES)
    }, [data])

    const commonReturn = {
        asAcquisitionBarData,
        asRegistrationPieData,
        asUsagePieData,
    }

    if (selectedTab === 'usage') {
        return {
            ...commonReturn,
            ...restUsageData
        }
    } else {
        return {
            ...commonReturn,
            ...rest,
            original: data,
            totalMembers: totalMembers,
        }
    }
}


export function useSMACMembersByCardType({lastYear = false}) {
    const {data, ...rest} = useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_CARD_TYPE, lastYear})
    const {data: asUsageBarData} = useSMACMembersByCardTypeAndActivity({lastYear})

    const card_group = useSelectorValue('card_group') || 'SMAC'
    const asNestedData = React.useMemo(() => {
        if (!data) {
            return null;
        }

        return data.filter(row => row.dimension !== 'Overall').filter(obj => obj?.card_group === card_group).reduce((acc, row) => {
            // TODO: check if bar charts need cardType -> cardStatus or cardStatus -> cardType nesting
            const cardType = row.dimension_value;
            if (!acc[cardType]) {
                acc[cardType] = {};
            }
            acc[cardType][row.card_status] = row.total_members;
            return acc
        }, {})
    })

    // TODO: simplify and implement DRY principle

    const asAcquisitionBarData = React.useMemo(() => {
        if (!asNestedData) {
            return null;
        }

        return Object.keys(asNestedData).reduce((acc, cardType) => {
            const cardTypeData = asNestedData[cardType];
            return {
                ...acc,
                [cardType]: ACQUISITION_CARD_STATUSES.reduce((acc, cardStatus) => {
                    acc[cardStatus] = cardTypeData[cardStatus] || 0;
                    return acc;
                }, {}),
            }
        }, {});
    }, [asNestedData]);

    const asRegistrationBarData = React.useMemo(() => {
        if (!asNestedData) {
            return null;
        }

        return Object.keys(asNestedData).reduce((acc, cardType) => {
            const cardTypeData = asNestedData[cardType];
            return {
                ...acc,
                [cardType]: REGISTRATION_CARD_STATUSES.reduce((acc, cardStatus) => {
                    acc[cardStatus] = cardTypeData[cardStatus] || 0;
                    return acc;
                }, {}),
            }
        }, {});
    })

    const selectedTab = useSelectorValue('mainTab')
    const barDataByTab = {
        acquisition: asAcquisitionBarData,
        registration: asRegistrationBarData,
        usage: asUsageBarData,
    }


    return {
        data,
        asNestedData,
        asAcquisitionBarData,
        asRegistrationBarData,
        asUsageBarData,
        asBarData: barDataByTab[selectedTab],
        ...rest
    }
}


export function useSMACCardStatusTableData() {
    const {data, ...rest} = useSMACMembersByCardType({})
    const {data: lastYearData, isLoading: isLoadingLastYear} = useSMACMembersByCardType({lastYear: true})

    const card_group = useSelectorValue('card_group') || 'SMAC'

    const setDimensionValueAsPk = (dataRow) => {
        return {
            pk: dataRow.dimension_value,
            ...dataRow
        }
    };

    const computeVsPercentages = (dataRow) => {
        const planValue = dataRow.sales_plan;
        const vsPlan = planValue ? dataRow.total_members / planValue * 100 : null;

        const lastYearValue = dataRow.lastYear?.total_members;
        const vsLastYear = lastYearValue ? dataRow.total_members / lastYearValue * 100 : null;

        return {
            vsPlan: vsPlan,
            vsLastYear: dataRow.isLoadingLastYear ? "..." : vsLastYear,
            ...dataRow,
        }
    }

    const createParentRow = (tableRows) => {
        const tmp = {
            total_members: tableRows.reduce((acc, row) => acc + row.total_members, 0),
            distribution: tableRows.reduce((acc, row) => acc + row.distribution, 0),
            sales_plan: tableRows.reduce((acc, row) => acc + row.sales_plan, 0),
            isLoadingLastYear,
            lastYear: {
                total_members: tableRows.reduce((acc, row) => acc + row.lastYear.total_members, 0)
            }
        }

        return computeVsPercentages(tmp)
    }

    const asCardStatusTableData = React.useMemo(() => {
        if (!data) {
            return [];
        }

        const visibleData = data
            .filter(row => row.card_status === CARD_STATUS_NEW || row.card_status === CARD_STATUS_EXISTING || row.card_status === CARD_STATUS_RENEWAL )
            .filter(row => row.card_group === card_group)
            .map(row => {
                const lastYearRow = lastYearData?.find(lyRow => {
                    return lyRow.dimension_value === row.dimension_value && lyRow.card_status === row.card_status
                })
                return {
                    ...row,
                    lastYear: lastYearRow || {total_members: 0},
                    isLoadingLastYear: isLoadingLastYear
                }
            });
        
        const totalVisibleMembers = visibleData.filter(row => row.dimension_value === 'Overall').reduce((acc, row) => acc + row.total_members, 0);

        const visibleDataWithDistribution = visibleData.filter(row => row.dimension_value !== 'Overall').map(row => {
            return {
                ...row,
                distribution: totalVisibleMembers === 0 ? 0 : row.total_members / totalVisibleMembers * 100
            }
        })

        const overallVisibleDataWithDistribution = visibleData.filter(row => row.dimension_value === 'Overall').map(row => {
            return {
                ...row,
                distribution: totalVisibleMembers === 0 ? 0 : row.total_members / totalVisibleMembers * 100
            }
        })

        const existingTableRows = visibleDataWithDistribution
            .filter(row => row.card_status === CARD_STATUS_EXISTING && row.card_group === card_group)
            .map(computeVsPercentages).map(setDimensionValueAsPk);
        const existingOverall = overallVisibleDataWithDistribution
            .filter(row => row.card_status === CARD_STATUS_EXISTING && row.card_group === card_group)
            .map(computeVsPercentages).map(setDimensionValueAsPk);
        const existingTableParentRow = existingTableRows && existingTableRows.length > 0 ? {
            ...createParentRow(existingOverall),
            pk: "Existing",
            subRows: existingTableRows
        } : null

        const newTableRows = visibleDataWithDistribution
            .filter(row => row.card_status === CARD_STATUS_NEW && row.card_group === card_group)
            .map(computeVsPercentages).map(setDimensionValueAsPk);
        const newOverall = overallVisibleDataWithDistribution
            .filter(row => row.card_status === CARD_STATUS_NEW && row.card_group === card_group)
            .map(computeVsPercentages).map(setDimensionValueAsPk);
        const newTableParentRow = newTableRows && newTableRows.length > 0 ? {
            ...createParentRow(newOverall),
            pk: "New",
            subRows: newTableRows
        } : null

        const renewalTableRows = visibleDataWithDistribution
            .filter(row => row.card_status === CARD_STATUS_RENEWAL && row.card_group === card_group)
            .map(computeVsPercentages).map(setDimensionValueAsPk);
        const renewalOverall = overallVisibleDataWithDistribution
        .filter(row => row.card_status === CARD_STATUS_RENEWAL && row.card_group === card_group)
        .map(computeVsPercentages).map(setDimensionValueAsPk);
        const renewalTableParentRow = renewalTableRows && renewalTableRows.length > 0 ? {
            ...createParentRow(renewalOverall),
            pk: "Renewal",
            subRows: renewalTableRows
        } : null

        // building Total
        const cardTypes = new Set(visibleData.map(row => row.dimension_value ));
        const rowsByCardType = Array.from(cardTypes).filter((cardTypes) => cardTypes !=='Overall').map(cardType => {
            const cardTypeRows = visibleDataWithDistribution.filter(row => row.dimension_value === cardType && row.card_group === card_group)
            return {
                ...createParentRow(cardTypeRows),
                pk: cardType
            }
        });

        const overallCardTypes =Array.from(cardTypes).filter((cardTypes) => cardTypes ==='Overall').map(cardType => {
            const cardTypeRows = overallVisibleDataWithDistribution.filter(row => row.dimension_value === cardType && row.card_group === card_group)
            return {
                ...createParentRow(cardTypeRows),
                pk: cardType
            }
        });

        const totalTableParentRow = {
            ...createParentRow(overallCardTypes),
            pk: "Total",
            subRows: rowsByCardType
        }

        return [
            totalTableParentRow,
            newTableParentRow,
            existingTableParentRow,
            renewalTableParentRow
        ].filter(item => item !== null)
    }, [data, lastYearData, card_group])

    return {
        data: asCardStatusTableData,
        originalData: data,
        originalLastYearData: lastYearData,
        isLoadingLastYear,
        ...rest
    }
}


export function useSMACMembersByAge() {
    const {data, ...rest} = useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_AGE})

    return {
        data: data?.filter(row => row.dimension !== 'Overall').sort((a, b) => {
            return customAgeBinOrder.indexOf(a.dimension_value) - customAgeBinOrder.indexOf(b.dimension_value)
        }),
        ...rest
    }
}


export function useSMACMembersByMaritalStatus() {
    return useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_MARITAL_STATUS})
}


export function useSMACMemberByContactInformation() {
    const {
        data: emailData,
        asDict: emailDataAsDict,
        isLoading: isLoadingEmail,
        ...restEmail
    } = useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_EMAIL})
    const {
        data: mobileData,
        asDict: mobileDataAsDict,
        isLoading: isLoadingMobile,
        ...restMobile
    } = useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_MOBILE})
    const {
        data: cityAddressData,
        asDict: cityAddressDataAsDict,
        isLoading: isLoadingCityAddress,
        ...restCityAddress
    } = useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_CITY_ADDRESS})

    return {
        isLoading: isLoadingEmail || isLoadingMobile || isLoadingCityAddress,
        data: [
            ...(emailData || []),
            ...(mobileData || []),
            ...(cityAddressData || [])
        ],
        asDict: {
            "Email": emailDataAsDict,
            "Mobile": mobileDataAsDict,
            "City Address": cityAddressDataAsDict
        },
        ...restEmail
    }
}


export function useSMACMembersBySubscription() {
    return useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_CAMPAIGN_SUBSCRIPTION})
}


export function useSMACMembersByConsent() {
    return useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_CONSENT})
}

export function useSMACMembersByGender() {
    return useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_GENDER});
}

export function useSMACMembersByGeneration() {
    return useSMACMembersByDimension({dimension: DIMENSION_SMAC_MEMBER_GENERATION});
}


export function useSMACActivityData({lastYear = false}) {
    const organization = useSelectorValue('organization') || "SM Store";
    const registration = useSelectorValue('registration') || "All";
    const company = useSelectorValue('company') || 'All'

    const queryParams = {
        org: organization,
        card_status: registration
    }
    if (organization === 'SM Markets' || organization === 'Retail Affiliates') {
        queryParams.company = company
    }

    const {data, ...rest} = useSMACActivityStatusTableQuery({
        lastYear,
        params: queryParams
    })

    const branch = useSelectorValue('branch') || "All Branch";        
    const card_group = useSelectorValue('card_group') || "SMAC";

    const matchingData = React.useMemo(() => {
        if (!data) {
            return null;
        }

        return data.filter(row => row.store === branch && row.card_group === card_group)
    }, [data, branch, card_group])

    return {
        data: matchingData,
        ...rest
    }
}


export function useToplineSMACMemberCountForUsage({lastYear = false}) {
    const {data, ...rest} = useSMACActivityData({lastYear})

    const overallData = React.useMemo(() => {
        if (!data) {
            return null;
        }

        return data.filter(row => row.card_type === "Overall")
    }, [data])

    const totalMembers = React.useMemo(() => {
        if (!overallData) {
            return 0
        }
        return overallData.find(row => row.activity_status === "Active")?.total_members || 0;
    }, [overallData]);

    const asUsagePieData = React.useMemo(() => {
        if (!overallData) {
            return null;
        }

        return overallData.reduce((acc, row) => {
            acc[row.activity_status] = row.total_members;
            return acc;
        }, {})
    }, [overallData]);

    return {
        totalMembers: totalMembers,
        asUsagePieData: asUsagePieData,
        original: data,
        ...rest
    }
}


export function useSMACMembersByCardTypeAndActivity({lastYear = false}) {
    const {data, ...rest} = useSMACActivityData({lastYear})

    const byCardType = React.useMemo(() => {
        if (!data || data.length === 0) {
            return {}
        }

        return data
            .filter(row => row.card_type !== "Overall")
            .reduce((acc, row) => {
                const cardType = row.card_type;
                if (!acc[cardType]) {
                    acc[cardType] = {};
                }
                acc[cardType][row.activity_status] = row.total_members;
                return acc
            }, {})
    }, [data]);

    return {
        data: byCardType,
        original: data,
        ...rest
    }
}


export function useCityPenetrationTableData() {
    const { data, ...rest } = useCityPenetrationTableQuery({});

    const provincialData = React.useMemo(() => {
        if (!data) {
            return [];
        }
        
        const calculateVsLastYear = (current, lastYear) => {
            if (!current || !lastYear) return 0;  
            return (current / lastYear) * 100;
        };

        return data
            .filter(row => row.city === 'Overall')
            .map(provincialRow => {
                // Calculate vsLastYear values for penetration, smac_members, and smac_active_members
                const vsLastYearPenetration = calculateVsLastYear(provincialRow.penetration_perc, provincialRow.penetration_perc_ly);
                const vsLastYearSmacMembers = calculateVsLastYear(provincialRow.smac_members, provincialRow.smac_members_ly);
                const vsLastYearSmacActiveMembers = calculateVsLastYear(provincialRow.smac_active_members, provincialRow.smac_active_members_ly);

                return {
                    pk: provincialRow.province,
                    ...provincialRow,
                    vs_last_year_penetration: vsLastYearPenetration,
                    vs_last_year_smac_members: vsLastYearSmacMembers,
                    vs_last_year_smac_active_members: vsLastYearSmacActiveMembers,
                    subRows: data
                        .filter(subRow => subRow.province === provincialRow.province && subRow.city !== 'Overall')
                        .map(subRow => {
                            // Calculate vsLastYear for sub-rows (penetration, smac_members, and smac_active_members)
                            const vsLastYearPenetrationSubRow = calculateVsLastYear(subRow.penetration_perc, subRow.penetration_perc_ly);
                            const vsLastYearSmacMembersSubRow = calculateVsLastYear(subRow.smac_members, subRow.smac_members_ly);
                            const vsLastYearSmacActiveMembersSubRow = calculateVsLastYear(subRow.smac_active_members, subRow.smac_active_members_ly);

                            return {
                                ...subRow,
                                vs_last_year_penetration: vsLastYearPenetrationSubRow,
                                vs_last_year_smac_members: vsLastYearSmacMembersSubRow,
                                vs_last_year_smac_active_members: vsLastYearSmacActiveMembersSubRow,
                                pk: subRow.city
                            };
                        })
                        .sort((a, b) => a.pk?.localeCompare(b.pk))
                };
            })
            .sort((a, b) => a.pk?.localeCompare(b.pk));
    }, [data]);

    return {
        data: provincialData,
        ...rest
    };
}


export function useDayTimeSalesTableData() {
    const organization = useSelectorValue('organization') || "SM Store";
    const branch = useSelectorValue('branch') || "All Branch";
    const registration = useSelectorValue('registration') || "All";

    const params = {
        org: organization,
        store: branch,
        card_status: registration,
        columns: [
            'day_name',
            'hour_of_day',
            'sales',
        ],
        limit: 200
    }

    const {data, ...rest} = useDayTimeSalesTableQuery({params})

    const dayTimeSalesData = React.useMemo(() => {
        if (!data) {
            return [];
        }

        const asDict = data.reduce((acc, row) => {
            if (!acc[row.hour_of_day]) {
                acc[row.hour_of_day] = {}
            }
            acc[row.hour_of_day][row.day_name] = row.sales;
            return acc;
        }, {});

        const rowKeys = Object.keys(asDict).sort((a, b) => a - b);
        const hours = rowKeys.filter(hour => !Number.isNaN(hour) && hour >= 0 && hour <= 23);
        const days = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday'
        ]

        const maxPerDay = days.reduce((acc, day) => {
            acc[day] = Math.max(...hours.map(hour => asDict[hour][day] || 0));
            // arbitrary rule: ceiling for max value is 20k
            if (acc[day] > 20000) {
                acc[day] = 20000;
            }
            return acc;
        }, {});


        return rowKeys.map(hour => {
            return {
                pk: hour.toString(),
                hour: hour,
                ...days.reduce((acc, day) => {
                    acc[day] = asDict[hour][day] || 0;
                    return acc;
                }, {}),
                ...days.reduce((acc, day) => {
                    const val = asDict[hour][day] || 0;
                    const max = maxPerDay[day] || 0;
                    if (hours.includes(hour)) {
                        acc[`${day}_max_perc`] = max === 0 ? 0 : val / max;
                    }
                    return acc
                }, {})
            }
        })
    }, [data])

    return {
        data: dayTimeSalesData,
        ...rest
    }
}


export function useActivityByBusinessUnitTableData() {

    const organization = useSelectorValue('organization') || "SM Store";
    const branch = useSelectorValue('branch') || "All Branch";
    const registration = useSelectorValue('registration') || "All";
    const company = useSelectorValue('company') || 'All';
    const card_group = useSelectorValue('card_group') || 'SMAC'
    const params = {
        org: organization,
        store: branch,
        card_status: registration,
        limit: 1000,
        card_group: card_group
    }

    if (organization === 'SM Markets' || organization === 'Retail Affiliates') {
        params.company = company
    }

    const {data, ...rest} = useBUActivityTableQuery({params})

    const tableData = React.useMemo(() => {
        if (!data) {
            return [];
        }

        const topLevelRows = data.filter(row => row.dept_desc === 'Overall' && row.subdept_desc === 'Overall');
        return topLevelRows.map(row => {
            const subRowLevel1 = data.filter(subRow1 => subRow1.bu_desc === row.bu_desc && subRow1.dept_desc !== 'Overall' && subRow1.subdept_desc === 'Overall');

            return {
                pk: row.bu_desc,
                ...row,
                subRows: subRowLevel1.map(subRow1 => {
                    const subRowLevel2 = data.filter(subRow2 => subRow2.bu_desc === subRow1.bu_desc && subRow2.dept_desc === subRow1.dept_desc && subRow2.subdept_desc !== 'Overall');
                    return {
                        pk: subRow1.dept_desc,
                        ...subRow1,
                        subRows: subRowLevel2.map(subRow2 => {
                            return {
                                pk: subRow2.subdept_desc,
                                ...subRow2
                            }
                        }).sort((a, b) => a.pk?.localeCompare(b.pk))
                    }
                }).sort((a, b) => a.pk?.localeCompare(b.pk))
            }
        }).sort((a, b) => a.pk?.localeCompare(b.pk));
    }, [data])

    return {
        data: tableData,
        ...rest
    }
}


export const RECENCY_BY_BU = "BU Group"
export const RECENCY_BY_BRANCH = "Branch"

export function useCustomerRecencyTableData({recencyBy=RECENCY_BY_BU}) {
    const organization = useSelectorValue('organization') || "SM Store";
    const registration = useSelectorValue('registration') || "All";
    const company = useSelectorValue('company') || 'All';
    const card_group = useSelectorValue('card_group') || 'SMAC'
    const {data, ...rest} = useCustomerRecencyTableQuery({
        params: {
            limit: 10000,  // expected number of rows per period is less than 500
            card_group: card_group
        }
    })
    
    const organizationData = React.useMemo(() => {
        if (!data) {
            return [];
        }

        return data.filter(({ org, card_status, company: rowCompany }) => {
            const matchesOrg = org === organization;
            const matchesStatus = card_status === registration;

            return matchesOrg && matchesStatus;
        }).filter(row => {
            if (organization === 'SM Markets' || organization === 'Retail Affiliates') {
                return row.company === company;
            }
            return true;
        });
    }, [data, organization,registration, company]);

    const recencyDimensionData = React.useMemo(() => {
        if (!organizationData) {
            return [];
        }

        return organizationData.filter(row => row.dimension === recencyBy)
    }, [organizationData, recencyBy]);

    const tableData = React.useMemo(() => {
        if (!recencyDimensionData) {
            return [];
        }
        
        if(recencyBy !== RECENCY_BY_BU) {
            return recencyDimensionData.map(row => {
                return {
                    pk: row.dimension_value,
                    ...row
                }
            }).sort((a, b) => a.pk?.localeCompare(b.pk))
        }
        
        if (organization === 'SM Markets') {
            return recencyDimensionData.map(row => {
                return {
                    pk: row.dimension_subvalue,
                    ...row
                }
            }).sort((a, b) => a.pk?.localeCompare(b.pk))
        }
        
        // Default case: recencyBy === RECENCY_BY_BU and organization === 'SM Store'
        const topLevelRows = recencyDimensionData.filter(row => row.dimension === recencyBy && (row.dimension_subvalue === 'Overall' || row.dimension_subvalue === 'Total'));
        return topLevelRows.map(row => {
            const subRowLevel1 = recencyDimensionData.filter(subRow1 => subRow1.dimension_value === row.dimension_value && subRow1.dimension_subvalue !== 'Overall');
            const pk = row.dimension_value
            return {
                pk: pk,
                ...row,
                subRows: pk !== 'Total' ? subRowLevel1.map(subRow1 => {
                    return {
                        pk: subRow1.dimension_subvalue,
                        ...subRow1,
                    }
                }).sort((a, b) => a.pk?.localeCompare(b.pk)) : []
            }
        }).sort((a, b) => a.pk?.localeCompare(b.pk));

    }, [recencyDimensionData, recencyBy, organization])

    return {
        data: tableData,
        original: recencyDimensionData,
        ...rest
    }
}


export function useSKUPerformanceTableData({ bu, dept, subdept }) {
    const organization = useSelectorValue('organization') || "SM Store";
    const branch = useSelectorValue('branch') || "All";
    const registration = useSelectorValue('registration') || "All";
    const company = useSelectorValue('company') || 'All'
    const card_group = useSelectorValue('card_group') || 'SMAC'
    const params = {
        org: organization,
        store: branch,
        card_status: registration,
        bu_desc: bu,
        dept_desc: dept,
        subdept_desc: subdept,
        limit: 1000,
        columns: [
            'rank',
            'bottom_sku',
            'top_sku',
        ],
        card_group: card_group
    };

    if (organization === 'SM Markets' || organization === 'Retail Affiliates') {
        params.company = company 
    }

    const queryEnabled = !!bu && !!dept && !!subdept;
    if (!queryEnabled) {
        console.warn("useSKUPerformanceTableData: missing required parameters");
    }

    const { data, ...rest } = useSKUPerformanceTableQuery({
        params,
        useQueryOptions: {
            enabled: queryEnabled
        }
    });

    const tableData = React.useMemo(() => {
        if (!data) {
            return [];
        }

        const rankMap = data.reduce((acc, row) => {
            const { rank, top_sku, bottom_sku } = row;
            if (!acc[rank]) {
                acc[rank] = { topSKUs: [], bottomSKUs: [] };
            }

            if (top_sku) {
                acc[rank].topSKUs.push(top_sku);
            }

            if (bottom_sku) {
                acc[rank].bottomSKUs.push(bottom_sku);
            }

            return acc;
        }, {});

        return Object.keys(rankMap).map(rank => ({
            pk: rank,
            rank,
            topSKUs: rankMap[rank]?.topSKUs,
            bottomSKUs: rankMap[rank]?.bottomSKUs,
            original: {
                top: rankMap[rank]?.topSKUs,
                bottom: rankMap[rank]?.bottomSKUs
            }
        }));
    }, [data]);

    return {
        data: tableData,
        ...rest
    };
}



export function useHomeBranchTableData() {
    const organization = useSelectorValue('organization') || "SM Store";
    const registration = useSelectorValue('registration') || "All";
    const card_group = useSelectorValue('card_group') || 'SMAC'
    const {data, ...rest} = useHomeBranchTableQuery({
        params: {
            limit: 1000,  // expected number of rows per period is less than 500
            card_group: card_group
        }
    })
    
    const organizationData = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return data.filter(row => row.org === organization && row.card_status === registration)
    }, [data, organization,registration]);

    const tableData = React.useMemo(() => {
        if (!organizationData) {
            return [];
        }
        return organizationData.map(row => {
            return {
                pk: row.homebranch,
                ...row
            }
        }).sort((a, b) => a.pk?.localeCompare(b.pk))
    }, [organizationData])

    return {
        data: tableData,
        original: organizationData,
        ...rest
    }
}


// TODO: move this to constant?
export const DIMENSION_GENERATION = "Generation"
export const DIMENSION_CARD_TYPE = "Card Type"

export function useSMACCustomerSegmentDataByDimension({dimension=DIMENSION_GENERATION, lastYear=false, useQueryOptions = {}}) {
    const segment_bu = useSelectorValue('segment_bu');

    const {data, ...rest} = useSMACCustomerSegmentsData({lastYear, useQueryOptions})
    const matchingData = React.useMemo(() => {
        if (!data) {
            return null;
        }

        return data.filter(row => row.dimension === dimension).filter(row => {
            return !segment_bu || row.bu_grp === segment_bu || row.bu_grp === undefined || row.bu_grp === null
        })
    }, [data, dimension, segment_bu])

    return {
        data: matchingData,
        original: data,
        ...rest
    }
}

export const columnsToComputeLY = [
    'member_count',
    'avg_sales',
    'avg_txn',
    'atv',
    'visit_freq',
    'avg_spend_visit'
]


export function useSMACCustomerSegmentDataByDimensionTableData({dimension=DIMENSION_GENERATION, useQueryOptions = {}}) {
    const {data: dataThisYear, isLoading: isLoadingThisYear, ...rest} = useSMACCustomerSegmentDataByDimension({dimension, lastYear: false, useQueryOptions: useQueryOptions})

    const tableData = React.useMemo(() => {
        if (!dataThisYear) {
             return []
        }

        return dataThisYear.map(row => {
            return {
                pk: row.dimension_value,
                ...row,
                ...computeLY(row, null, columnsToComputeLY),
                isLoadingLastYear: false
            }
        }).filter(row => {
            return row.pk !== 'Gen Alpha'  // request by Kath 2024-10-24 11:21AM
        }).map(row => {
            if (row.pk.toString().toLowerCase() === 'total') {
                return {
                    ...row,
                    _isFooter: true
                }
            }
            return row
        })
    }, [dataThisYear])

    return {
        data: tableData,
        isLoading: isLoadingThisYear,
        isLoadingLastYear: false,
        original: dataThisYear,
        originalLastYear: dataThisYear,
        ...rest
    }
}


export function useSMACCustomerSegmentDataRFMTableData({ useQueryOptions = {} }) {
    const organizationFromContext = useSelectorValue('organization');
    const branchFromContext = useSelectorValue('branch');
    const registration = useSelectorValue('registration')
    const company = useSelectorValue('company')

    const {data: dataThisYear, isLoading: isLoadingThisYear, ...rest} = useCustomerSegments({segmentationType: SEGMENT_TYPE_RFM, store: branchFromContext, organization: organizationFromContext, lastYear: false, useQueryOptions})
    const {data: dataLastYear, isLoading: isLoadingLastYear} = useCustomerSegments({segmentationType: SEGMENT_TYPE_RFM, store: branchFromContext, organization: organizationFromContext, lastYear: true, useQueryOptions })

    const tableData = React.useMemo(() => {
        if (!dataThisYear || registration !== CARD_STATUS_REGISTERED) {
             return []
        }
        return dataThisYear.map(row => {
            const lastYearRow = dataLastYear?.find(lyRow => lyRow.segment_label === row.segment_label)
            return {
                pk: row.segment_label,
                ...row,
                ...computeLY(row, lastYearRow, columnsToComputeLY),
                isLoadingLastYear
            }
        })
    }, [dataThisYear, dataLastYear, isLoadingLastYear, registration])

    return {
        data: tableData,
        isLoading: isLoadingThisYear,
        isLoadingLastYear,
        original: dataThisYear,
        originalLastYear: dataLastYear,
        ...rest
    }
}

export function useSMACCustomerSegmentDataBySegmentTypeTableData() {
    const segmentTypeFromContext = useSelectorValue('segment_type');
    const segmentType = segmentTypeFromContext === 'Demographics' ? DIMENSION_GENERATION : segmentTypeFromContext
    const isSegmentTypeRFM = segmentType === 'RFM'

    const responseForRFM = useSMACCustomerSegmentDataRFMTableData({
        useQueryOptions: {
            enabled: isSegmentTypeRFM
        }
    })
    const responseForDemogsCardType = useSMACCustomerSegmentDataByDimensionTableData({
        dimension: segmentType,
        useQueryOptions: {
            enabled: !isSegmentTypeRFM
        }
    })

    if (segmentType === 'RFM') {
        return responseForRFM;
    } else {
        return responseForDemogsCardType;
    }
}

export function useBasketSizeTableData() {
    const organization = useSelectorValue('organization') || 'SM Store';
    const store = useSelectorValue('branch');
    const registration = useSelectorValue('registration') || 'All';
    const company = useSelectorValue('company') || 'All'
    const card_group = useSelectorValue('card_group') || 'SMAC'
    const params = {
        org: organization,
        store: store,
        card_status: registration,
        card_group: card_group
    }

    if(organization === 'SM Markets' || organization === 'Retail Affiliates'){
        params.company = company
    }

    const {data, ...rest} = useSMACBasketSizeTableQuery({params})
    const tableData = React.useMemo(() => {
        if (!data) {
            return [];
        }

        return data.map(row => {
            return {
                pk: row.basket_size,
                ...row,
                sales: parseFloat(row.sales),
                sales_perc: parseFloat(row.sales_perc),
            }
        }).sort((a, b) => a.pk?.localeCompare(b.pk))
    }, [data]);

    return {
        data: tableData,
        original: data,
        ...rest
    }
}