import { useContext, useMemo } from "react";
import ConditionalSelectComponent from "../../../../common/components/ConditionalSelectComponent";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { getSelectedOption } from "../../../../common/utils";
import { useSelectorState } from "../../common/providers/selectorProvider";
import { METRIC_FILTER_OPTIONS } from "../constant";

export default function MetricColumnSelector() {
    const [selectedMetricColumn, setSelectedMetricColumn] = useSelectorState('metric_column', []);
    const [selectedMetric, setSelectedMetric] = useSelectorState('metric', []);

    const windowSize = useContext(WindowSizeContext)
    const options = useMemo(() => {
        return [
            { value: 'mtd_actual', label: 'Actual MTD' },
            { value: 'mtd_vs_plan', label: 'vs Plan MTD' },
            { value: 'mtd_vs_ly', label: 'vs Last Year MTD' },
            { value: 'ytd_actual', label: 'Actual YTD' },
            { value: 'ytd_vs_plan', label: 'vs Plan YTD' },
            { value: 'ytd_vs_ly', label: 'vs Last Year YTD' },
        ]
    },)

    const matchedMetricLabel = useMemo(() => {
        const selectedOption = METRIC_FILTER_OPTIONS?.find(option => option?.value === selectedMetric);
        return selectedOption?.label;
    }, [selectedMetric]);

    return <ConditionalSelectComponent
        id='metric_filter'
        labelId='metric_filter_label'
        options={options}
        label={`${matchedMetricLabel} Metric`}
        value={selectedMetricColumn ? (windowSize.isOnMobile ? selectedMetricColumn : getSelectedOption(selectedMetricColumn, options)) : '' || null}
        onSelectChange={(event, newValue) => {
            windowSize.isOnMobile ? setSelectedMetricColumn(event.target.value, 'metric_column')
                : setSelectedMetricColumn(newValue?.value, 'metric_column')
        }}
        endAdornment={true}
        startAdornment={false}
        clearSelected={() => setSelectedMetricColumn('', 'metric_column')}
        disableClearable={false}
    />

}