export function getValueOrDefault(obj, key, defaultValue) {
    if (obj === undefined) {
        return defaultValue;
    }
    if (obj[key] === undefined) {
        return defaultValue;
    }
    return obj[key];
}


export function forceParseFloat(value) {
    if (value === undefined || value === null || value === '') {
        return 0;
    }
    if (typeof value === 'string') {
        const parsed = parseFloat(value);
        if (isNaN(parsed)) {
            return 0;
        }
        return parsed;
    }
    // TODO: handle other types and ensure output is a number
    return value
}

export function titleCaseString(string) {
    const lowerCaseString = string.toLowerCase();

    const words = lowerCaseString.split(" ");
 
    return words.map((word) => { 
        // add string or word that will be all capital
        if(word === 'sm' || word === 'ncr' || word === 'bf' || word === 'cdo' || word === 'estore') {
            return word.toUpperCase()
        }
        return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");
}

export function getPercentageAndValue(data) {
    if (!data) return [];

    const totalCount = Object.values(data).reduce((sum, value) => sum + value, 0);

    return Object.keys(data).reduce((result, generation) => {
        result.push({
            [generation]: ((data[generation] / totalCount) * 100).toFixed(1),  // Percentage
            actual_value: data[generation]  // Actual value
        });
        return result;
    }, []);
};

export const getDifferencePercentageColor = (value) => {
    let color;

    if (value  >= 0) {
        color = '#00b38a'
    }else {
        color = '#ea324c'
    }
   
    return color
}