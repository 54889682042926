import { Typography, TextField, Box, Button, Stack } from "@mui/material";
import ActionModal from "../ActionModal";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useState } from "react";
import ApiClient from "../../../../common/API";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import handleError from "../../../admin/ErrorHandling";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export default function EditIPSet({ row, open, setOpen }) {
    const ids = row?.row?.original?.id
    const ip_set_name = row?.row?.original?.ip_set_name
    const ip_addresses = row?.row?.original?.ip_addresses
    const queryClient = useQueryClient();

    const [hasChanges, setHasChanges] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [editedIPSetData, setEditedIPSetData] = useState({
        ip_set_name: ip_set_name ? ip_set_name : '',
        ip_addresses: ip_addresses ? ip_addresses : [],
    })

    const handleSave = () => {
        ApiClient().patch(`ip-set/${ids}/`, editedIPSetData)
            .then(() => {
                queryClient.invalidateQueries(['ip-set']);
                toast.success(`IP Set ${ip_set_name} updated!`);
            })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "IP Set", "edit");
                return toast.error(errorMessage)
            })
    }

    const handleWarningConfirmation = () => {
        setEditedIPSetData({
            ip_set_name: ip_set_name ? ip_set_name : '',
            ip_addresses: ip_addresses ? ip_addresses : [],
        })
        setShowWarningModal(false)
        setHasChanges(false)
        setOpen(false)
    }
    const handleIPSetNameChange = (e) => {
        setHasChanges(true);
        const { value } = e.target;
        setEditedIPSetData((prevIPSetData) => ({
            ...prevIPSetData,
            ip_set_name: value,
        }));
    };

    const handleIPSetAddressesChange = (e) => {
        setHasChanges(true);
        const { value } = e.target;
        const ipArray = value.split(',')
        setEditedIPSetData((prevIPSetData) => ({
            ...prevIPSetData,
            ip_addresses: ipArray,
        }));
    };
    const closeModal = () => {
        if (hasChanges) {
            setShowWarningModal(true)
        }
        else {
            setOpen(false);
        }
    };


    return (
        <>
            <ActionModal
                action={"Edit"}
                title={ip_set_name}
                open={open}
                close={closeModal}
                icon={<EditRoundedIcon />}
                content={
                    <Box sx={{ justifyContent: 'center', mx: 2, mt: 2 }}>
                        <Typography> IP Set Name: </Typography>
                        <TextField variant="outlined" size="small" sx={{ width: '100%' }}
                            placeholder="Input IP Set Name"
                            value={editedIPSetData.ip_set_name}
                            onChange={handleIPSetNameChange}

                        >
                        </TextField>
                        <Typography mt={2}>
                            IP Addresses:
                        </Typography>
                        <TextField variant="outlined" size="small" sx={{ width: '100%' }} multiline maxRows={2}
                            placeholder="Input IP Addresses"
                            value={editedIPSetData.ip_addresses}
                            onChange={handleIPSetAddressesChange}
                        >
                        </TextField>
                    </Box>
                }
                button={
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#0A4FB4', fontWeight: 'bold', textTransform: 'none', width: '40%',
                            '&:hover': {
                                backgroundColor: '#0A4FB4',
                            }
                        }}
                        onClick={handleSave}
                    > Save </Button>
                }
            />
            {
                showWarningModal && (
                    <ActionModal
                        action={"Warning"}
                        open={showWarningModal}
                        close={() => setShowWarningModal(false)}
                        content={<Stack direction={'row'} sx={{ px: 3, pt: 2 }} spacing={2}>
                            <WarningRoundedIcon sx={{ fontSize: '50px' }} color="warning" />
                            <Typography sx={{ fontSize: 18, alignSelf: 'center', textAlign: 'center' }}>
                                There are unsaved changes. Are you sure you want to close the modal?
                            </Typography>
                        </Stack>
                        }
                        button={
                            <Button
                                onClick={handleWarningConfirmation}
                                variant="contained" sx={{
                                    backgroundColor: '#ed6c02', fontWeight: 'bold', textTransform: 'none', width: '40%',
                                    '&:hover': {
                                        backgroundColor: '#ed6c02',
                                    }
                                }}> Confirm </Button>
                        }
                        customWidthMD={4}
                    />
                )
            }
        </>
    )
}