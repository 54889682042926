import OperationsToplinePerformance from "../ToplinePerformance/OperationsToplinePerformance";
import OperationsZoneToStorePerformance from "../ZonetoStorePerformance/OperationsZoneToStorePerformance";
import OperationsBUToDepartmentPerformance from "../BUtoDepartmentPerformance/OperationsBUToDepartmentPerformance";
import {useIsComponentUsable} from "../../common/providers/blacklistedComponentsProvider/hooks";
import {
    GENERAL_BU_DEPT_PERFORMANCE,
    GENERAL_STORE_PERFORMANCE,
    GENERAL_TOPLINE,
    OPERATIONS_BU_DEPT_PERFORMANCE,
    OPERATIONS_STORE_PERFORMANCE,
    OPERATIONS_TOPLINE
} from "../dashboardDefinition";


export default function OperationsContext({selectedTab}) {
    const isGeneralToplineUsable = useIsComponentUsable(GENERAL_TOPLINE);
    const isOperationsToplineUsable = useIsComponentUsable(OPERATIONS_TOPLINE);

    const isToplineUsable = isGeneralToplineUsable && isOperationsToplineUsable;

    const isGeneralBUDeptPerformanceUsable = useIsComponentUsable(GENERAL_BU_DEPT_PERFORMANCE);
    const isOperationsBUDeptPerformanceUsable = useIsComponentUsable(OPERATIONS_BU_DEPT_PERFORMANCE);

    const isBUDeptPerformanceUsable = isGeneralBUDeptPerformanceUsable && isOperationsBUDeptPerformanceUsable;

    const isGeneralStorePerformanceUsable = useIsComponentUsable(GENERAL_STORE_PERFORMANCE);
    const isOperationsStorePerformanceUsable = useIsComponentUsable(OPERATIONS_STORE_PERFORMANCE);

    const isStorePerformanceUsable = isGeneralStorePerformanceUsable && isOperationsStorePerformanceUsable;

    const BUtoDeptInfromation = <div style={{ width: '25rem', padding: 5 }}>
        <div><span style={{ fontWeight: 'bold' }}>Data is not applicable to the following metrics:</span> </div>
        <div style={{ margin: 5 }}>
            <li> <span>Catchment Rate</span></li>
            <li> <span>Conversion Rate</span></li>
        </div>
    </div>


    return <>
        {
            isToplineUsable && <OperationsToplinePerformance />
        }
        {
            isBUDeptPerformanceUsable && <OperationsBUToDepartmentPerformance information={BUtoDeptInfromation} selectedTab={selectedTab} />
        }
        {
            isStorePerformanceUsable && <OperationsZoneToStorePerformance />
        }
    </>
}