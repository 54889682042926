import { useMemo, useState } from "react";
import ActionModal from "../ActionModal";
import { Typography, TextField, Box, Button, Stack } from "@mui/material";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DropdownCheckboxV3 from "../../../../common/components/DropdownCheckboxV3";
import { CustomSwitch } from "../swtichComponent";
import { useGroupList, useUserList } from "../../../../common/API/hooks";
import { getSelectedOption } from "../../../../common/utils";
import { useIPSet } from "../hooks";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { toast } from "react-toastify";
import ApiClient from "../../../../common/API";
import { useQueryClient } from "react-query";
import handleError from "../../../admin/ErrorHandling";


export default function EditIPRule({ row, open, setOpen }) {
    const queryClient = useQueryClient();
    const ids = row?.row?.original?.id
    const rule_name = row?.row?.original?.rule_name
    const users = row?.row?.original?.users
    const groups = row?.row?.original?.groups
    const ip_sets = row?.row?.original?.ip_sets
    const is_restricted = row?.row?.original?.is_restricted

    // data formatting
    const formattedUsers = users.map(user => user.id);
    const formattedGroups = groups.map(group => group.id);

    const { data: userList, isLoading: userLoading } = useUserList();
    const { data: groupList, isLoading: groupLoading } = useGroupList();
    const { data: ipsetList, isLoading: ipsetLoading } = useIPSet()


    const [hasChanges, setHasChanges] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [editedIPRuletData, setEditedIPRuleData] = useState({
        rule_name: rule_name || '',
        user_ids: formattedUsers,
        group_ids: formattedGroups,
        ip_sets: ip_sets,
        is_restricted: is_restricted
    })

    const handleSave = () => {
        ApiClient().patch(`ip-rule/${ids}/`, editedIPRuletData)
            .then(() => {
                queryClient.invalidateQueries(['ip-rule']);
                toast.success(`IP Rule ${rule_name} updated!`);
            })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "IP Rule", "edit");
                return toast.error(errorMessage)
            })
    }

    const handleWarningConfirmation = () => {
        setEditedIPRuleData({
            rule_name: rule_name || '',
            user_ids: formattedUsers || [],
            group_ids: formattedGroups || [],
            ip_sets: ip_sets || [],
            is_restricted: is_restricted || false
        })
        setShowWarningModal(false)
        setHasChanges(false)
        setOpen(false)
    }


    const userOptions = useMemo(() => {
        if (userLoading) {
            return []
        }
        if (!userList) {
            return []
        }
        return userList?.map((user) => {
            return {
                value: user.id,
                label: user.username,
                key: user.id,
            }
        })
    }, [userList, userLoading]);

    const groupOptions = useMemo(() => {
        if (groupLoading) {
            return []
        }
        if (!groupList) {
            return []
        }
        return groupList?.map((group) => {
            return {
                value: group.id,
                label: group.name,
                key: group.id,
            }
        })
    }, [groupList, groupLoading]);

    const ipsetsOptions = useMemo(() => {
        if (ipsetLoading) {
            return []
        }
        if (!ipsetList) {
            return []
        }
        return ipsetList?.map((ip_set) => {
            return {
                value: ip_set.id,
                label: ip_set.ip_set_name,
                key: ip_set.id,
            }
        })
    }, [ipsetList, ipsetLoading]);

    const handleIPRuleNameChange = (e) => {
        setHasChanges(true);
        const { value } = e.target;
        setEditedIPRuleData((prevIPRuleData) => ({ ...prevIPRuleData, rule_name: value }));
    };

    const handleUserChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value)
        setEditedIPRuleData((prevUserData) => ({
            ...prevUserData,
            user_ids: selectedValues
        }));
    };

    const handleGroupChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value)
        setEditedIPRuleData((prevGroupData) => ({
            ...prevGroupData,
            group_ids: selectedValues
        }));
    };

    const handleIPSetChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value)
        setEditedIPRuleData((prevIPSetData) => ({
            ...prevIPSetData,
            ip_sets: selectedValues
        }));
    };

    const handleSwitchChange = (event) => {
        const { checked } = event.target;
        setHasChanges(true);
        setEditedIPRuleData((prevData) => ({
            ...prevData,
            is_restricted: checked
        }));
    };

    const closeModal = () => {
        if (hasChanges) {
            setShowWarningModal(true)
        }
        else {
            setOpen(false);
        }
    };

    return (
        <>
            <ActionModal
                action={"Edit"}
                title={rule_name}
                open={open}
                close={closeModal}
                icon={<EditRoundedIcon />}
                content={
                    <Box sx={{ justifyContent: 'center', mx: '5%', mt: 2 }}>
                        <Typography> Rule Name: </Typography>
                        <TextField variant="outlined" size="small" sx={{ width: '100%' }}
                            placeholder="Input Rule Name"
                            value={editedIPRuletData?.rule_name}
                            onChange={handleIPRuleNameChange}
                        >
                        </TextField>
                        <Typography mt={2}>
                            Users:
                        </Typography>
                        <DropdownCheckboxV3
                            options={userOptions}
                            placeholder="Select User/s"
                            limitTags={3}
                            isLoading={userLoading}
                            isDisabled={userLoading}
                            onChange={(event, newValue) => {
                                handleUserChange(getSelectedOption(newValue, userOptions));
                            }}
                            value={getSelectedOption(editedIPRuletData?.user_ids, userOptions)}

                        />
                        <Typography mt={2}>
                            Groups:
                        </Typography>
                        <DropdownCheckboxV3
                            options={groupOptions}
                            placeholder='Select Group/s'
                            limitTags={3}
                            isLoading={groupLoading}
                            isDisabled={groupLoading}
                            onChange={(event, newValue) => {
                                handleGroupChange(getSelectedOption(newValue, groupOptions));
                            }}
                            value={getSelectedOption(editedIPRuletData?.group_ids, groupOptions)}

                        />
                        <Typography mt={2}>
                            IP Sets:
                        </Typography>
                        <DropdownCheckboxV3
                            options={ipsetsOptions}
                            placeholder="Select IP Set/s"
                            limitTags={3}
                            isLoading={ipsetLoading}
                            isDisabled={ipsetLoading}
                            onChange={(event, newValue) => {
                                handleIPSetChange(getSelectedOption(newValue, ipsetsOptions));
                            }}
                            value={getSelectedOption(editedIPRuletData?.ip_sets, ipsetsOptions)}
                        />
                        <Stack direction="row" spacing={1} sx={{ mt: 3, alignItems: 'center' }}>
                            <Typography>
                                Restricted:
                            </Typography>
                            <CustomSwitch
                                checked={editedIPRuletData.is_restricted}
                                onChange={handleSwitchChange}
                            />
                        </Stack>

                    </Box>
                }
                button={
                    <Button variant="contained" sx={{
                        backgroundColor: '#0A4FB4', fontWeight: 'bold', textTransform: 'none', width: '40%',
                        '&:hover': {
                            backgroundColor: '#0A4FB4',
                        }
                    }}
                        onClick={handleSave}
                    > Save </Button>
                }
            />
            {
                showWarningModal && (
                    <ActionModal
                        action={"Warning"}
                        open={showWarningModal}
                        close={() => setShowWarningModal(false)}
                        content={<Stack direction={'row'} sx={{ px: 3, pt: 2 }} spacing={2}>
                            <WarningRoundedIcon sx={{ fontSize: '50px' }} color="warning" />
                            <Typography sx={{ fontSize: 18, alignSelf: 'center', textAlign: 'center' }}>
                                There are unsaved changes. Are you sure you want to close the modal?
                            </Typography>
                        </Stack>
                        }
                        button={
                            <Button
                                onClick={handleWarningConfirmation}
                                variant="contained" sx={{
                                    backgroundColor: '#ed6c02', fontWeight: 'bold', textTransform: 'none', width: '40%',
                                    '&:hover': {
                                        backgroundColor: '#ed6c02',
                                    }
                                }}> Confirm </Button>
                        }
                        customWidthMD={4}
                    />
                )
            }
        </>
    )
}