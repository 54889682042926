import React from "react";
import {ClickAwayListener, Fade, styled, Typography} from "@mui/material";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {Box} from "@mui/system";




export default function CellTooltip({title, informationList=[], children}) {
    if (informationList.length === 0) {
        return children;
    }

    return (
        <Tooltip
            disableTouchListener
                title={
                    <Box component="div" sx={{ maxHeight: '280px', overflow: 'auto'}}  px={1}>
                        {
                            (!!title && !!informationList && informationList.length > 0) && <Typography sx={{fontSize: "1em", fontWeight: "bold"}}>
                                {title}
                            </Typography>
                        }
                        {
                            informationList?.map((information, index) => (
                                <Typography key={index} sx={{fontSize: "1em"}}>
                                    {information}
                                </Typography>
                            ))
                        }
                    </Box>
                }
            >{children} </Tooltip>
    )
}