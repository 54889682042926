import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelectorState } from '../../../customDashboards/common/providers/selectorProvider'
import { InputAdornment, styled, TextField } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment';

const StyledDatePicker = styled(DatePicker)``;

export default function DatePickerSelector() {
    const [date, setDate] = useSelectorState('date', null);

    return <StyledDatePicker
        selected={date}
        onChange={date => setDate(date)}
        dateFormat="yyyy-MM-dd"
        placeholderText="Select a date"
        isClearable
        className="react-datepicker-wrapper"
        maxDate={moment().toDate()} // Disable future dates
        customInput={
            <TextField
                size="small"
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: '#fff'
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CalendarTodayIcon />
                        </InputAdornment>
                    ),
                }}
            />
        }
    />
}