import React from 'react';

export const HorizontalTooltipDivider = () => (
    <hr style={{
        margin: "0.3rem 0 0.2rem 0",
        padding: "0",
        border: "1px solid white",
        backgroundColor: "white",
        opacity: 1
    }} />
);