import React from "react";
import {useOrganizationOptions} from "../hooks";
import ContextBasedDropdownSelector from "../../common/presentation/selectors/ContextBasedDropdownSelector";
import { useSelectorValue } from "../../common/providers/selectorProvider";

export default function OrgranizationSelector() {
    const { data: options, isLoading } = useOrganizationOptions()
    const filteredOptions = options.filter(option => option.value !== "Retail Affiliates"); //remove Retail Affiliates for this release,
    const defaultVal = useSelectorValue('organization') || 'SM Store';
    
    return (
        <ContextBasedDropdownSelector
        isLoading={isLoading}
        autoSelectFirstOption={true}
        options={filteredOptions}
        label={"Organization"}
        contextKey={"organization"}
        defaultValue={defaultVal}
        />
    )
}