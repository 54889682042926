import {useState, useContext, useEffect} from 'react';
import OpexSummary from './OpexSummary'
import OpexPerGroup from './OpexPerGroup'
import {WindowSizeContext} from '../../../common/context/WindowSizeContext';
import '../../../index.css'
import FilterCompany from '../../../common/components/FilterCompany';
import FilterMonth from '../../../common/components/FilterMonth';
import FilterYear from '../../../common/components/FilterYear';
import {Grid, Typography, Box, GlobalStyles} from '@mui/material';
import Back from '../../../common/components/BackNavigateComponent';
import {useOpexData, useAvailableCompanies, useAvailableDateRange} from "./hooks";
import OpexStoresPerformance from './OpexStorePerformance';
import {OperationTypeFilterContext} from "./contexts";
import {OPEX_TABLE} from "./constants";
import {TABLE_NAME as SALES_TABLE_NAME} from "../Sales/hooks";
import DashboardMetaProvider from '../common/providers/DashboardMetaProvider';
import { dashboardDefinition } from './dashboardDefinition';
import { registerDashboard } from '../../../common/utils';

registerDashboard(dashboardDefinition)
export default function Opex() {
    const [selectedRows, setSelectedRows] = useState([]);
    const windowSize = useContext(WindowSizeContext)
    const [expanded, setExpanded] = useState({});
    const [autoResetExpanded, setAutoResetExpanded] = useState(false);
    const [selectedOperationTypes, setSelectedOperationTypes] = useState([]);

    // const { availableCompanies, isAvailableCompaniesLoading } = useAvailableCompanies();
    // const companyIds = (availableCompanies && !isAvailableCompaniesLoading) ?  availableCompanies : [];

    const [filters, setFilters] = useState({
        company_id: [2, 7, 142], //companyIds.length > 0 ? companyIds : [],
        group: "region", // region since it's the default part that will be shown
        operation_type: null, // all operation types are default
        // year_month: [null,null],
        month: null,
        year: null,
    })

    useEffect(() => {
        //reset selectrow only when filter group change
        setSelectedRows([])
    }, [filters.group, filters.operation_type])

    // useEffect(() => {
    //     setFilters((prevFilters) => ({
    //       ...prevFilters,
    //       company_id: companyIds.length > 0 ? companyIds : [],
    //     }));
    // }, [companyIds]);


    const {data: dateRangeV2, dateRangeIsLoading, error, isSuccess} = useAvailableDateRange()

    const params = {
        company_id: filters?.company_id,
        month: filters?.month,
        year: filters?.year,
        // year_month__gte: filters.year_month[0] ? moment(filters.year_month[0]).format('YYYY-MM'): filters.year_month[0],
        // year_month__lte: filters.year_month[1] ? moment(filters.year_month[1]).format('YYYY-MM'): filters.year_month[1]
    }

    function onDropdownFilterChange(value, field) {
        setFilters({
            ...filters,
            [field]: value
        })
    }

    function onSelectRow(row) {
        if (selectedRows?.includes(row[filters?.group])) {
            //unselecting of row
            setSelectedRows(selectedRows?.filter(val => val !== row[filters?.group]))
        } else {
            setSelectedRows([...(selectedRows || []), row[filters?.group]])
        }
    }

    const calculateDateRange = (data) => {
        if (!data || data.length === 0) {
            return {};
        }

        const startDate = data[0] + '-01';
        const endDate = data[data.length - 1] + '-01';

        return {
            from: startDate,
            to: endDate,
        };
    };

    // const dateRange = calculateDateRange(dateRangeV2);

    const dateRange = (dateRangeV2 && !dateRangeIsLoading)
        ? calculateDateRange(dateRangeV2)
        : {};

    const [period, setPeriod] = useState('mtd');

    const onTogglePeriodChange = (event, val) => {
        if (val !== null) {
            setPeriod(val);
        }
    };

    useEffect(() => {
        //reset selectrow only when filter group change
        setSelectedRows([])
    }, [filters.group])

    return <DashboardMetaProvider dashboardDefinition={dashboardDefinition}>
        <OperationTypeFilterContext.Provider value={{
            selected: selectedOperationTypes,
            setSelected: setSelectedOperationTypes,
            toggle: (operationType) => {
                if (selectedOperationTypes.includes(operationType)) {
                    setSelectedOperationTypes(selectedOperationTypes.filter((ot) => ot !== operationType));
                } else {
                    setSelectedOperationTypes([...selectedOperationTypes, operationType]);
                }
            },
            asParams: {operation_type__in: selectedOperationTypes},
        }}>
            <GlobalStyles
                styles={{
                    body: {backgroundColor: '#e0e0e0'},
                }}
            />
            <Grid container>
                <Grid item xs={12} marginY={2} display="flex" mb={1}>
                    <Back/>
                    <Typography variant='h5' ml={3}>
                        Markets OPEX Dashboard
                    </Typography>
                </Grid>
                <Grid item xs={12} marginBottom={2} sx={{
                    position: `${!windowSize.isOnMobile ? 'sticky' : ''}`,
                    top: `${!windowSize.isOnMobile ? '5rem' : ''}`,
                    zIndex: 7,
                    backgroundColor: 'transparent'
                }}>
                    <Box flexGrow={1} xs={12} paddingX={1}
                         sx={{backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: '.5rem'}}>
                        <Grid container columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} paddingY={1}
                              paddingX={windowSize.isOnMobile && 1}>
                            <Grid item xs={12} sm={5}>
                                <FilterCompany
                                    tableName={SALES_TABLE_NAME}
                                    filters={filters}
                                    onPillSelectChange={onDropdownFilterChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <FilterMonth
                                    onDropdownChange={onDropdownFilterChange}
                                    filters={filters} dateRangeData={dateRange}
                                    isSuccess={isSuccess} dateRangeIsLoading={dateRangeIsLoading}/>
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <FilterYear
                                    onDropdownChange={onDropdownFilterChange}
                                    filters={filters} dateRangeData={dateRange}
                                    isSuccess={''} dateRangeIsLoading={dateRangeIsLoading}/>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <OpexSummary
                        params={params}
                        period={period}
                        onToggleChange={onTogglePeriodChange}
                    />
                </Grid>
                <Grid item xs={12} marginY={0}>
                    <OpexPerGroup
                        params={params}
                        filters={filters}
                        onDropDownFilterChange={onDropdownFilterChange}
                        period={period}
                        onToggleChange={onTogglePeriodChange}
                        selectedRows={selectedRows}
                        onSelectRow={onSelectRow}
                        expanded={expanded}
                        autoResetExpanded={autoResetExpanded}
                    />
                </Grid>
                <Grid item xs={12} marginY={0}>
                    <OpexStoresPerformance
                        params={params}
                        filters={filters}
                        onDropDownFilterChange={onDropdownFilterChange}
                        period={period}
                        onToggleChange={onTogglePeriodChange}
                        selectedRows={selectedRows}
                    />
                </Grid>
            </Grid>
        </OperationTypeFilterContext.Provider>
    </DashboardMetaProvider>
}
