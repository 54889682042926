import { IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelectorState } from '../../common/providers/selectorProvider';
import { NumericFormatField } from '../hooks/utils';

export default function MetricInputValueSelector() {
    const [metricValueFilter, setMetricValueFilters] = useSelectorState('metric_value', []);
    const [selectedMetric, setSelectedMetric] = useSelectorState('metric_column', []);

    const percentageFields = ['mtd_vs_plan', 'mtd_vs_ly', 'ytd_vs_plan', 'ytd_vs_ly']
    const showPercentage = percentageFields.includes(selectedMetric)

    return (
        <TextField
            value={metricValueFilter}
            onChange={(event) => {
                setMetricValueFilters(event.target.value, 'metric_value');
            }}
            label="Value"
            placeholder="Input numeric value"
            id="metric_filter_value"
            size="small"
            fullWidth
            sx={{ backgroundColor: '#FFF', borderRadius: '.25rem' }}
            InputProps={{
                inputComponent: NumericFormatField,
                endAdornment: <>
                    {
                        metricValueFilter?.length !== 0 ?
                            <IconButton
                                aria-label="clear input metric value"
                                onClick={() => setMetricValueFilters('', 'metric_value')}
                                edge="end"
                            >
                                <ClearIcon fontSize='small' />
                            </IconButton> : null
                    }
                    {showPercentage ? <InputAdornment position="end">%</InputAdornment> : null}
                </>
            }}
        />
    )
}