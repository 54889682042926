import React from "react";
import TmpTSSSales from "../TmpTSSSales";
import SelectorProvider from "../common/providers/selectorProvider";
import { dashboardDefinition } from "./dashboardDefinition";

export default function PersonalShopper() {
    const additionalMetricBlacklist = [
        'actual_mall_foot_traffic',
        'actual_sm_store_foot_traffic',
        'actual_space_nsa',
        'actual_catchment_rate',
        'actual_conversion_rate',
    ]

    const toplineMetricItems = [
        'actual_sales',
        'actual_transactions',
        'basket_size',
        'actual_smac_sales',
        'actual_units_sold',
        'actual_units_sold_per_txn',
    ]

    return (
        <SelectorProvider defaults={{
            "viewBy": 'channel',
            "metric": "actual_sales",
            "period": "mtd"
        }}>
            <TmpTSSSales
                additionalParams={{ cm_channel_desc: 'Personal Shopper' }}
                title="Personal Shopper Performance"
                componentProps={{
                    topLine: {
                        componentProps: {
                            metricFilter: {
                                optionsFilter: (option, viewBy) => {
                                    if (additionalMetricBlacklist?.includes(option.value)) {
                                        return false
                                    }
                                    return true
                                }
                            }
                        }
                    },
                    topLineDataProvider: {
                        topLineMetricItems: toplineMetricItems,
                        enableMetricPlan: toplineMetricItems,
                        enabledVSPlan: ['actual_sales'],
                        enabledVSLastYear: toplineMetricItems,
                    },
                    salesPerformancePerGroup: {
                        enabled: false
                    },
                    storePerformance: {
                        enableFilterDisplay: false,
                        selectedRows: ['Personal Shopper'],
                        enableBranchLink: false
                    }
                }}
                moduleDefinition={dashboardDefinition}
            />
        </SelectorProvider>
    )
}