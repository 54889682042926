import {TABLE_DIM_BRANCH, TABLE_DIM_SUBDEPT, TABLE_FCT_RA, TABLE_FCT_TSS, TABLE_DIM_CHANNEL, VIEW_FCT_RA_TSS} from "./hooks/constants";

export const dashboardName = 'Sales & Operations'
export const DASHBOARD_PATH = 'sm-store/sales-and-operations'

export const dashboardDatasource = [];


export const TAB_SELECTOR = 'tab_selector';
export const TAB_SALES = 'tab_sales';
export const TAB_OPERATIONS = 'tab_operations';
export const TAB_MANPOWER = 'tab_manpower';

export const GENERAL_TOPLINE = 'general_topline';
export const GENERAL_BU_DEPT_PERFORMANCE = 'general_bu_dept_performance';
export const GENERAL_STORE_PERFORMANCE = 'general_store_performance';
export const GENERAL_MTD = 'general_mtd';
export const GENERAL_YTD = 'general_ytd';

export const GENERAL_ZONE_STORE_TOGGLE = 'zone_store_toggle';

export const SALES_TOPLINE = 'sales_topline';
export const SALES_BU_DEPT_PERFORMANCE = 'sales_bu_dept_performance';
export const SALES_STORE_PERFORMANCE = 'sales_store_performance';

export const SALES_METRIC_SALES = 'sales_metric_sales';
export const SALES_METRIC_TRANSACTIONS = 'sales_metric_transactions';
export const SALES_METRIC_TRANSACTIONS_PLAN = 'sales_metric_transactions_plan';
export const SALES_METRIC_ATV = 'sales_metric_atv';
export const SALES_METRIC_ATV_PLAN = 'sales_metric_atv_plan';
export const SALES_METRIC_UNITS_SOLD = 'sales_metric_units_sold';

export const OPERATIONS_TOPLINE = 'operations_topline';
export const OPERATIONS_BU_DEPT_PERFORMANCE = 'operations_bu_dept_performance';
export const OPERATIONS_STORE_PERFORMANCE = 'operations_store_performance';

export const OPERATIONS_METRIC_CATCHMENT_RATE = 'operations_metric_catchment_rate';
export const OPERATIONS_METRIC_CONVERSION_RATE = 'operations_metric_conversion_rate';
export const OPERATIONS_METRIC_SPACE_PRODUCTIVITY = 'operations_metric_space_productivity';

export const MANPOWER_TOPLINE = 'manpower_topline';
export const MANPOWER_BU_DEPT_PERFORMANCE = 'manpower_bu_dept_performance';
export const MANPOWER_STORE_PERFORMANCE = 'manpower_store_performance';

export const MANPOWER_METRIC_TOTAL_MANPOWER_PRODUCTIVITY = 'manpower_metric_total_manpower_productivity';
export const MANPOWER_METRIC_SELLING_MANPOWER_PRODUCTIVITY = 'manpower_metric_selling_manpower_productivity';
export const MANPOWER_METRIC_NON_SELLING_MANPOWER_PRODUCTIVITY = 'manpower_metric_non_selling_manpower_productivity';
export const MANPOWER_METRIC_PERSONAL_SHOPPER_MANPOWER_PRODUCTIVITY = 'manpower_metric_personal_shopper_manpower_productivity';


export const RA_DATA_SOURCES_FILTER = 'ra_data_source_filter';

export const dashboardComponents = [
    {
        component: "Tab Selector",
        id: TAB_SELECTOR,
        subcomponents: [
            {
                component: "Sales Tab",
                id: TAB_SALES,
                name: "Sales",
            },
            {
                component: "Operations Tab",
                id: TAB_OPERATIONS,
                name: "Operations",
            },
            {
                component: "Manpower Tab",
                id: TAB_MANPOWER,
                name: "Manpower",
            }
        ]
    },
    {
        component: "General Dashboard Sections",
        id: "general_dashboard_sections",
        readOnly: true,
        subcomponents: [
            {
                component: "General Topline",
                id: GENERAL_TOPLINE,
                name: "Topline",
            },
            {
                component: "Business Unit/Department Performance",
                id: GENERAL_BU_DEPT_PERFORMANCE,
                name: "Business Unit/Department Performance",
            },
            {
                component: "Store Performance",
                id: GENERAL_STORE_PERFORMANCE,
                name: "Store Performance",
            },
            // {
            //     component: "MTD",
            //     id: GENERAL_MTD,
            //     name: "MTD",
            // },
            // {
            //     component: "YTD",
            //     id: GENERAL_YTD,
            //     name: "YTD",
            // }
        ]
    },
    {
        component: "Sales Tab",
        id: 'sales_tab_readonly',
        readOnly: true,
        subcomponents: [
            {
                component: "Sections",
                id: "sales_tab_sections",
                readOnly: true,
                subcomponents: [
                    {
                        component: "Sales Topline",
                        id: SALES_TOPLINE,
                        name: "Topline",
                    },
                    {
                        component: "Business Unit/Department Performance",
                        id: SALES_BU_DEPT_PERFORMANCE,
                        name: "Business Unit/Department Performance",
                    },
                    {
                        component: "Store Performance",
                        id: SALES_STORE_PERFORMANCE,
                        name: "Store Performance",
                    }
                ]
            },
            {
                component: "Metrics",
                id: "sales_metrics",
                readOnly: true,
                subcomponents: [
                    {
                        component: "Sales",
                        id: SALES_METRIC_SALES,
                        name: "Sales",
                    },
                    {
                        component: "Transactions",
                        id: SALES_METRIC_TRANSACTIONS,
                        name: "Transactions",
                    },
                    {
                        component: "ATV",
                        id: SALES_METRIC_ATV,
                        name: "ATV",
                    },
                    {
                        component: "Units Sold",
                        id: SALES_METRIC_UNITS_SOLD,
                        name: "Units Sold",
                    }
                ]
            },
        ]
    },

    {
        component: "Operations Tab",
        id: 'operations_tab_readonly',
        readOnly: true,
        subcomponents: [
            {
                component: "Sections",
                id: "operations_tab_sections",
                readOnly: true,
                subcomponents: [
                    {
                        component: "Operations Topline",
                        id: OPERATIONS_TOPLINE,
                        name: "Topline",
                    },
                    {
                        component: "Business Unit/Department Performance",
                        id: OPERATIONS_BU_DEPT_PERFORMANCE,
                        name: "Business Unit/Department Performance",
                    },
                    {
                        component: "Store Performance",
                        id: OPERATIONS_STORE_PERFORMANCE,
                        name: "Store Performance",
                    }
                ]
            },
            {
                component: "Metrics",
                id: "operations_metrics",
                readOnly: true,
                subcomponents: [
                    {
                        component: "Catchment Rate",
                        id: OPERATIONS_METRIC_CATCHMENT_RATE,
                        name: "Catchment Rate",
                    },
                    {
                        component: "Conversion Rate",
                        id: OPERATIONS_METRIC_CONVERSION_RATE,
                        name: "Conversion Rate",
                    },
                    {
                        component: "Space Productivity",
                        id: OPERATIONS_METRIC_SPACE_PRODUCTIVITY,
                        name: "Space Productivity",
                    }
                ]
            }
        ]
    },

    
    {
        component: "Manpower Tab",
        id: 'manpower_tab_readonly',
        readOnly: true,
        subcomponents: [
            {
                component: "Sections",
                id: "manpower_tab_sections",
                readOnly: true,
                subcomponents: [
                    {
                        component: "Manpower Topline",
                        id: MANPOWER_TOPLINE,
                        name: "Topline",
                    },
                    {
                        component: "Business Unit/Department Performance",
                        id: MANPOWER_BU_DEPT_PERFORMANCE,
                        name: "Business Unit/Department Performance",
                    },
                    {
                        component: "Store Performance",
                        id: MANPOWER_STORE_PERFORMANCE,
                        name: "Store Performance",
                    }
                ]
            },
            {
                component: "Metrics",
                id: "manpower_metrics",
                readOnly: true,
                subcomponents: [
                    {
                        component: "Total Manpower Productivity",
                        id: MANPOWER_METRIC_TOTAL_MANPOWER_PRODUCTIVITY,
                        name: "Total Manpower Productivity",
                    },
                    {
                        component: "Selling Productivity",
                        id: MANPOWER_METRIC_SELLING_MANPOWER_PRODUCTIVITY,
                        name: "Selling Productivity",
                    },
                    {
                        component: "Non-Selling Productivity",
                        id: MANPOWER_METRIC_NON_SELLING_MANPOWER_PRODUCTIVITY,
                        name: "Non-Selling Productivity",
                    },
                    {
                        component: "Personal Shopper Productivity",
                        id: MANPOWER_METRIC_PERSONAL_SHOPPER_MANPOWER_PRODUCTIVITY,
                        name: "Personal Shopper Productivity",
                    },
                ]
            }
        ]
    },
];

const dashboardDataSources = [
    {
        table: {name: TABLE_DIM_BRANCH},
        data_filter_columns: [
            {name: 'branch_key', type: 'int', label: 'Branch Key'},
            {name: 'branch_name', type: 'string', label: 'Branch Name'},
            {name: 'region', type: 'string', label: 'Region/Zone'},
        ],
        columns: [
            {name: 'branch_key'},
            {name: 'branch_name'},
            {name: 'region'},
        ]
    },
    {
        table: {name: TABLE_DIM_SUBDEPT},
        data_filter_columns: [
            {name: 'bu_grp', type: 'string', label: 'Business Unit Group'},
            {name: 'bu_desc', type: 'string', label: 'Business Unit'},
            {name: 'dept_desc', type: 'string', label: 'Department'},
            {name: 'subdept_desc', type: 'string', label: 'Sub-department'},
        ],
        columns: [
            {name: 'bu_grp'},
            {name: 'bu_desc'},
            {name: 'dept_desc'},
            {name: 'subdept_desc'},
        ]
    },
    {
        table: { name: TABLE_DIM_CHANNEL},
        data_filter_columns: [
            {name: 'channel_desc', type: 'string', label: 'Channel'}
        ],
        columns: [
            {name: 'channel_desc'}
        ]
    }
];



export const dashboardDefinition = {
    name: dashboardName,
    data_sources: dashboardDataSources,
    entry_points: [
        DASHBOARD_PATH,
    ],
    owners: [
        "jethro.s.guzman@smretail.com"
    ],
    path: DASHBOARD_PATH,  // this is the main path of the dashboard
    components: dashboardComponents,
    blacklisted_components: [
        RA_DATA_SOURCES_FILTER,
    ],
    context: {
        table_source_by_tag: {
            'sales': TABLE_FCT_TSS
        },
        partition_date_column: 'transaction_date',
        branch_name_column: 'branch_name',
        operations_filters: {
            org_env_code: 'DSP',
        }
    }
}



export const retailAffiliatesDashboardComponents = [
    {
        component: "Tab Selector",
        id: TAB_SELECTOR,
        subcomponents: [
            {
                component: "Sales Tab",
                id: TAB_SALES,
                name: "Sales",
            },
            {
                component: "Operations Tab",
                id: TAB_OPERATIONS,
                name: "Operations",
                default: false
            },
            {
                component: "Manpower Tab",
                id: TAB_MANPOWER,
                name: "Manpower",
                default: false
            }
        ],
        default: false
    },
    {
        component: "General Dashboard Sections",
        id: "general_dashboard_sections",
        readOnly: true,
        subcomponents: [
            {
                component: "General Topline",
                id: GENERAL_TOPLINE,
                name: "Topline",
            },
            {
                component: "Business Unit/Department Performance",
                id: GENERAL_BU_DEPT_PERFORMANCE,
                name: "Business Unit/Department Performance",
            },
            {
                component: "Store Performance",
                id: GENERAL_STORE_PERFORMANCE,
                name: "Store Performance",
            },
            // {
            //     component: "MTD",
            //     id: GENERAL_MTD,
            //     name: "MTD",
            // },
            // {
            //     component: "YTD",
            //     id: GENERAL_YTD,
            //     name: "YTD",
            // }
        ]
    },
    {
        component: "Sales Tab",
        id: 'sales_tab_readonly',
        readOnly: true,
        subcomponents: [
            {
                component: "Sections",
                id: "sales_tab_sections",
                readOnly: true,
                subcomponents: [
                    {
                        component: "Sales Topline",
                        id: SALES_TOPLINE,
                        name: "Topline",
                    },
                    {
                        component: "Business Unit/Department Performance",
                        id: SALES_BU_DEPT_PERFORMANCE,
                        name: "Business Unit/Department Performance",
                    },
                    {
                        component: "Store Performance",
                        id: SALES_STORE_PERFORMANCE,
                        name: "Store Performance",
                    }
                ]
            },
            {
                component: "Metrics",
                id: "sales_metrics",
                readOnly: true,
                subcomponents: [
                    {
                        component: "Sales",
                        id: SALES_METRIC_SALES,
                        name: "Sales",
                    },
                    {
                        component: "Transactions",
                        id: SALES_METRIC_TRANSACTIONS,
                        name: "Transactions",
                    },
                    {
                        component: "ATV",
                        id: SALES_METRIC_ATV,
                        name: "ATV",
                    },
                    {
                        component: "Units Sold",
                        id: SALES_METRIC_UNITS_SOLD,
                        name: "Units Sold",
                    }
                ]
            },
        ]
    },

    {
        component: "Operations Tab",
        id: 'operations_tab_readonly',
        readOnly: true,
        default: false,
    },
];
export const retailAffiliateDashboardDefinition = {
    ...dashboardDefinition,
    name: 'Retail Affiliate Sales',
    components: retailAffiliatesDashboardComponents,
    blacklisted_components: [
        TAB_SELECTOR,
        GENERAL_ZONE_STORE_TOGGLE,
        SALES_METRIC_TRANSACTIONS_PLAN,
        SALES_METRIC_ATV_PLAN,
    ],
    context: {
        ...dashboardDefinition.context,
        table_source_by_tag: {
            'sales': VIEW_FCT_RA_TSS
        },
        partition_date_column: 'transaction_date',
        branch_name_column: 'branch_name_standardized',
        bu_to_department_auto_flatten: true,
        // operations_filters: {
        //     org_env_code: 'TWP',
        // },
        // sales_filters: {
        //     org_env_code: 'TWP',
        // }
    },
    selectorDefaults: {
        zone_store_toggle: 'store'
    }
}


export const raAceHardwareDashboardDefinition = {
    ...retailAffiliateDashboardDefinition,
    name: 'Ace Hardware Sales',
    context: {
        ...retailAffiliateDashboardDefinition.context,
        sales_filters: {
            // org_env_code: ['WAP', 'DSP'],
            // org_code: ['WAP', 'DSP'],
            bu_desc: "Ace Hardware/Workshop"

        },
        operations_filters: {
            // org_env_code: 'WAP',
            bu_desc: "Ace Hardware/Workshop"
        },
    }
}

export const raSportsCentralDashboardDefinition = {
    ...retailAffiliateDashboardDefinition,
    name: 'Sports Central Sales',
    context: {
        ...retailAffiliateDashboardDefinition.context,
        sales_filters: {
            // org_env_code: ['SCP', 'DSP'],
            // org_code: ['SCP', 'DSP'],
            bu_desc: "Sports Central"
        },
        operations_filters: {
            // org_env_code: 'SCP',
            bu_desc: "Sports Central"
        },
    }
}

export const raToyKingdomDashboardDefinition = {
    ...retailAffiliateDashboardDefinition,
    name: 'Toy Kingdom Sales',
    context: {
        ...retailAffiliateDashboardDefinition.context,
        sales_filters: {
            // org_env_code: ['TWP', 'DSP'],
            // org_code: ['TWP', 'DSP'],
            bu_desc: 'Toyworld'
        },
        operations_filters: {
            bu_desc: 'Toyworld'
            // org_env_code: 'TWP',
        },
    }
}

export const raBabyCompanyDashboardDefinition = {
    ...retailAffiliateDashboardDefinition,
    name: 'Baby Company Sales',
    context: {
        ...retailAffiliateDashboardDefinition.context,
        sales_filters: {
            // org_env_code: ["TWP", "NCP", "DSP"],
            // org_code: ["TWP", "NCP", "DSP"],
            dept_desc_standardized: "Babies Accs & Furn"
        },
        operations_filters: {
            // org_env_code: ["TWP", "NCP"],
            // org_code: ["TWP", "NCP"],
            dept_desc_standardized: "Babies Accs & Furn"
        }
    }
}

export const raPetExpressDashboardDefinition = {
    ...retailAffiliateDashboardDefinition,
    name: 'Pet Express Sales',
    context: {
        ...retailAffiliateDashboardDefinition.context,
        sales_filters: {
            // org_env_code: ['TWP', 'DSP'],
            // org_code: ['TWP', 'DSP'],
            dept_desc_standardized: "Pet Express"
        },
        operations_filters: {
            // org_env_code: "TWP",
            // org_code: "TWP",
            dept_desc_standardized: "Pet Express"
        }
    }
}

export const raSuppliesStationDashboardDefinition = {
    ...retailAffiliateDashboardDefinition,
    name: 'Supplies Station Sales',
    context: {
        ...retailAffiliateDashboardDefinition.context,
        sales_filters: {
            // org_env_code: ["TWP", "SSP", "DSP"],
            // org_code: ["TWP", "SSP", "DSP"],
            bu_desc: "Supplies Station"
        },
        operations_filters: {
            // org_env_code: ["TWP", "SSP", "DSP"],
            // org_code: ["TWP", "SSP"],
            bu_desc: "Supplies Station"
        }
    }
}

export const raKulturaDashboardDefinition = {
    ...retailAffiliateDashboardDefinition,
    name: 'Kultura Sales',
    context: {
        ...retailAffiliateDashboardDefinition.context,
        sales_filters: {
            // org_env_code: ['KSP', "DSP"],
            // org_code: ['KSP', "DSP"],
            bu_desc: "Kultura"
        },
        operations_filters: {
            bu_desc: "Kultura"
            // org_env_code: 'KSP',
        },
    }
}

export const raSurplusDashboardDefinition = {
    ...retailAffiliateDashboardDefinition,
    name: 'Surplus Sales',
    context: {
        ...retailAffiliateDashboardDefinition.context,
        sales_filters: {
            // org_env_code: ['SMP', 'DSP'],
            // org_code: ['SMP', 'DSP'],
            bu_desc: 'Surplus Shop'
        },
        operations_filters: {
            bu_desc: 'Surplus Shop'
            // org_env_code: 'SMP',
        },
    }
}

export const raSACIDashboardDefinition = {
    ...retailAffiliateDashboardDefinition,
    name: 'SACI Sales',
    context: {
        ...retailAffiliateDashboardDefinition.context,
        sales_filters: {
            // org_env_code: 'SAP',
            // org_code: 'SAP'
            bu_desc: "Star Appliance"
        },
        operations_filters: {
            // org_env_code: 'SAP',
            bu_desc: "Star Appliance"
        },
    }
}
