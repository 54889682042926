import React from 'react'
import ReactEcharts from "echarts-for-react";
import { normalizeValueForColor, showData } from '../utils';
import { Box } from '@mui/material';
import ChartWithShowAll from '../common/ChartWithShowAll';

export default function MostActiveUsersChart({ data }) { 
    const sortedData = data.sort((a, b) => b.view_count - a.view_count);
    const [showAll, setShowAll] = React.useState(false);    
    const newData = showData(showAll, sortedData);
    const xAxisData = newData.map(item => item.user).reverse();
    const seriesData = newData.map((item,index) => ({
        value : item.view_count,
        distinct_count: item.module_distinct_count,
        email: item.user,
        itemStyle: {
            color : newData?.length <= 3 ? '#000099' : `hsl(240, 100%, ${normalizeValueForColor(item.view_count, newData)}%)`
        }
    })).reverse();

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }, 
            formatter: function (params) {
                const dataIndex = params[0].dataIndex;
                return `Email: ${seriesData[dataIndex].email} <br>
                        View count: ${seriesData[dataIndex].value} <br>
                        Distinct Module count: ${seriesData[dataIndex].distinct_count}`;
            }
        },
        legend: {},
        grid: {
            containLabel: true,
            top: '3%',
            left: '5%',
            right: '3%',
            bottom: '3%'
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: xAxisData,
            axisLabel: {
                formatter: function (value) {
                    const display = value.split('@')[0] || value;
                    return display;
                }
            }
        },
        series: [
            {
                type: 'bar',
                data: seriesData,
                barMaxWidth: 30
            }
        ]
    };

    return <ChartWithShowAll length={ data?.length} showAll={showAll} setShowAll={setShowAll}>
        <Box sx={{ height: '25rem', overflow: 'auto' }}>
            <ReactEcharts option={option} notMerge={true} lazyUpdate={true} style={{ width: '100%', height: !showAll ? '25rem' : '50rem' }} /> 
        </Box>
    </ChartWithShowAll>
}