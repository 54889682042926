// import ManpowerContent from "./Manpower";
import OperationsContent from "./Operations";
import SalesContent from "./Sales";
import LoadingComponent from "../../../common/components/LoadingComponent";
import ManpowerContent from "./Manpower";


export default function TabContent({ selectedTab }) {

    switch (selectedTab) {
        case 'operations':
            return <OperationsContent selectedTab={selectedTab} />;
        case 'manpower':
            return <ManpowerContent selectedTab={selectedTab} />;
        case 'sales':
            return <SalesContent  selectedTab={selectedTab}/>
        default:
            return <>
                <LoadingComponent/>
            </>
    }

}