import './App.css';
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'
import { useEffect, useState } from 'react';
import Login from './public/Login'
import CustomerAnalytics from './private/experimental/CustomerAnalytics';
import CampaignCreation from './private/campaign/CampaignCreation';
import CampaignList from './private/campaign/CampaignList';
import ChatEnabledCustomerAnalytics from "./private/experimental/ChatEnabledCustomerAnalytics";
import CuratedAnalytics from './private/experimental/CuratedAnalytics';
import LocationAndMarketAnalytics from './private/customDashboards/StoresAnalytics/Instances/LocationAndMarketAnalytics'
import DataMonitoring from './private/experimental/DataMonitoring';
import Dashboard from './private/customDashboards/Dashboard';
import UserManagement from './private/admin/UserManagement';
import GroupManagement from './private/admin/GroupManagement';
import { WindowSizeContext } from './common/context/WindowSizeContext';
import LookerStudioEmbed from './private/experimental/LookerStudioEmbed';
import ChartGallery from './private/selfServiceDashboard/ChartGallery';
import { ModulePermissionsContext } from './common/context/ModulePermissionsContext';
import DashboardEditor from './private/selfServiceDashboard/DashboardEditor';
import DataExplorer from './private/selfServiceDashboard/DataExplorer';
import ModulePermission from './private/admin/ModulePermission';
import { LoginContext } from './common/context/LoginContext';
import AdminManagement from './private/admin/AdminManagement/AdminManagement';
// additional import
import Main from './Main';
import LoginHistory from "./private/admin/LoginHistory";
import BranchView from './private/customDashboards/common/presentation/branchView';
import BusinessUnitView from './private/customDashboards/common/presentation/businessUnitView';
import ZoneView from './private/customDashboards/common/presentation/zoneView';
import { isLoggedIn } from './common/utils';
import EnvironmentSettingsProvider from "./common/providers/EnvironmentSettingsProvider";
import { NoPermissionAccess } from "./common/components/NoPermissionAccess";
import { DASHBOARD_GROUPS } from "./Constants/customDashboards";
import PrivatePathComponent from "./common/components/privatePathComponent";
import PermissionManagement from './private/admin/PermissionManagement';
import OpsPermissionManagement from './private/admin/PermissionManagement/OpsPermissionManagement';
import BlacklistedComponentsProvider from "./private/customDashboards/common/providers/blacklistedComponentsProvider";
import WAFManagement from './private/admin/WAFManagement';
import DashboardUsageMonitoring from './private/admin/DashboardUsageMonitoring';


function NoMatchRoute() {
	return <h5>Invalid URL</h5>
}

function ChannelAnalytics() {
	return <h5>Channel Analytics</h5>
}

function CategoryAnalytics() {
	return <h5>Category Analytics</h5>
}

function hasAccess(strModule, isParent, data) {
	if (isParent) {
		const parentLinks = data.map(module => module?.split('/')?.[0])
		return parentLinks?.length > 0 && parentLinks.includes(strModule)
	} else {
		return data?.length > 0 && data.includes(strModule)
	}
}


function PrivateRoute() {
	return isLoggedIn() ? <Outlet /> : <Navigate to="/login" />
}

function App() {
	const MOBILE_WIDTH = 576 //in px
	const [modules, setModules] = useState([])
	const [loginDetails, setLoginDetails] = useState([])
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
		isOnMobile: window.innerWidth < MOBILE_WIDTH
	});

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
				isOnMobile: window.innerWidth < MOBILE_WIDTH
			});
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	function setModuleList(val) {
		setModules([... new Set(val)])
	}

	const queryClient = new QueryClient()
	const defaultRoutePath = modules?.length > 0 && modules.includes('dashboard') ? "dashboard" : '/' + modules[0]


	return (
		<QueryClientProvider client={queryClient}>
			<EnvironmentSettingsProvider>
				<LoginContext.Provider value={{
					loginDetails: loginDetails,
					setLoginDetails: (val) => setLoginDetails(val)
				}}>
					<ModulePermissionsContext.Provider value={{
						moduleList: modules,
						setModuleList: setModuleList
					}}>
						<BlacklistedComponentsProvider>
						<WindowSizeContext.Provider value={windowSize}>
							<Routes>
								<Route path="/" element={<PrivateRoute />}>
									<Route element={<Main />}>
										<Route path="/" element={<Navigate to={defaultRoutePath} replace />} />


										{
											DASHBOARD_GROUPS.flatMap(
												group => group.contents.filter(
													content => (!!content.path || !!content.pathForRoute) && !!content.component
												).map(
													content => {
														// if we have a specific path for routing, use it instead; otherwise, cleanup the path for the dashboard
														const cleanedPath = content.pathForRoute || content.path.trim().replace(/^\/+|\/+$/g, '')  // remove trailing slashes and empty spaces
														const component =
															<PrivatePathComponent>{content.component}</PrivatePathComponent>
														return <Route path={cleanedPath} element={component} key={cleanedPath} />
													}
												)
											)
										}

										{/*
							 The following are custom routes that cannot yet be handled by DASHBOARD_GROUPS constant declaration.
							 They could be sub dashboards or dashboards within a dashboards
							*/}
										<Route path="sbu/sales/branch-view/:branch/:branch_id/:month/:year/:period" element={hasAccess('sbu/sales', false, modules) ? <BranchView /> : <NoPermissionAccess />} />

										<Route path="sbu/sales/bu-view/:business_unit/:month/:year/:period" element={hasAccess('sbu/sales', false, modules) ? <BusinessUnitView /> : <NoPermissionAccess />} />

										<Route path="sbu/sales/bu-view/:business_unit/:period" element={hasAccess('sbu/sales', false, modules) ? <BusinessUnitView /> : <NoPermissionAccess />} />
										<Route path="sbu/sales/zone-view/:zone/:month/:year/:period" element={hasAccess('sbu/sales', false, modules) ? <ZoneView /> : <NoPermissionAccess />} />

										{/*
							The following are non-dashboard routes
							*/}

										<Route path="customer-analytics" element={hasAccess('customer-analytics', false, modules) ? <CustomerAnalytics /> : <NoPermissionAccess />} />
										<Route path="chat-enabled-customer-analytics" element={hasAccess('chat-enabled-customer-analytics', false, modules) ? <ChatEnabledCustomerAnalytics /> : <NoPermissionAccess />} />
										<Route path="channel-analytics" element={hasAccess('channel-analytics', false, modules) ? <ChannelAnalytics /> : <NoPermissionAccess />} />
										<Route path="category-analytics" element={hasAccess('category-analytics', false, modules) ? <CategoryAnalytics /> : <NoPermissionAccess />} />
										<Route path="campaign-creation" element={hasAccess('campaign-creation', false, modules) ? <CampaignCreation /> : <NoPermissionAccess />} />
										<Route path="campaign-list" element={hasAccess('campaign-list', false, modules) ? <CampaignList /> : <NoPermissionAccess />} />
										<Route path="location-analysis" element={hasAccess('location-analytics/analysis', false, modules) ? <LocationAndMarketAnalytics /> : <NoPermissionAccess />} />

										<Route path="others/looker-studio-test" element={hasAccess('others/looker-studio-test', false, modules) ? <LookerStudioEmbed /> : <NoPermissionAccess />} />
										<Route path="others/data-monitoring" element={hasAccess('others/data-monitoring', false, modules) ? <DataMonitoring /> : <NoPermissionAccess />} />
										<Route path="others/curated-analytics" element={hasAccess('others/curated-analytics', false, modules) ? <CuratedAnalytics /> : <NoPermissionAccess />} />
										<Route path="chart-gallery" element={hasAccess('chart-gallery', false, modules) ? <ChartGallery /> : <NoPermissionAccess />} />
										<Route path="dashboard-editor" element={hasAccess('dashboard-editor', false, modules) ? <DashboardEditor /> : <NoPermissionAccess />} />
										<Route path="data-explorer" element={hasAccess('data-explorer', false, modules) ? <DataExplorer /> : <NoPermissionAccess />} />
										<Route path="campaign-creation" element={hasAccess('campaign-creation', false, modules) ? <CampaignCreation /> : <NoPermissionAccess />} />
										<Route path="user-management" element={hasAccess('user-management', false, modules) ? <UserManagement /> : <NoPermissionAccess />} />
										<Route path="group-management" element={hasAccess('group-management', false, modules) ? <GroupManagement /> : <NoPermissionAccess />} />
										<Route path="module-permission" element={hasAccess('module-permission', false, modules) ? <ModulePermission /> : <NoPermissionAccess />} />
										<Route path="dashboard-usage-monitoring" element={hasAccess('dashboard-usage-monitoring', false, modules) ? <DashboardUsageMonitoring /> : <NoPermissionAccess />} />
										<Route path="login-history" element={hasAccess('user-management', false, modules) ? <LoginHistory /> : <NoPermissionAccess />} />
										<Route path="permission-management" element={hasAccess('permission-management', false, modules) ? <PermissionManagement /> : <NoPermissionAccess />} />
										<Route path="ops-permission-management/:toggle_group/:email/:id" element={hasAccess('permission-management', false, modules) ? <OpsPermissionManagement /> : <NoPermissionAccess />} />
										<Route path="admin-management" element={<AdminManagement />} />
										<Route path="dashboard" element={<Dashboard />} />
										<Route path="waf-management" element={hasAccess('waf-management', false, modules) ? <WAFManagement /> : <NoPermissionAccess />} />
										<Route
											path="*"
											element={<NoMatchRoute />}
										/>
									</Route>
								</Route>
								<Route path="/login" element={<Login />} />
							</Routes>
						</WindowSizeContext.Provider>
						</BlacklistedComponentsProvider>

					</ModulePermissionsContext.Provider>
				</LoginContext.Provider>
			</EnvironmentSettingsProvider>
		</QueryClientProvider>
	);
}

export default App;
