
export const dashboardName = 'SMAC Performance'
export const DASHBOARD_PATH = 'retail-dashboard/smac-performance'


export const dashboardDefinition = {
    name: dashboardName,
    entry_points: [
        'retail-dashboard/smac-performance',
    ],
    owners: [
        "raphaelchristopher.a.sepnio@smretail.com"
    ],
    path: DASHBOARD_PATH
}