import { Grid, Typography } from "@mui/material";
import Section from "../../../../common/components/Dashboard/Section";
import { useSelectorValue } from "../../common/providers/selectorProvider";
import BasePerformanceTable from "./BasePerformanceTable";
import { useMemo } from "react";
import { NOT_APPLICABLE_METRICS } from "../hooks/constants";

export default function BasePerformanceSection({ 
    title, // i.e. Zero to Store Performance
    selectorsOrFilters,
    categoryName, 
    firstColumnCellFormat, 
    tableId, // i.e. zeroToStorePerformance
    data = [],
    information,
    isLoading,
    orderArray,
    enableRowSelection,
    rowSelectionDepth,
    onSelectRow,
    customGridItemStyle,
    isRowExpanded = false,
}) {

    const metric = useSelectorValue('metric') || 'sales';
    const showNotApplicableMessage = tableId === 'buDeptPerformance' && NOT_APPLICABLE_METRICS.includes(metric);

    const notApplicableMessage = <Grid item xs={12} style={{ textAlign: 'center',  padding: '1rem' }}> 
        <Typography sx={{ fontSize: "1rem", color: "black", textAlign: "center" }} > Business Unit to Department Performance is not applicable for this metric.</Typography>    
    </Grid>;

    const content = useMemo(() => {
        if(showNotApplicableMessage) {
            return notApplicableMessage;
        }
        return <BasePerformanceTable
            categoryName={categoryName}
            firstColumnCellFormat={firstColumnCellFormat}
            tableId={tableId}
            data={data}
            isLoading={isLoading}
            enableRowSelection={enableRowSelection}
            rowSelectionDepth={rowSelectionDepth}
            onSelectRow={onSelectRow}
            orderArray={orderArray}
            isRowExpanded={isRowExpanded}
        />
    }, [showNotApplicableMessage, data])

    return <Section
        title={title}
        information={information}
        customGridItemStyle={customGridItemStyle}
        selectorsOrFilters={selectorsOrFilters}>
        { content }
    </Section>
}