
import React from 'react';
import { useSelectorContext, useSelectorValue } from '../../common/providers/selectorProvider';
import ToggleSelector from '../../common/presentation/selectors/toggleSelector';

export default function CardSelector() {
    const card_group = useSelectorValue('card_group') || 'SMAC';
    const TOGGLE_OPTIONS = [
        { label: "SMAC", value: "SMAC" },
        { label: "SLP", value: "SLP" }
    ]
    
    const {setValue: setSelectorValue} = useSelectorContext()
    const setCard = (value) => setSelectorValue('card_group', value)

    const onToggleChange = (event, val) => {
        if (val !== null) {
            setCard(val);
        }
    };

    return <ToggleSelector
        options={TOGGLE_OPTIONS}
        value={card_group}
        onChange={onToggleChange}
        additionalStyles={{ textTransform: 'none' }}
        isPillView={true}
    />
}