import React from 'react'
import ReactEcharts from "echarts-for-react";
import { Box } from '@mui/system';
import { normalizeValueForColor, showData } from '../utils/index.js';
import ChartWithShowAll from '../common/ChartWithShowAll.jsx';

export default function MostActiveModuleChart({ data }) { 
    const dashboardRegistry = window.dashboardRegistry;
    const sortedData = data.filter(item => item.module !== '/dashboard').sort((a, b) => b.view_count - a.view_count);
    const [showAll, setShowAll] = React.useState(false);    
    const newData = showData(showAll, sortedData);
    const xAxisData = newData.map(item => item.module).reverse();
    const seriesData = newData.map((item,index) => ({
        value : item.view_count,
        itemStyle: {
            color :  newData?.length <= 3 ? '#000099' : `hsl(240, 100%, ${normalizeValueForColor(item.view_count, newData)}%)`
        }
    })).reverse();

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }, 
            formatter: function (params) {
                const dataIndex = params[0].dataIndex;
                const viewCount = seriesData[dataIndex].value;
                return `No. of times visited: ${viewCount} `;
            }
        },
        legend: {},
        grid: {
            containLabel: true,
            top: '3%',
            left: '5%',
            right: '3%',
            bottom: '3%'
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: xAxisData,
            axisLabel: {
                formatter: function (value) {
                    const labelDisplay = dashboardRegistry[value]?.name || value;
                    return labelDisplay;
                }
            }
        },
        series: [
            {
                type: 'bar',
                data: seriesData,
                barMaxWidth: 30
            }
        ]
    };

    return <ChartWithShowAll length={ data?.length} showAll={showAll} setShowAll={setShowAll}>
        <Box sx={{ height: '25rem', overflow: 'auto' }}>
            <ReactEcharts option={option} notMerge={true} lazyUpdate={true} style={{ width: '100%', height: !showAll ? '25rem' : '50rem' }} /> 
        </Box>
    </ChartWithShowAll>
}