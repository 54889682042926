import React, {useState} from 'react';
import { useSMACMembersByCardType } from "../../hooks";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { orderArray, xAxisValuesOrder } from "../../constants";
import { getDefaultPercentagePointColor } from "../util";
import HorizontalBarGroupGraph from '../HorizontalBarGroupGraph';
import { useSelectorValue } from '../../../common/providers/selectorProvider';
import { sortArrayBasedOnGivenArrayWithoutKey } from '../../../../../common/utils';


const LEGEND_COLOR_MAP = {
    'Renewal': '#B0C5D4',
    'Existing': '#7E95C7',
    'New': '#84BFF7',
    'Inactive': '#AFAFAF',
    'Active': '#4E88DD',
    'Registered': '#4E88DD',
    'Unregistered': '#AFAFAF',
}


const LabelMapping = {
    'SMAC': 'Blue',
}

const sortKeyMapping = {
    'acquisition': 'Existing',
    'registration': 'Registered',
    'usage': 'Active',
}
export default function SMACCardType({showMoreBtn}) {
    const [toggleShowMore, setToggleShowMore] = useState(false);
    const selectedTab = useSelectorValue('mainTab')
    const card_group = useSelectorValue('card_group') || 'SMAC'    
    
    const isSLPSelected = card_group === 'SLP' ;

    const { asBarData, isLoading } = useSMACMembersByCardType({});
    const { asBarData: asBarDataLY, isLoading: isLoadingLY } = useSMACMembersByCardType({ lastYear: true });
    
    const sortKeysByHighestExistingValue = (data) => {
        return Object.keys(data).sort((a, b) => {
            const existingA = data[a]?.[sortKeyMapping[selectedTab]] || 0;
            const existingB = data[b]?.[sortKeyMapping[selectedTab]] || 0;
            // trick to sort by highest existing value
            // should be existingB - existingA but in echarts the 1st item in the array
            // will be displayed at the bottom of the chart so we need to reverse the order
            return existingA - existingB; 
        });
    };

    const sortedSLPXValues = toggleShowMore ? sortKeysByHighestExistingValue(asBarData || {}) : sortKeysByHighestExistingValue(asBarData || {}).reverse().slice(0, 3).reverse(); // 1st reverse to order the array to fit in the slice(0,3), 2nd reverse to revert the order back to display the highest at the top.
    const sortedSMACValues = sortArrayBasedOnGivenArrayWithoutKey(Object.keys(asBarData || {}), xAxisValuesOrder);

    const dynamicXAxisValues = isSLPSelected ? sortedSLPXValues : sortedSMACValues;

    const title =   isSLPSelected ? 'SLP Card Types' : 'SMAC Card Types'

    const chartData = React.useMemo(() => {
        if (!asBarData) {
            return {}
        }

        const legends = dynamicXAxisValues.reduce((acc, key) => {
            if (!asBarData[key]) {
                return acc
            }
            Object.keys(asBarData[key]).forEach((legend) => {
                if (!acc.includes(legend)) {
                    acc.push(legend)
                }
            })
            return acc
        }, [])
        const filteredLegend = orderArray.filter(legend => legends.includes(legend));
        const totalByAxis = dynamicXAxisValues.reduce((acc, key) => {
            if (!asBarData[key]) {
                return acc
            }
            Object.keys(asBarData[key]).forEach((legend) => {
                if (!acc[key]) {
                    acc[key] = 0
                }
                acc[key] += asBarData[key][legend]
            })
            return acc

        }, {})

        const totalByAxisLastYear = dynamicXAxisValues.reduce((acc, key) => {
            if (!asBarDataLY?.[key]) {
                return acc
            }
            Object.keys(asBarDataLY[key]).forEach((legend) => {
                if (!acc[key]) {
                    acc[key] = 0
                }
                acc[key] += asBarDataLY[key][legend]
            })
            return acc
        }, {});

        const firstSeriesData = filteredLegend.map(legend => {
            const percentages = dynamicXAxisValues.map(key => {
                const total = totalByAxis?.[key];
                const val = asBarData?.[key]?.[legend]; 
                
                return (total && val) ? (val / total * 100) : 0;
            });
        
            return {
                name: legend,
                data: percentages,
                dataLabel: dynamicXAxisValues.map(key => {
                    const data = asBarData?.[key]; 
                    return data ? data[legend] : 0; 
                }),
                subdataLabel: percentages,
                color: LEGEND_COLOR_MAP[legend],
                textColor: '#000000'
            };
        });

        const secondSeriesData = firstSeriesData.map(series => {
            if (!asBarDataLY || Object.keys(asBarDataLY).length === 0) {
                return []
            }
            const currentPercentages = series.subdataLabel
            const previousPercentages = dynamicXAxisValues.map(key => {
                const total = totalByAxisLastYear[key]
                const val = asBarDataLY?.[key]?.[series.name]
                return (total && val) ? (val / total * 100) : 0
            })
            const pp = currentPercentages.map((val, index) => {
                return val - previousPercentages[index]
            })
            return {
                dataLabelPP: pp.map((val, index) => {
                    const result = val.toFixed(0);
                    if (result === '-0') {
                        return '0';
                    } else if (result >= 1) {
                        return '+' + result;
                    }
                    return result;
                }),
                textColor: pp.map(val => {
                    return getDefaultPercentagePointColor(val)
                }),
            }
        })

        const combinedSeriesData = firstSeriesData?.map((series, index) => ({
            ...series,
            ...secondSeriesData[index]
        }));

        return {
            seriesData: combinedSeriesData,
            legendData: filteredLegend
        }
    }, [asBarData, asBarDataLY, dynamicXAxisValues]);
    
    
    if (isLoading || isLoadingLY || !asBarData) {
        return <LoadingComponent />
    }
    
    return <HorizontalBarGroupGraph
        showMoreBtn={showMoreBtn && (asBarData && Object.keys(asBarData)?.length > 3)}
        btnText={toggleShowMore ? 'Show Top 3' : 'Show More'}
        onClick={() => setToggleShowMore(!toggleShowMore)}
        title={title}
        xAxisData={dynamicXAxisValues.map(key => LabelMapping[key] || key)}
        {...chartData}
    />
}