import React from 'react';
import ToggleSelector from '../../common/presentation/selectors/toggleSelector';
import { useSelectorState } from '../../common/providers/selectorProvider';
import {SELECTOR_ZONE_STORE_TOGGLE} from "../constant";

const TOGGLE_OPTIONS = [
    { label: "Zone View", value: 'zone' },
    { label: "Store View", value: 'store' },
]

export default function ZonetoStoreToggleSelector() {
    const [zoneToStore, setZoneToStore] = useSelectorState(SELECTOR_ZONE_STORE_TOGGLE)

    const onToggleChange = (event, val) => {
        if (val !== null) {
            setZoneToStore(val);
        }
    };

    return <ToggleSelector
        options={TOGGLE_OPTIONS}
        value={zoneToStore}
        onChange={onToggleChange}
        additionalStyles={{ textTransform: 'none' }}
    />
}