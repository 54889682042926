import { Button, Typography, TextField, Box, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useState, useMemo } from "react";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DropdownCheckboxV3 from "../../../../common/components/DropdownCheckboxV3";
import { CustomSwitch } from "../swtichComponent";
import { useSave } from "../../utils";
import ActionModal from "../ActionModal";
import { useGroupList, useUserList } from "../../../../common/API/hooks";
import { useIPSet } from "../hooks";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { getSelectedOption } from "../../../../common/utils";
import { toast } from "react-toastify";
import handleError from "../../../admin/ErrorHandling";


export default function AddIPRule() {
    const [open, setOpen] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);

    const { data: userList, isLoading: userLoading } = useUserList();
    const { data: groupList, isLoading: groupLoading } = useGroupList();
    const { data: ipsetList, isLoading: ipsetLoading } = useIPSet()
    const defaultValue = { rule_name: '', users: [], groups: [], ip_sets: [], is_restricted: true, is_active: true };
    const [ipruleData, setIPRuleData] = useState(defaultValue)
    const { rule_name, users, groups, ip_sets } = ipruleData;

    const handleWarningConfirmation = () => {
        setIPRuleData(defaultValue)
        setShowWarningModal(false)
        setHasChanges(false)
        setOpen(false)
    }

    const closeModal = () => {
        if (hasChanges) {
            setShowWarningModal(true)
        }
        else {
            setOpen(false);
        }
    };
    const { saveData: saveIPRule } = useSave()

    const userOptions = useMemo(() => {
        if (userLoading || !userList) {
            return []
        }
        return userList?.map((user) => {
            return {
                value: user.id,
                label: user.username,
                key: user.id,
            }
        })
    }, [userList, userLoading]);

    const groupOptions = useMemo(() => {
        if (groupLoading || !groupList) {
            return []
        }
        return groupList?.map((group) => {
            return {
                value: group.id,
                label: group.name,
                key: group.id,
            }
        })
    }, [groupList, groupLoading]);

    const ipsetsaOptions = useMemo(() => {
        if (ipsetLoading || !ipsetList) {
            return []
        }
        return ipsetList?.map((ip_set) => {
            return {
                value: ip_set.id,
                label: ip_set.ip_set_name,
                key: ip_set.id,
            }
        })
    }, [ipsetList, ipsetLoading]);

    const handleIPRuleNameChange = (e) => {
        setHasChanges(true);
        const { value } = e.target;
        setIPRuleData((prevIPRuleData) => ({ ...prevIPRuleData, rule_name: value }));
    };

    const handleUserChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value)
        setIPRuleData((prevUserData) => ({
            ...prevUserData,
            users: selectedValues
        }));
    };

    const handleGroupChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value)
        setIPRuleData((prevGroupData) => ({
            ...prevGroupData,
            groups: selectedValues
        }));
    };

    const handleIPSetChange = (selectedOptions) => {
        setHasChanges(true);
        const selectedValues = selectedOptions.map((option) => option.value)
        setIPRuleData((prevIPSetData) => ({
            ...prevIPSetData,
            ip_sets: selectedValues
        }));
    };

    const handleSwitchChange = (event) => {
        const { checked } = event.target;
        setHasChanges(true);
        setIPRuleData((prevData) => ({
            ...prevData,
            is_restricted: checked
        }));
    };


    const handleSave = () => {
        const ipruleData = {
            rule_name: rule_name,
            user_ids: users,
            group_ids: groups,
            ip_sets: ip_sets,
            is_restricted: true,
            is_active: true
        }
        saveIPRule('ip-rule', ipruleData).then(() => {
            setIPRuleData(defaultValue)
            setOpen(false)
            return toast.success("IP Rule saved!")
        })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "IP Rule", "save");
                return toast.error(errorMessage);
            });
    }


    return (
        <>
            <Button
                startIcon={<AddIcon />}
                variant="contained"
                size="medium"
                sx={{ bgcolor: "#0A4FB4" }}
                onClick={() => setOpen(true)}
            >
                Add IP Rule
            </Button>
            <ActionModal
                action={"Add IP Rule"}
                title={rule_name}
                open={open}
                close={closeModal}
                icon={<EditRoundedIcon />}
                content={
                    <Box sx={{ justifyContent: 'center', mx: '5%', mt: 2 }}>
                        <Typography> Rule Name: </Typography>
                        <TextField variant="outlined" size="small" sx={{ width: '100%' }}
                            placeholder="Input Rule Name"
                            value={rule_name}
                            onChange={handleIPRuleNameChange}
                        >
                        </TextField>
                        <Typography mt={2}>
                            Users:
                        </Typography>
                        <DropdownCheckboxV3
                            options={userOptions}
                            placeholder="Select User/s"
                            limitTags={3}
                            isLoading={userLoading}
                            isDisabled={userLoading}
                            onChange={(event, newValue) => {
                                handleUserChange(getSelectedOption(newValue, userOptions));
                            }}
                            value={getSelectedOption(users, userOptions)}
                        />

                        <Typography mt={2}>
                            Groups:
                        </Typography>
                        <DropdownCheckboxV3
                            options={groupOptions}
                            placeholder='Select Group/s'
                            limitTags={3}
                            isLoading={groupLoading}
                            isDisabled={groupLoading}
                            onChange={(event, newValue) => {
                                handleGroupChange(getSelectedOption(newValue, groupOptions));
                            }}
                            value={getSelectedOption(groups, groupOptions)}
                        />
                        <Typography mt={2}>
                            IP Sets:
                        </Typography>
                        <DropdownCheckboxV3
                            options={ipsetsaOptions}
                            placeholder="Select IP Set/s"
                            limitTags={3}
                            isLoading={ipsetLoading}
                            isDisabled={ipsetLoading}
                            onChange={(event, newValue) => {
                                handleIPSetChange(getSelectedOption(newValue, ipsetsaOptions));
                            }}
                            value={getSelectedOption(ip_sets, ipsetsaOptions)}
                        />
                        <Stack direction="row" spacing={1} sx={{ mt: 3, alignItems: 'center' }}>
                            <Typography>
                                Restricted:
                            </Typography>
                            <CustomSwitch
                                checked={ipruleData?.is_restricted}
                                onChange={handleSwitchChange}
                            />
                        </Stack>

                    </Box>
                }
                button={
                    <Button variant="contained" sx={{
                        backgroundColor: '#0A4FB4', fontWeight: 'bold', textTransform: 'none', width: '40%',
                        '&:hover': {
                            backgroundColor: '#0A4FB4',
                        }
                    }}
                        onClick={handleSave}
                    > Save </Button>
                }
            />
            {
                showWarningModal && (
                    <ActionModal
                        action={"Warning"}
                        open={showWarningModal}
                        close={() => setShowWarningModal(false)}
                        content={<Stack direction={'row'} sx={{ px: 3, pt: 2 }} spacing={2}>
                            <WarningRoundedIcon sx={{ fontSize: '50px' }} color="warning" />
                            <Typography sx={{ fontSize: 18, alignSelf: 'center', textAlign: 'center' }}>
                                There are unsaved changes. Are you sure you want to close the modal?
                            </Typography>
                        </Stack>
                        }
                        button={
                            <Button
                                onClick={handleWarningConfirmation}
                                variant="contained" sx={{
                                    backgroundColor: '#ed6c02', fontWeight: 'bold', textTransform: 'none', width: '40%',
                                    '&:hover': {
                                        backgroundColor: '#ed6c02',
                                    }
                                }}> Confirm </Button>
                        }
                        customWidthMD={4}
                    />
                )
            }
        </>
    )
}