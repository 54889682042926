import { Grid, Paper, Typography } from "@mui/material";
import AddIPSet from "./AddIPSet";
import IPSetTable from "./IPSetTable";
import { useIPSet } from "../hooks";
import { useState, useEffect } from "react";


export default function IPSet({ filters, handleRadioChange }) {
    const [actionApply, setActionApply] = useState(false);
    const { data, isLoading, refetch } = useIPSet();

    useEffect(() => {
        if (actionApply && !isLoading) {
            refetch();
            setActionApply(false);
        }
    }, [actionApply, isLoading, refetch]);

    return (
        <>
            <Paper sx={{ border: 2, borderRadius: 3, borderColor: '#BDBDBD', backgroundColor: "#F3F3F3", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, p: 1 }}>
                <Grid container alignItems="center" justifyContent="space-between" display={{ xs: 'block', md: 'flex' }}>
                    <Grid item md={3.5} m={1} >
                        <Typography variant="h5"> IP Sets </Typography>
                    </Grid>
                    <Grid item m={1} >
                        <AddIPSet />
                    </Grid>
                </Grid>
                <IPSetTable data={data} isLoading={isLoading} filters={filters} handleRadioChange={handleRadioChange} setActionApply={setActionApply} />
            </Paper >
        </>
    )
}