import {useTopLine} from "../hooks/sales";
import ToplinePerformance from "./index";
import {useTopLineData} from "../hooks/operations";

export default function OperationsToplinePerformance() {
    const information = [
        { name: 'Catchment Rate', value: 'Dept Store Foot Traffic / Mall Foot Traffic' },
        // { name: 'Catchment Rate Plan', value: 'N/A' },
        { name: 'Conversion Rate', value: '(In Store + Personal Shopper Pick-up Transactions) / Dept Store Foot Traffic' },
        // { name: 'Conversion Rate Plan', value: 'N/A' },
        { name: 'Space Productivity', value: 'Gross Sales / Months Coverage / Net Selling Area in sqm' },
        { name: 'Space Productivity Plan', value: 'Sales Plan / Months Coverage / Net Selling Area in sqm' },
    ]

    const { data: sortedData, isLoading } = useTopLineData();

    return <ToplinePerformance information={information} data={sortedData} isLoading={isLoading} />
}