import React from 'react';
import { Box, IconButton } from "@mui/material";
import SubRowExpander from '../../../common/components/DataTable/SubRowExpander';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export default function CellWithSubRow({
    row,
    children,
    isLoadingSubRows = false,
    boxStyle = {},
    marginLeftMinimum = 0,
    onRowClicked, // should pass a function instead of a boolean
    isMultiSelect = false,
    visibilityIconSelected,
    isRadioSelected = false,
}) {

    const hasSubRows = row.original.subRows && row.original.subRows.length > 0;
    const isTotalLabel = row?.original?.pk?.toLowerCase() === 'total';
    
    return (
        <Box sx={{
            fontWeight: (row.depth === 0 && hasSubRows) || isTotalLabel ? 'bold' : 'regular',
            marginLeft: `${(row.depth * 1) + marginLeftMinimum}rem`,
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            ...boxStyle,
        }}>
            {onRowClicked && isMultiSelect && (
                <VisibilityIcon
                    sx={{ marginRight: '.5rem', cursor: 'pointer' }}
                    fontSize='small'
                    color={visibilityIconSelected ? 'primary' : 'disabled'}
                    onClick={onRowClicked}
                />
            )}
            {onRowClicked && !isMultiSelect && (
                <IconButton size='small'>
                    {
                        isRadioSelected ? <RadioButtonCheckedIcon sx={{ fontSize: 18, cursor: 'pointer', color: "#1976D2" }} onClick={onRowClicked}  /> : 
                        <RadioButtonUncheckedIcon sx={{ fontSize: 18, cursor: 'pointer', color: "#1976D2" }} onClick={onRowClicked} />
                    }
                </IconButton>
            )}
            {children}
            {hasSubRows > 0 && (
                <SubRowExpander row={row} isLoadingSubRows={isLoadingSubRows} />
            )}
        </Box>
    );
}