import DeleteIcon from '@mui/icons-material/Delete';
import ActionModal from "../ActionModal";
import { Button, Stack, Typography } from '@mui/material';
import ApiClient from '../../../../common/API';
import { toast } from 'react-toastify';
import handleError from "../../../admin/ErrorHandling";

export default function DeleteIPRule({ row, open, setOpen, setActionApply }) {
    const rule_name = row?.row?.original?.rule_name
    const ip_rule_ids = row?.row?.original?.id

    const handleDelete = (event) => {
        event.stopPropagation();
        ApiClient().delete(`ip-rule/${ip_rule_ids}/`)
            .then(() => {
                setActionApply(true);
                setOpen(false);
                return toast.success(`IP Rule ${rule_name} deleted`)
            })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "IP Rule", "delete");
                return toast.error(errorMessage)
            })
    }


    return <ActionModal
        action={rule_name}
        open={open}
        close={() => setOpen(false)}
        icon={<DeleteIcon />}
        content={<Stack direction={'row'} sx={{ px: 3, pt: 2 }} spacing={2}>
            <DeleteIcon sx={{ fontSize: '50px' }} color="error" />
            <Typography sx={{ fontSize: 18, alignSelf: 'center' }}>
                Are you sure you want to <span style={{ fontWeight: 'bold', color: '#dd0000' }}>DELETE</span> this IP Rule?
            </Typography>
        </Stack>
        }
        button={
            <Button
                onClick={handleDelete}
                variant="contained" sx={{
                    backgroundColor: '#dd0000', fontWeight: 'bold', textTransform: 'none', width: '40%',
                    '&:hover': {
                        backgroundColor: '#dd0000',
                    }
                }}> Delete </Button>
        }
    />
}