import { useMemo } from "react";
import { Card, Grid } from "@mui/material";
import SMACMemberCard from "./components/SMACMemberCard";
import { topLineDisplayNumber } from "../../../../common/utils";
import { useToplineSMACMemberCount } from "../hooks";
import AcquisitionSMACBarGraph from "./components/AcquisitionSMACBarGraph";
import ToplineDoughnutGraph from "./components/ToplineDoughnutGraph";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import SMACCardType from "./components/SMACCardType";
import { useContext } from "react";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import BlacklistableComponentWrapper from "../../common/providers/blacklistedComponentsProvider/BlacklistableComponentWrapper";
import { 
    COMPONENT_SMAC_MEMBERS_ACQUISITION, 
    COMPONENT_SMAC_CARD_TYPES_ACQUISITION,
    COMPONENT_SMAC_MEMBERS_REGISTRATION,
    COMPONENT_SMAC_CARD_TYPES_REGISTRATION,
    COMPONENT_SMAC_ACTIVE_MEMBERS,
    COMPONENT_SMAC_CARD_TYPES_USAGE
} from "../dashboardDefinition";
import { useSelectorValue } from "../../common/providers/selectorProvider";

export default function ToplinePerformanceCharts({ selectedTab, blacklistedComponents }) {
    const { totalMembers, asRegistrationPieData, asUsagePieData, isLoading } = useToplineSMACMemberCount({})
    const { totalMembers: totalMembersLY } = useToplineSMACMemberCount({ lastYear: true })
    const organization = useSelectorValue('organization');
    const card_group = useSelectorValue('card_group');
    const isSLP = card_group === 'SLP';
    const vsLastYearTotalMembers = ((totalMembers / totalMembersLY) * 100).toFixed(0);
    const dynamicHeader = isSLP ? "SLP" : "SMAC";
    const acquisition_smac_members = { header: `${dynamicHeader} Members`, overallvalue: topLineDisplayNumber(totalMembers), vsplan: "", vslastyear: vsLastYearTotalMembers }
    const registration_smac_members = { header: `${dynamicHeader} Members`, overallvalue: topLineDisplayNumber(totalMembers), vsplan: "", vslastyear: vsLastYearTotalMembers }
    const usage_smac_members = { header: `${dynamicHeader} Active Members`, overallvalue: topLineDisplayNumber(totalMembers), vsplan: "", vslastyear: vsLastYearTotalMembers, style: { variant: "body2" } }

    const windowSize = useContext(WindowSizeContext);
    const gridSize = useMemo(() => {
        const getGridSize = (leftComponentId, rightComponentId) => {
            return (blacklistedComponents?.includes(leftComponentId) || 
                    blacklistedComponents?.includes(rightComponentId)) ? 12 : 6;
        };

        switch (selectedTab) {
            case 'registration':
                return getGridSize(COMPONENT_SMAC_MEMBERS_REGISTRATION, COMPONENT_SMAC_CARD_TYPES_REGISTRATION);
            case 'usage':
                return getGridSize(COMPONENT_SMAC_ACTIVE_MEMBERS, COMPONENT_SMAC_CARD_TYPES_USAGE);
            case 'acquisition':
                return getGridSize(COMPONENT_SMAC_MEMBERS_ACQUISITION, COMPONENT_SMAC_CARD_TYPES_ACQUISITION);
            default:
                return 6; // Default size if no tab matches
        }
    }, [ selectedTab, blacklistedComponents]);

    switch (selectedTab) {
        case 'registration':
            return (
                <Grid container spacing={1} mt={-.1}>
                    {/* left content */}
                    <BlacklistableComponentWrapper componentId={COMPONENT_SMAC_MEMBERS_REGISTRATION}>
                        <Grid item xs={12} sm={gridSize}>
                            <Card>
                                {isLoading ? <LoadingComponent />

                                    : <Grid container xs={12} sm={12} display={"flex"}>
                                        <Grid item xs={12} sm={4} p={1.5}>
                                            <SMACMemberCard data={registration_smac_members} />
                                        </Grid>
                                        <Grid item xs={12} sm={8} alignContent={"center"}>
                                            <ToplineDoughnutGraph data={asRegistrationPieData} label={'Registered'} />
                                        </Grid>
                                    </Grid>
                                }
                            </Card>
                        </Grid>
                    </BlacklistableComponentWrapper>

                    {/* right content */}
                    <BlacklistableComponentWrapper componentId={COMPONENT_SMAC_CARD_TYPES_REGISTRATION}>
                        <Grid item xs={12} sm={gridSize}>
                            <Card>
                                <SMACCardType showMoreBtn={isSLP}/>
                            </Card>
                        </Grid>
                    </BlacklistableComponentWrapper>
                </Grid>
            )

        case 'usage':
            return (
                <Grid container spacing={1} mt={-.1}>
                    {/* left content */}
                    <BlacklistableComponentWrapper componentId={COMPONENT_SMAC_ACTIVE_MEMBERS}>
                        <Grid item xs={12} sm={gridSize}>
                            <Card>
                                {isLoading ? <LoadingComponent />
                                    : <Grid container xs={12} sm={12} display={"flex"}>
                                        <Grid item xs={12} sm={4} p={1.5}>
                                            <SMACMemberCard data={usage_smac_members} />
                                        </Grid>
                                        <Grid item xs={12} sm={8} alignContent={"center"}>
                                            <ToplineDoughnutGraph data={asUsagePieData} label={'Active'} />
                                        </Grid>
                                    </Grid>
                                }
                            </Card>
                        </Grid>
                    </BlacklistableComponentWrapper>

                    {/* right content */}
                    <BlacklistableComponentWrapper componentId={COMPONENT_SMAC_CARD_TYPES_USAGE}>
                        <Grid item xs={12} sm={gridSize}>
                            <Card>
                                <SMACCardType showMoreBtn={isSLP && organization !== 'SM Store'}/>
                            </Card>
                        </Grid>
                    </BlacklistableComponentWrapper>
                </Grid>
            )
        case 'acquisition':
            return (
                <Grid container spacing={1} mt={-.1}>
                {/* <Grid display="flex" justifyContent="space-between" alignItems="center" width="100%"> > */}
                    {/* left content */}
                    <BlacklistableComponentWrapper componentId={COMPONENT_SMAC_MEMBERS_ACQUISITION}>
                        <Grid item xs={12} sm={gridSize}>
                            <Card>
                                {isLoading ? <LoadingComponent />
                                    : <Grid container xs={12} sm={12} display={"flex"} height={'100%'}>
                                    
                                            <Grid item xs={12} sm={4} p={1.5}>
                                                <SMACMemberCard data={acquisition_smac_members} />
                                            </Grid>
                                    
                                            <Grid item xs={12} sm={8} display={'flex'} mx={windowSize.isOnMobile && 2}>
                                                <Grid item xs={12} sm>
                                                    <AcquisitionSMACBarGraph />
                                                </Grid>
                                            </Grid>
                                        
                                    </Grid>
                                }
                            </Card>
                        </Grid >
                    </BlacklistableComponentWrapper>

                    {/* right content */}
                    <BlacklistableComponentWrapper componentId={COMPONENT_SMAC_CARD_TYPES_ACQUISITION}>
                        < Grid item xs={12} sm={gridSize} >
                            <Card>
                                <SMACCardType showMoreBtn={isSLP}/>
                            </Card>
                        </Grid >
                    </BlacklistableComponentWrapper>
                </Grid >
            )
        default: <></> // Show nothing
    }
}