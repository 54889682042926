import React, {useContext} from 'react';
import {DashboardMetaContext} from "../contexts";
import { useDashboardTracking } from '../../../../common/API/hooks';


export default function DashboardMetaProvider({children, dashboardDefinition, additionalContext= {}}) {
    const {path, name} = dashboardDefinition;
    useDashboardTracking({path, name})

    return <DashboardMetaContext.Provider value={{
        ...dashboardDefinition,
        context: {
            ...(dashboardDefinition.context || {}),
            ...additionalContext
        }
    }}>
        {children}
    </DashboardMetaContext.Provider>;
}

export function useDashboardPath() {
    const {path} = useContext(DashboardMetaContext)

    return path
}

export function useDashboardComponents() {
    const {components} = useContext(DashboardMetaContext);

    return components
}

export function useDashboardMetaContext(contextKey=null) {
    const {context} = useContext(DashboardMetaContext);

    if (contextKey) {
        return context?.[contextKey]
    }
    return context
}

export function useDashboardTableSource() {
    const {context} = useContext(DashboardMetaContext);

    return context?.tableSource
}

export function useDefinitionBlacklistedComponents() {
    // these returns the blacklisted components on the current dashboard definition
    // blacklisted components here are components that are blacklisted and cannot be enabled, as opposed to per user
    // generally these components are not even part of the controllable components
    const {blacklisted_components} = useContext(DashboardMetaContext);

    return blacklisted_components || []
}

export function useIsComponentAllowedInDefinition(componentId) {
    const blacklistedComponents = useDefinitionBlacklistedComponents()

    return !blacklistedComponents.includes(componentId)
}
