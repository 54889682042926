import { useMemo, useState } from "react";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import EditIPRule from "./EditIPRule";
import DeleteIPRule from "./DeleteIPRule";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useIPSet } from "../hooks";


export default function IPRuleTable({ data, isLoading, setActionApply }) {
    const { data: ipsetList, isLoading: ipsetLoading } = useIPSet()

    function MenuAction({ row, setActionApply }) {
        const [editModalOpen, setEditModalOpen] = useState(false)
        const [deleteModalOpen, setDeleteModalOpen] = useState(false)

        return <>

            <Tooltip title="Edit">
                <IconButton>
                    <EditRoundedIcon onClick={() => setEditModalOpen(true)} />
                </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
                <IconButton>
                    <DeleteRoundedIcon
                        onClick={() => setDeleteModalOpen(true)}
                        sx={{ color: "red" }}
                    />
                </IconButton>
            </Tooltip>

            <EditIPRule row={row} open={editModalOpen} setOpen={setEditModalOpen} setActionApply={setActionApply} />
            <DeleteIPRule row={row} open={deleteModalOpen} setOpen={setDeleteModalOpen} setActionApply={setActionApply} />
        </>
    }

    const IP_RULE_COLUMNS = useMemo(() => {
        return [
            {
                id: 'rule_name',
                header: 'Rule Name',
                accessorKey: 'rule_name',
                cell: ({ row, getValue }) => {
                    const value = getValue();
                    return <Box sx={{ marginX: 'auto', marginLeft: '1em', width: "13em", textWrap: "nowrap", my: 1 }}>
                        <Typography> {value} </Typography>
                    </Box>
                },
                customStyle: { textAlign: "left", verticalAlign: "middle" }

            },
            {
                id: 'users',
                header: 'Users',
                accessorKey: 'users',
                cell: ({ row, getValue }) => {
                    const users = getValue();
                    return (
                        <Box>
                            {Array.isArray(users) && users.length > 0 ? (
                                users.map((user, index) => (
                                    <Box key={index} sx={{ marginLeft: '1em', my: 1 }}>
                                        <Typography>
                                            <li>
                                                {user.username}
                                            </li>
                                        </Typography>

                                    </Box >
                                ))
                            ) : (
                                <Box></Box>
                            )
                            }
                        </Box >
                    );
                },
                customStyle: { textAlign: "left", verticalAlign: "top" }
            },
            {
                id: 'groups',
                header: 'Groups',
                accessorKey: 'groups',
                cell: ({ row, getValue }) => {
                    const groups = getValue();
                    return (
                        <Box>
                            {Array.isArray(groups) && groups.length > 0 ? (
                                groups.map((group, index) => (
                                    <Box key={index} sx={{ marginLeft: '1em', my: 1 }}>
                                        <Typography>
                                            <li>
                                                {group.name}
                                            </li>
                                        </Typography>
                                    </Box>
                                ))
                            ) : (
                                <Box></Box>
                            )
                            }
                        </Box >
                    );
                },
                customStyle: { textAlign: "left", verticalAlign: "top" }
            },
            {
                id: 'ip_set',
                header: 'IP Sets',
                accessorKey: 'ip_sets',
                cell: ({ row, getValue }) => {
                    const ip_sets = getValue();
                    return (
                        <Box>
                            {Array.isArray(ip_sets) && ip_sets.length > 0 ? (
                                ip_sets.map((ip_set, index) => {
                                    // Check if the ip_set id is in the ipsetList
                                    const matchedIpSet = ipsetList.find(ip => ip?.id === ip_set);

                                    return (
                                        <Box key={index} sx={{ marginLeft: '1em', my: 1 }}>
                                            <Typography>
                                                {matchedIpSet ? (
                                                    <li>{matchedIpSet?.ip_set_name}</li>
                                                ) : (
                                                    <li>Not found</li>
                                                )}
                                            </Typography>
                                        </Box>
                                    );
                                })
                            ) : (
                                <Box></Box>
                            )}
                        </Box>
                    );
                },
                customStyle: { textAlign: "left", verticalAlign: "top" }
            },
            {
                id: 'is_restricted',
                header: 'Has Restriction',
                accessorKey: 'is_restricted',
                cell: ({ row, getValue }) => {
                    const value = getValue();
                    return (
                        <Box sx={{ marginX: 'auto', width: "13em", textWrap: "nowrap", my: 1 }}>
                            {value ? (
                                <CheckBoxRoundedIcon sx={{ color: 'green' }} />
                            ) : (
                                <DisabledByDefaultRoundedIcon sx={{ color: 'red' }} />
                            )}
                        </Box>
                    );
                },
                customStyle: { textAlign: "center" }
            },
            {
                id: 'menu_actions',
                header: 'Action Menu',
                cell: (row) => (
                    <MenuAction row={row} setActionApply={setActionApply} />
                ),
                customStyle: { textAlign: "center", }

            }
        ]
    }, [data, ipsetList]
    );

    if (isLoading || ipsetLoading) {
        return <LoadingComponent />
    }

    return <TableComponentV2
        columns={IP_RULE_COLUMNS}
        data={data}
        tableId={'IPRule'}
        isCompact
        customTableHeaderStyle={{ backgroundColor: '#0A4FB4', color: 'white' }}
        customTableContainerStyle={{
            borderRadius: "0",
            boxShadow: 'none',
        }}

    />
}