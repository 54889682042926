import React, { useMemo, useContext } from 'react';
import { ConvertToReactSelectOption, getSelectedOption } from '../utils';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete'; //STARTS HERE!
import TextField from '@mui/material/TextField';
import { WindowSizeContext } from '../context/WindowSizeContext';
import { CircularProgress } from '@mui/material';

export default function FilterYear({ onDropdownChange, filters, dateRangeData, isLoading, isSuccess, isDisabled }) {
    const windowSize = useContext(WindowSizeContext);

    const fromYear = dateRangeData && moment(dateRangeData.from).year();
    const toYear = dateRangeData && moment(dateRangeData.to).year();
    let yearOptions = [];

    if (fromYear && toYear) {
        for (let i = fromYear; i <= toYear; i++) {
            yearOptions.push(i)
        }
    }

    const options = !isLoading && ConvertToReactSelectOption(yearOptions)

    const selectedYearOption = useMemo(() => {
        if (!options || options.length === 0 || isLoading) {
            return null
        }

        const option = getSelectedOption(filters?.year, options)
        if (option) {
            return option
        } else {
            const defaultOptionValue = (options && options.length > 0) ? options.filter(option => option.value === toYear)?.[0]?.value : null
            onDropdownChange(defaultOptionValue, 'year')
            return null
        }
    }, [options, filters?.year, isLoading])

    return (
        <>
            <Autocomplete
                fontFamily="Henry Sans"
                value={selectedYearOption}
                onChange={(event, newValue) => {
                    if (newValue) {                       // Ensure newValue is not null
                        onDropdownChange(newValue.value, 'year');
                    }
                }}
                disableClearable
                id="filter_year_options"
                size="small"
                options={options}
                sx={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} 
                    label='Year'
                    onKeyDown={windowSize.isOnMobile ? (e) => e.preventDefault(): null}
                    disabled={isLoading || isDisabled}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress size={15} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />}
                disabled={isDisabled}
            />
        </>
    );

}