import SalesToplinePerformance from "../ToplinePerformance/SalesToplinePerformance";
import SalesZoneToStorePerformance from "../ZonetoStorePerformance/SalesZoneToStorePerformance";
import SalesBUToDepartmentPerformance from "../BUtoDepartmentPerformance/SalesBUToDepartmentPerformance";
import {useIsComponentUsable} from "../../common/providers/blacklistedComponentsProvider/hooks";
import {
    GENERAL_BU_DEPT_PERFORMANCE,
    GENERAL_STORE_PERFORMANCE,
    GENERAL_TOPLINE,
    SALES_BU_DEPT_PERFORMANCE,
    SALES_STORE_PERFORMANCE,
    SALES_TOPLINE
} from "../dashboardDefinition";


export default function SalesContent({selectedTab}) {

    const isGeneralToplineUsable = useIsComponentUsable(GENERAL_TOPLINE);
    const isSalesToplineUsable = useIsComponentUsable(SALES_TOPLINE);

    const isToplineUsable = isGeneralToplineUsable && isSalesToplineUsable;

    const isGeneralBUDeptPerformanceUsable = useIsComponentUsable(GENERAL_BU_DEPT_PERFORMANCE);
    const isSalesBUDeptPerformanceUsable = useIsComponentUsable(SALES_BU_DEPT_PERFORMANCE);

    const isBUDeptPerformanceUsable = isGeneralBUDeptPerformanceUsable && isSalesBUDeptPerformanceUsable;

    const isGeneralStorePerformanceUsable = useIsComponentUsable(GENERAL_STORE_PERFORMANCE);
    const isSalesStorePerformanceUsable = useIsComponentUsable(SALES_STORE_PERFORMANCE);

    const isStorePerformanceUsable = isGeneralStorePerformanceUsable && isSalesStorePerformanceUsable;


    return <>
        {
            isToplineUsable && <SalesToplinePerformance/>
        }
        {
            isBUDeptPerformanceUsable && <SalesBUToDepartmentPerformance selectedTab={selectedTab}/>
        }
        {
            isStorePerformanceUsable && <SalesZoneToStorePerformance/>
        }
    </>
}