import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useSelectorState } from '../../common/providers/selectorProvider';

export default function PeriodRadioButton({ optionKey, label }) {
    const [selectedRadio, setSelectedRadio] = useSelectorState('latest_full_month', 'latest_month'); // 'latest_month' or 'full_month'
    const isSelected = selectedRadio === optionKey;

    const onRadioClicked = (type) => {
        setSelectedRadio(type);
    };

    const radioIconStyles = {
        fontSize: 18,
        cursor: 'pointer',
        color: "#1976D2",
    };

    return (
        <Grid item xs={12} sm={12} display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
            {
                selectedRadio === optionKey 
                    ? <RadioButtonCheckedIcon sx={radioIconStyles} onClick={() => onRadioClicked(optionKey)} /> 
                    : <RadioButtonUncheckedIcon sx={radioIconStyles} onClick={() => onRadioClicked(optionKey)} />
            }
            <Typography>
                {label}
            </Typography>
        </Grid>
    );
};