import Section from "../../../../../common/components/Dashboard/Section";
import CustomerActivityByBUTable from "./CustomerActivityByBUTable";
import { useSelectorValue } from "../../../common/providers/selectorProvider";

export default function StoreCustomerActivityByBU(){
    const organization = useSelectorValue('organization') || 'SM Store'

    const title = organization === "SM Markets"
    ? "Customer Activity by Category"
    : "Customer Activity by Business Unit";

    return <Section
        title={title}>

        <CustomerActivityByBUTable/>
    </Section>
}