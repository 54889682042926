import ReactECharts from 'echarts-for-react';
import {abbreviateNumber, topLineDisplayNumber} from '../../../../common/utils';
import {CHART_FONT_SIZE} from "../constants";
import { Box, Button, Grid } from '@mui/material';
import { useMemo } from 'react';

export default function HorizontalBarGroupGraph({ title, seriesData, xAxisData, showMoreBtn, onClick, btnText }) {
    const xAxisDataLength = xAxisData?.length;
    const chartHeight = useMemo(() => {
        if (xAxisDataLength > 3) {
            return `${xAxisDataLength * 7.5}rem`;
        }
        return '22rem';
    }, [xAxisDataLength]); 

    const option = {
        animation: false,
        legend: {
            bottom: 0,
            textStyle: {
                fontSize: CHART_FONT_SIZE
            }
        },
        grid: {
            top: '10%',
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, .2],
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#000000'
                }
            },
            axisLabel: {
                formatter: function (value) {
                    return abbreviateNumber(value);
                },
                textStyle: {
                    fontSize: CHART_FONT_SIZE
                }
            }
        },
        yAxis: {
            type: 'category',
            data: xAxisData,
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#000000'
                }
            },
            axisTick: { show: false },
            axisLabel: {
                textStyle: {
                    fontSize: CHART_FONT_SIZE
                }
            }
        },
        series: [
            ...seriesData.map(item => ({
                name: item?.name,
                type: 'bar',
                data: item?.dataLabel.map((dataPoint, index) => ({
                    value: dataPoint,
                    label: {
                        color: item?.textColor[index],
                        formatter: (params) => {
                            const currentData = item?.dataLabel[params.dataIndex];
                            const subdataLabelValue = item?.subdataLabel[params.dataIndex]?.toFixed(0);
                            const percentagePoint = params && item?.dataLabelPP
                                ? item.dataLabelPP[params.dataIndex] !== undefined
                                    ? item.dataLabelPP[params.dataIndex] + "pp"
                                    : null // or any default value you prefer
                                : [];
                            return `{default_color|${topLineDisplayNumber(currentData)} (${subdataLabelValue}%)} ${percentagePoint}`;
                        },
                        rich: {
                            default_color: {
                                color: '#000000',
                                fontSize: CHART_FONT_SIZE
                            }
                        },
                        textStyle: {
                            fontSize: CHART_FONT_SIZE
                        }
                    }
                })),
                itemStyle: { color: item?.color },
                label: {
                    show: true,
                    position: 'right'
                },
                barGap: '75%'
            })),
        ]
    };


    return <>
        <Grid display="flex" justifyContent="space-between" alignItems="center" px={3} pt={2.1}>
            <Box sx={{ fontWeight: '550', fontSize: '1.3rem' }}>
                {title}
            </Box>
            {
                showMoreBtn && <Box >
                    <Button variant="contained" size="small" sx={{ textTransform:'none'}} onClick={onClick}>{btnText}</Button>
                </Box>
            }          
            
        </Grid>
        <Box sx={{ height: '22rem', overflow: 'auto' }}>
            <ReactECharts option={option} notMerge={true} lazyUpdate={true} style={{ height: chartHeight, width: '100%' }} />
        </Box>
    </>
}