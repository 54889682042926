import * as React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import {useCheckHasAccessToPath} from "./hooks";


export default function CardContents({name, image, path, access, icon, isComingSoon, shrinkIcon, disabled=false, isNewDashboard}) {
    const hasAccessFromHook = useCheckHasAccessToPath(path)
    const hasAccess = access === undefined ? hasAccessFromHook : access;

    const largeCard = name === 'Overview'
    const Item = styled(Paper)(({theme}) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        padding: theme.spacing(1),
        position: 'relative',
        cursor: isComingSoon ? 'not-allowed' : 'pointer',
        height: "5.5em",
        width: largeCard ? '100%' : 'auto'
    }));

    if (!hasAccess || disabled) {
        return null
    }

    const labelColor = isComingSoon ? 'grey' : 'auto'

    const renderRibbon = () => {
        switch (true) {
            case isComingSoon:
                return <div className="ribbon ribbon-top-right"><span>coming soon</span></div>;
            case isNewDashboard:
                return <div className="ribbon ribbon-top-right"><span>new</span></div>;
            default:
                return null;
        }
    }
    
    return (
        <Grid item xs={largeCard ? 12 : 6} display={!hasAccess ? 'none' : ''} sx={{
            cursor: isComingSoon ? 'not-allowed !important' : 'pointer',
        }}>
            <CardActionArea LinkComponent={RouterLink} to={path} disabled={isComingSoon}>
                <Item>
                    {renderRibbon()}
                    <Stack direction={'column'}>
                        {image &&
                            <Box component="img" src={image} alt="SM Logo"
                                 sx={{width: '3em', height: shrinkIcon ? shrinkIcon : '3em'}} alignSelf={'center'}
                            />
                        }
                        {!!icon && icon}
                        <Typography align={'center'} variant='subtitle2' fontFamily={'Henry Sans'}
                                    color={labelColor}
                                    sx={{lineHeight: shrinkIcon ? 1.2 : 2}}
                        >
                            {name}
                        </Typography>
                    </Stack>
                </Item>
            </CardActionArea>
        </Grid>
    )
}