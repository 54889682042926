import { useContext, useEffect, useState } from "react";
import { Box, GlobalStyles, Grid, Typography } from "@mui/material";
import Back from "../../../common/components/BackNavigateComponent";
import ZeroSalesOverview from "./ZeroSalesOverview";
import ZeroSalesToolTip from "./components/ZeroSalesToolTip";
import CompanyFilter from "./components/CompanyFilter";
import NumOfDaysFilter from "./components/NumOfDaysFilter";
import AsOfDate from "./components/AsOfDate";
import { WindowSizeContext } from "../../../common/context/WindowSizeContext";
import { STORE_ZERO_SALES_TABLE, useAvailableDateRange } from "./hooks";
import moment from "moment";
import StoreArticleZeroSales from "./StoreArticleZeroSales";
import { registerDashboard } from "../../../common/utils";
import { dashboardDefinition } from "./dashboardDefinition";
import DashboardMetaProvider from "../common/providers/DashboardMetaProvider";


registerDashboard(dashboardDefinition)

export default function ZeroSales() {
    const { data: dateRangeData, isLoading: isDateRangeLoading } = useAvailableDateRange();
    const windowSize = useContext(WindowSizeContext)

    const [filters, setFilters] = useState({
        company_id: [2, 7, 142],
        days: 3,
        startDate: null,
        endDate: null,
    });
   
    const [selectedStore, setSelectedStore] = useState(null) 

    const [ overviewTableIsEmpty, setOverviewTableIsEmpty ] = useState(null)

    useEffect(() => {
        if (dateRangeData?.max && filters?.days) {
            setFilters(prevFilters => ({
                ...prevFilters,
                startDate: moment(dateRangeData.max).subtract(prevFilters?.days - 1, 'days').format('YYYY-MM-DD'),
                endDate: moment(dateRangeData.max).format('YYYY-MM-DD'),
            }));
        }
    }, [dateRangeData, filters?.days]);

    function onDropdownFilterChange(value, field) {
        setFilters({
            ...filters,
            [field]: value
        })
    }

    const handleRadioChange = (storeId, storeName) => {
        setFilters(prevState => {
            if (filters?.store_id && prevState?.store_id === storeId) {
                onDropdownFilterChange(null, 'store_id')
                setSelectedStore(null)
                return {
                    ...prevState,
                    store_id: null
                }
            } else {
                onDropdownFilterChange(storeId, 'store_id')
                setSelectedStore(storeName)
                return {
                    ...prevState,
                    store_id: storeId
                }
            }
        });
    };

    const coverageStartDate = dateRangeData?.max
        ? moment(dateRangeData.max).subtract(filters.days - 1, 'days').format('MMMM DD, YYYY')
        : '---';
    const coverageEndDate = dateRangeData?.max
        ? moment(dateRangeData.max).format('MMMM DD, YYYY')
        : '---';

    return <DashboardMetaProvider dashboardDefinition={dashboardDefinition}>
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#e0e0e0' },
            }}
        />
        <Grid container xs={12}>

            <Grid item xs={12} sx={{ display: !windowSize.isOnMobile && "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Grid item xs display="flex" sx={{ alignItems: "center" }}>
                    <Back />
                    <Typography variant="h5" ml={3}> Zero Sales </Typography>
                    <ZeroSalesToolTip />
                </Grid>
                <Grid item xs>
                    <AsOfDate />
                </Grid>

            </Grid>

            <Grid container >
                <Box flexGrow={1} xs={12} mb={1} sx={{ backgroundColor: '#f6f6f8', border: "1px solid #e0e0e0", borderRadius: ".5rem" }}>
                    <Grid container columnSpacing={1} rowSpacing={windowSize.isOnMobile ? 1.5 : .5} padding={1} paddingX={windowSize.isOnMobile && 1}>
                        <Grid item xs={12} sm={5} mx={1} >
                            <CompanyFilter
                                tableName={STORE_ZERO_SALES_TABLE}
                                filters={filters}
                                onPillSelectChange={onDropdownFilterChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} mx={1}>
                            <NumOfDaysFilter
                                filters={filters}
                                onFilterChange={onDropdownFilterChange}
                                isLoading={isDateRangeLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sm mx={1} gap={1} sx={{ display: !windowSize.isOnMobile && 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Typography sx={{ fontWeight: "bold" }}> Coverage Data: </Typography>
                            <Typography sx={{ textDecoration: "underline" }}>
                                {isDateRangeLoading ? 'Loading...' : `${coverageStartDate} - ${coverageEndDate}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            <ZeroSalesOverview filters={filters} handleRadioChange={handleRadioChange} setOverviewTableIsEmpty={setOverviewTableIsEmpty} />
            <StoreArticleZeroSales filters={filters} onFilterChange={setFilters} selectedStore={selectedStore} overviewTableIsEmpty={overviewTableIsEmpty}/>

        </Grid >
    </DashboardMetaProvider>

}