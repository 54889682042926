import { useQuery } from "react-query"
import ApiClient from "../../../common/API"


export function useIPRule(filters, useQueryOptions) {
    const query = useQuery(['ip-rule', filters], () => {
        return ApiClient().get("/ip-rule/", {
            params: {
                ...filters
            }
        }).then(response => response.data);
    }, {
        staleTime: 15 * 60 * 1000,
        cacheTime: 30 * 60 * 1000,
        ...useQueryOptions
    });
    return {
        data: query.data,
        isLoading: query.isLoading,
        refetch: query.refetch, // Expose refetch function
    };
}


export function useIPSet(filters, useQueryOptions) {
    const query = useQuery(['ip-set', filters], () => {
        return ApiClient().get("/ip-set/", {
            params: {
                ...filters
            }
        }).then(response => response.data);
    }, {
        staleTime: 15 * 60 * 1000,
        cacheTime: 30 * 60 * 1000,
        ...useQueryOptions
    });
    return {
        data: query.data,
        isLoading: query.isLoading,
        refetch: query.refetch, // Expose refetch function
    };
}

