import React from 'react';
import { useUserList } from '../../../../common/API/hooks';
import ContextBasedDropdownSelector from '../../../customDashboards/common/presentation/selectors/ContextBasedDropdownSelector';

export default function UserSelector() {
    
    const { data: userList, isLoading } = useUserList();
    
    const options = [
        { value: "All", label: "All" },
        ...(userList?.map(option => ({
            value: option.username,
            label: option.username,
        })) || [])
    ];
    
    return <ContextBasedDropdownSelector
        isLoading={isLoading}
        autoSelectFirstOption={false}
        options={options}
        disableClearable={false}
        label={"User List"}
        contextKey={"user"}
        defaultValue={"All"}
    />
}