import { useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import Back from "../../../common/components/BackNavigateComponent";
import IPSet from "./IPSet";
import IPRule from "./IPRule";


export default function WAFManagement() {
    const [filters, setFilters] = useState();
    const handleRadioChange = (ipSetId, ipSetName) => {
        setFilters(prevState => {
            if (filters?.id && prevState?.id === ipSetId) {
                return {
                    ...prevState,
                    id: null,
                    name: null
                }
            } else {
                return {
                    ...prevState,
                    id: ipSetId,
                    name: ipSetName
                }
            }
        })
    }
    return (
        <>
            <Grid sx={{ mt: 3, display: 'flex' }}>
                <Back />
                <Typography variant="h5" style={{ marginLeft: '16px' }}>
                    Web Application Firewall Management
                </Typography>
            </Grid>
            <Divider sx={{ my: 2, borderBottom: 3 }} />


            <Grid container xs={12} rowSpacing={5}>
                <Grid item xs={12}>
                    <IPSet filters={filters} handleRadioChange={handleRadioChange} />

                </Grid>
                <Grid item xs={12} >

                    <IPRule filters={filters} />

                </Grid>
            </Grid>

        </>
    )
}