import CustomerSegments from "./CustomerSegments";
import StoreCustomerActivityByBU from "./StoreCustomerActivityByBU";
import StoreCustomerActivityByHomeBranch from "./StoreCustomerActivityByHomeBranch";
import StoreCustomerRecency from "./StoreCustomerRecency";
import StoreSKUPerformanceBySales from "./StoreSKUPerformanceBySales";
import AverageSpendPerVisit from "./AverageSpendPerVisit";
import BlacklistableComponentWrapper from "../../common/providers/blacklistedComponentsProvider/BlacklistableComponentWrapper";
import { 
    COMPONENT_CUSTOMER_ACTIVITY_BY_HOMEBRANCH,
    COMPONENT_CUSTOMER_RECENCY,
    COMPONENT_CUSTOMER_ATV_BY_BASKET_TIER,
    COMPONENT_CUSTOMER_SEGMENTS,
    COMPONENT_CUSTOMER_ACTIVITY_BY_BU,
    COMPONENT_SKU_PERFORMANCE_BY_SALES,
} from "../dashboardDefinition";

export default function UsageContent() {

    return <>
        <BlacklistableComponentWrapper componentId={COMPONENT_CUSTOMER_ACTIVITY_BY_HOMEBRANCH}>
            <StoreCustomerActivityByHomeBranch/>
        </BlacklistableComponentWrapper>

        <BlacklistableComponentWrapper componentId={COMPONENT_CUSTOMER_RECENCY}>
            <StoreCustomerRecency/>
        </BlacklistableComponentWrapper>

        <BlacklistableComponentWrapper componentId={COMPONENT_CUSTOMER_ATV_BY_BASKET_TIER}>
            <AverageSpendPerVisit/>
        </BlacklistableComponentWrapper>

        <BlacklistableComponentWrapper componentId={COMPONENT_CUSTOMER_SEGMENTS}>
            <CustomerSegments/>
        </BlacklistableComponentWrapper>

        <BlacklistableComponentWrapper componentId={COMPONENT_CUSTOMER_ACTIVITY_BY_BU}>
            <StoreCustomerActivityByBU/>
        </BlacklistableComponentWrapper>

        <BlacklistableComponentWrapper componentId={COMPONENT_SKU_PERFORMANCE_BY_SALES}>
            <StoreSKUPerformanceBySales/>
        </BlacklistableComponentWrapper>
    </>
}