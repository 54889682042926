import React from 'react';
import Section from "../../../../../common/components/Dashboard/Section";
import { useSelectorValue } from "../../../common/providers/selectorProvider";
import { Grid } from '@mui/material';
import SegmentToggleSelector from './SegmentToggleSelector';
import CustomerSegmentsTable from './CustomerSegmentsTable';
import { useSMACCustomerSegmentDataBySegmentTypeTableData } from "../../hooks";
import moment from 'moment';
import { PERIOD_MONTHLY } from "../../../../../Constants/general";
import BusinessUnitSelector from "./BusinessUnitSelector";
import {Box} from "@mui/system";

export default function CustomerSegments() {
    const { data, isLoading } = useSMACCustomerSegmentDataBySegmentTypeTableData()

    const yearFromContext = useSelectorValue('year');
    const periodicityFromContext = useSelectorValue('periodicity');
    const period = useSelectorValue('period');
    const org = useSelectorValue('organization');
    const isRA = org === 'Retail Affiliates';
    const month = period === PERIOD_MONTHLY ?
        moment().month(periodicityFromContext - 1).format('MMMM') :
        ''

    const informationContent = <>
        <div>

            <strong>Card Type</strong> and <strong>Demographics</strong> are generated monthly.<br/>
            <strong>RFM Segments</strong> can be generated quarterly, semi-annual or yearly.<br/>
        </div>
        <div>
            <strong>Generation</strong> segments based on birth year:
            <div style={{ paddingLeft: "2em" }}>
                <div>Gen Z - 1997 - 2006</div>
                <div>Millennial - 1982 - 1996</div>
                <div>Gen X - 1965 - 1981</div>
                <div>Boomers - 1964 and earlier</div>
            </div>
        </div>
    </>

    return (
        <Section title={`${month} ${yearFromContext} Customer Segments`}
            information={informationContent}
            selectorsOrFilters={[
                <Box sx={{ paddingRight: '1em' }}>
                   { !isRA && <BusinessUnitSelector /> } 
                </Box>,
                <SegmentToggleSelector />
            ]}
        >
            <Grid item xs={12}>
                <CustomerSegmentsTable data={data} isLoading={isLoading} />
            </Grid>
        </Section>
    )
}