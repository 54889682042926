import { Button, Typography, TextField, Box, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import ActionModal from "../ActionModal";
import { useSave } from "../../utils";
import { toast } from "react-toastify";
import handleError from "../../../admin/ErrorHandling";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';


export default function AddIPSet() {
    const [open, setOpen] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const defaultValue = { ip_set_name: '', ip_addresses: [] };
    const [ipsetData, setIPSetData] = useState(defaultValue)
    const { ip_set_name, ip_addresses } = ipsetData;
    const { saveData: saveIPSet } = useSave()

    const handleSave = () => {
        const ipsetData = {
            ip_set_name: ip_set_name,
            ip_addresses: ip_addresses
        }
        saveIPSet('ip-set', ipsetData).then(() => {
            setIPSetData(defaultValue)
            setOpen(false)
            return toast.success("IP Set saved!")
        })
            .catch(error => {
                console.error(error);
                const errorMessage = handleError(error, "IP Set", "save");
                return toast.error(errorMessage);
            });
    }

    const handleWarningConfirmation = () => {
        setIPSetData(defaultValue)
        setShowWarningModal(false)
        setHasChanges(false)
        setOpen(false)
    }

    const handleIPSetNameChange = (e) => {
        setHasChanges(true);
        const { value } = e.target;
        setIPSetData((prevIPSetData) => ({
            ...prevIPSetData,
            ip_set_name: value,
        }));
    };

    const handleIPSetAddressesChange = (e) => {
        setHasChanges(true);
        const { value } = e.target;
        const ipArray = value.split(',')
        setIPSetData((prevIPSetData) => ({
            ...prevIPSetData,
            ip_addresses: ipArray,
        }));
    };

    const closeModal = () => {
        if (hasChanges) {
            setShowWarningModal(true)
        }
        else {
            setOpen(false);
        }
    };

    return (
        <>
            <Button
                startIcon={<AddIcon />}
                variant="contained"
                size="medium"
                sx={{ bgcolor: "#0A4FB4" }}
                onClick={() => setOpen(true)}
            >
                Add IP Rule
            </Button>
            <ActionModal
                action={"Add IP Set"}
                title={ip_set_name}
                open={open}
                close={closeModal}
                content={
                    <Box sx={{ justifyContent: 'center', mx: 2, mt: 2 }}>
                        <Typography> IP Set Name: </Typography>
                        <TextField variant="outlined" size="small" sx={{ width: '100%' }}
                            placeholder="Input IP Set Name"
                            value={ip_set_name}
                            onChange={handleIPSetNameChange}

                        >
                        </TextField>
                        <Typography mt={2}> IP Addresses: </Typography>
                        <TextField variant="outlined" size="small" sx={{ width: '100%' }} multiline maxRows={2}
                            placeholder="Input IP Addresses"
                            value={ip_addresses}
                            onChange={handleIPSetAddressesChange}

                        >
                        </TextField>
                    </Box >
                }
                button={
                    < Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#0A4FB4', fontWeight: 'bold', textTransform: 'none', width: '40%',
                            '&:hover': {
                                backgroundColor: '#0A4FB4',
                            }
                        }}
                        onClick={handleSave}
                    > Create </Button >
                }
            />
            {
                showWarningModal && (
                    <ActionModal
                        action={"Warning"}
                        open={showWarningModal}
                        close={() => setShowWarningModal(false)}
                        content={<Stack direction={'row'} sx={{ px: 3, pt: 2 }} spacing={2}>
                            <WarningRoundedIcon sx={{ fontSize: '50px' }} color="warning" />
                            <Typography sx={{ fontSize: 18, alignSelf: 'center', textAlign: 'center' }}>
                                There are unsaved changes. Are you sure you want to close the modal?
                            </Typography>
                        </Stack>
                        }
                        button={
                            <Button
                                onClick={handleWarningConfirmation}
                                variant="contained" sx={{
                                    backgroundColor: '#ed6c02', fontWeight: 'bold', textTransform: 'none', width: '40%',
                                    '&:hover': {
                                        backgroundColor: '#ed6c02',
                                    }
                                }}> Confirm </Button>
                        }
                        customWidthMD={4}
                    />
                )
            }
        </>
    )
}