
export const dashboardName = 'SM Store Operations'
export const DASHBOARD_PATH = 'sbu/sales'


export const dashboardDefinition = {
    name: dashboardName,
    entry_points: [
        'sbu/sales',
    ],
    owners: [
        "raphaelchristopher.a.sepnio@smretail.com"
    ],
    path: DASHBOARD_PATH
}