import { Grid, Paper, Typography } from "@mui/material";
import AddIPRule from "./AddIPRule";
import IPRuleTable from "./IPRuleTable";
import { useIPRule } from "../hooks";
import { useState, useEffect } from "react";

export default function IPRule({ filters }) {
    const [actionApply, setActionApply] = useState(false);
    const { data, isLoading, refetch } = useIPRule();

    useEffect(() => {
        if (actionApply && !isLoading) {
            refetch();
            setActionApply(false); // Reset actionApply after refetch
        }
    }, [actionApply, isLoading, refetch]);

    const filteredData = filters?.id
        ? data?.filter(item =>
            item.ip_sets?.some(ipSet => ipSet === filters.id)
        )
        : data;

    return (
        <>
            <Paper sx={{ border: 2, borderRadius: 3, borderColor: '#BDBDBD', backgroundColor: "#F3F3F3", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, p: 1 }}>
                <Grid container alignItems="center" justifyContent="space-between" display={{ xs: 'block', md: 'flex' }}>
                    <Grid item md={3.5} m={1} >
                        <Typography variant="h5"> IP Rules </Typography>
                    </Grid>
                    <Grid item m={1} >
                        <AddIPRule />
                    </Grid>
                </Grid>
                {(!filteredData && filteredData?.length === 0) ? (
                    <Grid xs={12} sx={{ bgcolor: '#FFFFFF', textAlign: 'center', my: 1 }}>
                        <Typography variant="h6">There are no available IP Rules.</Typography>
                    </Grid>
                ) : (
                    <IPRuleTable data={filteredData} isLoading={isLoading} setActionApply={setActionApply} />
                )}
                {(filteredData?.length === 0) &&
                    <Grid xs={12} sx={{ bgcolor: '#FFFFFF', textAlign: 'center', my: 1 }}>
                        <Typography variant="h6">IP Set <span style={{ color: '#0A4FB4', textDecoration: 'underline' }}>{filters?.name}</span> has not been applied to any IP Rules yet.</Typography>
                    </Grid>
                }
            </Paper >
        </>
    );
}
