export const VIEW_BY_USAGE = 'usage';
export const VIEW_BY_ACQUISITION = 'acquisition';
export const VIEW_BY_REGISTRATION = 'registration';

export const VIEW_BY = [
    { label: 'Acquisition', value: VIEW_BY_ACQUISITION },
    { label: 'Registration', value: VIEW_BY_REGISTRATION },
    { label: 'Usage', value: VIEW_BY_USAGE },
]


export const xAxisData = ['Start', 'Blue', 'Prestige'];
export const xAxisValuesOrder = ['Prestige', 'SMAC', 'Start'];
export const xAxisLabels = ['Prestige', 'Blue', 'Start'];
export const sortingOrders = ['Start', 'SMAC', 'Prestige'];

export const orderArray = [
    'New', 'Existing', 'Renewal',  // expected acquisition items
    'Registered', 'Unregistered',  // expected registration items
    'Active', 'Inactive'  // expected usage items
];

export const organizationOrder = [ 'SM Store', 'SM Markets', 'Retail Affiliates']

export const CHART_FONT_SIZE = "14px";