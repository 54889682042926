import moment from 'moment';
import { useFactTSSJoinQuery, useFactTSSQuery } from "./sales";
import React, { useMemo } from "react";
import {hasColumn, processBUDeptDict} from './utils';
import { useAggStoreBUJoinQuery } from './operations';
import { rollupDataBy } from '../../../../common/utils';
import { useSelectorValue } from "../../common/providers/selectorProvider";
import {useDashboardMetaContext} from "../../common/providers/DashboardMetaProvider";


export const useMaxRecordDateMoment = () => {
    const { asDateRange, isLoading } = usePeriodValues();

    if (!asDateRange || isLoading) {
        return {
            data: null,
            isLoading: isLoading
        }
    }
    return {
        data: moment(asDateRange.to, "YYYY-MM-DD"),
        isLoading: isLoading
    }
}


export const usePeriodValues = () => {
    const { data, isLoading, ...rest } = useFactTSSQuery({
        aggregates: [
            'transaction_date_max', 'transaction_date_min', 'load_date_max',
            'header_tran_date_max', 'header_tran_date_min',
        ],
    });
    const selectedTab = useSelectorValue('selected_tab') || 'sales';


    const asDateRange = React.useMemo(() => {
        if (!data || isLoading) return null;

        const dateKey = data[0]['transaction_date_max'] ? 'transaction_date' : 'header_tran_date';

        const toMoment = moment(data[0][`${dateKey}_max`], "YYYY-MM-DD");
        const endOfToMomentMonth = moment(data[0][`${dateKey}_max`], "YYYY-MM-DD").endOf('month');
        if (selectedTab === 'operations') {
            if (endOfToMomentMonth.isAfter(toMoment)) {
                return {
                    from: moment(data[0][`${dateKey}_min`], "YYYY-MM-DD"),
                    to: toMoment.subtract(1, 'month').endOf('month'),  // Subtract 1 month to get the last day of the previous month
                }
            }
        }

        return {
            from: moment(data[0][`${dateKey}_min`], "YYYY-MM-DD"),
            to: moment(data[0][`${dateKey}_max`], "YYYY-MM-DD"),
        }
    }, [data, isLoading, selectedTab]);

    return {
        ...rest,
        data,
        asDateRange,
        isLoading
    }
}

export const useBUDeptGroupings = () => {
    const salesFiltersFromDefinition = useDashboardMetaContext('sales_filters') || {};
    const orgEnvFilter = useOrgEnvFilterFromSourceSelector();

    const params = {
        ...salesFiltersFromDefinition,
        ...orgEnvFilter,
        columns: ['bu_grp', 'bu_desc', 'dept_desc_standardized'],
        group_by: ['bu_grp', 'bu_desc', 'dept_desc_standardized'],
        order_by: ['bu_grp', 'bu_desc', 'dept_desc_standardized'].join(','),

        limit: 150,
    }
    const hasSubDept = true

    const { data: salesDepts } = useFactTSSJoinQuery({ ...params }, { enabled: true }, hasSubDept);
    const { data: opsDepts } = useAggStoreBUJoinQuery({ ...params }, { enabled: true }, hasSubDept)

    // For union distinct of departments used by Sales and Ops tab
    const data = rollupDataBy([...(salesDepts || []), ...(opsDepts || [])], params.columns)

    const buDeptMaps = useMemo(() => {
        if (!data || data?.length < 1) {
            return {
                buGrpToBUDict: {},
                buToDeptDict: {},
                deptList: []
            }
        }

        const { bu_grp_to_bu, bu_to_dept, dept_list } = processBUDeptDict(data);

        return {
            buGrpToBUDict: bu_grp_to_bu,
            buToDeptDict: bu_to_dept,
            deptList: dept_list,
        }
    }, [data]);

    const buGroupData = rollupDataBy(data, ['bu_grp'])
    const buData = rollupDataBy(data, ['bu_desc']);
    const departmentData = rollupDataBy(data, ['dept_desc_standardized']);

    return {
        data,
        buGrpToBUDict: buDeptMaps['buGrpToBUDict'],
        buToDeptDict: buDeptMaps['buToDeptDict'],
        deptList: buDeptMaps['deptList'],
        buGroupData,
        buData,
        departmentData
        // ...rest
    }
}


export const createParentMapping = (data, parents = [], currentMapping = {}) => {
    return data.reduce((acc, row) => {
        const value = row['pk'];
        const pkKey = row['pkKey'];
        acc[`${pkKey}|${value}`] = parents;
        if (row['subRows'] && row['subRows'].length > 0) {
            const newParents = [...parents, { pk: value, pkKey: pkKey }];
            createParentMapping(row['subRows'], newParents, acc);
        }
        return acc
    }, currentMapping)
}

export const createParentMappingV2 = (data, parents = [], currentMapping = {}) => {
    if (!data || data.length < 1) {
        return [];
    }

    return data.reduce((acc, { bu_grp, bu_desc, dept_desc_standardized }) => {
        // Helper function to add unique parent objects
        const addUniqueParent = (key, parent) => {
            if (!acc[key]) {
                acc[key] = [];
            }
            if (!acc[key].some(item => item.pk === parent.pk && item.pkKey === parent.pkKey)) {
                acc[key].push(parent);
            }
        };

        addUniqueParent(`bu_desc|${bu_desc}`, { pk: bu_grp, pkKey: 'bu_grp' });
        addUniqueParent(`dept_desc_standardized|${dept_desc_standardized}`, { pk: bu_grp, pkKey: 'bu_grp' });
        addUniqueParent(`dept_desc_standardized|${dept_desc_standardized}`, { pk: bu_desc, pkKey: 'bu_desc' });

        // Ensure bu_grp key exists in the accumulator
        if (!acc[`bu_grp|${bu_grp}`]) {
            acc[`bu_grp|${bu_grp}`] = [];
        }

        return acc;
    }, {});
}


export const useStoreNames = () => {
    const branchNameColumn = useDashboardMetaContext('branch_name_column');
    const salesFiltersFromDefinition = useDashboardMetaContext('sales_filters') || {};
    const hasSubDept = hasColumn(salesFiltersFromDefinition, 'subdept_desc_standardized') ||
        hasColumn(salesFiltersFromDefinition, 'dept_desc_standardized') ||
        hasColumn(salesFiltersFromDefinition, 'bu_desc') ||
        hasColumn(salesFiltersFromDefinition, 'bu_grp');
    const orgEnvFilter = useOrgEnvFilterFromSourceSelector();

    const { data, ...rest } = useFactTSSJoinQuery({
        ...salesFiltersFromDefinition,
        ...orgEnvFilter,
        group_by: [branchNameColumn],
        limit: 1000
    }, {}, hasSubDept, true)

    return {
        data: data?.map(row => row[branchNameColumn]),
        ...rest
    }
}


export const useOrgEnvFilterFromSourceSelector = () => {
    const raSourceStandAlone = useSelectorValue('ra_source_stand_alone', true);
    const raSourceDSP = useSelectorValue('ra_source_dsp', true);
    const raSourceMarkets = useSelectorValue('ra_source_markets', true);

    if ((raSourceStandAlone && raSourceDSP && raSourceMarkets) || (!raSourceStandAlone && !raSourceDSP && !raSourceMarkets)) {
        return {}
    }

    const dspOrgEnvCode = ['DSP'];
    const marketsOrgEnvCode = ['RA_MKT', 'ESTORE'];
    const raOrgEnvCodes = [
        'FAP',
        'CBP',
		'SCP',
		'SAP',
		'WAP',
		'HWP',
		'SSP',
		'ECP',
		'TWP',
		'BSP',
		'NCP',
		'SHP',
		'MSP',
		'KSP',
		'MBP',
		'DYP',
		'HWP',
		'SMP',
		'SLP',
		'CMP',
		'UQO',
		'WZP'
    ]

    const orgEnvCode = [
        ...(raSourceStandAlone ? raOrgEnvCodes : []),
        ...(raSourceDSP ? dspOrgEnvCode : []),
        ...(raSourceMarkets ? marketsOrgEnvCode : [])
    ];

    return {
        org_env_code: orgEnvCode,
        org_code: orgEnvCode
    }
}