import React, { useEffect, useState } from 'react';
import { useSelectorContext, useSelectorState, useSelectorValue } from '../../common/providers/selectorProvider';
import FilterYear from '../../../../common/components/FilterYear';
import moment from 'moment';
import DropdownCheckboxV3 from '../../../../common/components/DropdownCheckboxV3';
import { getSelectedOption } from '../../../../common/utils';
import { useBUDeptGroupings } from '../hooks/values';


export default function DepartmentSelector({ departmentMeta }) {
    const { departmentData } = useBUDeptGroupings()
    const [selectedDepartments, setSelectedDepartments] = useSelectorState('dept_desc_standardized', []) || [];

    const metric = useSelectorValue('metric') || 'sales';
    const canUseSelector = metric !== 'catchment_rate' && metric !== 'conversion_rate';

    const selectedBUGrps = useSelectorValue('bu_grp');
    const selectedBUs = useSelectorValue('bu_desc');

    const filteredDept =   selectedBUGrps?.length || selectedBUs?.length
    ? departmentData.filter(({ bu_grp, bu_desc }) =>
        (selectedBUGrps?.length ? selectedBUGrps.includes(bu_grp) : true) &&
        (selectedBUs?.length ? selectedBUs.includes(bu_desc) : true)
    )
    : departmentData;

    const deptOptions = (filteredDept || [])
        .map((row) => {
            return {
                value: row?.dept_desc_standardized,
                label: row?.dept_desc_standardized,
            }
        })
        .filter((option) => option.value !== "" && option.label !== "")
        .sort((a, b) => a.label?.localeCompare(b.label));

    
    function handleSelectDept(data) {
        let result = data.map(a => a.value);
        setSelectedDepartments(result)
        return result
    }

    const displayedValue = canUseSelector ?
        getSelectedOption(selectedDepartments, deptOptions) :
        [];

    if (!deptOptions || deptOptions.length <= 1) {  // if we only have one option, don't show the selector
        return null
    }

    return (
        <DropdownCheckboxV3
            id="department_filter"
            options={deptOptions}
            label="Department"
            limitTags={2}
            value={displayedValue || []}
            onChange={(event, newValue) => {
                handleSelectDept(getSelectedOption(newValue, deptOptions))
            }}
            disabled={!canUseSelector}
        />
    )
}